import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Box, Button, Divider, FormLabel, IconButton, List, ListItem, ListItemIcon, FormControlLabel, ListItemText, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Tooltip, Zoom } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import PayrollCompositionTable from '../components/PayrollCompositionTable';
import PayrollGeneralViewTable from '../components/PayrollGeneralViewTable';
import PriceTable from '../components/PriceTable';
import QuantityTable from '../components/QuantityTable';
import QuantityVsPriceTable from '../components/QuantityVsPriceTable';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import moment from 'moment';
import 'moment/locale/pt-br';
import TableChartIcon from '@material-ui/icons/TableChart';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PeriodSelection from '../components/PeriodSelection';
import KeyboardShortcutsTooltip from '../components/KeyboardShortcutsTooltip';
import TableHelper from '../helper/TableHelper';
import TableEditingInput from '../components/TableEditingInput';
import ManualInputTable from '../components/ManualInputTable';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PermissionHelper from '../helper/PermissionHelper';
import Methods from '../constants/Methods';
import Operators from '../constants/Operators';
import PlanningHelper from '../helper/PlanningHelper';
import PlanningRowHelper from '../helper/PlanningRowHelper';
import DataHelper from '../helper/DataHelper';
import GeneralItemViewTable from '../components/GeneralItemViewTable';

const TYPE = 'people_expenses';
export default class PeopleExpensesPage extends Component {
    state = {
        tab: 0,
        docs: [],
        auxDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        editId: null,
        editDoc: {},
        editing: false,
        accountPlanDocs: [],
        resultCenterDocs: [],
        employeeDocs: [],
        productsDocs: [],
        periodDocs: [],
        editInputIndex: 0,
        editInputValue: 0,
        editingData: false,
        savingEdit: false,
        dataset: [],
        editingDataDoc: {},
        type: 'expense',
        editingField: ``,
        description: ``,
        method: ``,
        loadingText: 'Carregando Despesas de Pessoal...',
        start: moment(),
        end: moment().add(3, 'month'),
        id_period: null,
        applyToAllMonths: false,
        targetAccountPlan: null,
        products: [],
        allRevenue: false,
        expensesData: {},
        employeeExists: false,
        employee: ``,
        resultCenter: null,
        selectedPeriod: [],
    }

    async getPlanningElement() {
        this.setState({ loading: true });

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');
        let query = Firestore.customQuery('planning').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', TYPE);

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (isSuperAdmin || this.state.accountPlanDocs.find(item => item.id === data.id_target_account_plan) || (data.id_result_center && this.state.resultCenterDocs.find(item => item.id === data.id_result_center))) {       
                docs.push(data);
            }
        });

        this.setState({ docs: docs, auxDocs: docs, loading: false });

        await this.filterPlanningElement();
    }

    async filterPlanningElement() {

        this.setState({ loading: true });

        let docs = [];
        let start = null;
        let end = null;

        this.state.selectedPeriod.forEach((id, key) => {

            let period = this.state.periodDocs.find(item => item.id === id);
            let periodStart = moment(period.start.toDate ? period.start.toDate() : period.start).startOf('month').startOf('day');
            let periodEnd = moment(period.end.toDate ? period.end.toDate() : period.end).endOf('month').endOf('day');

            if (start === null || periodStart.isBefore(start)) {

                start = periodStart;
            }

            if (end === null || periodEnd.isAfter(end)) {

                end = periodEnd;
            }
        });

        if (start && end) {

            this.state.auxDocs.forEach((doc, key) => {

                let docStart = moment(doc.start.toDate ? doc.start.toDate() : doc.start);
                let docEnd = moment(doc.end.toDate ? doc.end.toDate() : doc.end);

                if (docStart.isSameOrAfter(start, 'date') && docEnd.isSameOrBefore(end, 'date')) {

                    docs.push(doc);
                }
            });

            this.setState({ docs, loading: false });

        } else {

            await this.getPlanningElement();
        }
    }

    async getPeriods() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        let selectedPeriod = [];
        selectedPeriod.push(docs[0].id);

        this.setState({ periodDocs: docs, loading: false, selectedPeriod });
    }

    async updatePeopleExpenses() {
        this.setState({ updatingDb: true });

        const rows = DataHelper.queryToArray(await Firestore.customQuery('planning_row').where('type', '==', 'people_expenses').where('id_company', '==', SessionHelper.getData().id_company).get());
        const query = DataHelper.queryToArray(await Firestore.customQuery('planning').where('table_type', '==', 'payroll_composition').where('id_company', '==', SessionHelper.getData().id_company).get());

        let people = [];

        query.forEach(doc => {
            if (doc.components) doc.components.forEach(row => { people.push({ ...row, id_parent: doc.id }) });
        });

        for (let row of rows) {
            if (row.method == Methods.multiply.key) {
                let uniquePeople = [];
                let uniquePeopleHistory = [];

                let multiplier = 0;
                let multiplier_history = 0;

                people.forEach(person => {
                    let isHiredInMonth = person.hire_date && !person.fire_date ? moment(row.date.toDate()).isSameOrAfter(person.hire_date.toDate()) : person.hire_date && person.fire_date ? moment(row.date.toDate()).isBetween(person.hire_date.toDate(), person.fire_date.toDate(), 'month', '[)') : false;
                    let isHiredInHistoryMonth = person.hire_date && !person.fire_date ? moment(moment(row.date.toDate()).subtract(1, 'years')).isSameOrAfter(person.hire_date.toDate()) : person.hire_date && person.fire_date ? moment(moment(row.date.toDate()).subtract(1, 'years')).isBetween(person.hire_date.toDate(), person.fire_date.toDate(), 'month', '[)') : false;

                    if (isHiredInMonth && !uniquePeople.includes(person.id)) {
                        uniquePeople.push(person.id);
                        multiplier += 1;
                    }
                    if (isHiredInHistoryMonth && !uniquePeopleHistory.includes(person.id)) {
                        uniquePeopleHistory.push(person.id);
                        multiplier_history += 1;
                    }
                });

                Firestore.update({ multiplier, multiplier_history }, 'planning_row', row.id);
            }

            if (row.method == Methods.absolute.key && row.relationship.id_component) {
                let value = 0;

                people.forEach(person => {
                    if (row.relationship.id_component && person.id_parent == row.id_parent && person.id == row.relationship.id_component) {
                        let isHiredInMonth = person.hire_date && !person.fire_date ? moment(row.date.toDate()).isSameOrAfter(person.hire_date.toDate()) : person.hire_date && person.fire_date ? moment(row.date.toDate()).isBetween(person.hire_date.toDate(), person.fire_date.toDate(), 'month', '[)') : false;
                        let hasPromotion = person.promotion && person.promotion_date ? moment(row.date.toDate()).isSameOrAfter(person.promotion_date.toDate()) : false;
                        let hasDissidio = person.dissidio && person.dissidio_date ? moment(row.date.toDate()).isSameOrAfter(person.dissidio_date.toDate()) : false;

                        if (isHiredInMonth) {
                            if (person.salary) value = person.salary;
                            if (hasPromotion) value += (person.salary * (person.promotion / 100));
                            if (hasDissidio) value += (person.salary * (person.dissidio / 100));
                        } else {
                            value = 0;
                        }
                    }
                });

                Firestore.update({ value }, 'planning_row', row.id);
            }
        }


        this.setState({ updatingDb: false });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async getAccountPlanDocs() {
        this.setState({ loading: true });

        let docs = await PermissionHelper.getUserAccountPlans(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        this.setState({ accountPlanDocs: docs, loading: false });
    }

    async getResultCenterDocs() {

        this.setState({ loading: true });

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        this.setState({ resultCenterDocs: docs, loading: false });
    }

    async getEmployeeDocs() {
        this.setState({ loading: true });

        let query = await Firestore.customQuery('employee').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ employeeDocs: docs, loading: false });
    }

    async getDocs() {
        await this.getAccountPlanDocs();
        await this.getResultCenterDocs();
        await this.getEmployeeDocs();
        await this.getPeriods();
        await this.getPlanningElement();
    }

    async addNewDoc() {
        if (this.state.loadingModal) return;

        if (this.state.description && this.state.targetAccountPlan && this.state.method && moment(this.state.start).isValid() && moment(this.state.end).isValid() && SessionHelper.getData().id_company) {
            this.setState({ loadingModal: true });

            const start = moment(this.state.start);
            const end = moment(this.state.end);

            let insertInfo = await PlanningHelper.insertPlanning(
                {
                    type: TYPE,
                    description: this.state.description,
                    start: start.toDate(),
                    end: end.toDate(),
                    operator: Operators.minus.key,
                    table_type: this.state.table_type,
                    method: this.state.method,
                    id_target_account_plan: this.state.targetAccountPlan,
                    id_result_center: this.state.resultCenter,
                    id_period: this.state.id_period
                }
            );

            if (insertInfo.success && this.state.table_type != 'payroll_composition') {
                PlanningRowHelper.insertRow(
                    {
                        type: TYPE,
                        operator: Operators.minus.key,
                        method: this.state.method,
                        id_parent: insertInfo.insertId,
                        relationship: {
                            id_target_account_plan: this.state.targetAccountPlan,
                            id_result_center: this.state.resultCenter,
                        }
                    },
                    start,
                    end
                );
            }

            await this.getPlanningElement();

        } else {
            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false, addModal: false, description: ``, start: moment(), end: moment().add(3, 'month'), accountPlans: [], method: '', rows: [],
        });
    }

    addModal = () => {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.table_type}
                        onChange={(v) => {
                            if (v.target.value == 'payroll_composition') {
                                this.setState({ method: Methods.absolute.key, table_type: 'payroll_composition' });
                            } else {
                                this.setState({ method: v.target.value, table_type: v.target.value });
                            }

                        }}
                        docs={Object.keys(this.getMethodologyLookup()).map(key => ({ name: this.getMethodologyLookup()[key], key }))}
                        label={'Metodologia de Planejamento*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.targetAccountPlan}
                        onChange={(v) => {
                            this.setState({ targetAccountPlan: v.target.value })
                        }}
                        docs={this.state.accountPlanDocs}
                        label={'Conta Contábil (Destino)'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.resultCenter}
                        onChange={(v) => {
                            this.setState({ resultCenter: v.target.value })
                        }}
                        docs={this.state.resultCenterDocs}
                        label={'Centro de Resultado'}
                    />
                </div>

                <PeriodSelection periods={this.state.periodDocs} onStartChange={(v) => { this.setState({ start: v }) }} onEndChange={(v) => { this.setState({ end: v }) }} onDocChange={(id) => { this.setState({ id_period: id }) }}/>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.addNewDoc() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false, description: ``, start: moment(), end: moment().add(3, 'month') }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    addEmployeeModal = () => {
        return (
            <div>
                <FormControlLabel
                    style={{ marginTop: 15 }}
                    control={<Checkbox checked={this.state.employeeExists} onChange={(v) => { this.setState({ employeeExists: v.target.checked, description: ``, employee: `` }) }} />}
                    label={'É um colaborador existente no sistema? (Preenchimento Automático)'}
                />

                {!this.state.employeeExists ?
                    <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />
                    :
                    <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                        <DefaultSelect
                            multiple={false}
                            disabled={false}
                            search={true}
                            searchField={'name'}
                            displayField={'name'}
                            valueField={'id'}
                            value={this.state.employee}
                            onChange={(v) => {
                                this.setState({ employee: v.target.value });
                            }}
                            docs={this.state.employeeDocs}
                            label={'Colaborador*'}
                        />
                    </div>
                }


                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.insertEmployeeIntoPayroll() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addEmployeeModal: false, description: `` }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    async insertEmployeeIntoPayroll() {
        let employeeExists = this.state.employeeExists;

        if ((!this.state.description && !employeeExists) || (!this.state.employee && employeeExists)) {
            toast.warn("Preencha a descrição ou selecione um colaborador", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({ loadingModal: true });

        let description = this.state.description || ``, salary = null, role = ``, employee_id = null;

        let id_component = await Firestore.getId(`planning_components`);

        if (employeeExists) {
            employee_id = this.state.employee;
            id_component = employee_id;

            let employee = (await Firestore.getDoc('employee', employee_id)).data();
            if (employee.name) description = employee.name;
            if (employee.salary) salary = employee.salary / 100;
            if (employee.role) role = ((await Firestore.getDoc('role', employee.role)).data()).description;
        }

        const start = moment(this.state.editDoc.start.toDate());
        const end = moment(this.state.editDoc.end.toDate());

        const component = {
            id: id_component,
            description,
            role,
            salary,
            dissidio: null,
            dissidio_date: null,
            promotion: null,
            promotion_date: null,
            fire_date: null,
            hire_date: null
        };

        let components = this.state.editDoc.components || [];
        components.push(component);

        await PlanningHelper.updatePlanning({ components }, this.state.editDoc.id);

        PlanningRowHelper.insertRow(
            {
                type: TYPE,
                operator: this.state.editDoc.operator,
                method: this.state.editDoc.method,
                id_parent: this.state.editDoc.id,
                relationship: {
                    id_target_account_plan: this.state.editDoc.id_target_account_plan,
                    id_result_center: this.state.editDoc.id_result_center,
                    id_component
                }
            },
            start,
            end
        );

        await this.setState({ loadingModal: false, editDoc: { ...this.state.editDoc, components }, addEmployeeModal: false });
        await this.setEditingDocs(this.state.editDoc, true);
    }

    getMethodologyLookup() {
        return {
            'payroll_composition': 'Composição da Folha Salarial',
            'multiply': Methods.multiply.label,
            'absolute': Methods.absolute.label
        }
    }

    renderAccountPlan(account) {
        let doc = this.state.accountPlanDocs.find(item => item.id === account);
        let text = doc ? doc.description : '';

        return text;
    }

    getProductsLookup(hideCode) {
        let docs = this.state.productsDocs;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = `${doc.description}${hideCode ? `` : ` (${doc.code})`}`;
        });

        return lookup;
    }

    async setEditingDocs(rowData, firstLoad = false) {
        if (firstLoad) this.setState({ loadingText: `Obtendo dados do registro...`, loading: true });

        let editDocRows = await PlanningRowHelper.getRowsByParentId(rowData.id);

        this.setState({ editId: rowData.id, editDoc: rowData, editDocRows });

        if (firstLoad) this.setState({ tab: 0, loading: false, editing: true });

        await this.updatePeopleExpenses();
    }

    renderGrid() {
        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        { PlanningHelper.renderPlanningPeriodSelection(this.state.periodDocs, this.state.selectedPeriod, async (selectedPeriod) => { await this.setState({ selectedPeriod }); await this.filterPlanningElement(); }) }
                    </div>
                </div> 

                <DefaultTable
                    marginTop={10}
                    title={'Despesas de Pessoal'}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Metodologia de Planejamento', field: 'table_type', lookup: this.getMethodologyLookup() },
                        { title: 'Conta Contábil (Destino)', field: 'id_target_account_plan', editComponent: props => PlanningHelper.renderAccountEdit(props, this.state.accountPlanDocs), render: rowData => <div>{PlanningHelper.renderAccountPlan(rowData.id_target_account_plan, this.state.accountPlanDocs)}</div> },
                        { title: 'Centro de Resultado', field: 'id_result_center', editComponent: props => PlanningHelper.renderResultCenterEdit(props, this.state.resultCenterDocs), render: rowData => <div>{PlanningHelper.renderResultCenter(rowData.id_result_center, this.state.resultCenterDocs)}</div> },
                        { title: 'Sinal', field: 'operator', render: rowData => rowData.operator === Operators.sum.key ? Operators.sum.label : Operators.minus.label, lookup: PlanningRowHelper.getOperatorLookup() },
                        { title: 'Período Inícial', field: 'start', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                        { title: 'Período Final', field: 'end', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                    ]}
                    actions={[
                        {
                            icon: TableChartIcon,
                            tooltip: 'Abrir Despesa de Pessoal',
                            onClick: async (event, rowData) => { await this.setEditingDocs(rowData, true); await this.setState({ tab: 0 }); }
                        },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if (newData.start && newData.end) {
                            newData.start = newData.start.seconds ? new Date(newData.start.seconds * 1000) : newData.start.toDate ? newData.start.toDate() : newData.start;
                            newData.end = newData.end.seconds ? new Date(newData.end.seconds * 1000) : newData.end.toDate ? newData.end.toDate() : newData.end;
                        }

                        
                        if (newData.id_target_account_plan) {

                            newData.id_result_center = newData.id_result_center ? newData.id_result_center : null;
                            newData.method = newData.table_type;
                            
                            await PlanningHelper.updatePlanning({ description: newData.description, operator: newData.operator, id_target_account_plan: newData.id_target_account_plan, id_result_center: newData.id_result_center, table_type: newData.table_type, start: newData.start, end: newData.end }, oldData.id);

                            let prev = this.state.docs;
                            const index = prev.indexOf(oldData);
                            prev[index] = { ...oldData, description: newData.description, operator: newData.operator, id_target_account_plan: newData.id_target_account_plan, id_result_center: newData.id_result_center, table_type: newData.table_type, start: newData.start, end: newData.end };
                            
                            this.setState({ docs: prev });
    
                            return prev;

                        } else {

                            toast.warn('A Conta de Destino não pode ser vazia');
                            return;
                        }
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);
                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        await PlanningHelper.deletePlanning(oldData.id);
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Despesa de Pessoal'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />

            </div>
        );
    }

    TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                style={{ maxHeight: '100%', overflowY: 'auto' }}
                {...other}
            >
                {value === index && (
                    <Box>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    renderFixedCostTable(rows, method) {
        return (
            <div style={styles.tableContainer}>
                <ManualInputTable rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderFixedCostTableTabs() {
        return (
            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <Box boxShadow={1}>
                    <Tabs
                        style={{ backgroundColor: '#fff' }}
                        value={this.state.tab}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: Colors.primary } }}
                        onChange={(event, index) => { this.setState({ tab: index }) }}
                    >
                        <Tab label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary }}>Despesas de Pessoal</span>} />
                    </Tabs>

                    <TableHelper.TabPanel value={this.state.tab} index={0}>
                        {this.renderFixedCostTable(this.state.editDocRows, this.state.editDoc.method)}
                    </TableHelper.TabPanel>
                </Box>
            </div>
        )
    }

    renderNotSelected() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', alignItems: 'center', flex: 1, width: '100%', height: '100%' }}>
                <img style={{ height: 360, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }} src={process.env.PUBLIC_URL + '/spreadsheet.png'} />

                <div style={{ textAlign: 'center', fontSize: 18, color: Colors.primary, marginTop: 35, fontWeight: '600', maxWidth: '50%' }}>Adicione um novo colaborador, ou possível contratação da folha salarial em "<AddIcon style={{ color: Colors.primary, fontSize: 18 }} /> Adicionar Colaborador" para editar e visualizar os custos da folha salarial.</div>
                <div style={{ textAlign: 'center', fontSize: 16, color: Colors.primary, marginTop: 10, fontWeight: '400', maxWidth: '50%' }}>Clique em "<TableChartIcon style={{ color: Colors.primary, fontSize: 16 }} /> Resumo de Custos" para ter uma visão geral dos custos.</div>
            </div>
        )
    }

    renderPayrollCompositionTable() {
        return (
            <div style={styles.tableContainer}>
                <PayrollCompositionTable rows={this.state.editDoc.components} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderPayrollEditing} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[this.state.editDoc.table_type])}
            </div>
        );
    }

    renderGeneralViewTable() {
        return (
            <div style={{ width: '100%' }}>
                <PayrollGeneralViewTable jumpToItem={() => { this.setState({ showGeneralView: false }) }} rows={this.state.editDocRows} state={this.state} editDoc={this.state.editDoc} />
            </div>
        );
    }

    renderPayrollComposition() {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: -40 }}>
                    <DefaultButton color={Colors.contrast} onClick={() => { this.setState({ addEmployeeModal: true }) }} title={'Adicionar Colaborador'} leftIcon={<AddIcon />} />
                    <DefaultButton
                        disabled={this.state.updatingDb}
                        color={this.state.editDocRows.length > 0 ? Colors.contrast : Colors.disabled}
                        onClick={async () => {
                            await this.setEditingDocs(this.state.editDoc, false);

                            if (this.state.editDocRows.length > 0) {
                                this.setState({ showGeneralView: !this.state.showGeneralView });
                            }
                        }}
                        title={'Resumo de Custos'}
                        leftIcon={this.state.showGeneralView ? <VisibilityOffIcon /> : <TableChartIcon />}
                    />
                </div>


                <div style={styles.plannigContainer}>
                    {this.state.editDocRows.length > 0 || this.state.showGeneralView ?
                        <div style={{ width: '100%', paddingLeft: 10, paddingTop: 10 }}>
                            {this.state.showGeneralView ? this.renderGeneralViewTable() : this.renderPayrollCompositionTable()}
                        </div>
                        :
                        <div style={{ width: '100%', paddingLeft: 10, paddingTop: 30 }}>
                            {this.renderNotSelected()}
                        </div>
                    }
                </div>
            </div>

        );
    }

    renderUnitaryCostTableTabs() {
        return (
            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <Box boxShadow={1}>
                    <Tabs
                        style={{ backgroundColor: '#fff' }}
                        value={this.state.tab}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: Colors.primary } }}
                        onChange={(event, index) => { this.setState({ tab: index }) }}
                    >
                        <Tab label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary }}>Despesa por Colaborador</span>} />
                        <Tab label={<span style={{ fontWeight: this.state.tab === 1 ? 'bold' : '', color: Colors.primary }}>Quantidade de Colaboradores Ativos</span>} />
                        <Tab label={<span style={{ fontWeight: this.state.tab === 2 ? 'bold' : '', color: Colors.primary }}>Custo Total</span>} />
                    </Tabs>

                    <TableHelper.TabPanel value={this.state.tab} index={0}>
                        {this.renderUnitaryCostPriceTable(this.state.editDocRows, this.state.editDoc.method)}
                    </TableHelper.TabPanel>
                    <TableHelper.TabPanel value={this.state.tab} index={1}>
                        {this.renderUnitaryCostQuantityTable(this.state.editDocRows, this.state.editDoc.method)}
                    </TableHelper.TabPanel>
                    <TableHelper.TabPanel value={this.state.tab} index={2}>
                        {this.renderUnitaryCostTable(this.state.editDocRows, this.state.editDoc.method)}
                    </TableHelper.TabPanel>
                </Box>
            </div>
        )
    }

    renderUnitaryCostTable(rows, method) {
        return (
            <div style={styles.tableContainer}>
                <QuantityVsPriceTable rows={rows} moreIsBetter={false} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderUnitaryCostPriceTable(rows, method) {
        return (
            <div style={styles.tableContainer}>
                <PriceTable rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} moreIsBetter={false} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderUnitaryCostQuantityTable(rows, method) {
        return (
            <div style={styles.tableContainer}>
                <QuantityTable rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={() => { }} moreIsBetter={true} hideTotal={true} readOnly={true} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderTableTabs() {
        switch (this.state.editDoc.table_type) {
            case 'payroll_composition':
                return this.renderPayrollComposition();
            case Methods.multiply.key:
                return this.renderUnitaryCostTableTabs();
            case Methods.absolute.key:
                return this.renderFixedCostTableTabs();
            default:
                return null;
        }
    }

    renderEditing() {
        return (
            <div style={styles.plannigContainerWrapper}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 20, justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <DefaultButton onClick={() => { this.setState({ editId: null, editDoc: {}, editing: false, selectedDeduction: {}, tab: 0, showGeneralView: false }) }} title={'Voltar'} leftIcon={<ChevronLeftIcon />} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300, textAlign: 'center' }}>
                        <FormLabel style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1 }}>{this.state.editDoc.description}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 2, fontWeight: 200 }}>Despesa de Pessoal</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Conta Contábil (Destino): {this.renderAccountPlan(this.state.editDoc.id_target_account_plan)}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Centro de Resultado: {PlanningHelper.renderResultCenter(this.state.editDoc.id_result_center, this.state.resultCenterDocs)}</FormLabel>
                    </div>

                    <KeyboardShortcutsTooltip />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', maxHeight: '100%' }}>
                    <div style={styles.plannigContainer}>
                        {this.renderTableTabs()}
                    </div>
                </div>

                <DefaultModal loading={this.state.loadingModal} content={this.addEmployeeModal()} title={'Adicionar colaborador ao planejamento'} onClose={() => { this.setState({ addEmployeeModal: false }) }} open={this.state.addEmployeeModal} />
            </div>
        )
    }

    saveEdit = async (percentage, saveEntireRow) => {
        try {
            this.setState({ savingEdit: true });

            if (this.state.rowId) {
                let editInputValue = Number(this.state.editInputValue);

                if (percentage == true) {
                    if (editInputValue > 100) editInputValue = 100;
                }

                let saveIds = [];

                if (saveEntireRow) {
                    this.state.editingRows.forEach(row => saveIds.push(row.id));
                } else {
                    saveIds = [this.state.rowId];
                }

                for (let id of saveIds) {
                    let data = this.state.editDocRows.find(item => item.id === id);
                    data[this.state.editingField] = editInputValue;

                    await PlanningRowHelper.updateRow(data, id);
                }

                this.setState({ editingData: false, editInputValue: editInputValue });
            } else {
                throw new Error();
            }
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.setState({ savingEdit: false });
    }

    savePayrollEdit = async (percentage, saveEntireColumn) => {
        let value;

        if (this.state.editInputType == `date`) {
            value = this.state.editInputValue;
        } else if (this.state.editInputType == `string`) {
            value = `${this.state.editInputValue}`;
        } else {
            value = Number(this.state.editInputValue);

            if (percentage == true) {
                if (value > 100) value = 100;
            }

            if (value < 0) value *= -1;
        }

        if (saveEntireColumn) {
            this.state.editDoc.components.forEach(row => {
                row[this.state.editingElement] = value;
            });
        } else {
            this.state.editDoc.components[this.state.rowIndex][this.state.editingElement] = value;
        }


        try {
            this.setState({ savingEdit: true });
            await PlanningHelper.updatePlanning({ components: this.state.editDoc.components }, this.state.editDoc.id);
            await this.updatePeopleExpenses();
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.setState({ editingData: false, editInputValue: value, editInputIndex: 0, rowIndex: 0, savingEdit: false });
    }

    renderPayrollEditing = (defaultValue, type) => {
        return <TableEditingInput
            state={this.state}
            setParentState={this.setParentState}
            defaultValue={defaultValue}
            percentage={type == 'percentage'}
            saveEdit={this.savePayrollEdit}
            type={type}
            maxDate={this.state.editDoc.end}
        />
    }

    renderInputEditing = (defaultValue, percentage) => {
        return (
            <TableEditingInput state={this.state} setParentState={this.setParentState} defaultValue={defaultValue} percentage={percentage} saveEdit={this.saveEdit} />
        );
    }

    setParentState = data => {
        this.setState(data);
    }
    renderContent() {
        return this.state.editing ? this.renderEditing() : this.renderGrid();
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : this.renderContent();
    }
}

const styles = {
    container: {
        padding: 25,
    },
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        borderRadius: 5
    },
    plannigContainerWrapper: {
        width: '100%',
        flexDirection: 'column',
        flex: 1,
        padding: 25,
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainerColumns: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: 0,
        width: '100%',
        maxHeight: '100%',
        height: 'auto',
        backgroundColor: Colors.background
    },
    listWrapper: {
        width: 280,
        borderRadius: 5,
        maxHeight: '100%',
        backgroundColor: `#ffffff`
    },
}
