import React from "react";
import NotificationHelper from "../helper/NotificationHelper";

export default class Comment extends React.Component {

    render() {

        let comment = this.props.text;
        comment = NotificationHelper.parseComment(comment);

        return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} dangerouslySetInnerHTML={{ __html: comment }}/>;
    }
}