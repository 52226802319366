import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import { withRouter } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import CheckRoundedIcon from '@material-ui/icons/CheckCircle';
import { toast, ToastContainer } from 'react-toastify';
import Auth from '../api/firebase/Auth';
import DefaultLoader from '../components/DefaultLoader';

class LoginPage extends Component {

    state = {
        loading: false,
        error: false,
        email: '',
        success: false,
    }

    renderLoading() {
        return <DefaultLoader css={{ position: 'absolute', top: '38vh', left: '49%' }} />
    }

    renderSuccess() {

        return (
            <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', width: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{paddingBottom: 70, paddingTop: 15, height: 64}} src={`${process.env.PUBLIC_URL}/logo-dark.png`} /> 
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold', color: 'green' }}>
                    <CheckRoundedIcon style={{ marginRight: 5, fontSize: 26 }}/>
                    <div style={{ fontSize: 20 }}>{'E-mail de verificação enviado!'}</div>
                </div>
                <div style={{ color: 'grey', fontSize: 14, marginTop: 20 }}>{'O link enviado possui validade de 1 hora.'}</div>
                <div style={{ color: 'grey', fontSize: 14, marginTop: 10 }}>{'Verifique o seu e-mail.'}</div>
                <Button onClick={() => { window.location.href = '/' }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >VOLTAR</Button>
            </Card>
        )
    }

    async sendEmail() {

        if (this.state.error) {

            toast.warn('Preencha todos os campos corretamente');

        } else {

            try {

                await this.setState({ loading: true });

                await Auth.resetPassword(this.state.email);

                await this.setState({ loading: false, success: true });

            } catch (error) {

                this.setState({ loading: false });
                toast.error('Houve um problema ao enviar o e-mail');
            }
        }
    }

    handleShortcut = (evt) => {
        if (evt.key === "Enter") {
            this.sendEmail();
        }
    }

    renderEmail() {

        return (
            <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', width: 400, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                <img style={{ paddingBottom: 70, paddingTop: 15, height: 64, }} src={`${process.env.PUBLIC_URL}/logo-dark.png`} />
                <form style={{ width: 400 }}>
                    <DefaultInput onKeyDown={this.handleShortcut} onError={(error, msg) => { this.setState({ error: error }) }} required={true} email={true} value={this.state.email} onChange={(text) => { this.setState({ email: text }) }} label={'Informe o seu E-mail'} />
                    <Button onClick={() => { this.sendEmail() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >ENVIAR E-MAIL</Button>
                </form>
                <a href={'/'} className={'register-link'}>{'Voltar ao Login'}</a>
            </Card>
        )
    }

    render() {
        return (
            <Container style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'auto' }} maxWidth={false}>
                <div style={{height: '100vh'}}>
                    { this.state.success ? this.renderSuccess() : this.renderEmail() }
                    {this.state.loading ? this.renderLoading() : null}
                </div>
                <ToastContainer style={{}}/>
            </Container>
        );
    }
}

export default withRouter(LoginPage);
 