import React, { Component } from 'react';
import { IconButton, Tooltip, Zoom } from '@material-ui/core';
import Colors from '../constants/Colors';
import KeyboardIcon from '@material-ui/icons/Keyboard';

export default class KeyboardShortcutsTooltip extends Component {
    render() {
        return (
            <Tooltip TransitionComponent={Zoom}
                title={this.props.text ? this.props.text : <div>
                    <p><a style={{ fontWeight: 'bold' }}>Duplo Clique:</a> Um duplo clique numa célula a edita, se a mesma for editável</p>
                    <p><a style={{ fontWeight: 'bold' }}>Enter:</a> Salva a célula selecionada</p>
                    <p><a style={{ fontWeight: 'bold' }}>Shift + Enter:</a> Salva todas as células de uma linha com o valor que está sendo editado.</p>
                    <p><a style={{ fontWeight: 'bold' }}>Esc:</a> Cancela a ação.</p>
                </div>}
                aria-label="add">
                <IconButton color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true">
                    <KeyboardIcon style={{ color: Colors.primary }} />
                </IconButton>
            </Tooltip>
        );
    }
}