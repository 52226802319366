import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, InputLabel, Tooltip, IconButton } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';
import SessionHelper from '../helper/SessionHelper';
import IosSwitch from '../components/IosSwitch';
import ProfileCard from '../components/ProfileCard';
import Add from '@material-ui/icons/AddBox';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AccountPlanHelper from '../helper/AccountPlanHelper';

export default class AccountPackagePage extends Component {

    state = {
        docs: [],
        accountPlans: [],
        selectedAccountPlans: [],
        permissionDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        confirmModal: false,
        editId: null,
        description: null,
        code: null,
        observation: '',
        loadingText: '',
        parentId: false,
        parentName: '',
        initialRecursiveId: '',
        readUsers: [
            SessionHelper.getFirebaseAuth().uid
        ],
        writeUsers: [
            SessionHelper.getFirebaseAuth().uid
        ],
        responsable: [SessionHelper.getFirebaseAuth().uid],
        allWrite: false,
        allRead: false,
        loading: true,
        loadingModal: false,
        addModal: false,
        permissionModal: false,
        currentPermissionDoc: {},
        allReadEdit: false,
        allWriteEdit: false,
        responsableEdit: null,
    }

    async getDocs() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company);
        query = await query.orderBy('description', 'asc').get();

        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        await this.setState({ docs: docs });

        await this.getAccountPlans();
    }

    async getUsers() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ users: docs, loading: false });
    }

    async getAccountPlans() {
        this.setState({ loading: true });

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        let idArray = [];
        docs.forEach(account_plan => idArray.push(account_plan.id));

        let refDocs = [];

        for (let i = 0; i < this.state.docs.length; i++) {
            let account_package = this.state.docs[i];

            if (account_package.account_plans && account_package.account_plans.length > 0) {
                for (let j = 0; j < account_package.account_plans.length; j++) {
                    let account_plan = account_package.account_plans[j];

                    if (!idArray.includes(account_plan)) {
                        let newAccountPackageDoc = {};
                        Object.assign(newAccountPackageDoc, account_package)

                        newAccountPackageDoc.account_plans.splice(j, 1);
                        delete newAccountPackageDoc.id;
                        delete newAccountPackageDoc.tableData;

                        await Firestore.update(newAccountPackageDoc, 'account_package', account_package.id);
                    } else {
                        docs.forEach(account_plan_doc => {
                            let accountPlanRef = {};
                            Object.assign(accountPlanRef, account_plan_doc)

                            if (account_plan_doc.id == account_plan) {
                                accountPlanRef.parent_id = account_package.id;
                                refDocs.push(accountPlanRef);
                            }
                        });
                    }
                }
            }
        }

        let mergedDocs = this.state.docs.concat(refDocs)

        this.setState({ accountPlans: docs, loading: false, docs: mergedDocs });
    }

    async componentDidMount() {
        await this.getDocs();
        await this.getUsers();
    }

    codeExists = async (code) => {
        let exists = false;

        if (code) {
            const query = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).where('code', '==', code).limit(1).get();

            if (query.docs.length > 0) {
                exists = true;
            }
        } else {
            exists = true;
        }


        return exists;
    }

    async getParent(parentId) {
        const query = await Firestore.getDoc('account_package', parentId);

        return query.data();
    }

    async addPackage(parentId) {
        
        try {

            if (parentId) {
                this.addChildrenToParent(parentId);
                return;
            }
    
            if (this.state.responsable.length && this.state.description && this.state.readUsers.length > 0 && this.state.writeUsers.length > 0 && SessionHelper.getData().id_company && SessionHelper.getData().id_company && this.state.selectedAccountPlans && this.state.selectedAccountPlans.length > 0) {
    
                let accountPlans = AccountPlanHelper.getAccountsFromParent(this.state.selectedAccountPlans, this.state.accountPlans);
    
                let data = {
                    description: this.state.description,
                    account_plans: accountPlans,
                    responsable: this.state.responsable,
                    id_company: SessionHelper.getData().id_company,
                    readUsers: this.state.readUsers,
                    writeUsers: this.state.writeUsers
                };
    
                if (this.state.allRead == true) { data.readUsers = []; data.readUsers.push('allRead'); }
                if (this.state.allWrite == true) { data.writeUsers = []; data.writeUsers.push('allWrite'); }
    
                try {
                    this.setState({ loadingModal: true });
    
                    await Firestore.insert(data, 'account_package');
    
                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    await this.getDocs();
                } catch (error) {
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    this.setState({ loadingModal: false });
                }
    
            } else {
                toast.warn("Preencha os campos obrigatórios", {
                    position: toast.POSITION.TOP_RIGHT
                });
    
                return;
            }
    
            this.setState({ loadingModal: false, addModal: false, description: null, selectedAccountPlans: [] });

        } catch (error) {

            toast.warn("Houve um problema ao cadastrar o pacote", {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ loadingModal: false });
        }
    }

    async insertChild(parentId, parentName) {
        this.setState({ addModal: true, parentId, parentName });
    }

    async addChildrenToParent(parentId) {
        try {
            this.setState({ loadingModal: true });

            let accountPlans = AccountPlanHelper.getAccountsFromParent(this.state.selectedAccountPlans, this.state.accountPlans);
            await Firestore.update({ account_plans: accountPlans }, 'account_package', parentId);

            toast.success("Cadastrado com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });

            await this.getDocs();

        } catch (error) {
            toast.error("Houve um problema ao cadastrar", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({ loadingModal: false });
        }

        this.setState({ loadingModal: false, addModal: false, selectedAccountPlans: [], parentId: '', parentName: '' });
    }

    async removeChildFromParent(parentId, childId) {
        let parent = (await Firestore.getDoc('account_package', parentId)).data();

        const index = parent.account_plans.indexOf(childId);

        parent.account_plans.splice(index, 1);

        await Firestore.update(parent, 'account_package', parentId);

        toast.success("Removido com sucesso", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    renderPeopleList(array, field) {
        if (!array) return;

        return (
            <div style={{ overflowY: 'auto', maxHeight: 210, width: '100%' }}>
                {array.map((item, key) => {
                    let user = this.state.users[this.state.users.findIndex(doc => doc.id === item)];

                    if (user && user.id) {
                        return (
                            <div style={{ marginBottom: 5, borderTop: '0.5px solid lightgrey' }}>
                                <Tooltip onClick={() => { this.removePerson(key, field) }} style={{ position: 'relative', float: 'right', color: 'red', marginTop: 5 }} title="Remover"><IconButton><DeleteIcon /></IconButton></Tooltip>
                                <div style={{ padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <ProfileCard userId={user.id} user={user} useMouseAsAnchor={true} iconColor={Colors.primary} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>{user.name}</div>
                                    </div>
                                </div>
                                {!this.state.docs[key + 1] && <div style={{ borderBottom: '0.5px solid lightgrey' }} />}
                            </div>
                        )
                    } else {
                        return null;
                    }
                })}
            </div>
        )
    }

    removePerson(key, field) {
        let currentPermissionDoc = this.state.currentPermissionDoc;

        if (field === 'responsable') {

            if (typeof currentPermissionDoc[field] === 'object') {

                currentPermissionDoc[field].splice(key, 1);

            } else {

                currentPermissionDoc[field] = null;
            }

            this.setState({ responsableEdit: currentPermissionDoc[field] });

        } else {

            currentPermissionDoc[field].splice(key, 1);
        }

        this.setState({ currentPermissionDoc });
    }

    permissionModalEdit() {
        return (
            <div style={{ marginTop: 30 }}>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        searchField={'name'}
                        id={'user_selection_responsable_edit'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.responsableEdit}
                        onChange={(v) => {
                            this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, responsable: v.target.value }, responsableEdit: v.target.value })
                        }}
                        docs={this.state.users}
                        label={'Usuários Responsáveis*'}
                    />
                </div>
                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allReadEdit}
                            searchField={'name'}
                            id={'user_selection_read_edit'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.currentPermissionDoc.readUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, readUsers: v.target.value } })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Visualizar'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Visualizar*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allReadEdit: evt }); }} checked={this.state.allReadEdit} />
                    </div>
                </div>

                <div style={{ marginTop: 25, marginBottom: 20, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allWriteEdit}
                            searchField={'name'}
                            id={'user_selection_write_edit'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.currentPermissionDoc.writeUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, writeUsers: v.target.value } })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Editar e Excluir'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Editar e Excluir*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allWriteEdit: evt }); }} checked={this.state.allWriteEdit} />
                    </div>
                </div>
            </div>
        );
    }

    permissionModal() {
        return (
            <div>
                {this.permissionModalEdit()}
                <InputLabel style={{ paddingTop: 10, paddingBottom: 10 }}>Usuários Responsáveis{!this.state.currentPermissionDoc.responsable ? ': Não atribuído' : ''}</InputLabel>
                {this.state.currentPermissionDoc.responsable ? this.renderPeopleList(typeof this.state.currentPermissionDoc.responsable === 'object' ? this.state.currentPermissionDoc.responsable : [this.state.currentPermissionDoc.responsable], 'responsable') : null}

                <InputLabel style={{ paddingTop: 10, paddingBottom: 10 }}>Usuários que podem Visualizar{this.state.currentPermissionDoc.readUsers && this.state.currentPermissionDoc.readUsers.includes('allRead') ? ': Todos na Empresa' : ''}</InputLabel>
                {this.state.currentPermissionDoc.readUsers && !this.state.currentPermissionDoc.readUsers.includes('allRead') || SessionHelper.getData().type != 'user' ? this.renderPeopleList(this.state.currentPermissionDoc.readUsers, 'readUsers') : null}

                <InputLabel style={{ paddingTop: 10, paddingBottom: 30 }}>Usuários que podem Editar e Excluir{this.state.currentPermissionDoc.writeUsers && this.state.currentPermissionDoc.writeUsers.includes('allWrite') ? ': Todos na Empresa' : ''}</InputLabel>
                {this.state.currentPermissionDoc.writeUsers && !this.state.currentPermissionDoc.writeUsers.includes('allRead') || SessionHelper.getData().type != 'user' ? this.renderPeopleList(this.state.currentPermissionDoc.writeUsers, 'writeUsers') : null}

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.saveEditPerms() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'SALVAR'}</Button>
                    <Button onClick={() => { this.setState({ permissionModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    saveEditPerms = async () => {
        let data = {};

        Object.assign(data, this.state.currentPermissionDoc);

        if (this.state.allReadEdit == true) { data.readUsers = []; data.readUsers.push('allRead'); }
        if (this.state.allWriteEdit == true) { data.writeUsers = []; data.writeUsers.push('allWrite'); }
        if (this.state.responsableEdit) { data.responsable = this.state.responsableEdit }

        try {
            this.setState({ loadingModal: true });
            const docId = data.id;

            delete data.tableData;
            delete data.id;

            await Firestore.update(data, 'account_package', docId);

            toast.success("Editado com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });

            await this.getDocs();
        } catch (error) {
            toast.error("Houve um problema ao editar", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({ loadingModal: false });
            return;
        }

        this.setState({ loadingModal: false, permissionModal: false });
    }

    renderAddPermissions() {
        return (
            <div>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        disabled={false}
                        searchField={'name'}
                        id={'user_selection_responsable'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.responsable}
                        onChange={(v) => {
                            this.setState({ responsable: v.target.value })
                        }}
                        docs={this.state.users}
                        label={'Usuários Responsáveis*'}
                    />
                </div>

                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allRead}
                            searchField={'name'}
                            id={'user_selection_read'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.readUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ readUsers: v.target.value })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Visualizar'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Visualizar*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allRead: evt }); }} checked={this.state.allRead} />
                    </div>
                </div>

                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allWrite}
                            searchField={'name'}
                            id={'user_selection_write'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.writeUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ writeUsers: v.target.value })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Editar e Excluir'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Editar e Excluir*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch disabled={!this.state.allRead} label="Todos" onChange={(evt) => { this.setState({ allWrite: evt }); }} checked={this.state.allWrite} />
                    </div>
                </div>
            </div>
        )
    }

    addModal() {
        return (
            <div>
                {this.state.parentId && this.state.parentName ? <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 18 }} component="legend">Adicionar ao Pacote: {this.state.parentName}</FormLabel> : null}
                {!this.state.parentId && !this.state.parentName ? <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} /> : null}

                <div style={{ marginTop: 15 }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        searchField={['description', 'code']}
                        id={'account_plan_selection'}
                        valueField={'id'}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        value={this.state.selectedAccountPlans}
                        onChange={(v) => { this.setState({ selectedAccountPlans: v.target.value }) }}
                        docs={this.state.accountPlans}
                        label={'Selecione um ou mais Plano de Contas*'}
                    />
                </div>

                {!this.state.parentId && !this.state.parentName ? this.renderAddPermissions() : null}


                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addPackage(this.state.parentId) }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'}/>
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'}/>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true, parentId: false }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>

                <DefaultTable
                    title={'Pacotes de Contas'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        {
                            title: 'Descrição',
                            field: 'description',
                            render: rowData => <div>{`${rowData.description} ${rowData.code ? `(${rowData.code})` : ''}`}</div>
                        }
                    ]}
                    rowStyle={(rowData, index, level) => ({ borderInlineStart: `${level * 30}px solid ${Colors.primary}`, backgroundColor: `rgba(0,0,0, 0.1)` })}
                    actions={[
                        rowData => !rowData.parent_id ? ({
                            icon: Add,
                            tooltip: 'Adicionar Contas ao Pacote',
                            onClick: async (event, rowData) => {
                                if (rowData.account_plans && rowData.account_plans.length > 0) {
                                    this.setState({ selectedAccountPlans: rowData.account_plans });
                                } else {
                                    this.setState({ selectedAccountPlans: [] });
                                }
                                await this.insertChild(rowData.id, rowData.description);
                            }
                        }) : null,
                        rowData => !rowData.parent_id ? ({
                            icon: PeopleIcon,
                            tooltip: 'Permissões',
                            onClick: (event, rowData) => {
                                let data = {};

                                this.setState({ currentPermissionDoc: rowData, allReadEdit: rowData.readUsers.includes('allRead'), allWriteEdit: rowData.writeUsers.includes('allWrite'), responsableEdit: typeof rowData.responsable === 'object' ? rowData.responsable : [rowData.responsable], permissionModal: true })
                            }
                        }) : null,
                    ]}
                    isEditHidden={(rowData) => (rowData.parent_id ? true : false)}
                    deleteText={'Apagar? (Caso seja uma conta, apagará somente deste pacote, não apagará a conta)'}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        if (oldData.parent_id) {
                            toast.warn("Não é possível editar Plano de Contas aqui, vá ao menu 'Contas > Planos de Conta' para Editar.", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return;
                        }

                        let prev = this.state.docs;

                        const index = prev.indexOf(oldData);

                        prev[index] = newData;

                        this.setState({ docs: prev })
                        if (oldData.id) {

                            let data = {};
                            Object.assign(data, newData);
                            if (data.tableData) {
                                delete data['tableData'];
                            }

                            await Firestore.update(data, 'account_package', oldData.id);

                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.forceUpdate();

                        return prev;
                    }}
                    treeView={true}
                    onRowDelete={async (oldData) => {
                        if (oldData.id) {


                            this.setState({ loading: true });

                            if (oldData.parent_id) {
                                await this.removeChildFromParent(oldData.parent_id, oldData.id);
                            } else {
                                await Firestore.delete('account_package', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }

                            await this.getDocs();

                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Pacote de Contas'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.permissionModal()} title={'Permissões de Pacote de Contas'} onClose={() => { this.setState({ permissionModal: false }) }} open={this.state.permissionModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
