import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class CurrencyInput extends Component {
  render() {
    return (
        <NumberFormat style={{ paddingTop: this.props.noPadding ? 0 : 10, paddingBottom: this.props.noPadding ? 0 : 10, color: Colors.primary }} id={this.props.id} type={this.props.type} required={this.props.required} fullWidth={true} label={this.props.label} customInput={TextField} value={this.props.value} prefix={'R$'} decimalScale={2} fixedDecimalScale={true} decimalSeparator={','} thousandSeparator={'.'} onValueChange={(e) => { this.props.onChange(e) }}/>
    )
  }
}
