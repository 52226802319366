import { InputLabel, Table, TableBody, TableCell, TableHead, TableRow, TextareaAutosize, Tooltip } from "@material-ui/core";
import React from "react";
import Colors from "../constants/Colors";
import DefaultButton from './DefaultButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import FastForwardIcon from '@material-ui/icons/FastForward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SyncIcon from '@material-ui/icons/Sync';
import CommentIcon from '@material-ui/icons/Comment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Firestore from "../api/firebase/Firestore";
import SessionHelper from "../helper/SessionHelper";
import DefaultSelect from "./DefaultSelect";
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { toast } from "react-toastify";
import DefaultModal from "./DefaultModal";
import RelationField from "./RelationField";
import moment from "moment";
import FilePicker from "./FilePicker";
import TextEditorTranslation from "../constants/TextEditorTranslation";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import ActionPlanHelper from "../helper/ActionPlanHelper";
import { MentionsInput, Mention } from 'react-mentions';
import NotificationHelper from "../helper/NotificationHelper";
import Comment from "./Comment";

const priority = [
    { 'label': 'Baixa', value: 'low' },
    { 'label': 'Média', value: 'medium' },
    { 'label': 'Alta', value: 'high' },
    { 'label': 'Urgente', value: 'highest' },
];

const situation = [
    { 'label': 'Não Iniciada', value: 'not_started' },
    { 'label': 'Em Desenvolvimento', value: 'started' },
    { 'label': 'Cancelada', value: 'canceled' },
    { 'label': 'Concluída', value: 'finished' },
];

export default class ActionPlanTasks extends React.Component {

    state = {
        loading: true,
        loadingModal: false,
        userDocs: [],
        tasks: this.props.tasks || [],
        auxTasks: [],
        add: false,
        hover: null,
        task: '',
        description: '',
        why: '',
        responsable: '',
        start: new Date(),
        end: new Date(),
        priority: 'medium',
        situation: 'not_started',
        edit: null,
        mode: this.props.mode,
        moveTaskModal: false,
        actionTaskKey: false,
        fileModal: false,
        commentModal: false,
        newComment: EditorState.createEmpty(),
        newCommentHtml: '',
        hoverComment: null,
        historyModal: false,
    }

    async componentDidMount() {

        await this.getUsers();

        this.setState({ loading: false });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;
            data.display = data.name;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async addTask() {

        if (this.state.task.length && this.state.responsable && this.state.priority && this.state.situation && this.state.start && this.state.end) {

            try {

                this.setState({ loading: true });
    
                let task = {
                    task: this.state.task,
                    description: this.state.description,
                    why: this.state.why,
                    id_responsable: this.state.responsable,
                    start: this.state.start,
                    end: this.state.end,
                    priority: this.state.priority,
                    situation: this.state.situation,
                    comments: [],
                    files: [],
                    history: [],
                    date: new Date(),
                    id_user: SessionHelper.getFirebaseAuth().uid,
                }

                if (task.situation === 'finished' || task.situation === 'canceled') {

                    task.finish_date = new Date();

                } else {

                    task.finish_date = null;
                }

                task.history.push({
                    text: '<div>Etapa cadastrada.</div>',
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    date: new Date(),
                });

                let tasks = this.state.tasks;
                tasks.push(task);

                if (this.props.idActionPlan && !this.props.evaluator && SessionHelper.getFirebaseAuth().uid != task.id_responsable) {
                    
                    await ActionPlanHelper.sendMailEtapas(this.props.idActionPlan, task.id_user, [tasks.length - 1]);
                }

                this.props.onAddTask(tasks);
    
                toast.success('Etapa adicionada com sucesso');
                this.setState({ loading: false, add: false, tasks, task: '', description: '', why: '', responsable: '', start: new Date(), end: new Date(), priority: 'medium', situation: 'not_started' });  
    
            } catch (error) {

                toast.error('Houve um problema ao salvar essa etapa');
                this.setState({ loading: false });
            }

        } else {

            toast.warn('Preencha todos os campos obrigatórios');
        }
    }

    async edit(key) {

        if (!this.state.loading) {

            let tasks = this.state.tasks;
            
            if (tasks[key] && tasks[key].task.length && tasks[key].id_responsable && tasks[key].priority && tasks[key].situation && tasks[key].start && tasks[key].end) {
    
                try {
    
                    this.setState({ loading: true });
    
                    let auxTasks = this.state.auxTasks;
                    
                    if (!Array.isArray(tasks[key].history)) {
        
                        tasks[key].history = [];
                    }
    
                    let start = moment(auxTasks[key]['start'].toDate ? auxTasks[key]['start'].toDate() : auxTasks[key]['start']).format('DD/MM/YYYY');
                    let startNew = moment(tasks[key]['start'].toDate ? tasks[key]['start'].toDate() : tasks[key]['start']).format('DD/MM/YYYY');
                    
                    let end = moment(auxTasks[key]['end'].toDate ? auxTasks[key]['end'].toDate() : auxTasks[key]['end']).format('DD/MM/YYYY');
                    let endNew = moment(tasks[key]['end'].toDate ? tasks[key]['end'].toDate() : tasks[key]['end']).format('DD/MM/YYYY');
    
                    if (auxTasks[key]['task'] !== tasks[key]['task']) {
        
                        tasks[key].history.push({
                            text: `<div>Atividade alterado de <b>${auxTasks[key]['task']}</b> para <b>${tasks[key]['task']}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    } 
                    
                    if (auxTasks[key]['description'] !== tasks[key]['description']) {
            
                        tasks[key].history.push({
                            text: `<div>Descrição alterado de <b>${auxTasks[key]['description']}</b> para <b>${tasks[key]['description']}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    }
                    
                    if (auxTasks[key]['why'] !== tasks[key]['why']) {
            
                        tasks[key].history.push({
                            text: `<div>Justificativa alterado de <b>${auxTasks[key]['why']}</b> para <b>${tasks[key]['why']}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    }
                    
                    if (auxTasks[key]['id_responsable'] !== tasks[key]['id_responsable']) {
            
                        let oldUserQuery = await Firestore.getDoc('user', auxTasks[key]['id_responsable']);
                        let newUserQuery = await Firestore.getDoc('user', tasks[key]['id_responsable']);
    
                        let oldUser = oldUserQuery.data();
                        let newUser = newUserQuery.data();
    
                        tasks[key].history.push({
                            text: `<div>Responsável alterado de <b>${oldUser.name}</b> para <b>${newUser.name}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    }
                    
                    if (start !== startNew) {
    
                        tasks[key].history.push({
                            text: `<div>Início alterado de <b>${start}</b> para <b>${startNew}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    }
                    
                    if (end !== endNew) {
            
                        tasks[key].history.push({
                            text: `<div>Fim alterado de <b>${end}</b> para <b>${endNew}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    }
                    
                    if (auxTasks[key]['priority'] !== tasks[key]['priority']) {
    
                        let oldPriority = priority.find(item => item.value === auxTasks[key]['priority']);
                        let newPriority = priority.find(item => item.value === tasks[key]['priority']);
    
                        tasks[key].history.push({
                            text: `<div>Prioridade alterado de <b>${oldPriority.label}</b> para <b>${newPriority.label}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });
                    }
                    
                    if (auxTasks[key]['situation'] !== tasks[key]['situation']) {
            
                        let oldSituation = situation.find(item => item.value === auxTasks[key]['situation']);
                        let newSituation = situation.find(item => item.value === tasks[key]['situation']);
    
                        tasks[key].history.push({
                            text: `<div>Situação alterado de <b>${oldSituation.label}</b> para <b>${newSituation.label}</b></div>`,
                            id_user: SessionHelper.getFirebaseAuth().uid,
                            date: new Date(),
                        });

                        if (['finished', 'canceled'].includes(newSituation.value) && this.props.idActionPlan) {

                            if (SessionHelper.getFirebaseAuth().uid != this.props.plano.id_responsable) {

                                let actionPlanStatus = ActionPlanHelper.getStatus(tasks, true);
                                await ActionPlanHelper.sendMailEtapaConcluida(newSituation.value, this.props.idActionPlan, key, actionPlanStatus);
                            }
                        }
                    }

                    this.props.onAddTask(tasks);
        
                    toast.success('Etapa editada com sucesso');
                    this.setState({ loading: false, edit: null, auxTasks: [] });  
        
                } catch (error) {
    
                    toast.error('Houve um problema ao editar essa etapa');
                    this.setState({ loading: false });
                }
    
            } else {
    
                toast.warn('Preencha todos os campos obrigatórios');
            }
        }
    }

    async moveTask() {

        if (this.state.actionTaskKey >= 0 && this.state.moveTaskModal) {

            try {

                this.setState({ loadingModal: true });

                let tasks = this.state.tasks;
                let oldStatusIndex = situation.findIndex(item => item.value === tasks[this.state.actionTaskKey].situation);
                let newStatusIndex = oldStatusIndex + 1;
    
                if (newStatusIndex === 2) {
    
                    newStatusIndex++;
                }

                tasks[this.state.actionTaskKey].situation = situation[newStatusIndex].value;

                if (situation[newStatusIndex].value === 'finished') {

                    tasks[this.state.actionTaskKey]['finish_date'] = new Date();

                    if (SessionHelper.getFirebaseAuth().uid != this.props.plano.id_responsable && this.props.idActionPlan) {

                        let actionPlanStatus = ActionPlanHelper.getStatus(tasks, true);
                        await ActionPlanHelper.sendMailEtapaConcluida(situation[newStatusIndex].value, this.props.idActionPlan, this.state.actionTaskKey, actionPlanStatus);
                    }

                } else {

                    tasks[this.state.actionTaskKey]['finish_date'] = null;
                }

                tasks[this.state.actionTaskKey].history.push({
                    text: `<div>Situação alterado de <b>${situation[oldStatusIndex].label}</b> para <b>${situation[newStatusIndex].label}</b></div>`,
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    date: new Date(),
                });

                this.props.onAddTask(tasks);

                toast.success('Status alterado com sucesso');
                this.setState({ loadingModal: false, moveTaskModal: false, tasks, actionTaskKey: null });

            } catch (error) {

                toast.error('Houve um problema ao avançar o status');
                this.setState({ loadingModal: false });
            }
        }
    }

    remove(key) {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            let tasks = this.state.tasks;

            if (tasks[key]) {
    
                tasks.splice(key, 1);
    
                this.props.onAddTask(tasks);

                toast.success('Etapa removida com sucesso');
                this.setState({ tasks });
            }
        }
    }

    removeComment(key) {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            let tasks = this.state.tasks;

            if (tasks[this.state.actionTaskKey]) {
    
                if (tasks[this.state.actionTaskKey].comments[key]) {

                    tasks[this.state.actionTaskKey].comments.splice(key, 1);
    
                    this.props.onAddTask(tasks);
    
                    toast.success('Comentário removido com sucesso');
                    this.setState({ tasks });
                }
            }
        }
    }

    handleCancelEdit() {

        let auxTasks = this.state.auxTasks;
        this.setState({ tasks: auxTasks, edit: null });
    }

    handleEdit(key) {

        let tasks = this.state.tasks;

        for (let index = 0; index < tasks.length; index++) {
            
            tasks[index].start = tasks[index].start.toDate ? tasks[index].start.toDate() : tasks[index].start;
            tasks[index].end = tasks[index].end.toDate ? tasks[index].end.toDate() : tasks[index].end;
        }

        let auxTasks = this.state.tasks;

        for (let index = 0; index < auxTasks.length; index++) {
            
            if (auxTasks[index].finish_date && auxTasks[index].finish_date.toDate) {
             
                auxTasks[index].finish_date = auxTasks[index].finish_date.toDate();
            }
        }

        if (tasks[key]) {

            this.setState({ add: false, task: '', description: '', why: '', responsable: '', start: new Date(), end: new Date(), priority: 'medium', situation: 'not_started', edit: key, auxTasks: JSON.parse(JSON.stringify(auxTasks)) });
        }
    }

    handleValueEdit(field, value) {

        let tasks = this.state.tasks;

        if (tasks[this.state.edit]) {

            tasks[this.state.edit][field] = value;

            if (field === 'situation' && value === 'finished') {

                tasks[this.state.edit]['finish_date'] = new Date();

            } else {

                tasks[this.state.edit]['finish_date'] = null;
            }

            this.setState({ tasks });
        }
    }

    getBackgroundColor(task, alpha = 0.2) {

        let color = '';

        if (task.situation === 'finished') {

            color = `rgba(89, 166, 61, ${alpha})`;

        } else if (task.situation === 'not_started') {

            let end = moment(task.end.toDate ? task.end.toDate() : task.end);
            
            if (moment().startOf('day').isAfter(end.startOf('day'))) {

                color = `rgba(215, 77, 77, ${alpha})`;

            } else {

                color = 'white';
            }
        
        } else if (task.situation === 'started') {
         
            color = `rgba(245, 190, 64, ${alpha})`;
        }
        
        return color;
    }

    renderSituation(situation, finish_date, end) {

        end = moment(end.toDate ? end.toDate() : end);

        let text = '';
        let isAtrasada = moment().startOf('day').isAfter(end.startOf('day'));

        if (isAtrasada) {

            let diff = moment().startOf('day').diff(end.startOf('day'), 'days');

            if (situation === 'finished') {

                text = (

                    <div>{`Concluída com ${diff} dias de atraso`}<br/>({moment(finish_date.toDate ? finish_date.toDate() : finish_date).format('DD/MM/YYYY HH:mm')})</div>
                )
    
            } else if (situation === 'not_started') {
    
                text = (

                    <div>{`Atrasada com ${diff} dias de atraso`}<br/>(Não Iniciada)</div>
                )
            
            } else if (situation === 'started') {
             
                text = (

                    <div>{`Atrasada com ${diff} dias de atraso`}<br/>(Em Desenvolvimento)</div>
                )

            } else if (situation === 'canceled') {
             
                text = (

                    <div>{`Cancelada com ${diff} dias de atraso`}<br/>({moment(finish_date.toDate ? finish_date.toDate() : finish_date).format('DD/MM/YYYY HH:mm')})</div>
                )
            }

            return text;
        }

        if (situation === 'finished') {

            text = (

                <div>{`Concluída`}<br/>({moment(finish_date.toDate ? finish_date.toDate() : finish_date).format('DD/MM/YYYY HH:mm')})</div>
            )

        } else if (situation === 'not_started') {

            text = (

                <div>{`Não Iniciada`}</div>
            )
        
        } else if (situation === 'started') {
         
            text = (

                <div>{`Em Desenvolvimento`}</div>
            )

        } else if (situation === 'canceled') {
         
            text = (

                <div>{`Cancelada`}<br/>({moment(finish_date.toDate ? finish_date.toDate() : finish_date).format('DD/MM/YYYY HH:mm')})</div>
            )
        }

        return text;
    }

    renderAdd() {

        return <DefaultButton disabled={this.state.edit !== null} onClick={() => { this.setState({ add: true, task: '', description: '', why: '', responsable: '', start: new Date(), end: new Date(), priority: 'medium', situation: 'not_started' }) }} display={'inline'} color={Colors.success} leftIcon={<AddIcon />} label={''}/>
    }

    renderSave() {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                <DefaultButton loading={this.state.loading} onClick={() => { this.addTask(); }} display={'inline'} color={Colors.success} leftIcon={<CheckIcon />} label={''}/>
                <DefaultButton loading={this.state.loading} onClick={() => { this.setState({ add: false, task: '', description: '', why: '', responsable: '', start: new Date(), end: new Date(), priority: 'medium', situation: 'not_started' }) }} display={'inline'} color={Colors.error} leftIcon={<CancelIcon />} label={''}/>
            </div>
        )
    }

    renderActions(task, key) {

        if (this.state.edit === key) {

            return (

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                    <CheckIcon onClick={() => this.edit(key)} style={{ color: Colors.success, cursor: 'pointer' }}/>
                    <CancelIcon onClick={() => this.handleCancelEdit()} style={{ color: Colors.error, cursor: 'pointer' }}/>
                </div>
            )

        } else {

            if (this.state.edit === null) {

                return (

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                        { this.state.mode === 'view' && task.situation !== 'finished' && task.situation !== 'canceled' && !this.props.readOnly  ?
                            <Tooltip title={'Avançar Status'}>
                                <FastForwardIcon onClick={() => this.setState({ moveTaskModal: true, actionTaskKey: key })} style={{ color: Colors.info, cursor: 'pointer' }}/>
                            </Tooltip>  
                        : null }
                        { this.state.mode === 'view' ?
                            <Tooltip title={'Comentários'}>
                                <CommentIcon onClick={() => this.setState({ commentModal: true, actionTaskKey: key })} style={{ color: 'grey', cursor: 'pointer' }}/>
                            </Tooltip>
                        : null }  
                        { this.state.mode === 'view' ? 
                            <Tooltip title={'Anexos'}>
                                <AttachFileIcon onClick={() => this.setState({ fileModal: true, actionTaskKey: key })} style={{ color: 'grey', cursor: 'pointer' }}/>
                            </Tooltip> 
                        : null } 
                        { this.state.mode === 'view' ? 
                            <Tooltip title={'Histórico de Alterações'}>
                                <SyncIcon onClick={() => this.setState({ historyModal: true, actionTaskKey: key })} style={{ color: 'grey', cursor: 'pointer' }}/>
                            </Tooltip>
                        : null }
                        { !this.props.readOnly ?
                            <Tooltip title={'Editar'}>
                                <EditIcon onClick={() => this.handleEdit(key)} style={{ color: Colors.success, cursor: 'pointer' }}/>
                            </Tooltip>
                        : null }
                        { (task.situation !== 'finished' || this.state.mode === 'add') && !this.props.readOnly ?
                            <Tooltip title={'Remover'}>
                                <DeleteIcon onClick={() => this.remove(key)} style={{ color: Colors.error, cursor: 'pointer' }}/>
                            </Tooltip>
                        : null }
                    </div>
                )
            }
        }
    }

    renderEmpty() {

        return (

            <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell colSpan={'100%'} style={{ textAlign: 'center', color: 'grey', padding: 20 }}>Nenhuma etapa cadastrada.</TableCell>
            </TableRow>
        )
    }

    renderAddTask() {

        if (this.state.mode === 'add')  {

            return (

                <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ padding: 10 }}>{`${this.state.tasks.length + 1}ª Etapa`}</TableCell>
                    <TableCell style={{ height: 150, padding: 10 }}>
                        <TextareaAutosize onBlur={(v) => { this.setState({ task: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                    </TableCell>
                    <TableCell style={{ height: 150, padding: 10 }}>
                        <TextareaAutosize onBlur={(v) => { this.setState({ description: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                    </TableCell>
                    <TableCell style={{ height: 150, padding: 10 }}>
                        <TextareaAutosize onBlur={(v) => { this.setState({ why: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <DefaultSelect
                            required={true}
                            search={true}
                            searchField={'name'}
                            id={'responsable_select'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.responsable}
                            onChange={(v) => {
                                this.setState({ responsable: v.target.value })
                            }}
                            docs={this.state.userDocs}
                            label={''}
                        />
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label=""
                                views={["year", "month", "date"]}
                                openTo={'date'}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ start: v }) }}
                                value={this.state.start}
                            />
                        </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ }}
                                invalidDateMessage={false}
                                format={'dd/MM/yyyy'}
                                autoOk={true}
                                label=""
                                views={["year", "month", "date"]}
                                openTo={'date'}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ end: v }) }}
                                value={this.state.end}
                            />
                        </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <DefaultSelect
                            required={true}
                            id={'priority_select'}
                            valueField={'value'}
                            displayField={'label'}
                            value={this.state.priority}
                            onChange={(v) => {
                                this.setState({ priority: v.target.value })
                            }}
                            docs={priority}
                            label={''}
                        />
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <DefaultSelect
                            required={true}
                            id={'situation_select'}
                            valueField={'value'}
                            displayField={'label'}
                            value={this.state.situation}
                            onChange={(v) => {
                                this.setState({ situation: v.target.value })
                            }}
                            docs={situation}
                            label={''}
                        />
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
            )

        } else if (this.state.mode === 'view') {

            return (

                <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ height: 150, padding: 10 }}>
                        <TextareaAutosize onBlur={(v) => { this.setState({ task: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                    </TableCell>
                    <TableCell style={{ height: 150, padding: 10 }}>
                        <TextareaAutosize onBlur={(v) => { this.setState({ description: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                    </TableCell>
                    <TableCell style={{ height: 150, padding: 10 }}>
                        <TextareaAutosize onBlur={(v) => { this.setState({ why: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <DefaultSelect
                            required={true}
                            search={true}
                            searchField={'name'}
                            id={'responsable_select'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.responsable}
                            onChange={(v) => {
                                this.setState({ responsable: v.target.value })
                            }}
                            docs={this.state.userDocs}
                            label={''}
                        />
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                <KeyboardDatePicker
                                    style={{ }}
                                    invalidDateMessage={false}
                                    format={'dd/MM/yyyy'}
                                    autoOk={true}
                                    label=""
                                    views={["year", "month", "date"]}
                                    openTo={'date'}
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    onChange={(v) => { this.setState({ start: v }) }}
                                    value={this.state.start}
                                />
                                <KeyboardDatePicker
                                    style={{ }}
                                    invalidDateMessage={false}
                                    format={'dd/MM/yyyy'}
                                    autoOk={true}
                                    label=""
                                    views={["year", "month", "date"]}
                                    openTo={'date'}
                                    cancelLabel={'Cancelar'}
                                    okLabel={'Confirmar'}
                                    onChange={(v) => { this.setState({ end: v }) }}
                                    value={this.state.end}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <DefaultSelect
                            required={true}
                            id={'priority_select'}
                            valueField={'value'}
                            displayField={'label'}
                            value={this.state.priority}
                            onChange={(v) => {
                                this.setState({ priority: v.target.value })
                            }}
                            docs={priority}
                            label={''}
                        />
                    </TableCell>
                    <TableCell style={{ padding: 10 }}>
                        <DefaultSelect
                            required={true}
                            id={'situation_select'}
                            valueField={'value'}
                            displayField={'label'}
                            value={this.state.situation}
                            onChange={(v) => {
                                this.setState({ situation: v.target.value })
                            }}
                            docs={situation}
                            label={''}
                        />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            )
        }
    }

    renderTasks() {

        return this.state.tasks.map((task, key) => {

            let isHover = this.state.hover === key;

            if (this.state.edit === key) {

                return (

                    <TableRow style={{ backgroundColor: 'white' }}>
                        { this.state.mode === 'add' ?
                            <TableCell style={{ padding: 10 }}>{`${key + 1}ª Etapa`}</TableCell>
                        : null }
                        <TableCell style={{ height: 150, padding: 10 }}>
                            <TextareaAutosize value={task.task} onChange={(v) => { this.handleValueEdit('task', v.target.value) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                        </TableCell>
                        <TableCell style={{ height: 150, padding: 10 }}>
                            <TextareaAutosize value={task.description} onChange={(v) => { this.handleValueEdit('description', v.target.value) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                        </TableCell>
                        <TableCell style={{ height: 150, padding: 10 }}>
                            <TextareaAutosize value={task.why} onChange={(v) => { this.handleValueEdit('why', v.target.value) }} onBlur={(v) => { this.setState({ why: v.target.value }) }} placeholder="Digite algo..." style={{ height: '100%', width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                        </TableCell>
                        <TableCell style={{ padding: 10 }}>
                            <DefaultSelect
                                required={true}
                                search={true}
                                searchField={'name'}
                                id={'responsable_select'}
                                valueField={'id'}
                                displayField={'name'}
                                value={task.id_responsable}
                                onChange={(v) => {
                                    this.handleValueEdit('id_responsable', v.target.value)
                                }}
                                docs={this.state.userDocs}
                                label={''}
                            />
                        </TableCell>
                        { this.state.mode === 'view' ?
                            <TableCell style={{ padding: 10 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <KeyboardDatePicker
                                            style={{ }}
                                            invalidDateMessage={false}
                                            format={'dd/MM/yyyy'}
                                            autoOk={true}
                                            label=""
                                            views={["year", "month", "date"]}
                                            openTo={'date'}
                                            cancelLabel={'Cancelar'}
                                            okLabel={'Confirmar'}
                                            onChange={(v) => { this.handleValueEdit('start', v) }}
                                            value={task.start.toDate ? task.start.toDate() : task.start}
                                        />
                                        <KeyboardDatePicker
                                            style={{ }}
                                            invalidDateMessage={false}
                                            format={'dd/MM/yyyy'}
                                            autoOk={true}
                                            label=""
                                            views={["year", "month", "date"]}
                                            openTo={'date'}
                                            cancelLabel={'Cancelar'}
                                            okLabel={'Confirmar'}
                                            onChange={(v) => { this.handleValueEdit('end', v) }}
                                            value={task.end.toDate ? task.end.toDate() : task.end}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TableCell>
                        : null }
                        { this.state.mode === 'add' ?
                            <TableCell style={{ padding: 10 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        label=""
                                        views={["year", "month", "date"]}
                                        openTo={'date'}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => { this.handleValueEdit('start', v) }}
                                        value={task.start.toDate ? task.start.toDate() : task.start}
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                        : null }
                        { this.state.mode === 'add' ?
                            <TableCell style={{ padding: 10 }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ }}
                                        invalidDateMessage={false}
                                        format={'dd/MM/yyyy'}
                                        autoOk={true}
                                        label=""
                                        views={["year", "month", "date"]}
                                        openTo={'date'}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={(v) => { this.handleValueEdit('end', v) }}
                                        value={task.end.toDate ? task.end.toDate() : task.end}
                                    />
                                </MuiPickersUtilsProvider>
                            </TableCell>
                        : null }
                        <TableCell style={{ padding: 10 }}>
                            <DefaultSelect
                                required={true}
                                id={'priority_select'}
                                valueField={'value'}
                                displayField={'label'}
                                onChange={(v) => { this.handleValueEdit('priority', v.target.value) }}
                                value={task.priority}
                                docs={priority}
                                label={''}
                            />
                        </TableCell>
                        <TableCell style={{ padding: 10 }}>
                            <DefaultSelect
                                required={true}
                                id={'situation_select'}
                                valueField={'value'}
                                displayField={'label'}
                                onChange={(v) => { this.handleValueEdit('situation', v.target.value) }}
                                value={task.situation}
                                docs={situation}
                                label={''}
                            />
                        </TableCell>
                        { this.state.mode === 'view' ?
                            <TableCell style={{ padding: 10, textAlign: 'center' }}>
                                <div style={{ fontWeight: 'bold', backgroundColor: task.comments && task.comments.length > 0 ? Colors.success : Colors.danger, color: 'white', display: 'inline', padding: 5, borderRadius: 4 }}>{task.comments ? task.comments.length  : 0}</div>
                            </TableCell>
                        : null }
                        <TableCell style={{ padding: 10 }}>{this.renderActions(task, key)}</TableCell>
                    </TableRow>
                )

            } else {

                if (this.state.mode === 'add') {

                    return (

                        <TableRow onMouseEnter={() => { this.setState({ hover: key }) }} onMouseLeave={() => { this.setState({ hover: null }) }} style={{ backgroundColor: isHover ? '#f3f3f3f3' : 'white' }}>
                            <TableCell style={{ padding: 10 }}>{`${key + 1}ª Etapa`}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.task}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.description}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.why}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.id_responsable ? <RelationField loading={false} collection={'user'} field={'name'} id={task.id_responsable}/> : ''}</TableCell>
                            <TableCell style={{ padding: 10 }}>{moment(task.start.toDate ? task.start.toDate() : task.start).format('DD/MM/YYYY')}</TableCell>
                            <TableCell style={{ padding: 10 }}>{moment(task.end.toDate ? task.end.toDate() : task.end).format('DD/MM/YYYY')}</TableCell>
                            <TableCell style={{ padding: 10, textAlign: 'center' }}>{task.priority ? priority.find(item => item.value === task.priority).label : ''}</TableCell>
                            <TableCell style={{ padding: 10, textAlign: 'center' }}>{task.situation ? situation.find(item => item.value === task.situation).label : ''}</TableCell>
                            <TableCell style={{ padding: 10 }}>{this.renderActions(task, key)}</TableCell>
                        </TableRow>
                    )
                
                } else if (this.state.mode === 'view') {

                    return (

                        <TableRow onMouseEnter={() => { this.setState({ hover: key }) }} onMouseLeave={() => { this.setState({ hover: null }) }} style={{ backgroundColor: isHover ? '#f3f3f3f3' : this.getBackgroundColor(task) }}>
                            <TableCell style={{ padding: 10 }}>{task.task}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.description}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.why}</TableCell>
                            <TableCell style={{ padding: 10 }}>{task.id_responsable ? <RelationField loading={false} collection={'user'} field={'name'} id={task.id_responsable}/> : ''}</TableCell>
                            <TableCell style={{ padding: 10 }}>{moment(task.start.toDate ? task.start.toDate() : task.start).format('DD/MM/YYYY') + ' → ' + moment(task.end.toDate ? task.end.toDate() : task.end).format('DD/MM/YYYY')}</TableCell>
                            <TableCell style={{ padding: 10, textAlign: 'center' }}>{this.renderPriority(task.priority)}</TableCell>
                            <TableCell style={{ padding: 10, textAlign: 'center' }}>{this.renderSituation(task.situation, task.finish_date, task.end)}</TableCell>
                            <TableCell style={{ padding: 10, textAlign: 'center' }}>
                                <div style={{ fontWeight: 'bold', backgroundColor: task.comments && task.comments.length > 0 ? Colors.success : Colors.danger, color: 'white', display: 'inline', padding: 5, borderRadius: 4 }}>{task.comments ? task.comments.length  : 0}</div>
                            </TableCell>
                            <TableCell style={{ padding: 10 }}>{this.renderActions(task, key)}</TableCell>
                        </TableRow>
                    )
                }
            }
        });
    }

    renderHeader() {

        let required = this.state.add ? '*' : '';

        if (this.state.mode === 'add') {

            return (

                <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Etapa</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Atividade (O Quê?){required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Descrição (Como?)</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Justificativa (Por Quê?)</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Responsável (Quem?){required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3'}}>Data Início (Quando?){required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Data Fim (Quando?){required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Prioridade{required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Situação{required}</TableCell>
                    <TableCell style={{ backgroundColor: '#f3f3f3f3' }}></TableCell>
                </TableRow>
            )

        } else if (this.state.mode === 'view') {

            return (

                <TableRow style={{ backgroundColor: 'white' }}>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Atividade{required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Descrição</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Justificativa</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Colaborador{required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3'}}>Início/Fim{required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Prioridade{required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Situação{required}</TableCell>
                    <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Comentários</TableCell>
                    <TableCell style={{ backgroundColor: '#f3f3f3f3' }}></TableCell>
                </TableRow>
            )
        }
    }

    renderPriority(value) {

        let taskPriority = priority.find(item => item.value === value);
        let color = '';

        if (!taskPriority) {

            return '';
        }

        if (taskPriority.value === 'low') {

            color = Colors.success;
        
        } else if (taskPriority.value === 'medium') {

            color = Colors.info;

        } else if (taskPriority.value === 'high') {

            color = Colors.danger;

        } else if (taskPriority.value === 'highest') {

            color = Colors.error;
        }

        return <div style={{ display: 'inline', backgroundColor: color, color: 'white', fontWeight: 'bold', textAlign: 'center', paddingTop: 3, paddingBottom: 3, paddingLeft: 6, paddingRight: 6, borderRadius: 5 }}>{taskPriority.label}</div>;
    }

    moveTaskModal() {

        if (this.state.moveTaskModal && this.state.actionTaskKey >= 0) {

            let task = this.state.tasks[this.state.actionTaskKey];
            let oldStatusIndex = situation.findIndex(item => item.value === task.situation);
            let newStatusIndex = oldStatusIndex + 1;

            if (newStatusIndex === 2) {

                newStatusIndex++;
            }

            let newStatusTask = { ...task };
            newStatusTask.situation = situation[newStatusIndex].value;

            return (

                <div>
                    <div>Ao efetuar essa alteração, essa etapa do plano de ação irá passar para outro status, de acordo com a tabela abaixo:</div>
                    <Table id={'action-plan-tasks-table'} style={{ boxShadow: Colors.boxShadow, marginTop: 20 }}>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>de Status</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>para Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell style={{ padding: 20, textAlign: 'center' }}>
                                    <div style={{ border: '0.3px solid lightgrey', fontWeight: 'bold', color: oldStatusIndex > 0 ? 'white' : 'lightgrey', display: 'inline', padding: 5, backgroundColor: this.getBackgroundColor(task, 1), borderRadius: 5 }}>{situation[oldStatusIndex].label}</div>
                                </TableCell>
                                <TableCell style={{ padding: 20, textAlign: 'center' }}>
                                    <div style={{ border: '0.3px solid lightgrey', fontWeight: 'bold', color: newStatusIndex > 0 ? 'white' : 'lightgrey', display: 'inline', padding: 5, backgroundColor: this.getBackgroundColor(newStatusTask, 1), borderRadius: 5 }}>{situation[newStatusIndex].label}</div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.moveTask() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'}/>
                        <DefaultButton onClick={() => { this.setState({ moveTaskModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'}/>
                    </div>
                </div>
            )
        }
    }

    historyModal() {

        if (this.state.historyModal && this.state.actionTaskKey >= 0) {

            let history = this.state.tasks[this.state.actionTaskKey].history || [];
            
            return (

                <div>
                    <Table id={'action-plan-tasks-table'} style={{ boxShadow: Colors.boxShadow, marginTop: 20 }}>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>O quê</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Quem</TableCell>
                                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f3f3f3f3' }}>Quando</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                history.map((item, key) => {

                                    return (

                                        <TableRow style={{ backgroundColor: 'white' }}>
                                            <TableCell style={{ padding: 10, textAlign: 'left' }}><div dangerouslySetInnerHTML={{ __html: item.text }}/></TableCell>
                                            <TableCell style={{ padding: 10, textAlign: 'center' }}><RelationField loading={false} collection={'user'} field={'name'} id={item.id_user}/></TableCell>
                                            <TableCell style={{ padding: 10, textAlign: 'center' }}>{moment(item.date.toDate ? item.date.toDate() : item.date).format('DD/MM/YYYY HH:mm')}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            { !history.length ?
                            
                            <TableRow style={{ backgroundColor: 'white' }}>
                                <TableCell colSpan={'100%'} style={{ padding: 10, textAlign: 'center', color: 'grey' }}>{'Nenhum registro encontrado.'}</TableCell>
                            </TableRow>
                            
                            : null }
                        </TableBody>
                    </Table>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ historyModal: false, actionTaskKey: null }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'}/>
                    </div>
                </div>
            )
        }
    }

    fileModal() {

        if (this.state.fileModal && this.state.actionTaskKey >= 0) {

            let files = this.state.tasks[this.state.actionTaskKey].files || [];

            return (

                <div>
                    <FilePicker label={''} onUpload={(files) => {

                        let tasks = this.state.tasks;
                        tasks[this.state.actionTaskKey].files = files;
                        
                        this.props.onAddTask(tasks);
                        this.setState({ tasks });

                    }} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} files={files} multiple={true} iconHeight={200} iconWidth={200}/>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ fileModal: false, actionTaskKey: null }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'}/>
                    </div>
                </div>
            )
        }
    }

    commentModal() {

        if (this.state.commentModal && this.state.actionTaskKey >= 0) {

            return (

                <div>
                    {this.renderComments()}
                    {this.renderAddComment()}
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.setState({ commentModal: false, actionTaskKey: null }) }} color={Colors.primary} loading={this.state.loadingModal} width={'100%'} title={'FECHAR'}/>
                    </div>
                </div>
            )
        }
    }

    async addComment() {
        
        if (this.state.newCommentHtml.length) {

            try {

                this.setState({ loadingModal: true });
    
                let tasks = this.state.tasks;

                if (!Array.isArray(tasks[this.state.actionTaskKey].comments)) {

                    tasks[this.state.actionTaskKey].comments = [];
                }

                let comment = this.state.newCommentHtml;

                tasks[this.state.actionTaskKey].comments.push({
                    comment: comment,
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    date: new Date(),
                });
                
                if (this.props.idActionPlan) {

                    let query = await Firestore.getDoc('action_plan', this.props.idActionPlan);
                    let actionPlan = { id: query.id, ...query.data() };
    
                    NotificationHelper.notifyMentions(comment, 'action_plan', `${SessionHelper.getData().name} mencionou você no plano de ação ${actionPlan.description}.`, `<div>${SessionHelper.getData().name} mencionou você no plano de ação <b>${actionPlan.description}</b>.</div>`, `<div>${SessionHelper.getData().name} mencionou você na atividade <b>${tasks[this.state.actionTaskKey].task}</b> do plano de ação <b>${actionPlan.description}</b>.</div>`, actionPlan.id);
                }
        
                this.props.onAddTask(tasks);
        
                toast.success('Comentário adicionado com sucesso');
                this.setState({ tasks, newComment: EditorState.createEmpty(), newCommentHtml: '', loadingModal: false });
    
            } catch (error) {

                toast.error('Houve um problema ao salvar o comentário');
                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn('A mensagem não pode ser vazia');
        }
    }

    renderAddComment() {

        let photo = SessionHelper.getData().photo ? <img style={{ color: Colors.primary, height: 48, width: 48, color: Colors.primary, borderRadius: 48 / 2 }} src={SessionHelper.getData().photo} /> : <AccountCircleIcon style={{ color: Colors.primary, height: 48, width: 48, color: Colors.primary, borderRadius: 48 / 2 }} />;
        
        return (

            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
                {
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            {photo}
                            <MentionsInput forceSuggestionsAboveCursor={true} allowSpaceInQuery={true} style={{ width: '100%' }} className='comments-textarea' value={this.state.newCommentHtml} onChange={event => this.setState({newCommentHtml: event.target.value})}>
                            <Mention
                                trigger='@'
                                data={this.state.userDocs}
                                style={{
                                    background: '#daf4fa',
                                }}
                                markup={'@[__display__](__id__)'}
                                displayTransform={(id, display) => `@${display}`}
                            />
                            </MentionsInput>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10, paddingLeft: 55, marginTop: 10 }}>
                            <DefaultButton onClick={() => { this.addComment() }} color={Colors.success} leftIcon={<CheckIcon />} title={'Salvar'}/>
                            <DefaultButton onClick={() => { this.setState({ newComment: EditorState.createEmpty(), newCommentHtml: '' }) }} color={Colors.notSelected} leftIcon={<CancelIcon />} title={'Cancelar'}/>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderComments() {

        let comments = this.state.tasks[this.state.actionTaskKey].comments || [];

        if (!comments.length) {

            return <p style={{ color: 'grey', textAlign: 'center', marginTop: 100 }}>Nenhum comentário encontrado.</p>
        }

        return comments.map((comment, key) => {

            let isHover = this.state.hoverComment === key;

            return (

                <div onMouseEnter={() => { this.setState({ hoverComment: key }) }} onMouseLeave={() => { this.setState({ hoverComment: null }) }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: 20, gap: 10 }}>
                    <RelationField loading={false} collection={'user'} field={'photo'} id={comment.id_user} render={(value) => {
                        return value ? <img style={{ color: Colors.primary, height: 48, width: 48, color: Colors.primary, borderRadius: 48 / 2 }} src={value} /> : <AccountCircleIcon style={{ color: Colors.primary, height: 48, width: 48, color: Colors.primary, borderRadius: 48 / 2 }} />
                    }}/>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                            <RelationField loading={false} collection={'user'} field={'name'} id={comment.id_user} render={(value) => {
                                return <b>{value}</b>
                            }}/>
                            <div>{'•'}</div>
                            <Tooltip title={moment(comment.date.toDate ? comment.date.toDate() : comment.date).format('DD/MM/YYYY')}>
                                <div>{moment(comment.date.toDate ? comment.date.toDate() : comment.date).calendar().toLowerCase()}</div>
                            </Tooltip>
                        </div>
                        <Comment text={comment.comment}/>
                    </div>
                    { isHover ?
                    
                        <div>
                            <DeleteIcon onClick={() => this.removeComment(key)} style={{ color: Colors.error, cursor: 'pointer' }}/>
                        </div>
                    
                    : null }
                </div>
            )
        });
    }

    render() {

        return (

            <div style={{ overflowX: 'scroll' }}>
                <Table id={'action-plan-tasks-table'} style={{ boxShadow: Colors.boxShadow, marginTop: 20 }}>
                    <TableHead>
                        { this.renderHeader() }
                    </TableHead>
                    <TableBody>
                        { !this.state.tasks.length && !this.state.add ? this.renderEmpty() : null }
                        { this.state.tasks.length ? this.renderTasks() : null }
                        { this.state.add ? this.renderAddTask() : null }
                        <TableRow style={{ backgroundColor: 'white' }}>
                            { !this.props.readOnly ?
                                <TableCell colSpan={'100%'} style={{ color: 'grey', textAlign: 'center', padding: 10 }}>
                                    { this.state.add ? this.renderSave() : this.renderAdd() }
                                </TableCell>
                            : null }
                        </TableRow>
                    </TableBody>
                </Table>
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.historyModal()} title={'Histórico de Alterações'} onClose={() => { this.setState({ historyModal: false }) }} open={this.state.historyModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.moveTaskModal()} title={'Avançar o Status?'} onClose={() => { this.setState({ moveTaskModal: false }) }} open={this.state.moveTaskModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.fileModal()} title={'Anexos'} onClose={() => { this.setState({ fileModal: false }) }} open={this.state.fileModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.commentModal()} title={'Comentários'} onClose={() => { this.setState({ commentModal: false }) }} open={this.state.commentModal} />
            </div>
        )
    }
}