import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CalculusHelper from '../helper/CalculusHelper';
import TableHelper from '../helper/TableHelper';

export default class PercentageTable extends Component {
    render() {
        let table;

        let rows = this.props.rows;

        let plannedCounter = 0;
        let historyCounter = 0;

        table = (
            <div style={styles.tableContainer}>
                <TableContainer>
                    <Table id={'revenue-planning'}>
                        {TableHelper.getTableHeader(rows, true, false)}

                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Orçado (%)</TableCell>
                                {rows.map((period, key) => {
                                    plannedCounter += period.value;
                                    return <TableCell align={this.props.state.editingData && this.props.state.rowId == period.id ? "center" : "right"} onDoubleClick={!this.props.readOnly ? () => { this.props.setParentState({ editingData: true, rowId: period.id, editingRows: rows, editingField: 'value', editInputValue: period.value }); } : () => { }}>
                                        {this.props.state.editingData && this.props.state.rowId == period.id && this.props.state.editingField == 'value' ? this.props.renderInputEditing(period.value, true) : `${CalculusHelper.formatFloat(period.value, true)}%`}
                                    </TableCell>
                                })}
                                {TableHelper.getMeanCellPercentageTable(rows, this.props.comparativeRows, 'value')}
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Histórico (%)</TableCell>
                                {rows.map((period, key) => {
                                    historyCounter += period.value_history_percentage;
                                    return <TableCell align={this.props.state.editingData && this.props.state.rowId == period.id ? "center" : "right"}>
                                        {`${CalculusHelper.formatFloat(period.value_history_percentage, true)}%`}
                                    </TableCell>
                                })}
                                {TableHelper.getMeanCellPercentageTable(rows, this.props.comparativeRows, 'value_history')}
                            </TableRow>

                            {TableHelper.getVariationRow(
                                rows,
                                true,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.value,
                                        period.value_history_percentage
                                    );
                                },
                                true,
                                false,
                                true,
                                rows,
                                this.props.comparativeRows,
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );

        return table;
    }
}

const styles = {
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    }
}