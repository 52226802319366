import React, { Component } from 'react';
import Colors from '../constants/Colors';
import { InputLabel, Select, MenuItem, Checkbox, Chip } from '@material-ui/core';
import DefaultButton from '../components/DefaultButton';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/HighlightOff';

export default class DefaultSelect extends Component {

    state = {
        auxDocs: this.props.docs,
        docs: this.props.docs,
        open: false,
        value: this.props.value,
        searchField: this.props.searchField || [],
        search: '',
        actualSearch: [],
    }

    componentDidUpdate(prev) {

        if (prev.value !== this.props.value) {
            this.setState({ value: this.props.value })
        }

        if (prev.docs !== this.props.docs) {
            this.setState({ docs: this.props.docs, auxDocs: this.props.docs })
        }

        if (prev.searchField !== this.props.searchField) {
            this.setState({ searchField: this.props.searchField })
        }
    }

    renderDefault(doc) {

        return `${doc[this.props.displayField]} ${this.props.secondaryDisplay && doc[this.props.secondaryDisplay] ? ` (${doc[this.props.secondaryDisplay]})` : ''}`
    }

    handleValueChange(v) {

        if (this.props.multiple) {

            let values = [];

            if (v.target.value.includes('all')) {

                values = this.selectAll();

            } else {

                v.target.value.forEach((value, key) => {

                    if (value !== undefined && value !== 'object' && value !== 'all') {
    
                        values.push(value);
                    }
                }); 
            }

            this.setState({ value: values });

        } else if (v.target.value) {

            this.setState({ value: v.target.value });
        }
    }

    renderValues(value) {

        if (this.props.multiple) {

            let result = [];

            value.forEach((v, key) => {

                let doc = this.state.auxDocs.find(item => item[this.props.valueField] === v);

                if (doc) {

                    result.push(doc[this.props.displayField]);
                }
            });

            return result.join(', ');

        } else {

            let doc = this.state.auxDocs.find(item => item[this.props.valueField] === value);

            if (doc) {

                return doc[this.props.displayField];
            }
        }
    }

    selectAll() {

        if (this.state.value.length === this.state.docs.length) {

            return [];

        } else {

            let docs = this.state.docs.map((doc, key) => {
                return doc[this.props.valueField];
            });

            return docs;
        }
    }

    renderSelectAll() {

        return (
            <MenuItem tabIndex={null} value={'all'}>
                <Checkbox disabled style={{ color: Colors.primary, padding: 5 }} checked={this.state.value.length === this.state.docs.length}/>
                {'Selecionar Todos'}
            </MenuItem>
        )
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                { this.props.label && <InputLabel id={this.props.id}>{this.props.label}</InputLabel> }
                <Select
                    multiple={this.props.multiple}
                    open={this.state.open}
                    onOpen={() => { this.setState({ open: true }) }}
                    style={{width: this.props.width ? this.props.width : '100%'}}
                    labelId={this.props.id}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    renderValue={(value) => { return this.renderValues(value) }}
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                    }}
                    onChange={(v) => { this.handleValueChange(v) }}>

                            { this.props.search && this.renderSearch() }
                            { this.props.multiple ? this.renderSelectAll() : null }
                            { this.props.allItems && this.props.allItems }
                            
                            { this.state.docs.map((doc, key) => {

                                return (
                                    <MenuItem tabIndex={null} key={key} value={doc[this.props.valueField]}>
                                        { this.props.multiple ? <Checkbox disabled style={{ color: Colors.primary, padding: 5 }} checked={this.state.value.includes(doc[this.props.valueField])}/> : null }
                                        {this.props.render ? this.props.render(doc) : this.renderDefault(doc)}
                                    </MenuItem>
                                )

                            }) }

                            <MenuItem button={false} tabIndex={null} style={{ display: 'flex', flexDirection: 'row', padding: 15, position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                                <DefaultButton onClick={(v) => { v.stopPropagation(); this.props.onChange({target:{value:this.state.value}}); this.setState({ open: false }) }} leftIcon={<CheckIcon/>} width={'100%'} title={'Confirmar'}/>
                                <div style={{ marginRight: 5, marginLeft: 5 }}/>
                                <DefaultButton onClick={(v) => { v.stopPropagation(); this.setState({ open: false, value: this.props.multiple ? [] : null }); this.props.onChange({target:{value:this.props.multiple ? [] : null}}); }} leftIcon={<CancelIcon style={{ backgroundColor: 'lightgrey', color: '#000000' }}/>} width={'100%'} color={'lightgrey'} textColor={'#000000'} title={'Cancelar'}/>
                            </MenuItem>
                </Select>
            </div>
        )
    }

    async handleSearch(value) {
    
        let result = [];

        if (this.props.docs.length) {

            let searchFields = this.state.searchField;
            if (typeof searchFields === 'string') { searchFields = [searchFields] };

            searchFields.forEach((searchField, key) => {

                let array = this.props.docs;
                let searchFieldResult = array.filter((doc) => {

                    const field = doc[searchField] ? doc[searchField].toString().toUpperCase() : ''.toUpperCase();
                    const search = value.toString().toUpperCase();

                    if (field.indexOf(search) > -1) {

                        if (!result.includes(doc)) {

                            return true;
                        }
                    }
                });
                
                result = [...result, ...searchFieldResult];
            });
        }

        this.setState({ docs: result });
    }

    clearSearch() {
        this.setState({ docs: this.state.auxDocs })
    }

    async removeSearch(key) {

        let actualSearch = this.state.actualSearch;
        actualSearch.splice(key, 1);

        await this.setState({ actualSearch });
        
        if (actualSearch.length === 0) {
             
            this.clearSearch();

        } else {

            actualSearch.forEach((search, key) => {

                this.handleSearch(search);
            });

        }
    }

    renderSearch() {

        return (
            <div style={{ margin: 12, paddingLeft: 5, paddingRight: 5, paddingTop: 3, paddingBottom: 3, border: '1px solid lightgrey', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', borderRadius: 5, alignItems: 'center', boxShadow: Colors.boxShadow }}>
                { this.state.actualSearch.map((tag, key) => <Chip style={{ margin: 5, backgroundColor: Colors.primary, color: 'white' }} deleteIcon={<DeleteIcon style={{ color: 'white', fontSize: 8 }}/>} label={tag} onDelete={() => { this.removeSearch(key) }}/>) }
                <input
                    value={this.state.search}
                    onBlur={ async (evt) => {

                        if (this.state.search && !this.state.actualSearch.includes(evt.target.value)) {

                            let search = this.state.search;

                            await this.setState({ actualSearch: [...this.state.actualSearch, this.state.search], search: '' });
                            await this.handleSearch(search);
                        }
                    }}

                    onChange={(evt) => {

                        this.setState({ search: evt.target.value });
                    }}
                    style={{ padding: 8, border: 0, fontSize: 14, marginTop: 3, width: '100%' }}
                    placeholder={'Busque aqui...'}
                    onKeyDown={ async (evt) => {

                        if (evt.key === 'Enter') {
                            
                            if (this.state.search && !this.state.actualSearch.includes(evt.target.value)) {

                                let search = this.state.search;

                                await this.setState({ actualSearch: [...this.state.actualSearch, this.state.search], search: '' });
                                await this.handleSearch(search);

                            } else {

                                this.setState({ search: '' });
                            }
                        }

                    }}
                />
            </div>
        )
    }
}
