import React, { Component } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { Button, FormLabel, Table, TableHead, TableRow, TableCell, TableContainer, TableBody, IconButton, Tooltip } from '@material-ui/core';
import DefaultModal from './DefaultModal';
import Colors from '../constants/Colors';
import { toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CurrencyHelper from '../helper/CurrencyHelper';

export default class ExcelModal extends Component {
    state = {
        tableData: {
            cols: [],
            rows: []
        },
    }

    async importXlsx(event, callback) {
        let fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                toast.error("Houve um problema ao carregar", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {

                if (this.props.onFileChange) {

                    this.props.onFileChange(fileObj);
                }

                callback(resp);
            }
        });
    }

    openFileBrowser = () => {
        this.fileInput.click();
    }

    loadPreview = (tableData) => {
        if (tableData.cols && tableData.rows) {
            let data = {
                cols: tableData.cols,
                rows: []
            };

            tableData.rows.forEach((row, key) => {
                if (row.length > 0 && key > 0) {
                    data.rows.push(row);
                }
            })

            this.setState({ tableData: data });

            toast.success("Arquivo carregado", {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    }

    loadSaveData = () => {
        const tableData = {};
        Object.assign(tableData, this.state.tableData);
        Object.freeze(tableData);

        let saveData = [];

        if (tableData.rows.length) {
            tableData.rows.forEach((row) => {
                let saveObj = {};
                saveObj['id_company'] = SessionHelper.getData().id_company;
                this.props.cols.forEach((col, key) => {

                    if (row[key]) {
                        
                        let value = row[key];

                        if (col.validators && col.validators.length) {

                            col.validators.forEach((validator, key) => {

                                value = validator(value);
                            });
                        }

                        saveObj[col.name] = value;
                    }
                });
                
                if (saveObj) saveData.push(saveObj);
            });

            return saveData;
        }
    }

    removeRow(row) {
        let tableData = this.state.tableData;

        const index = tableData.rows.indexOf(row);

        if (index > -1) tableData.rows.splice(index, 1);

        this.setState({ tableData })
    }

    reorderCols(startIndex, endIndex) {

        let cols = this.props.cols;

        const result = Array.from(cols);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        cols = result;

        if (this.props.onColsChange) {

            this.props.onColsChange(cols);
        }
    };

    renderData(data, col) {

        let colData = this.props.cols[col];

        if (colData) {

            if (colData.money && data) {

                return CurrencyHelper.formatMoney(data, false);
            }
        }
        return data;
    }

    renderPreview() {
        if (this.state.tableData.rows.length > 0) {
            const totalColSpan = 100;
            let unitColSpan;

            unitColSpan = parseInt(totalColSpan / this.state.tableData.cols.length);

            let rowsToRender = this.state.tableData.rows, howMoreLinesComponent = null, limit = 500;

            if (this.state.tableData.rows.length > limit) {
                rowsToRender = rowsToRender.slice(0, limit);
                howMoreLinesComponent = <FormLabel>Máximo de linhas no preview ({limit}). Linhas restantes na Tabela: {this.state.tableData.rows.length - limit} linhas.</FormLabel>
            }

            return (
                <div>
                    <div style={{ marginBottom: 20, marginTop: 30 }}>
                        <FormLabel>Pré-visualização de Tabela <FormLabel error={true}>(Verifique se os dados estão corretos)</FormLabel></FormLabel>
                    </div>
                    <div style={{ maxHeight: 300, minHeight: 200, overflow: 'auto' }}>
                    <ReactDragListView onDragEnd={(from, to) => { this.reorderCols(from, to) }} nodeSelector={'th'} handleSelector={'.header-drag'} lineClassName={'drag-line'}>
                        <Table style={{ overflow: 'hidden' }} id={'excel-preview-table'} aria-label="spanning table">             
                            <TableHead>               
                                <TableRow colSpan={unitColSpan}>    
                                        
                                        {

                                        this.state.tableData.cols.map((col, key) => this.props.cols[key]
                                        
                                        ? 

                                            <TableCell align="center" style={{ fontWeight: '600' }}>
                                                {this.props.cols[key].alias}
                                                <Tooltip title={'Ordenar Coluna'}>
                                                    <CompareArrowsIcon style={{ color: 'grey', cursor: 'grab', marginLeft: 15 }} className={'header-drag'}/>
                                                </Tooltip>
                                            </TableCell>
                                        : 
                                            <TableCell align="center"></TableCell>)
                                        }

                                    <TableCell rowSpan={56}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData.rows.map(row => <TableRow colspan={unitColSpan}>{this.state.tableData.cols.map((col, colKey) => row[colKey] !== undefined ? <TableCell align="center">{ }{this.renderData(row[colKey], colKey)}</TableCell> : <TableCell align="center"></TableCell>)}<TableCell align="center"><IconButton color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true" onClick={() => { this.removeRow(row) }}><DeleteIcon style={{ color: 'red' }} /> </IconButton></TableCell></TableRow>)}
                            </TableBody>
                            </Table>
                        </ReactDragListView>
                        <br />
                        {howMoreLinesComponent}
                    </div>
                </div>

            )
        }
    }

    renderExampleTable() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.props.cols.length);

        return (
            <TableContainer>
                <Table aria-label="spanning table">
                    <TableHead>
                        <TableRow colspan={unitColSpan}>
                            {this.props.cols.map((col, key) => <TableCell align="center" style={{ fontWeight: '600' }}>{col.alias}{col.required ? `*` : ``}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow colspan={unitColSpan}>{this.props.cols.map((col, key) => <TableCell align="center">{col.example}</TableCell>)}</TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    renderContent() {
        return (
            <div>
                <div style={{ marginBottom: 20 }}>
                    <FormLabel error={true}>Campos marcados com * são obrigatórios</FormLabel>
                </div>
                <div style={{ marginBottom: 10 }}>
                    <FormLabel>Os Itens serão Importados da esquerda para a direita da seguinte forma {this.props.cols.map((col, key) => `${col.alias}${col.required ? `*` : ``}${key < this.props.cols.length - 1 ? `,` : `.`} `)} O formato deve ser como o demonstrado abaixo:</FormLabel>
                </div>
                <div style={{ marginBottom: 30 }}>
                    {this.renderExampleTable()}
                </div>
                <FormLabel>Escolha um arquivo:</FormLabel>
                <input type="file" accept=".xls,.xlsx" hidden onChange={(event) => { this.importXlsx(event, this.loadPreview) }} ref={(ref) => { this.fileInput = ref }} onClick={(event) => { event.target.value = null }} style={{ "padding": "10px" }} />
                <div style={{ marginTop: 10 }}>
                    <Button onClick={() => { this.openFileBrowser() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: 220, marginRight: '2%' }} variant={'contained'}>{'Escolha um arquivo'}</Button>
                    <FormLabel>{this.fileInput ? this.fileInput.value.split(/(\\|\/)/g).pop() : 'arquivo.xlsx'}</FormLabel>
                </div>
                {this.renderPreview()}

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.props.onClose(); this.props.saveCallback(this.loadSaveData(), this.fileInput ? this.fileInput.value.split(/(\\|\/)/g).pop() : 'arquivo.xlsx'); }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'IMPORTAR'}</Button>
                    <Button onClick={() => { this.setState({ tableData: { cols: [], rows: [] } }); this.props.onClose(); }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <DefaultModal loading={this.props.loading} content={this.renderContent()} title={'Importar do Excel (.xlsx)'} onClose={() => { this.setState({ tableData: { cols: [], rows: [] } }); this.props.onClose(); }} open={this.props.visible} width={'80%'} />
        )
    }
}