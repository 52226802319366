import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Colors from '../constants/Colors';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
        cursor: 'pointer',
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: Colors.primary,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: Colors.primary,
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function IosSwitch(props) {
    const [state, setState] = React.useState({
        checked: props.checked,
    });

    useEffect(() => {

        setState({ checked: props.checked })

    }, [props.checked]);

    const handleChange = (event) => {

        let value = event.target.checked;

        if (state.checked !== props.checked) {
            
            setState({ checked: value });
        }

        if (props.onChange) { props.onChange(value) }
    };

    return (

        <FormControlLabel
            style={{ width: props.fullWidth ? '100%' : '', margin: props.margin ? props.margin : 0 }}
            disabled={props.disabled || false}
            control={<IOSSwitch value={props.value || ''} checked={state.checked} onChange={handleChange} />}
            label={props.label || ''}
            labelPlacement={props.labelPlacement || 'end'}
        />
    )
}