import React, { Component } from 'react';
import { Box } from '@material-ui/core';

import { TableHead, TableRow, TableCell, FormLabel } from "@material-ui/core";
import CalculusHelper from "./CalculusHelper";
import MeanCell from '../components/MeanCell';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from './SessionHelper';
import MeanCellVariation from '../components/MeanCellVariation';

export default class TableHelper {
    static getTableHeader(dataset, hasMean, hasTotal) {
        return (
            <TableHead>
                <TableRow style={{ backgroundColor: 'lightgray' }}>
                    <TableCell align="left" style={{ backgroundColor: 'lightgray' }}>Período</TableCell>
                    {dataset.map(period => <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>{period.date_label}</TableCell>)}
                    {hasMean ? <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#a4b3a7' }}>Média (x̄)</TableCell> : null}
                    {hasTotal ? <TableCell align="center" style={{ fontWeight: 'bold', backgroundColor: '#a4b3a7' }}>Total (Σ)</TableCell> : null}
                </TableRow>
            </TableHead>
        );
    }

    static getTableFooter(title) {
        return (
            <div style={{ padding: 10, display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
                <FormLabel>{title}</FormLabel>
            </div>
        );
    }

    static async getMeanCellNOfElements(rows, field) {

        let nOfElements = 0;
        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            if (field === 'value_history') {
                if (row[field] && Math.abs(row[field]) > 0) {
                    nOfElements++;
                } else {
                    let dateLabel = row.date_label;
                    let historyDateLabel = dateLabel.split('/');
                    dateLabel = `${historyDateLabel[0]}/${(parseInt(historyDateLabel[1]) - 1).toString()}`;
                    
                    let balancete = await Firestore.customQuery('balancete_row').where('id_company', '==', SessionHelper.getData().id_company).where('date_label', '==', dateLabel).limit(1).get();
                    if (balancete.size === 1) {
                        nOfElements++;
                    }
                }
            } else {
                nOfElements++;
            }
        }

        return nOfElements === 0 ? 1 : nOfElements;
    }

    static getMeanCell(type, counter, rows, valueField = 'value', percentage = false, historyPercentage = false, comparativeRows = []) {

        return <MeanCell type={type} counter={counter} valueField={valueField} rows={rows} percentage={percentage} comparativeRows={comparativeRows}/>
    }

    static getMeanCellPercentageTable(rows, comparativeRows, valueField = 'value') {

        return <MeanCell type={'percentage'} counter={0} valueField={valueField} rows={rows} comparativeRows={comparativeRows} percentage={true} historyPercentage={true}/>
    }

    static getTotalCell(type, counter) {
        let total = counter;
        let formatted = CalculusHelper.formatType(type, CalculusHelper.formatFloat(total));;

        return <TableCell align="right" style={{ fontWeight: 'bold' }}>{formatted}</TableCell>
    }

    static getVariationRow(dataset, percentage, plannedCounter, historyCounter, moreIsBetter, calculusCallback, hasMean, hasTotal, formatPercentage = false, rows = [], comparativeRows = []) {
        let variationCounter = 0;

        return (
            <TableRow>
                <TableCell align="left" style={{ fontWeight: 'bold' }}>{percentage ? `Variação (%)` : `Variação ($)`}</TableCell>
                {dataset.map((period, key) => {
                    let variation = calculusCallback(period, key);
                    variationCounter += variation;

                    return (
                        <TableCell
                            align="right"
                            style={{ backgroundColor: moreIsBetter ? (variation > 0 ? '#a4b3a7' : '#d1aeae') : (variation < 0 ? '#a4b3a7' : '#d1aeae') }}
                        >
                            {CalculusHelper.formatVariation(variation, percentage, formatPercentage)}
                        </TableCell>
                    )
                })}

                {hasMean ?
                    <TableCell
                        align="right"
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: moreIsBetter ?
                                (CalculusHelper.variation(plannedCounter, historyCounter, percentage) > 0 ? '#a4b3a7' : '#d1aeae') :
                                (CalculusHelper.variation(plannedCounter, historyCounter, percentage) < 0 ? '#a4b3a7' : '#d1aeae')
                        }}
                    >
                        {formatPercentage ? CalculusHelper.formatVariation(CalculusHelper.percentageHistoryTotal(rows, comparativeRows, 'value') + CalculusHelper.percentageHistoryTotal(rows, comparativeRows, 'value_history'), percentage, formatPercentage) : <MeanCellVariation percentage={percentage} type={percentage ? 'percentage' : 'money'} rows={dataset}/>}
                    </TableCell>
                    : null}

                {hasTotal ?
                    <TableCell
                        align="right"
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: moreIsBetter ?
                                (CalculusHelper.variation(plannedCounter, historyCounter, percentage) > 0 ? '#a4b3a7' : '#d1aeae') :
                                (CalculusHelper.variation(plannedCounter, historyCounter, percentage) < 0 ? '#a4b3a7' : '#d1aeae')
                        }}
                    >
                        {CalculusHelper.formatVariation(CalculusHelper.variation(plannedCounter, historyCounter, percentage), percentage, formatPercentage)}
                    </TableCell>
                    : null}
            </TableRow>
        );
    }

    static TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                style={{ maxHeight: '100%', overflowY: 'auto' }}
                {...other}
            >
                {value === index && (
                    <Box>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }
}