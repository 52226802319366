import React, { Component } from 'react';
import { Button, FormLabel, TableRow, TableCell, TableContainer, TableBody, Table, TableHead, Checkbox, FormControlLabel } from '@material-ui/core';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import ExcelModal from '../components/ExcelModal';
import DescriptionIcon from '@material-ui/icons/Description';
import DataHelper from '../helper/DataHelper';
export default class RolePage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        description: '',
        active: true,
        loadingText: 'Carregando cargos...',
        excelModal: false,
        duplicates: [],
        codesIndex: [],
        duplicateModal: false,
        insertedCounter: 0,
        colsub: [
            { name: 'description', alias: 'Descrição', required: true, example: 'Desc. Qualquer' }
        ],
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('role').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async addNewDoc() {

        if (this.state.description && SessionHelper.getData().id_company) {

            if (this.state.docs.findIndex(item => item.description.toUpperCase() == this.state.description.toUpperCase()) < 0) {

                try {

                    this.setState({ loadingModal: true });
    
                    let data = {
                        description: this.state.description,
                        active: this.state.active,
                        id_company: SessionHelper.getData().id_company,
                        date: new Date(),
                    };
    
                    await Firestore.insert(data, 'role');
    
                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    await this.getDocs();

                    this.setState({
                        loadingModal: false,
                        addModal: false,
                        description: '',
                        active: true,
                    });
    
                } catch (error) {
    
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('Já existe um cargo cadastrado com a mesma descrição');
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <FormControlLabel
                    style={{ marginTop: 8 }}
                    control={<Checkbox checked={this.state.active} onChange={(v) => { this.setState({ active: v.target.checked }) }} />}
                    label={'Ativo'}
                />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, description: '', active: true }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    async canDelete(id) {

        let canDelete = true;

        let employee = await Firestore.customQuery('employee').where('role', '==', id).limit(1).get();

        if (employee.size > 0) {

            canDelete = false;
        }

        return canDelete;
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    roleExists = async (doc) => {
        let exists = false;
        let duplicates = this.state.duplicates;
        let codesIndex = this.state.codesIndex;

        if (doc.description) {
            if (codesIndex.length < 1) {
                const query = await Firestore.customQuery('role').where('id_company', '==', doc.id_company).get();

                if (query.docs && query.docs.length > 0) {
                    for (let document of query.docs) {
                        if (document.data().description) {
                            if (!codesIndex.includes(document.data().description)) codesIndex.push(document.data().description);
                        }
                    }
                }
            }

            if (codesIndex.includes(doc.description)) {
                exists = true;
                duplicates.push(doc);
            } else {
                exists = false;
                codesIndex.push(doc.code);
            }

        } else {
            exists = true;
            duplicates.push(doc);
        }

        await this.setState({ duplicates, codesIndex });

        return exists;
    }

    handleExcelImport = async (data) => {
        if (data && data.length > 0) {
            this.setState({ loading: true });

            try {

                let chunks = DataHelper.createSubArrays(data, 500);
                let count = 0;

                await Promise.all(chunks.map( async (chunk) => {
                
                    for (let i = 0; i < chunk.length; i++) {
    
                        chunk[i].active = true;
                        chunk[i].date = new Date();
    
                        if ((await this.roleExists(chunk[i])) == false) {
                            await Firestore.insert(chunk[i], 'role');
                        }

                        count++;
                        this.setState({ loadingText: `Inserindo ${count} de ${data.length}` });
                    }
                }));

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                this.setState({ insertedCounter: data.length - this.state.duplicates.length });

                toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });


            } catch (error) {

                console.log(error);

                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            await this.getDocs();

            this.setState({ loading: false, loadingText: `` });
        }

        this.setState({ codesIndex: [] });
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    <div style={{ marginRight: 10 }}>
                            <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                        </div>
                        <div style={{}}>
                            <DefaultButton leftIcon={<DescriptionIcon />} onClick={() => { this.setState({ excelModal: true }) }} title={'Importar do Excel (.xlsx)'} width={290} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Cargos'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Ativo', field: 'active', type: 'boolean' },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;

                        if (oldData.id) {

                            let data = {
                                description: newData.description,
                                active: newData.active,
                            };

                            await Firestore.update(data, 'role', oldData.id);
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ docs: prev });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        if ( await (this.canDelete(oldData.id))) {

                            let prev = this.state.docs;
                            prev.splice(prev.indexOf(oldData), 1);

                            this.setState({ docs: prev });

                            if (oldData.id) {

                                await Firestore.delete('role', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;
                            }

                        } else {

                            toast.warn('Existem colaboradores vinculados a esse cargo', { autoClose: false });
                            return;
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Cargo'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
