import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { IconButton, Tabs, Tab, Box, FormLabel, TextareaAutosize, RadioGroup, Radio, FormControlLabel, Button, TableHead, Table, TableRow, TableCell, TableContainer, TableBody, Tooltip, Select, MenuItem } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import DefaultModal from '../components/DefaultModal';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';
import { toast } from 'react-toastify';
import Colors from '../constants/Colors';
import DefaultButton from '../components/DefaultButton';
import LocationPicker from '../components/LocationPicker';
import GeographicHelper from '../helper/GeographicHelper';

export default class CompanyPage extends Component {

    state = {
        tab: 0,
        loading: false,
        logoHover: false,
        infoModal: false,
        name: SessionHelper.getData().company.name,
        email: SessionHelper.getData().company.email,
        phone: SessionHelper.getData().company.phone,
        cnpj: SessionHelper.getData().company.cnpj,
        type: SessionHelper.getData().company.type,
        zipcode: SessionHelper.getData().company.zipcode,
        country: SessionHelper.getData().company.country,
        state: SessionHelper.getData().company.state,
        city: SessionHelper.getData().company.city,
        street: SessionHelper.getData().company.street,
        ddd: SessionHelper.getData().company.ddd,
        number: SessionHelper.getData().company.number,
        complemento: SessionHelper.getData().company.complemento,
        neighborhood: SessionHelper.getData().company.neighborhood,
        description: SessionHelper.getData().company.description,
        logo: SessionHelper.getData().company.logo,
        coordinates: SessionHelper.getData().company.coordinates ? SessionHelper.getData().company.coordinates : GeographicHelper.setFirebaseGeopoint(-27, -52),
        regime_tributario: SessionHelper.getData().company.regime_tributario ? SessionHelper.getData().company.regime_tributario : "simples",
        loadingModal: false,
        color: SessionHelper.getData().company.color || Colors.primary,
        startColor: SessionHelper.getData().company.color || Colors.primary,
    }

    async componentDidMount() {


    }

    handleChange(index) {
        this.setState({ tab: index });
    }

    TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    async upload(file) {

        if (file) {

            try {

                this.setState({ loading: true });

                let ref = await Storage.uploadFile('logo' || '', file);
                let downloadPath = await Storage.getFileFromRef(ref);

                if (!downloadPath) {
                    throw new Error('upload error');
                }

                await Firestore.update({ logo: downloadPath }, 'company', SessionHelper.getData().id_company);

                let data = SessionHelper.getData();
                data.company.logo = downloadPath;

                this.setState({ logo: downloadPath, loading: false });

                toast.success("Imagem adicionada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

            } catch (error) {

                toast.error("Houve um problema ao salvar a imagem", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loading: false });
            }
        }
    }

    async save() {

        if (this.state.name && this.state.email && this.state.street && this.state.neighborhood && this.state.number) {

            try {


                this.setState({ loading: true });

                let data = {
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone ? this.state.phone : '',
                    street: this.state.street,
                    neighborhood: this.state.neighborhood,
                    number: this.state.number,
                    complemento: this.state.complemento,
                    description: this.state.description ? this.state.description : '',
                    zipcode: this.state.zipcode,
                    country: this.state.country,
                    coordinates: this.state.coordinates,
                    state: this.state.state,
                    city: this.state.city,
                    ddd: this.state.ddd ? this.state.ddd : '',
                    regime_tributario: this.state.regime_tributario,
                    type: this.state.type,
                    color: this.state.color || Colors.primary,
                };

                await Firestore.geoUpdate(data, 'company', SessionHelper.getData().id_company,
                    GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lat,
                    GeographicHelper.getLatLngFromGeopoint(this.state.coordinates).lng
                );

                toast.success('Dados atualizados com sucesso');

                if (this.state.color !== this.state.startColor) {

                    toast.warn('Recarregue a página para atualizar a cor padrão do sistema', {autoClose: false});
                }

                this.setState({ loading: false });

            } catch (error) {
                console.log(error)

                toast.error('Erro ao atualizar dados');
                this.setState({ loading: false });
            }

        } else {

            toast.warn('Preencha todos os campos');
        }


    }

    async saveImages(files) {

        if (files.length) {

            await Firestore.update({ images: files }, 'company', SessionHelper.getData().id_company);
        }
    }

    renderLogo() {
        return (
            <div style={{ paddingBottom: 28, display: 'flex' }}>
                <input
                    multiple={false}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    type="file"
                    onChange={(v) => { this.upload(v.target.files[0]) }}
                />
                <label htmlFor="contained-button-file">

                    {!this.state.logo ?

                        <div style={{ height: 150, width: 350, backgroundColor: '#ededed', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                            <AddIcon style={{ fontSize: 40, color: 'grey' }} />
                        </div>

                        :

                        <div onMouseOver={() => { this.setState({ logoHover: true }) }} onMouseLeave={() => { this.setState({ logoHover: false }) }} style={{ position: 'relative', height: 150, width: 350, border: '1px solid lightgrey', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10, backgroundColor: Colors.dark }}>
                            {this.state.logoHover && <EditIcon style={{ color: Colors.light, cursor: 'pointer', position: 'absolute', marginBottom: 'auto', marginLeft: 'auto', margin: 5, marginTop: 15, fontSize: 30 }} />}
                            <img src={this.state.logo} style={{ height: 150, width: 350, padding: 25, }} />
                        </div>

                    }

                </label>
            </div>
        )
    }

    renderCompanyData() {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                    <div style={{ width: '60%' }}>
                        <FormLabel style={{ paddingBottom: 18 }} component="legend">Dados da Empresa</FormLabel>
                        <DefaultInput value={this.state.name} onChange={(text) => { this.setState({ name: text }) }} label={'Nome'} />
                        <DefaultInput value={this.state.email} onChange={(text) => { this.setState({ email: text }) }} label={'E-mail'} />

                        <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                            <DefaultInput value={this.state.ddd} onChange={(text) => { this.setState({ ddd: text }) }} label={'DDD'} />
                            <div style={{ marginLeft: 3, marginRight: 3 }} />
                            <DefaultInput value={this.state.phone} onChange={(text) => { this.setState({ phone: text }) }} label={'Telefone'} />
                        </div>

                        <DefaultInput value={this.state.cnpj} disabled={true} label={'Cnpj'} />

                        <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Descrição</FormLabel>
                        <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.description} onChange={(v) => { this.setState({ description: v.target.value }) }} placeholder="Escreva uma descrição..." />
                    </div>
                    <div style={{ marginLeft: 50 }}>
                        <FormLabel style={{ paddingBottom: 18 }} component="legend">Logo</FormLabel>
                        {this.renderLogo()}
                        <FormLabel style={{ fontSize: 12 }} component="legend">*Para melhor visualização, utilize de preferência uma imagem com tamanho de 550 por 400 pixels (Proporção 21:9).</FormLabel>
                        <FormLabel style={{ paddingBottom: 6, marginTop: 40 }} component="legend">Cor padrão</FormLabel>
                        <input onChange={(v) => { this.setState({ color: v.target.value }) }} style={{ width: 100, height: 50, marginTop: 10 }} value={this.state.color} type={'color'}/>
                    </div>
                </div>

                <FormLabel style={{ marginTop: 18 }} component="legend">Localização da Empresa</FormLabel>
                <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.coordinates)} />

                <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Endereço</FormLabel>
                <DefaultInput value={this.state.zipcode} disabled={false} onChange={(text) => { this.setState({ zipcode: text }) }} label={'Cep'} />

                <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    <DefaultInput value={this.state.country} disabled={true} onChange={(text) => { this.setState({ country: text }) }} label={'País'} />
                    <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                    <DefaultInput value={this.state.state} disabled label={'Estado'} />
                </div>

                <DefaultInput value={this.state.city} disabled={true} label={'Cidade'} />

                <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    <DefaultInput value={this.state.street} onChange={(text) => { this.setState({ street: text }) }} label={'Rua'} />
                    <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                    <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                        <DefaultInput value={this.state.number} onChange={(text) => { this.setState({ number: text }) }} label={'Número'} />
                    </div>
                    <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                        <DefaultInput value={this.state.complemento} onChange={(text) => { this.setState({ complemento: text }) }} label={'Complemento'} />
                    </div>
                </div>

                <DefaultInput value={this.state.neighborhood} onChange={(text) => { this.setState({ neighborhood: text }) }} label={'Bairro'} />

                <div style={{ paddingTop: 18 }}>
                    <DefaultButton width={'100%'} onClick={() => { this.save() }} title={'Salvar'} />
                </div>
            </div>
        )
    }

    handleMapChange = (geodata) => {
        let state = this.state;

        let keys = ['zipcode', 'country', 'state', 'city', 'street', 'number', 'complemento', 'neighborhood'];

        keys.forEach(key => { state[key] = '' });

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = GeographicHelper.setFirebaseGeopoint(geodata.coords.lat, geodata.coords.lng);
        }

        this.setState(state);
    }

    infoModal() {
        return (
            <div>
                <div style={{ paddingTop: 18 }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={17} />
                                    <TableCell align="center" colSpan={9}>
                                        Setor
                                    </TableCell>
                                    <TableCell colSpan={9} />
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={17} />
                                    <TableCell align="center" colSpan={3}>
                                        Indústria
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        Comércio
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        Serviços
                                    </TableCell>
                                    <TableCell align="center" colSpan={9}>
                                        Alíquotas sobre Base de Cálculo
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" colSpan={5}>
                                        Regime
                                    </TableCell>
                                    <TableCell align="center" colSpan={21}>
                                        Base de Cálculo
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        IRPJ
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        CSLL
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        IRPJ Adicional
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left" colSpan={5}>
                                        Lucro Presumido
                                    </TableCell>
                                    <TableCell align="left" colSpan={12}>
                                        Receita Bruta
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        8%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        8%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        32%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        15%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        9%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        10%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" colSpan={5}>
                                        Lucro Real
                                    </TableCell>
                                    <TableCell align="left" colSpan={12}>
                                        Resultado Antes de Imposto
                                    </TableCell>
                                    <TableCell align="center" colSpan={9}>
                                        100%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        15%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        9%
                                    </TableCell>
                                    <TableCell align="center" colSpan={3}>
                                        10%
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" colSpan={5}>
                                        SIMPLES
                                    </TableCell>
                                    <TableCell align="left" colSpan={30}>
                                        Não projetar IRPJ e CSLL - SIMPLES será calculado como imposto sobre receita
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ infoModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%', backgroundColor: Colors.primary, color: '#fff' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    renderTributarianRegime() {
        return (
            <div style={{ paddingTop: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'center', lineHeight: 32 }}>
                    <FormLabel style={{ marginTop: 5 }} component="legend">Enquadramento de Regime Tributário para Orçamento</FormLabel>
                    <IconButton style={{ marginTop: -11 }} color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true" onClick={() => { this.setState({ infoModal: true }) }}>
                        <InfoIcon style={{ color: Colors.primary }} />
                    </IconButton>
                </div>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="sex" name="sex" value={this.state.regime_tributario} onChange={(evt) => { this.setState({ regime_tributario: evt.target.value }) }}>
                    <FormControlLabel value="lucro_presumido" control={<Radio style={{ color: Colors.primary }} />} label="Lucro Presumido" />
                    <FormControlLabel value="lucro_real" control={<Radio style={{ color: Colors.primary }} />} label="Lucro Real" />
                    <FormControlLabel value="simples" control={<Radio style={{ color: Colors.primary }} />} label="Simples" />
                </RadioGroup>
            </div>
        );
    }

    renderCompanyType() {
        return (
            <div style={{ paddingTop: 8 }}>
                <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'center', lineHeight: 32 }}>
                    <FormLabel style={{ marginTop: 5 }} component="legend">Tipo de Empresa</FormLabel>
                </div>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="sex" name="sex" value={this.state.type} onChange={(evt) => { this.setState({ type: evt.target.value }) }}>
                    <FormControlLabel value="industria" control={<Radio style={{ color: Colors.primary }} />} label="Indústria" />
                    <FormControlLabel value="comercio" control={<Radio style={{ color: Colors.primary }} />} label="Comércio" />
                    <FormControlLabel value="servico" control={<Radio style={{ color: Colors.primary }} />} label="Serviços" />
                </RadioGroup>
            </div>
        );
    }

    render() {
        return this.state.loading ? <DefaultLoader loadingText={this.state.loadingText}/> : (
            <div style={styles.container}>
                <Box boxShadow={3}>
                    <Tabs
                        style={{ backgroundColor: '#fff' }}
                        value={this.state.tab}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: Colors.primary } }}
                        onChange={(event, index) => { this.handleChange(index) }}>
                        <Tab label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary }}>Dados Cadastrais</span>} />
                        <Tab label={<span style={{ fontWeight: this.state.tab === 1 ? 'bold' : '', color: Colors.primary }}>Configurações Fiscais</span>} />
                    </Tabs>
                    <this.TabPanel value={this.state.tab} index={0}>
                        {this.renderCompanyData()}
                    </this.TabPanel>
                    <this.TabPanel value={this.state.tab} index={1}>
                        {this.renderTributarianRegime()}
                        {this.renderCompanyType()}
                        <div style={{ paddingTop: 18 }}>
                            <DefaultButton width={'100%'} onClick={() => { this.save() }} title={'Salvar'} />
                        </div>
                    </this.TabPanel>
                </Box>
                <DefaultModal width={'70%'} loading={false} content={this.infoModal()} title={'Informações de Enquadramento de Regime Tributário para Orçamento'} onClose={() => { this.setState({ infoModal: false }) }} open={this.state.infoModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
