import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';

export default class DissidioPage extends Component {

    state = {
        docs: [],
        userDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        date: null,
        percentage: '',
        active: true,
        loadingText: 'Carregando...',
    }

    async getUsers() {

        let query = Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company);

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('dissidio').orderBy('date', 'desc').where('id_company', '==', SessionHelper.getData().id_company);

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {

        await this.getUsers();
        await this.getDocs();

        this.setState({ loading: false });
    }

    async addNewDoc() {

        if (this.state.percentage && this.state.date && SessionHelper.getData().id_company) {

            if (Number.isInteger(parseInt(this.state.percentage))) {

                try {
                    this.setState({ loadingModal: true });

                    let data = {
                        id_company: SessionHelper.getData().id_company,
                        id_user: SessionHelper.getFirebaseAuth().uid,
                        percentage: this.state.percentage,
                        date: this.state.date,
                        created_at: new Date(),
                    };

                    await Firestore.insert(data, 'dissidio');

                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    await this.getDocs();

                    this.setState({ addModal: false, date: null, percentage: '', loadingModal: false });

                } catch (error) {

                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('A porcentagem deve ser um número inteiro')
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({ loadingModal: false });
        }
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <DefaultInput required={true} label={'Porcentagem'} onBlur={(v) => { this.setState({ percentage: v }) }} />
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
                            invalidDateMessage={false}
                            format={'dd/MM/yyyy'}
                            autoOk={true}
                            label="Data"
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={async (v) => { await this.setState({ date: v }) }}
                            value={this.state.date}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false, percentage: '', date: null }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderDateEdit(props) {

        let value = props.value;

        if (value.seconds) {

            value = new Date(value.seconds * 1000);
        }

        return (
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    style={{ width: '100%' }}
                    invalidDateMessage={false}
                    format={'dd/MM/yyyy'}
                    autoOk={true}
                    cancelLabel={'Cancelar'}
                    okLabel={'Confirmar'}
                    onChange={(v) => { props.onChange(v) }}
                    value={value}
                />
            </MuiPickersUtilsProvider>
        )
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>

                <DefaultTable
                    title={'Dissídio'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Porcentagem', field: 'percentage', render: rowData => <div>{rowData.percentage ? `${rowData.percentage}%` : ''}</div> },
                        { title: 'Data', field: 'date', render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY')}</div>, editComponent: props => this.renderDateEdit(props) },
                        { title: 'Usuário', field: 'id_user', editable: false, render: rowData => <div>{rowData.id_user ? this.state.userDocs.find(item => item.id === rowData.id_user).name : ''}</div> },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;

                        newData.date = newData.date === null ? null : newData.date.seconds ? new Date(newData.date.seconds * 1000) : newData.date;

                        if (oldData.id) {

                            if (Number.isInteger(parseInt(newData.percentage))) {

                                let data = {
                                    percentage: newData.percentage,
                                    date: newData.date,
                                };

                                await Firestore.update(data, 'dissidio', oldData.id);

                                toast.success("Editado com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                prev[prev.indexOf(oldData)] = newData;

                                this.setState({ docs: prev });

                                return prev;

                            } else {

                                toast.warn('A porcentagem deve ser um valor inteiro');
                                return;
                            }
                        }
                    }}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            await Firestore.delete('dissidio', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            return prev;
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Dissídio'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
