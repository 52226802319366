import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import IosSwitch from '../components/IosSwitch';
import { Card, MenuItem, Select } from '@material-ui/core';
import DefaultDashboard from '../components/dashboard/DefaultDashboard';
import Firestore from '../api/firebase/Firestore';
import Colors from '../constants/Colors';
import SessionHelper from '../helper/SessionHelper';
import UserDashboard from '../components/dashboard/UserDashboard';

export default class HomePage extends Component {

    state = {
        dashboard: null,
        defaultDashboard: 'default',
        defaultDashboardCheckbox: false,
    }

    async componentDidMount() {

        await this.getParams();
        await this.getDefaultDashboard();
    }

    async getParams() {

        let query = await Firestore.getDoc('user', SessionHelper.getFirebaseAuth().uid);
        let defaultDashboard = 'default';

        if (query) {

            defaultDashboard = query.data().default_dashboard || 'default';
        }

        this.setState({ defaultDashboard: defaultDashboard, dashboard: defaultDashboard });
    }

    async getDefaultDashboard() {

        if (this.state.dashboard === this.state.defaultDashboard) {

            this.setState({ defaultDashboardCheckbox: true });

        } else {

            this.setState({ defaultDashboardCheckbox: false });
        }
    }

    async setDefaultDashboard() {

        let dashboard = this.state.dashboard;

        await this.setState({ defaultDashboard: dashboard });
        await Firestore.update({ default_dashboard: dashboard }, 'user', SessionHelper.getFirebaseAuth().uid);
        await this.getDefaultDashboard();
    }


    render() {
        return this.state.loading ? <DefaultLoader /> : (
            
            <div style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card style={{ paddingLeft: 25, paddingTop: 25, paddingBottom: 25, paddingRight: 25, width: '100%', height: 80, marginBottom: 8, display: 'flex', flexDirection: 'row', boxShadow: Colors.boxShadow }}>
                    <Select
                    style={{width: '100%', height: '100%', marginBottom: 25, marginRight: 25}}
                    labelId="tipo"
                    value={this.state.dashboard}
                    onChange={ async (v) => { await this.setState({ dashboard: v.target.value }); this.getDefaultDashboard(v.target.value); }}>
                            <MenuItem value={'default'}>{'GRÁFICOS DA EMPRESA'}</MenuItem>
                            <MenuItem value={'user'}>{'MEUS GRÁFICOS'}</MenuItem>
                    </Select>
                    <IosSwitch onChange={(v) => { this.setDefaultDashboard() }} disabled={this.state.defaultDashboardCheckbox} label={window.screen.width < 1366 ? `` : `Padrão`} labelPlacement={'start'} checked={this.state.defaultDashboardCheckbox} />
                </Card>
                { this.state.dashboard === 'default' && <DefaultDashboard/> }
                { this.state.dashboard === 'user' && <UserDashboard/> }
            </div>
        );
    }
}
