import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, TextareaAutosize, TableRow, TableCell, TableContainer, TableBody, Table, TableHead, InputLabel, Tooltip, IconButton } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import ExcelModal from '../components/ExcelModal';
import ProfileCard from '../components/ProfileCard';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import DefaultSelect from '../components/DefaultSelect';
import IosSwitch from '../components/IosSwitch';
import MeasurementUnits from '../constants/MeasurementUnits';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import ExcelImportValidators from '../constants/ExcelImportValidators';
import DataHelper from '../helper/DataHelper';

export default class ProductPage extends Component {

    state = {
        docs: [],
        accountPlanDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        importVariablesModal: false,
        permissioneEdit: null,
        editId: null,
        description: null,
        code: null,
        accountPlan: null,
        measurement_unit: ``,
        planning_methodology: ``,
        type: ``,
        observation: '',
        excelModal: false,
        loadingText: '',
        duplicates: [],
        codesIndex: [],
        duplicateModal: false,
        insertedCounter: 0,
        colsub: [
            { name: 'description', alias: 'Descrição', required: true, example: 'Desc. Qualquer' },
            { name: 'code', alias: 'Código', required: true, example: '42', validators: [ExcelImportValidators.trim] },
            { name: 'observation', alias: 'Observação', example: 'Obs. Qualquer' }
        ],
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getAccountPlanDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
        await this.getAccountPlanDocs();
    }

    async addNewDoc() {

        if (this.state.description && this.state.code && this.state.measurement_unit && this.state.type && this.state.planning_methodology && SessionHelper.getData().id_company) {

            let data = {
                description: this.state.description,
                code: this.state.code,
                measurement_unit: this.state.measurement_unit,
                type: this.state.type,
                planning_methodology: this.state.planning_methodology,
                id_account_plan: this.state.accountPlan,
                observation: this.state.observation,
                id_company: SessionHelper.getData().id_company
            };

            try {
                this.setState({ loadingModal: true });

                const codeExists = await this.codeExists(data);

                if (codeExists == false) {
                    await Firestore.insert(data, 'product');
                } else {
                    toast.warn("Este código já existe", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({ loadingModal: false });

                    return;
                }

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.getDocs();
            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false,
            addModal: false,
            description: null,
            code: null,
            observation: ``,
            codesIndex: []
        });
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <DefaultInput required={true} label={'Código'} onBlur={(v) => { this.setState({ code: v }) }} />
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.type}
                        onChange={(v) => {
                            this.setState({ type: v.target.value })
                        }}
                        docs={Object.keys(this.getProductTypeLookup()).map(key => ({ name: this.getProductTypeLookup()[key], key }))}
                        label={'Tipo*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.planning_methodology}
                        onChange={(v) => {
                            this.setState({ planning_methodology: v.target.value })
                        }}
                        docs={Object.keys(this.getProductMethodologyLookup()).map(key => ({ name: this.getProductMethodologyLookup()[key], key }))}
                        label={'Metodologia de Planejamento*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        searchField={'name'}
                        valueField={'key'}
                        value={this.state.measurement_unit}
                        onChange={(v) => {
                            this.setState({ measurement_unit: v.target.value })
                        }}
                        docs={Object.keys(this.getMeasurementUnitLookup()).map(key => ({ name: this.getMeasurementUnitLookup()[key], key }))}
                        label={'Unidade de Medida*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        secondaryDisplay={'code'}
                        displayField={'description'}
                        searchField={'description'}
                        valueField={'id'}
                        value={this.state.accountPlan}
                        onChange={(v) => {
                            this.setState({ accountPlan: v.target.value })
                        }}
                        docs={this.state.accountPlanDocs}
                        label={'Conta Contábil'}
                    />
                </div>

                <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Observação</FormLabel>
                <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.observation} onChange={(v) => { this.setState({ observation: v.target.value }) }} placeholder="Escreva uma descrição..." />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, description: null, code: null, observation: `` }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    selectImportTypeModal() {
        return (
            <div>
                <div style={{ marginBottom: 10 }}>
                    <FormLabel>Defina abaixo as variáveis de importação (Tipo, Metodologia de Planejamento, Unidade de Medida e Conta Contábil) que serão aplicadas a todos os Produtos ou Serviços da importação a seguir.
                    <br/><br/>Caso você queira importar Produtos ou Serviços com diferentes variáveis de importação, será necessário importar com arquivos separados.
                    </FormLabel>
                </div>
                <div style={{ marginTop: 30, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.type}
                        onChange={(v) => {
                            this.setState({ type: v.target.value })
                        }}
                        docs={Object.keys(this.getProductTypeLookup()).map(key => ({ name: this.getProductTypeLookup()[key], key }))}
                        label={'Tipo*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.planning_methodology}
                        onChange={(v) => {
                            this.setState({ planning_methodology: v.target.value })
                        }}
                        docs={Object.keys(this.getProductMethodologyLookup()).map(key => ({ name: this.getProductMethodologyLookup()[key], key }))}
                        label={'Metodologia de Planejamento*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        searchField={'name'}
                        valueField={'key'}
                        value={this.state.measurement_unit}
                        onChange={(v) => {
                            this.setState({ measurement_unit: v.target.value })
                        }}
                        docs={Object.keys(this.getMeasurementUnitLookup()).map(key => ({ name: this.getMeasurementUnitLookup()[key], key }))}
                        label={'Unidade de Medida*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        displayField={'description'}
                        valueField={'id'}
                        secondaryDisplay={'code'}
                        value={this.state.accountPlan}
                        onChange={(v) => {
                            this.setState({ accountPlan: v.target.value })
                        }}
                        docs={this.state.accountPlanDocs}
                        label={'Conta Contábil*'}
                    />
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.goToExcelImportation() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'SEGUIR PARA A IMPORTAÇÃO'}</Button>
                    <Button onClick={() => { this.setState({ importVariablesModal: false, description: null, code: null, observation: `` }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    goToExcelImportation = () => {
        if (this.state.measurement_unit && this.state.type && this.state.planning_methodology && this.state.accountPlan) {
            this.setState({ importVariablesModal: false, excelModal: true });
        } else {
            toast.warn("Preencha as variáveis de importação", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    codeExists = async (doc) => {
        let exists = false;
        let duplicates = this.state.duplicates;
        let codesIndex = this.state.codesIndex;

        if (doc.code) {
            if (codesIndex.length < 1) {
                const query = await Firestore.customQuery('product').where('id_company', '==', doc.id_company).get();

                if (query.docs && query.docs.length > 0) {
                    for (let document of query.docs) {
                        if (document.data().code) {
                            if (!codesIndex.includes(document.data().code)) codesIndex.push(document.data().code);
                        }
                    }
                }
            }

            if (codesIndex.includes(`${doc.code}`) || codesIndex.includes(doc.code)) {
                exists = true;
                duplicates.push(doc);
            } else {
                exists = false;
                codesIndex.push(doc.code);
            }

        } else {
            exists = true;
            duplicates.push(doc);
        }

        await this.setState({ duplicates, codesIndex });

        return exists;
    }

    handleExcelImport = async (data) => {
        if (data.length > 0) {
            this.setState({ loading: true });

            try {

                let chunks = DataHelper.createSubArrays(data, 500);
                let count = 0;

                await Promise.all(chunks.map( async (chunk) => {
                
                    for (let i = 0; i < chunk.length; i++) {
    
                        chunk[i].measurement_unit = this.state.measurement_unit;
                        chunk[i].type = this.state.type;
                        chunk[i].planning_methodology = this.state.planning_methodology;
                        chunk[i].id_account_plan = this.state.accountPlan;
    
                        if ((await this.codeExists(chunk[i])) == false) {
                            chunk[i].code = chunk[i].code.toString();
                            await Firestore.insert(chunk[i], 'product');
                        }

                        count++;
                        this.setState({ loadingText: `Inserindo ${count} de ${data.length}` });
                    }
                }));

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                this.setState({ insertedCounter: data.length - this.state.duplicates.length });

                toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });


            } catch (error) {

                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            await this.getDocs();

            this.setState({ loading: false, loadingText: `` });
        }

        this.setState({ codesIndex: [] });
    }

    getProductTypeLookup() {
        return {
            'product': 'Produto',
            'service': 'Serviço'
        }
    }

    getProductMethodologyLookup() {
        return {
            'multiply': 'Quantidade x Preço',
            'absolute': 'Lançamento da Receita'
        }
    }

    getAccountPlanLookup() {

        let lookup = {};

        this.state.accountPlanDocs.forEach((accountPlan, key) => {

            lookup[accountPlan.id] = accountPlan.description;
        });

        return lookup;
    }

    getMeasurementUnitLookup() {
        return MeasurementUnits;
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <div style={{ marginRight: 10 }}>
                            <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                        </div>
                        <div style={{ }}>
                            <DefaultButton leftIcon={<DescriptionIcon/>} onClick={() => { this.setState({ importVariablesModal: true }) }} title={'Importar do Excel (.xlsx)'} width={290} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Produtos e Serviços'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Código', field: 'code' },
                        { title: 'Tipo', field: 'type', lookup: this.getProductTypeLookup() },
                        { title: 'Metodologia de Planejamento', field: 'planning_methodology', lookup: this.getProductMethodologyLookup() },
                        { title: 'Unidade de Medida', field: 'measurement_unit', lookup: this.getMeasurementUnitLookup() },
                        { title: 'Conta Contábil', field: 'id_account_plan', lookup: this.getAccountPlanLookup() },
                        { title: 'Observação', field: 'observation' },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if (oldData.code != newData.code && await this.codeExists(newData)) {
                            toast.warn("O código informado já existe", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return;
                        }

                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev[index] = newData;

                        this.setState({ docs: prev })
                        if (oldData.id) {

                            let data = {
                                description: newData.description,
                                code: newData.code,
                                observation: newData.observation,
                                measurement_unit: newData.measurement_unit,
                                type: newData.type,
                                planning_methodology: newData.planning_methodology,
                                id_account_plan: newData.id_account_plan || null,
                            };

                            await Firestore.update(data, 'product', oldData.id);

                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.forceUpdate();

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {
                            await Firestore.delete('product', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Produto/Serviço'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.selectImportTypeModal()} title={'Selecionar variáveis de importação'} onClose={() => { this.setState({ importVariablesModal: false }) }} open={this.state.importVariablesModal} />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
