import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import ExcelModal from '../components/ExcelModal';
import moment from 'moment';
import DataHelper from '../helper/DataHelper';
import { FormLabel, Button, TableHead, Table, TableRow, TableCell, TableContainer, TableBody } from '@material-ui/core';
import Colors from '../constants/Colors';
import DefaultModal from '../components/DefaultModal';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RelationField from '../components/RelationField';
import DefaultInput from '../components/DefaultInput';
import ExcelImportValidators from '../constants/ExcelImportValidators';

export default class BudgetImportPage extends Component {

    state = {
        docs: [],
        loading: true,
        excelModal: false,
        importModal: false,
        duplicateModal: false,
        duplicates: [],
        insertedCounter: 0,
        loadingText: '',
        colsub: [
            { name: 'type', alias: 'Tipo', required: true, example: 'Receitas', validators: [ExcelImportValidators.trim] },
            { name: 'result_center_code', alias: 'Código CR', required: true, example: '3.1', validators: [ExcelImportValidators.trim] },
            { name: 'result_center_description', alias: 'Descrição CR', required: true, example: 'Centro de Resultado 1' },
            { name: 'account_code', alias: 'Código Conta', required: true, example: '3.1', validators: [ExcelImportValidators.trim] },
            { name: 'account_description', alias: 'Descrição Conta', required: true, example: 'Conta Contábil 1' },
            { name: 'product_code', alias: 'Código Produto', required: false, example: '3.1', validators: [ExcelImportValidators.trim] },
            { name: 'product_description', alias: 'Descrição Produto', required: false, example: 'Produto 1' },
        ],
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().startOf('month').startOf('day').toDate(),
        months: [],
        description: '',
        accountPlanDocs: [],
        resultCenterDocs: [],
        productDocs: [],
    }

    async componentDidMount() {
        await this.getDocs();
        await this.getAccountPlans();
        await this.getResultCenters();
        await this.getProducts();

        this.setState({ loading: false });
    }

    async getAccountPlans() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs });
    }

    async getProducts() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ productDocs: docs });
    }

    async getResultCenters() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('result_center').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ resultCenterDocs: docs });
    }

    async getDocs() {

        let query = await Firestore.customQuery('budget_import').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async revert(id) {

        try {

            if (!id) { throw new Error('Missing id') };

            toast.warn('Esse processo pode levar um tempo, por favor aguarde...', { autoClose: false });

            let planningQuery = await Firestore.customQuery('planning').where('id_company', '==', SessionHelper.getData().id_company).where('id_budget_import', '==', id).get();
            let planningRowQuery = await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('id_budget_import', '==', id).get();

            let docs = [...DataHelper.createSubArrays(planningQuery.docs, 250), ...DataHelper.createSubArrays(planningRowQuery.docs, 250)];
            await Promise.all(docs.map( async (chunk) => { await this.removePlanningChunk(chunk) }));

            await Firestore.delete('budget_import', id);
            await this.getDocs();

            toast.success('Removido com sucesso');
            
        } catch (error) {

            toast.error('Houve um problema ao reverter a importação');
        }
    }

    async removePlanningChunk(chunk) {

        for (let index = 0; index < chunk.length; index++) {

            const doc = chunk[index];
            await doc.ref.delete();
        }
    }

    getType(label) {

        let response = '';

        if (label === 'Receitas') {

            response = 'revenue_planning';

        } else if (label === 'Custos') {

            response = 'costs';

        } else if (label === 'Despesas de Pessoal') {

            response = 'people_expenses';

        } else if (label === 'Despesas') {

            response = 'expenses';

        } else if (label === 'Despesas e Receitas Financeiras') {

            response = 'expenses_and_revenue';

        } else if (label === 'Outras Despesas e Receitas') {

            response = 'other_expenses_and_revenue';

        } else if (label === 'Impostos') {

            response = 'taxes';

        } else if (label === 'Deduções') {

            response = 'deduction';
        }

        return response;
    }

    getOperator(label, value) {

        let response = '';

        if (label === 'Receitas') {

            response = 'sum';

        } else if (label === 'Custos') {

            response = 'minus';

        } else if (label === 'Despesas de Pessoal') {

            response = 'minus';

        } else if (label === 'Despesas') {

            response = 'minus';

        } else if (label === 'Despesas e Receitas Financeiras') {

            response = value >= 0 ? 'minus' : 'sum';

        } else if (label === 'Outras Despesas e Receitas') {

            response = value >= 0 ? 'minus' : 'sum';

        } else if (label === 'Impostos') {

            response = 'minus';

        } else if (label === 'Deduções') {

            response = 'minus';
        }

        return response;
    }

    handleExcelImport = async (data) => {
        if (data.length > 0) {
            this.setState({ loading: true });

            try {

                let accountDocs = DataHelper.createObjectFromArray(this.state.accountPlanDocs, 'code');
                let resultCenterDocs = DataHelper.createObjectFromArray(this.state.resultCenterDocs, 'code');
                let productDocs = DataHelper.createObjectFromArray(this.state.productDocs, 'code');
                let budgetImportId = await Firestore.getId('budget_import');
                let count = 0;

                let importedMonths = [];

                this.state.months.forEach((month, key) => {

                    importedMonths.push({
                        alias: month.alias,
                        date: month.date,
                        name: month.name,
                    });
                });

                let importData = {
                    description: this.state.description,
                    id_company: SessionHelper.getData().id_company,
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    months: importedMonths,
                    count: 0,
                    date: new Date(),
                }

                await Firestore.insert(importData, 'budget_import', budgetImportId);
                let chunks = DataHelper.createSubArrays(data, 500);

                await Promise.all(chunks.map( async (chunk) => {

                    for (let i = 0; i < chunk.length; i++) {
    
                        let months = [];

                        this.state.months.forEach((month, key) => {

                            months.push(chunk[i][month.name]);
                        });

                        let account = null;
                        let resultCenter = null;
                        let idResultCenters = [];
                        let productId = null;
                        let idProducts = [];
                        let parentId = await Firestore.getId('planning');
                        let description = '';
                        let type = this.getType(chunk[i].type);
    
                        if (chunk[i].account_code) {
    
                            account = accountDocs[chunk[i].account_code];
                        }
    
                        if (chunk[i].result_center_code) {
    
                            resultCenter = resultCenterDocs[chunk[i].result_center_code];
                            idResultCenters.push(resultCenter ? resultCenter.id : null);
                        }
    
                        if (chunk[i].product_code) {
    
                            let product = productDocs[chunk[i].product_code];
                            productId = product ? product.id : null;
                            idProducts.push(productId);
                        }
    
                        description = account ? account.description : resultCenter ? resultCenter.description : 'Importação'

                        let start = this.state.months[0].date;
                        let end = this.state.months[months.length - 1].date;
                        let total = 0;

                        for (let j = 0; j < months.length; j++) {
    
                            let monthName = this.state.months[j].name;

                            let value = isNaN(parseFloat(months[j])) ? 0 : parseFloat(months[j]);
                            total += value;
    
                            let insert = {
                                description: description,
                                date: new Date(),
                                method: 'absolute',
                                id_company: SessionHelper.getData().id_company,
                                multiplier: 0,
                                multiplier_history: 0,
                                id_parent: parentId,
                                id_budget_import: budgetImportId,
                                date_label: monthName,
                                start: start,
                                end: end,
                                value_history: 0,
                                value: Math.abs(value),
                                imported: true,
                                type: type,
                                operator: this.getOperator(chunk[i].type, value),
                                table_type: 'absolute',
                                relationship: { id_target_account_plan: account ? account.id : null, id_products: idProducts, id_product: productId, id_result_center: resultCenter ? resultCenter.id : null, id_result_centers: idResultCenters },
                            };
     
                            if (account) {
    
                                insert.id_target_account_plan = account.id;
                                insert.id_account_plan = account.id;
    
                                if (insert.type === 'taxes') {
    
                                    insert.target_account_plan = account.id;
                                }
                            }
    
                            if (resultCenter) {
    
                                insert.id_result_centers = [resultCenter.id];
                                insert.result_centers = [resultCenter.id];
                            }

                            await Firestore.insert(insert, 'planning_row');

                            count ++;
                            this.setState({ loadingText: `Inserindo ${count} de ${data.length * importedMonths.length}` })
                        }
    
                        let parent = {
                            description: description,
                            date: new Date(),
                            method: 'absolute',
                            id_company: SessionHelper.getData().id_company,
                            multiplier: 0,
                            multiplier_history: 0,
                            id_parent: parentId,
                            id_budget_import: budgetImportId,
                            start: start,
                            end: end,
                            imported: true,
                            type: type,
                            table_type: 'absolute',
                            operator: this.getOperator(chunk[i].type, total),
                        };
    
                        if (account) {
    
                            parent.id_target_account_plan = account.id;
                            parent.id_account_plan = account.id;
                        }
    
                        if (resultCenter) {
    
                            parent.id_result_centers = [resultCenter.id];
                            parent.id_result_center = resultCenter.id;
                            parent.result_centers = [resultCenter.id];
                        }

                        if (type === 'costs' && productId) {

                            parent.id_product = productId;
                        }
    
                        await Firestore.insert(parent, 'planning', parentId);
                    }
                }));

                await Firestore.update({ count: (data.length * importedMonths.length) - this.state.duplicates.length }, 'budget_import', budgetImportId);
                await this.getDocs();

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                this.setState({ insertedCounter: (data.length * importedMonths.length) - this.state.duplicates.length });

                toast.success(`${(data.length * importedMonths.length) - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });


            } catch (error) {
                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            this.setState({ loading: false, loadingText: `` });
        }
    }

    handleExcelModal() {

        let start = this.state.start;
        let end = this.state.end;
        let colsub = this.state.colsub;
        let months = [];

        const periodDurationInMonths = moment(end).diff(start, 'months');

        for (let i = 0; i <= periodDurationInMonths; i++) {
            
            const date = (moment(start).startOf('month').add(i, 'month')).toDate();
            const date_label = moment(date).format('MMM/YYYY').toLocaleLowerCase().toString();

            months.push({ name: date_label, date: date, alias: date_label, required: true, example: '1.500,00', money: true, validators: [ExcelImportValidators.trim] });
        }

        colsub = [ ...colsub, ...months ];

        this.setState({ colsub: colsub, months, excelModal: true, importModal: false });
    }

    duplicateModal() {

        if (this.state.duplicateModal) {

            const totalColSpan = 100;
            let unitColSpan;
    
            unitColSpan = parseInt(totalColSpan / this.state.colsub.length);
    
            return (
                <div>
                    <div style={{ marginBottom: 20, marginTop: 30 }}>
                        <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                        <br />
                        <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                        <br />
                        <br />
                        <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                    </div>
                    <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                        <TableContainer>
                            <Table aria-label="spanning table">
                                <TableHead>
                                    <TableRow colspan={unitColSpan}>
                                        {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                    </div>
                </div>
            )
        }
    }

    importModal() {

        if (this.state.importModal) {

            return (
                <div>
                    <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />
                    <div style={{ paddingTop: 10 }}/>
                    <FormLabel>Selecione o período dos dados:</FormLabel>
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <KeyboardDatePicker
                                style={{ width: '100%' }}
                                invalidDateMessage={false}
                                format={'MM/yyyy'}
                                autoOk={true}
                                label="Início"
                                views={["year", "month"]}
                                openTo={'month'}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ start: v }) }}
                                value={this.state.start}
                            />
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}/>
                            <KeyboardDatePicker
                                style={{ width: '100%' }}
                                invalidDateMessage={false}
                                format={'MM/yyyy'}
                                autoOk={true}
                                label="Fim"
                                views={["year", "month"]}
                                openTo={'month'}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={(v) => { this.setState({ end: v }) }}
                                value={this.state.end}
                                minDate={this.state.start}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.handleExcelModal() }} color={Colors.primary} loading={this.state.loadingModal} width={'50%'} title={'CONFIRMAR'} />
                        <div style={{ paddingLeft: 10, paddingRight: 10 }}/>
                        <DefaultButton onClick={() => { this.setState({ importModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'50%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader loadingText={this.state.loadingText}/> : (
            <div style={styles.container}>
                
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ importModal: true }) }} title={'Nova Importação'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Importar Orçamento'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description', editable: false },
                        { title: 'Registros Inseridos', field: 'count', editable: false },
                        { title: 'Período', field: 'months', render: rowData => <div>{`${rowData.months[0].name} até ${rowData.months[rowData.months.length - 1].name}`}</div>, editable: false },
                        { title: 'Usuário', field: 'id_user', render: rowData => <RelationField loading={false} collection={'user'} field={'name'} id={rowData.id_user}/>, editable: false },
                        { title: 'Data', field: 'date', render: rowData => moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm'), editable: false },
                    ]}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        if (oldData.id) {

                            await this.revert(oldData.id);
                            
                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                    data={this.state.docs}
                />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.importModal()} title={'Importar do Excel (.xlsx)'} onClose={() => { this.setState({ importModal: false }) }} open={this.state.importModal} width={'80%'}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
