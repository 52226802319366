import Operators from "../constants/Operators";

export default class AccountPlanHelper {

    static getAccountsFromParent(id_parents, accountDocs, result = []) {

        for (let index = 0; index < id_parents.length; index++) {
            
            let account = accountDocs.find(item => item.id === id_parents[index]);

            if (!result.includes(account.id)) {

                result.push(account.id);
            }

            if (account.children) {

                let children = this.getAccountsFromParent(account.children, accountDocs, result);
                
                for (let indexChildren = 0; indexChildren < children.length; indexChildren++) {

                    if (!result.includes(children[indexChildren])) {

                        result.push(children[indexChildren]);
                    }
                }
            }
        }

        return result;
    }

    static getAccountsFromPackage(id_packages, accountDocs, accountPackages) {

        let accounts = [];
        let result = [];
        
        for (let index = 0; index < id_packages.length; index++) {
            
            let account = accountPackages.find(item => item.id === id_packages[index]);
            accounts = [...accounts, ...account.account_plans];
        }

        result = this.getAccountsFromParent(accounts, accountDocs, accountPackages);

        return result;
    }

    static getAccountOperator(account) {

        let revenue = 0;
        let deduction = 0;

        for (let monthIndex = 0; monthIndex < account.revenue.length; monthIndex++) {
            
            revenue += account.revenue[monthIndex].value;
            deduction += account.deduction[monthIndex].value;
        }

        if (revenue > 0) {

            return Operators.sum.key;
        }

        if (deduction > 0) {

            return Operators.minus.key;
        }

        return Operators.sum.key;
    }
}