import React from "react";
import DefaultSelect from "./DefaultSelect";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DefaultButton from './DefaultButton';
import Colors from "../constants/Colors";
import { Menu, CircularProgress } from "@material-ui/core";
import SessionHelper from "../helper/SessionHelper";
import Firestore from "../api/firebase/Firestore";
import { toast } from 'react-toastify';
import DefaultInput from './DefaultInput';

export default class TagSelect extends React.Component {

    state = {
        loading: false,
        loadingAdd: false,
        showAdd: false,
        description: '',
        menuAnchor: null,
    }

    async addTag() {

        try {

            if (this.state.description) {

                this.setState({ loadingAdd: true });

                let data = {
                    description: this.state.description,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                await Firestore.insert(data, 'tag');
                this.props.onAddTag();

                this.setState({ loadingAdd: false, showAdd: false, menuAnchor: null });
                toast.success('Cadastrado com sucesso');

            } else {

                toast.warn('Preencha o nome da tag');
            }

        } catch (error) {
            
            toast.error('Houve um problema ao cadastrar');
        }
    }

    renderAdd() {

        return (

            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                anchorEl={this.state.menuAnchor}
                id={'add-tag'}
                open={this.state.showAdd}
                onClose={() => { this.setState({ showAdd: false }) }} >
                    <div style={{ margin: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 20 }}>
                        <DefaultInput onChange={(text) => { this.setState({ description: text }) }} label={'Descrição'} />
                        <DefaultButton width={40} leftIcon={this.state.loadingAdd ? <CircularProgress style={{ marginRight: 15 }} color={'white'} size={17}/> : <CheckIcon />} color={Colors.success} title={''} onClick={(evt) => { this.addTag() }}/>
                    </div>
            </Menu>
        )
    }

    render () {

        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center', gap: 20 }}>
                <DefaultSelect
                    search={true}
                    searchField={'description'}
                    id={'tag_select'}
                    valueField={'id'}
                    displayField={'description'}
                    value={this.props.value}
                    onChange={(v) => { this.props.onChange(v.target.value) }}
                    docs={this.props.docs}
                    label={'Tags'}
                    multiple={true}
                />
                <DefaultButton onClick={(evt) => { this.setState({ showAdd: true, menuAnchor: evt.target }) }} color={Colors.primary} loading={this.state.loading} width={'auto'} leftIcon={<AddIcon style={{ marginLeft: 6 }}/>} title={''} />
                {this.renderAdd()}
            </div>
        )
    }
}