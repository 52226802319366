import React, { Component } from 'react';
import Firestore from '../api/firebase/Firestore';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import ChartHelper from '../helper/ChartHelper';
import ClipLoader from "react-spinners/ClipLoader";
import Colors from '../constants/Colors';
import moment from 'moment';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import CurrencyHelper from '../helper/CurrencyHelper';

export default class CompanyChart extends Component {

    state = {
        loading: true,
        id: this.props.id,
        doc: this.props.doc,
        labels: [],
        datasets: [],
        edit: false,
        hover: false,
        columns: [...ChartHelper.getColumns(), ...ChartHelper.getColumns2()],
    }

    async componentDidMount() {

        try {

            if (this.props.onLoadComplete) {
    
                setTimeout(() => {

                    this.props.onLoadComplete();

                }, 1000);
            }

            await this.getDoc();

            if (this.state.doc) {

                await this.buildChart();
            }

        } catch (error) {

            console.log(error)
        }
    }

    async getDoc() {

        if (this.state.id) {

            let query = await Firestore.getDoc('charts', this.state.id);
            let data = { ...query.data(), id: query.id };

            data.start = data.start.toDate();
            data.end = data.end.toDate();
    
            this.setState({ doc: data });
        }
    }

    async buildChart() {

        this.setState({ loading: true });

        let labels = [];
        let datasets = [];

        if (this.state.doc.chart !== 'speedometer') {

            labels = ChartHelper.getLabelsFromPeriod(this.state.doc.start, this.state.doc.end, this.state.doc.period_type, this.state.chart);

        } else {

            labels = ChartHelper.getLabelsFromPeriod(this.state.doc.start, this.state.doc.end, 'accumulated', this.state.doc.chart, this.state.doc.columns);
        }

        datasets = await ChartHelper.buildDataset(this.state.doc.start, this.state.doc.end, this.state.doc.columns, this.state.doc.chart, this.state.doc.data_type, this.state.doc.data_source, this.state.doc.period_type, this.state.doc.filter_result_centers, this.state.doc.vertical_analysis_account_plan, this.state.doc.inverted);

        this.setState({ labels: labels, datasets: datasets, loading: false });
    }

    getChartOptions(type) {

        let options = {
            maintainAspectRatio: true,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                        maxTicksLimit: 6,
                        callback: (value, index, values) => {
                            
                            let dataset = this.state.datasets[0];
                            let column = this.state.columns.find(item => item.name === dataset.label);

                            if (column.type === 'money') {

                                return CurrencyHelper.formatMoney(value, true);
                            
                            } else {

                                return `${value.toFixed(2)}%`;
                            }
                        },
                    },
                }],
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {

                        if (type === 'speedometer') {

                            let dataset = data.datasets[tooltipItem.datasetIndex];
                            let currentValue = dataset.data[tooltipItem.index];

                            return CurrencyHelper.formatMoney(currentValue, false);

                        } else {

                            let dataset = data.datasets[tooltipItem.datasetIndex];
                            let column = this.state.columns.find(item => item.name === dataset.label);

                            if (column.type === 'money') {

                                return CurrencyHelper.formatMoney(tooltipItem.yLabel, true);
                            
                            } else {

                                return `${tooltipItem.yLabel.toFixed(2)}%`;
                            }
                        }
                    }
                }
            }
        };

        if (type === 'speedometer') {

            options = {...options, ...ChartHelper.getGaugeConfig()};
        }

        return options;
    }

    async handlePeriodChange(start, end) {
        
        let doc = this.state.doc;

        if (start) { doc.start = start };
        if (end) { doc.end = end };

        try {

            await this.setState({ doc });
            await this.buildChart();

        } catch (error) {

        }
    }

    addActionPlan() {

        let chart = this.state.doc;
        let link = (window.location.hostname == 'localhost' ? 'http://localhost:3000' : 'http://go.grupoadvis.com.br') + '/action_plan';
        
        let url = new URL(link);
        url.searchParams.append('add', true);
        url.searchParams.append('budget_link_period_start', moment(chart.start).unix());
        url.searchParams.append('budget_link_period_end', moment(chart.end).unix());

        if (chart.filter_result_centers && chart.filter_result_centers.length) {

            chart.filter_result_centers.forEach((result_center, key) => {

                url.searchParams.append('budget_link_result_center', result_center);       
            });   
        }

        if (chart.data_type === 'account') {

            url.searchParams.append('budget_link_account_plan', chart.data_source);
        }

        if (chart.data_type === 'account_package') {

            url.searchParams.append('budget_link_account_package', chart.data_source);
        }

        window.open(url.href, '_blank');
    }

    renderBarChart() {

        let options = this.getChartOptions(this.state.doc.chart);

        return (
            <Bar
            height={window.screen.width < 1080 ? 250 : 125}
            width={500}
            options={options}
            data={{
                labels: this.state.labels,
                datasets: this.state.datasets,
            }}
            />
        )
    }

    renderGaugeChart() {

        let options = this.getChartOptions(this.state.doc.chart);

        return (
            <Doughnut
            height={window.screen.width < 1080 ? 250 : 125}
            width={500}
            options={options}
            data={{
                labels: this.state.labels,
                datasets: this.state.datasets,
            }}
            />
        )
    }

    renderLineChart() {

        let options = this.getChartOptions(this.state.doc.chart);

        return (
            <Line
            height={window.screen.width < 1080 ? 250 : 125}
            width={500}
            options={options}
            data={{
                labels: this.state.labels,
                datasets: this.state.datasets,
            }}
            />
        )
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: 25 }}>
                <ClipLoader
                size={25}
                color={Colors.primary}
                loading={true}
                />
            </div>
        )
    }

    renderEdit() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%', marginTop: 8, marginBottom: 25 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        label="Início"
                        views={["year", "month"]}
                        openTo={'month'}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => { this.handlePeriodChange(v, null) }}
                        maxDate={this.state.doc.end}
                        value={this.state.doc.start}
                    />
                </MuiPickersUtilsProvider>
                <div style={{ marginLeft: 8, marginRight: 8 }}/>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%', marginTop: 8, marginBottom: 25 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        openTo={'month'}
                        views={["year", "month"]}
                        label="Fim"
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        minDate={this.state.doc.start}
                        onChange={(v) => { this.handlePeriodChange(null, v) }}
                        value={this.state.doc.end}
                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    toggleHover(value) {

        let hover = this.state.hover;
        hover = value;

        this.setState({ hover });
    }

    render() {

        return this.state.loading ? this.renderLoading() : (
            <div onMouseEnter={() => { this.toggleHover(true) }} onMouseLeave={() => { this.toggleHover(false) }} style={{ display: 'flex', flexDirection: 'column', marginTop: 25, marginBottom: 25, alignItems: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h2>{this.state.doc && this.state.doc.description ? `${this.state.doc.description} - ${moment(this.state.doc.start).format('MMM/YYYY')} até ${moment(this.state.doc.end).format('MMM/YYYY')}` : 'Gráfico sem nome'}</h2>
                    
                    { this.state.hover && !this.state.edit? 
                    
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <Tooltip title={'Editar'}>
                                <IconButton onClick={() => { this.setState({ edit: true }) }} style={{ boxShadow: Colors.boxShadow, width: 40, height: 40, marginLeft: 15, border: '1px solid lightgrey' }}>
                                    <EditIcon style={{ color: Colors.primary }}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Novo Plano de Ação'}>
                                <IconButton onClick={() => { this.addActionPlan() }} style={{ boxShadow: Colors.boxShadow, width: 40, height: 40, border: '1px solid lightgrey' }}>
                                    <EmojiObjectsIcon style={{ color: Colors.primary }}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    
                    : this.state.edit ?

                        <IconButton onClick={() => { this.setState({ edit: false }) }} style={{ boxShadow: Colors.boxShadow, width: 40, height: 40, marginLeft: 15, border: '1px solid lightgrey' }}>
                            <ClearIcon style={{ color: Colors.primary }}/>
                        </IconButton>

                    : null }
                </div>

                { this.state.edit ? this.renderEdit() : null }
                
                { this.state.doc.chart === 'bar' && this.renderBarChart() }
                { this.state.doc.chart === 'line' && this.renderLineChart() }
                { this.state.doc.chart === 'speedometer' && this.renderGaugeChart() }
            </div>
        )
    }
}
