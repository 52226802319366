export default {
    no_measurement_unit: 'Sem unidade de medida',
    g: 'Grama (g)',
    kg: 'Quilograma (kg)',
    ton: 'Tonelada (ton)',
    m: 'Metro (m)',
    m2: 'Metro quadrado (m²)',
    m3: 'Metro cúbico (m³)',
    l: 'Litro (l)',
    d: 'Dia (d)',
    h: 'Hora (h)',
    min: 'Minuto (min)'
}