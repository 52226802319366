import React from "react";
import DefaultLoader from "./DefaultLoader";
import DefaultButton from "./DefaultButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ReorderIcon from '@material-ui/icons/Reorder';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ClearIcon from '@material-ui/icons/ClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import EditIcon from '@material-ui/icons/Edit';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Menu, TextareaAutosize } from "@material-ui/core";
import Colors from "../constants/Colors";
import Firestore from "../api/firebase/Firestore";
import SessionHelper from "../helper/SessionHelper";
import moment from "moment";
import ActionPlanHelper from "../helper/ActionPlanHelper";
import RelationField from "./RelationField";
import BudgetStructureTable from "./BudgetStructureTable";
import PermissionHelper from "../helper/PermissionHelper";
import Functions from "../api/firebase/Functions";
import { toast } from "react-toastify";
import ActionPlanTasks from "./ActionPlanTasks";
import IosSwitch from "./IosSwitch";
import Timeline, { DateHeader, TimelineHeaders, SidebarHeader } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css';
import AddActionPlan from "./AddActionPlan";
import CurrencyHelper from "../helper/CurrencyHelper";
import ActionPlan from "../constants/ActionPlan";
import DefaultSelect from "./DefaultSelect";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Operators from "../constants/Operators";
import AccountPlanHelper from "../helper/AccountPlanHelper";

export default class ActionPlanView extends React.Component {

    state = {
        loading: true,
        loadingAvaliacao: false,
        tagDocs: [],
        userDocs: [],
        resultCenterDocs: [],
        accountPackageDocs: [],
        accountPlanDocs: [],
        budgetStructureDocs: [],
        generatedReport: {},
        loadingText: '',
        actionPlan: this.props.doc,
        loadingTable: false,
        showGantt: false,
        ganttSelectedItem: null,
        menuAnchor: null,
        ganttGroups: [],
        ganttItems: [],
        editId: null,
        reportAccountPlans: [],
        reportBudgetStructure: [],
        canCertificate: false,
        canApplyCertification: false,
        addCertificate: false,
        certificateDeploy: null,
        certificateEfficiency: null,
        certificateRiskAndOpportunity: null,
        certificateChanges: null,
        certificateJustify: null,
    }

    async componentDidMount() {

        await this.getUsers();
        await this.getTags();
        await this.getResultCenters();
        await this.getAccountPlanDocs();
        await this.getAccountPackageDocs();

        let canCertificate = ActionPlanHelper.canCertificate(this.state.actionPlan);
        let canApplyCertification = await ActionPlanHelper.canApplyCertification(this.state.actionPlan);
        
        this.setState({ loading: false, canCertificate, canApplyCertification });

        await this.getPeriodData();
    }

    async getActionPlan() {

        let query = await Firestore.getDoc('action_plan', this.state.actionPlan.id);

        if (query.exists) {

            let doc = { ...query.data(), id: query.id };
            let canCertificate = ActionPlanHelper.canCertificate(doc);
            let canApplyCertification = await ActionPlanHelper.canApplyCertification(doc);

            this.setState({ actionPlan: doc, canCertificate, canApplyCertification });
        }
    }

    async getResultCenters() {

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid);
        this.setState({ resultCenterDocs: docs });
    }

    async getAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs });
    }

    async getAccountPackageDocs() {

        let query = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPackageDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getTags() {

        let query = await Firestore.customQuery('tag').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ tagDocs: docs });
    }

    async getPeriodData() {

        if (this.state.actionPlan.budget_link_period_start && this.state.actionPlan.budget_link_period_end) {

            try {

                this.setState({ loadingTable: true, reportAccountPlans: [], reportBudgetStructure: [] });

                await this.getAccountPlanDocs();

                let months = [];
                let parsedMonths = [];
                let accounts = this.state.accountPlanDocs;
                let budgetStructure = this.state.budgetStructureDocs;
                let filterResultCenter = [...this.state.actionPlan.budget_link_result_center];
                let filterAccountPlan = [...this.state.actionPlan.budget_link_account_plan];
                let filterAccountPackage = [...this.state.actionPlan.budget_link_account_package];
                let columns = ['budget', 'accomplished'];

                if (filterResultCenter.length === this.state.resultCenterDocs.length) {

                    filterResultCenter = [];
                }

                let momentStart = moment(this.state.actionPlan.budget_link_period_start.toDate ? this.state.actionPlan.budget_link_period_start.toDate() : this.state.actionPlan.budget_link_period_start);
                let momentEnd = moment(this.state.actionPlan.budget_link_period_end.toDate ? this.state.actionPlan.budget_link_period_end.toDate() : this.state.actionPlan.budget_link_period_end);

                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

                    parsedMonths.push(momentStart.toJSON());
                    months.push(momentStart.toDate());
                    momentStart.add(1, 'month');
                }

                let body = { open: true, id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, selected_account_packages: filterAccountPackage, selected_account_plans: filterAccountPlan, id_result_centers: filterResultCenter, months: parsedMonths, columns: columns };
                let request = await Functions.request('POST', 'getBudgetStructure', body);
                
                if (request && request.accounts && request.budgetStructure) {
                    
                    accounts = request.accounts;
                    budgetStructure = request.budgetStructure;

                } else {

                    throw new Error('Request Error');
                }

                let generatedReport = {
                    report: months.length > 1 ? 'accumulated' :  'monthly',
                    view: months.length > 1 ? 'monthly_and_accumulated' : 'monthly',
                    columns: columns,
                    months: months,
                    verticalAnalysisAccountPlan: null,
                    filterResultCenter: [],
                };

                this.setState({ reportAccountPlans: accounts, reportBudgetStructure: budgetStructure, renderTable: true, loadingTable: false, generatedReport: generatedReport, showOptions: false });

            } catch (error) {

                toast.error('Houve um problema ao buscar os dados do período');
            }
        }
    }

    async handleBack(id) {

        this.setState({ editId: null });
    }

    async handleAdd(id) {

        let query = await Firestore.getDoc('action_plan', this.state.actionPlan.id);
        let actionPlan = {...query.data(), id: query.id};

        this.setState({ actionPlan: actionPlan, editId: null });
        toast.success(`Plano de Ação editado com sucesso`);

        await this.getPeriodData();
    }

    async askEvaluation() {

        this.setState({ loadingAvaliacao: true });

        await ActionPlanHelper.sendEmailAvaliador(this.state.actionPlan.id_evaluator, this.state.actionPlan.id_responsable, this.state.actionPlan.id);
        await Firestore.update({ evaluate_status: 'not_answered' }, 'action_plan', this.state.actionPlan.id);
        await this.getActionPlan();

        toast.success('Solicitação de avaliação enviada com sucesso!');
        this.setState({ loadingAvaliacao: false });
    }

    async answerEvaluation() {

        let link = `${window.location.hostname == "localhost" ? 'http://localhost:3000' : 'https://go.grupoadvis.com.br'}/action_plan_evaluate?id=${this.state.actionPlan.id}`;
        console.log(link);
        window.open(link, '_blank');
    }

    renderResultCenters() {

        let id_result_centers = this.state.actionPlan.budget_link_result_center;
        let resultCenters = [];

        for (let index = 0; index < id_result_centers.length; index++) {
            
            let resultCenter = this.state.resultCenterDocs.find(item => item.id === id_result_centers[index]);

            if (resultCenter) {

                resultCenters.push(resultCenter.description);
            }
        }

        if (!resultCenters.length) {

            resultCenters.push('Nenhum selecionado.');
        }

        return resultCenters.join(', ');
    }

    renderAccountPackages() {

        let id_account_packages = this.state.actionPlan.budget_link_account_package;
        let accountPackages = [];

        for (let index = 0; index < id_account_packages.length; index++) {
            
            let accountPackage = this.state.accountPackageDocs.find(item => item.id === id_account_packages[index]);

            if (accountPackage) {

                accountPackages.push(accountPackage.description);
            }
        }

        if (!accountPackages.length) {

            accountPackages.push('Nenhum selecionado.');
        }

        return accountPackages.join(', ');
    }

    renderAccountPlans() {

        let id_account_plans = this.state.actionPlan.budget_link_account_plan;
        let accountPlans = [];

        for (let index = 0; index < id_account_plans.length; index++) {
            
            let accountPlan = this.state.accountPlanDocs.find(item => item.id === id_account_plans[index]);

            if (accountPlan) {

                accountPlans.push(accountPlan.description);
            }
        }

        if (!accountPlans.length) {

            accountPlans.push('Nenhum selecionado.');
        }

        return accountPlans.join(', ');
    }

    renderTags() {

        let id_tags = this.state.actionPlan.id_tags;
        let tags = [];

        for (let index = 0; index < id_tags.length; index++) {
            
            let tag = this.state.tagDocs.find(item => item.id === id_tags[index]);

            if (tag) {

                tags.push(tag.description);
            }
        }

        if (tags.length) {

            return tags.map((tag, key) => {

                return <div style={{ backgroundColor: Colors.info, color: 'white', fontWeight: 'bold', textAlign: 'center', display: 'inline', paddingTop: 3, paddingBottom: 3, paddingLeft: 8, paddingRight: 8, borderRadius: 5, marginRight: 10 }}>{tag}</div>
            });
        }

        return '';
    }

    renderParticipants() {

        let id_participants = this.state.actionPlan.id_participants;
        let users = [];

        for (let index = 0; index < id_participants.length; index++) {
            
            let user = this.state.userDocs.find(item => item.id === id_participants[index]);

            if (user) {

                users.push(user.name);
            }
        }

        return users.join(', ');
    }

    getPeriod() {

        let tasks = this.state.actionPlan.tasks || [];
        let start = '';
        let end = '';

        if (tasks.length) {

            start = moment(tasks[0].start.toDate ? tasks[0].start.toDate() : tasks[0].start).format('DD/MM/YYYY');
            end = moment(tasks[tasks.length - 1].end.toDate ? tasks[tasks.length - 1].end.toDate() : tasks[tasks.length - 1].end).format('DD/MM/YYYY');
        
            return `${start} → ${end}`;
        }

        return '';
    }

    renderBudgetLink() {

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', border: '0.3px solid lightgrey' }}>
                <b style={{ backgroundColor: '#F6F6F6F6', padding: 15, textAlign: 'center' }}>Orçado x Realizado</b>
                <div style={{ padding: 10 }}>
                    <Table style={{ marginBottom: 10 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Centros de Resultado</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderResultCenters()}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Pacotes</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderAccountPackages()}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Contas</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderAccountPlans()}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Período de Referência</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{moment(this.state.actionPlan.budget_link_period_start.toDate ? this.state.actionPlan.budget_link_period_start.toDate() : this.state.actionPlan.budget_link_period_start).format('MM/YYYY')} → {moment(this.state.actionPlan.budget_link_period_end.toDate ? this.state.actionPlan.budget_link_period_end.toDate() : this.state.actionPlan.budget_link_period_end).format('MM/YYYY')}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50, textAlign: 'center', maxWidth: 1000 }} colSpan={2} rowSpan={2}>
                                    <BudgetStructureTable periodRowColSpan={4} hideStructure={this.state.actionPlan.budget_link_account_plan.length > 0 ? true : false} additionalRows={this.getAdditionalRows()} loadingTable={this.state.loadingTable} accountPlanDocs={this.state.reportAccountPlans} verticalAnalysisAccountPlan={null} variationFilter={[0, 0]} generatedReport={this.state.generatedReport} budgetStructureDocs={this.state.reportBudgetStructure} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Período de Verificação</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.state.actionPlan.budget_link_check_period ? moment(this.state.actionPlan.budget_link_check_period.toDate ? this.state.actionPlan.budget_link_check_period.toDate() : this.state.actionPlan.budget_link_check_period).format('MM/YYYY') : 'Não Informado'}</div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    }

    renderData() {

        return (

            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#F6F6F6F6', border: '0.3px solid lightgrey', height: 50 }} colSpan={'100%'}>{this.renderTags()}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', width: '40%' }}>
                                    <b>Duração</b>
                                    <div>{this.getPeriod()}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', width: '10%' }}>{ActionPlanHelper.getStatus(this.state.actionPlan.tasks)}</TableCell>
                                <TableCell rowSpan={2} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', width: '50%' }}>
                                    <b>Participantes</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderParticipants()}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <b>Responsável</b>
                                    <div><RelationField loading={false} collection={'user'} field={'name'} id={this.state.actionPlan.id_responsable}/></div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={'100%'} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <b>Superior / Avaliador</b>
                                    <div><RelationField loading={false} collection={'user'} field={'name'} id={this.state.actionPlan.id_evaluator}/></div>
                                    {this.state.actionPlan.id_evaluator ? this.renderEvaluate() : null}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={'100%'} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20 }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', border: '0.3px solid lightgrey' }}>
                                            <b style={{ backgroundColor: '#F6F6F6F6', padding: 15, textAlign: 'center' }}>Análise de Desvio</b>
                                            <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: this.state.actionPlan.deviation_analysis }}></div>
                                        </div>
                                        {this.renderBudgetLink()}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    }

    renderEvaluateStatus() {

        let icon = null;
        let status = null;
        let color = 'lightgrey';

        if (this.state.actionPlan.evaluate_status === 'not_answered') {

            icon = <InfoRoundedIcon style={{ marginRight: 5 }}/>
            status = 'Não Respondido';
        
        } else if (this.state.actionPlan.evaluate_status === 'approved') {

            icon = <CheckRoundedIcon style={{ marginRight: 5 }}/>
            status = 'Aprovado';
            color = Colors.success;

        } else if (this.state.actionPlan.evaluate_status === 'rejected') {

            icon = <ClearIcon style={{ marginRight: 5 }}/>
            status = 'Rejeitado';
            color = Colors.error;
        }

        return <div style={{ backgroundColor: color, color: 'white', fontWeight: 'bold', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 150, paddingTop: 3, paddingBottom: 3, paddingLeft: 0, paddingRight: 8, borderRadius: 5 }}>{icon}{status}</div>
    }

    renderEvaluate() {

        if (this.state.actionPlan.evaluate_status === 'approved' || this.state.actionPlan.evaluate_status === 'rejected' || (this.state.actionPlan.evaluate_status === 'not_answered' && SessionHelper.getFirebaseAuth().uid !== this.state.actionPlan.id_evaluator)) {
            
            return (

                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {this.renderEvaluateStatus()}
                    {this.state.actionPlan.justification && this.state.actionPlan.justification.length ?
                        <div>
                            <b>Feedback: </b>
                            {this.state.actionPlan.justification}
                        </div>
                    : null}
                    {this.state.actionPlan.evaluate_date ? moment(this.state.actionPlan.evaluate_date.toDate ? this.state.actionPlan.evaluate_date.toDate() : this.state.actionPlan.evaluate_date).format('DD/MM/YYYY HH:mm') : ''}
                    <DefaultButton loading={this.state.loadingAvaliacao} color={Colors.info} leftIcon={<EmailIcon />} onClick={() => { this.askEvaluation() }} width={280} title={'Solicitar nova validação'} />
                </div>
            )

        } else if (this.state.actionPlan.evaluate_status === 'not_answered' && SessionHelper.getFirebaseAuth().uid === this.state.actionPlan.id_evaluator) {

            return (

                <div style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10, color: Colors.danger, fontWeight: 'bold', border: `3px solid ${Colors.danger}`, padding: 10, borderRadius: 6, width: 450 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, marginBottom: 10 }}>
                        <InfoRoundedIcon />
                        Você foi designado como avaliador desse plano de ação.
                    </div>
                    <DefaultButton loading={this.state.loadingAvaliacao} color={Colors.info} leftIcon={<ChatBubbleIcon />} onClick={() => { this.answerEvaluation() }} title={'Responder avaliação'} />
                </div>
            )

        } else {

            return (

                <div style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10, color: Colors.danger, fontWeight: 'bold', border: `3px solid ${Colors.danger}`, padding: 10, borderRadius: 6, width: 400 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, marginBottom: 10 }}>
                        <InfoRoundedIcon />
                        Este plano ainda não foi validado pelo superior.
                    </div>
                    <DefaultButton loading={this.state.loadingAvaliacao} color={Colors.info} leftIcon={<EmailIcon />} onClick={() => { this.askEvaluation() }} title={'Solicitar validação'} />
                </div>
            )
        }
    }

    async handleAddTasks(tasks) {

        let actionPlan = this.state.actionPlan;
        actionPlan.tasks = tasks;

        await Firestore.update({ tasks: tasks }, 'action_plan', this.state.actionPlan.id);
        await this.getActionPlan();

        this.setState({ actionPlan });
    }

    async certificatePlan() {

        if (!this.state.loadingAvaliacao) {

            if (this.state.certificateDeploy && this.state.certificateRiskAndOpportunity && this.state.certificateEfficiency && this.state.certificateChanges) {

                this.setState({ loadingAvaliacao: true });
    
                let data = {
                    certificated: true,
                    certificate_deploy: this.state.certificateDeploy,
                    certificate_efficiency: this.state.certificateEfficiency,
                    certificate_risk_and_opportunity: this.state.certificateRiskAndOpportunity,
                    certificate_changes: this.state.certificateChanges,
                    certificate_justify: this.state.certificateJustify,
                    certificate_id_user: SessionHelper.getFirebaseAuth().uid,
                    certificate_date: new Date(),
                };

                await Firestore.update(data, 'action_plan', this.state.actionPlan.id);
                await ActionPlanHelper.sendMailCertificacao(this.state.actionPlan.id);
                await this.getActionPlan();
    
                toast.success('Certificação salva com sucesso!');
                this.setState({ loadingAvaliacao: false, addCertificate: false });
    
            } else {
    
                toast.warn('Preencha todos os campos obrigatórios');
                this.setState({ loadingAvaliacao: false });
            }
        }
    }

    handleGanttSelect(id, evt) {

        let task = this.state.actionPlan.tasks[id - 1];

        if (task) {

            this.setState({ ganttSelectedItem: task, menuAnchor: evt.target });
        }
    }

    handleGantt(v) {

        let groups = [];
        let items = [];

        if (v) {

            this.state.actionPlan.tasks.forEach((task, key) => {

                groups.push({
                    id: key + 1,
                    title: task.task,
                });
    
                items.push({
                    id: key + 1,
                    group: key + 1,
                    title: ActionPlanHelper.getTaskSituation(task.situation),
                    start_time: moment(task.start.toDate ? task.start.toDate() : task.start),
                    end_time: moment(task.end.toDate ? task.end.toDate() : task.end),
                    itemProps: {
                        style: {
                            background: ActionPlanHelper.getTaskBackgroundColor(task, 1),
                        }
                    }
                });
            });
        }

        this.setState({ showGantt: v, ganttGroups: groups, ganttItems: items });
    }

    getAdditionalRows() {

        return [
            {
                title: 'Média Orçado',
                render: (account) => {

                    let value = 0;

                    for (let monthIndex = 0; monthIndex < account.revenue.length; monthIndex++) {
                        
                        value += (account.revenue[monthIndex].value - account.deduction[monthIndex].value);
                    }

                    value = value / account.revenue.length;

                    return <TableCell style={{ backgroundColor: 'white', textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
                showHeader: false,
            },
            {
                title: 'Média Realizado',
                render: (account) => {

                    let value = 0;

                    for (let monthIndex = 0; monthIndex < account.revenue.length; monthIndex++) {
                        
                        value += account.accomplished[monthIndex].value;
                    }

                    value = value / account.accomplished.length;

                    return <TableCell style={{ backgroundColor: 'white', textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
                showHeader: false,
            },
            {
                title: 'Meta de Variação (%)',
                render: (account) => {

                    let value = this.state.actionPlan.budget_link_goal[account.id] && this.state.actionPlan.budget_link_goal[account.id].goal ? this.state.actionPlan.budget_link_goal[account.id].goal : 0;
                    let color = Colors.report.default;

                    if ((value >= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.sum.key) || (value <= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.minus.key)) {
    
                        color = Colors.report.green;
    
                    } else {
        
                        color = Colors.report.red;   
                    }

                    return <TableCell style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold' }}>{value.toFixed(2).toString().replace('.', ',')}%</TableCell>
                },
            },
            {
                title: 'Novo Valor Projetado',
                render: (account) => {

                    let value = this.state.actionPlan.budget_link_goal[account.id] && this.state.actionPlan.budget_link_goal[account.id].value_projection ? this.state.actionPlan.budget_link_goal[account.id].value_projection : 0;
                    let color = Colors.report.default;

                    if ((value >= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.sum.key) || (value <= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.minus.key)) {
    
                        color = Colors.report.green;
    
                    } else {
        
                        color = Colors.report.red;   
                    }

                    return <TableCell style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
            },
        ]
    }

    renderTasks() {

        return (

            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <b>Etapas</b>
                        <Tooltip title={'Clique para visualizar o gráfico Gantt'}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ReorderIcon/>
                            <IosSwitch margin={0} label={''} value={'showGantt'} checked={this.state.showGantt} onChange={(v) => { this.handleGantt(v) }} />
                            <ClearAllIcon/>
                        </div>
                        </Tooltip>
                    </div>
                    { this.state.showGantt ? this.renderGantt() : <ActionPlanTasks plano={this.state.actionPlan} mode={'view'} idActionPlan={this.state.actionPlan.id} tasks={this.state.actionPlan.tasks} evaluator={this.state.actionPlan.id_evaluator} onAddTask={(tasks) => { this.handleAddTasks(tasks) }}/> }
                </div>
            </div>
        )
    }

    renderGantt() {

        return (

            <div style={{ boxShadow: Colors.boxShadow }}>
                <Timeline
                    onItemClick={(id, evt) => { this.handleGanttSelect(id, evt) }}
                    onItemSelect={(id, evt) => { this.handleGanttSelect(id, evt) }}
                    groups={this.state.ganttGroups}
                    items={this.state.ganttItems}
                    defaultTimeStart={moment().add(-360, 'hour')}
                    defaultTimeEnd={moment().add(360, 'hour')}
                    canMove={false}
                    canChangeGroup={false}
                    canResize={false}
                    timeSteps={{
                        second: 1,
                        minute: 1,
                        hour: 24,
                        day: 1,
                        month: 1,
                        year: 1
                    }}
                >
                    <TimelineHeaders >
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div {...getRootProps()} style={{...getRootProps().style, backgroundColor: '#f0f0f0', borderTop: '1px solid #bbb', borderLeft: '1px solid #bbb' }} ></div>
                        }}
                    </SidebarHeader>
                        <DateHeader unit="month" />
                        <DateHeader unit="day" />
                    </TimelineHeaders>
                </Timeline>
            </div>
        )
    }

    renderGanttSelectedItem() {

        if (this.state.ganttSelectedItem) {

            return (

                <Menu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    anchorEl={this.state.menuAnchor}
                    id={'gantt-selected-item-menu'}
                    open={this.state.ganttSelectedItem}
                    style={{ marginTop: 40, height: 'auto' }}
                    onClose={() => { this.setState({ ganttSelectedItem: null }) }} >
    
                        <div style={{ width: 450, padding: 0 }}>
                            <div style={{ borderBottom: '0.5px solid lightgrey', padding: 10, backgroundColor: '#f3f3f3f3' }}>{ActionPlanHelper.getTaskSituation(this.state.ganttSelectedItem.situation)}</div>
                            <div style={{ padding: 10 }}>
                                {this.state.ganttSelectedItem.task} <br/>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                                    <b>Responsável:</b>
                                    <RelationField loading={false} collection={'user'} field={'name'} id={this.state.ganttSelectedItem.id_responsable}/>
                                </div>
                            </div>
                        </div>
                </Menu>
            )
        }
    }

    renderCertificationForm() {

        return (
            
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, gap: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'flex-start' }}>
                    <DefaultSelect
                        required={true}
                        id={'deploy_select'}
                        valueField={'value'}
                        label={'Implantação*'}
                        displayField={'label'}
                        value={this.state.certificateDeploy}
                        onChange={(v) => {
                            this.setState({ certificateDeploy: v.target.value })
                        }}
                        docs={ActionPlan.deploy}
                    />
                    <DefaultSelect
                        required={true}
                        id={'risk_and_opportunity_select'}
                        valueField={'value'}
                        label={'Avaliação de Riscos e Oportunidades*'}
                        displayField={'label'}
                        value={this.state.certificateRiskAndOpportunity}
                        onChange={(v) => {
                            this.setState({ certificateRiskAndOpportunity: v.target.value })
                        }}
                        docs={ActionPlan.risk_and_opportunity}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'flex-start' }}>
                    <DefaultSelect
                            required={true}
                            id={'efficiency_select'}
                            valueField={'value'}
                            label={'Eficácia*'}
                            displayField={'label'}
                            value={this.state.certificateEfficiency}
                            onChange={(v) => {
                                this.setState({ certificateEfficiency: v.target.value })
                            }}
                            docs={ActionPlan.efficiency}
                        />
                        <DefaultSelect
                            required={true}
                            id={'changes_select'}
                            valueField={'value'}
                            label={'Mudanças no Sistema de Gestão da Qualidade*'}
                            displayField={'label'}
                            value={this.state.certificateChanges}
                            onChange={(v) => {
                                this.setState({ certificateChanges: v.target.value })
                            }}
                            docs={ActionPlan.changes}
                        />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <TextareaAutosize onBlur={(v) => { this.setState({ certificateJustify: v.target.value }) }} placeholder="Justificativa" style={{ height: 300, width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14 }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '40%', gap: 10 }}>
                    <DefaultButton disabled={this.state.answered} loading={this.state.loadingAvaliacao} width={'100%'} color={Colors.error} leftIcon={<Cancel />} onClick={() => { this.setState({ addCertificate: false, certificateDeploy: 'deployed', certificateEfficiency: 'effective', certificateRiskAndOpportunity: 'not_apply', certificateChanges: 'not_apply', certificateJustify: '' }) }} title={'Cancelar'} />
                    <DefaultButton disabled={this.state.answered} loading={this.state.loadingAvaliacao} width={'100%'} color={Colors.success} leftIcon={<CheckCircle />} onClick={() => { this.certificatePlan() }} title={'Salvar'} />
                </div>
            </div>
        )
    }

    renderCertificationData() {

        return (

            <div style={{ marginTop: 20, width: '100%', overflow: 'scroll' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                <b>Implantação</b>
                                <div>{ActionPlan.deploy.find(item => item.value === this.state.actionPlan.certificate_deploy).label}</div>
                            </TableCell>
                            <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                <b>Eficácia</b>
                                <div>{ActionPlan.efficiency.find(item => item.value === this.state.actionPlan.certificate_efficiency).label}</div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                <b>Riscos e Oportunidades</b>
                                <div>{ActionPlan.risk_and_opportunity.find(item => item.value === this.state.actionPlan.certificate_risk_and_opportunity).label}</div>
                            </TableCell>
                            <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                <b>Sistema Gestão da Qualidade</b>
                                <div>{ActionPlan.changes.find(item => item.value === this.state.actionPlan.certificate_changes).label}</div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                <b>Justificativa</b>
                                <div>{this.state.actionPlan.certificate_justify}</div>
                            </TableCell>
                            <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                <b>Avaliador</b>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                    <RelationField loading={false} collection={'user'} field={'name'} id={this.state.actionPlan.certificate_id_user}/>
                                    <div>-</div>
                                    <div>{moment(this.state.actionPlan.certificate_date.toDate ? this.state.actionPlan.certificate_date.toDate() : this.state.actionPlan.certificate_date).format('DD/MM/YYYY HH:mm')}</div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        )
    }

    renderCertification() {

        if (this.state.canCertificate) {

            return (

                <div style={{ marginTop: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <b>Certificação de Qualidade</b>
                        </div>
                        {
                            !this.state.addCertificate ?

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'grey', gap: 10 }}>
                                    { this.state.canApplyCertification ? 'A qualidade desse plano não foi certificada.' : null }
                                    { this.state.canApplyCertification ? <DefaultButton loading={this.state.loadingAvaliacao} color={Colors.success} leftIcon={<BrightnessLowIcon />} onClick={() => { this.setState({ addCertificate: !this.state.addCertificate, certificateDeploy: 'deployed', certificateEfficiency: 'effective', certificateRiskAndOpportunity: 'not_apply', certificateChanges: 'not_apply', certificateJustify: '' }) }} title={'Certificar Qualidade'} /> : null }
                                    { !this.state.canApplyCertification ? this.renderCertificationData() : null }
                                </div>

                            : this.renderCertificationForm() }
                    </div>
                </div>
            )
        }
    }

    render() {

        if (this.state.loading) {

            return <div><DefaultLoader loadingText={this.state.loadingText} /></div>;
        }

        if (this.state.editId) {

            return <AddActionPlan onAdd={(id) => { this.handleAdd(id) }} onBack={() => { this.handleBack() }} editId={this.state.editId}/>
        }

        return (

            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<ArrowBackIcon />} onClick={() => { this.props.onBack() }} title={'Voltar'} />
                    </div>
                    <div style={{ fontWeight: 'bold', fontSize: 24 }}>{this.state.actionPlan.description}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton color={Colors.success} leftIcon={<EditIcon style={{ marginLeft: 5}}/>} onClick={() => { this.setState({ editId: this.state.actionPlan.id }) }} title={''} />
                    </div>
                </div>
                {this.renderData()}
                {this.renderCertification()}
                {this.renderTasks()}
                {this.renderGanttSelectedItem()}
            </div>
        )
    }
}

const styles = {
    container: {
        padding: 25,
    }
}