import moment from "moment";
import Firestore from "../api/firebase/Firestore";
import Methods from "../constants/Methods";
import Operators from "../constants/Operators";
import PlanningRowHelper from "./PlanningRowHelper";
import SessionHelper from "./SessionHelper";
import Functions from '../api/firebase/Functions';

export default class DataHelper {
    static orderArrayToRender(array, orderField) {
        array.sort(function (a, b) {
            return a[orderField].localeCompare(b[orderField])
        });

        return array;
    }

    static queryToArray(query) {
        let arr = [];

        if (!query.docs.length) return arr;

        query.forEach(doc => {
            let elem = { ...doc.data(), id: doc.id };
            arr.push(elem);
        });

        return arr;
    }

    static findCommonElementsInArrays(a1, a2) {
        return a1.some(item => a2.includes(item));
    }

    static async getPlanningDataInPeriod(id_account_plans, start, end) {
        let output = {
            revenue_planning: {},
            deduction: {},
            costs: {},
            expenses: {},
            people_expenses: {},
            other_expenses_and_revenue: {},
            expenses_and_revenue: {},
        };

        for (let category of Object.keys(output)) output[category].total = 0;

        const revenues = await this.getAccountsRevenueInPeriod(id_account_plans, start, end);
        const deductions = await this.getAccountsDeductionsInPeriod(id_account_plans, start, end);

        const methods = [revenues, deductions];

        let operatorIndex = 0;

        for (let method of methods) {
            for (let account of Object.keys(method)) {
                for (let month of method[account]) {
                    let categories = Object.keys(month.categories);

                    for (let category of categories) {
                        if (output[category]) {
                            if (!output[category][account]) output[category][account] = { value: 0, value_history: 0 };

                            if (operatorIndex > 0) {
                                month.categories[category].value = month.categories[category].value * -1;
                                month.categories[category].value_history = month.categories[category].value_history * -1;
                            }

                            output[category][account].value += month.categories[category].value;
                            output[category][account].value_history += month.categories[category].value_history;

                            output[category].total += month.categories[category].value;
                        }
                    }
                }
            }

            operatorIndex++;
        }

        return output;
    }

    static async calculateTaxes(start, end, taxType, account, account_type) {
        
        let momentStart = moment(start);
        let momentEnd = moment(end);
        let months = [];
        let regime = SessionHelper.getData().company.regime_tributario;
        let type = SessionHelper.getData().company.type;
        let calculusBase = 1;
        let quota = 1;
        let additionalIrpjLimit = 20000;
        let additionalIrpjAmount = 0.10;
        let taxes = [];

        if (regime === 'lucro_presumido') {

            if (type === 'industria' || type === 'comercio') {

                calculusBase = 0.08;
            
            } else {

                calculusBase = 0.32;
            }

        } else {

            calculusBase = 1;
        }

        if (taxType === 'irpj') {
            
            quota = 0.15;
        
        } else if (taxType === 'csll') {

            quota = 0.09;
        }

        while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

            months.push(momentStart.toJSON());
            momentStart.add(1, 'month');
        }

        let body = { account_type: account_type, id_account_plan: account, id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, months: months, columns: ['budget'], open: false };
        let request = await Functions.request('POST', 'getAccountData', body, true);

        if (request && request.accounts) {

            let data = account_type === 'account' ? request.accounts.find(item => item.id === account) : request.budgetStructure.find(item => item.id === account);
            
            for (let monthIndex = 0; monthIndex < data.revenue.length; monthIndex++) {
            
                let planned = (data.revenue[monthIndex].value - data.deduction[monthIndex].value);

                if (data.isPercentage) {
        
                    planned = data.percentage[monthIndex].value;
                }

                if (planned > 0) {

                    let aditionalCalculusBase = 0;
                    planned = planned * calculusBase;

                    if (taxType === 'irpj' && planned > additionalIrpjLimit) {
    
                        let diff = planned - additionalIrpjLimit;
                        aditionalCalculusBase = diff * additionalIrpjAmount;
                    }
    
                    planned = planned * quota;
                    planned += aditionalCalculusBase;
                    
                    taxes.push({
                        date_label: moment(months[monthIndex]).format('MMM/YYYY'),
                        value: planned,
                    });

                } else {

                    taxes.push({
                        date_label: moment(months[monthIndex]).format('MMM/YYYY'),
                        value: 0,
                    });
                }
            }
        }

        return taxes;
    }

    static createArrayChunks(array, size) {

        let chunks = [];

        for (let i = 0; i < array.length; i += size) {
            
            let chunk = array.slice(i, i + size);
            chunks.push(chunk);
        }

        return chunks;
    }

    static createSubArrays(array, chunks = 1){
        const result = Array.from(Array(chunks),item=>[]);
        
        for(let index=0; index<array.length ; index++) {
           result[index % chunks].push(array[index]);
        }
      
        return result;
    }

    static createObjectFromArray(array, key, formatter = null) {

        let result = {};

        for (let index = 0; index < array.length; index++) {
            
            if (array[index][key]) {

                let objectKey = formatter ? formatter(array[index][key]) : array[index][key].toString();
                result[objectKey] = array[index];
            }
        }

        return result;
    }

    static async getAccountsRevenueInPeriod(id_account_plans, start, end, id_result_centers) {
        let output = {};
        let preLoadedRows = null;

        if (id_result_centers && id_result_centers.length > 10) {

            preLoadedRows = { docs: [] };
            id_result_centers = this.createArrayChunks(id_result_centers, 10);

            for (let index = 0; index < id_result_centers.length; index++) {

                const id_result_center_chunk = id_result_centers[index];
                let chunkData = await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.sum.key).where('relationship.id_result_center', 'in', id_result_center_chunk).orderBy('date', 'asc').get();

                if (chunkData.size > 0) {

                    preLoadedRows.docs = [...preLoadedRows.docs, ...chunkData.docs];
                }
            }

        } else {

            preLoadedRows = Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.sum.key);
            if (id_result_centers && id_result_centers.length > 0) preLoadedRows = preLoadedRows.where('relationship.id_result_center', 'in', id_result_centers);
            preLoadedRows = await preLoadedRows.orderBy('date', 'asc').get();
        }
        
        for (let id_account_plan of id_account_plans) {
            output[id_account_plan] = await this.getAccountRevenuesInPeriod(id_account_plan, start, end, preLoadedRows);
        }

        return output;
    }

    static async getAccountsDeductionsInPeriod(id_account_plans, start, end, id_result_centers, preLoadedRevenue = []) {
        let output = {};
        let preLoadedRows = null;

        preLoadedRevenue = preLoadedRevenue && preLoadedRevenue.length ? preLoadedRevenue : await this.getAccountsRevenueInPeriod(id_account_plans, start, end, id_result_centers);

        if (id_result_centers && id_result_centers.length > 10) {

            preLoadedRows = { docs: [] };
            id_result_centers = this.createArrayChunks(id_result_centers, 10);

            for (let index = 0; index < id_result_centers.length; index++) {

                const id_result_center_chunk = id_result_centers[index];
                let chunkData = await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.minus.key).where('relationship.id_result_center', 'in', id_result_center_chunk).orderBy('date', 'asc').get();

                if (chunkData.size > 0) {

                    preLoadedRows.docs = [...preLoadedRows.docs, ...chunkData.docs];
                }
            }

        } else {

            preLoadedRows = Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.minus.key);
            if (id_result_centers && id_result_centers.length > 0) preLoadedRows = preLoadedRows.where('relationship.id_result_center', 'in', id_result_centers);
            preLoadedRows = await preLoadedRows.orderBy('date', 'asc').get();
        }

        for (let id_account_plan of id_account_plans) {
            let accountPreLoadedRevenue = preLoadedRevenue[id_account_plan] || PlanningRowHelper.getPeriodRowArray(start.toDate(), end.toDate());
            output[id_account_plan] = await this.getAccountDeductionsInPeriod(id_account_plan, start, end, accountPreLoadedRevenue, preLoadedRows);
        }

        return output;
    }

    static async getAccountRevenueInPeriod(id_account_plan, start, end) {
        let id_products = await DataHelper.getAccountProducts(id_account_plan);

        return await this.getProductsRevenueInPeriod(id_products.ids, start, end);
    }

    static async getProductsRevenueInPeriod(id_products, start, end, preLoadedRows = false) {
        try {
            const query = preLoadedRows ? preLoadedRows : await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.sum.key).orderBy('date', 'asc').get();

            let rows = DataHelper.queryToArray(query);
            let output = PlanningRowHelper.getPeriodRowArray(start.toDate(), end.toDate());

            let methods = [
                [Methods.absolute.key, Methods.multiply.key],
                [Methods.percentage.key]
            ];

            for (let method of methods) {
                for (let row of rows) {
                    if (PlanningRowHelper.rowIsInPeriod(start, end, row)) {
                        if (PlanningRowHelper.productHasRelationToRow(id_products, row)) {
                            output.forEach(period => {
                                if (period.date_label == row.date_label && method.includes(row.method)) {
                                    let values;

                                    if (row.method == Methods.percentage.key) {
                                        values = PlanningRowHelper.calculatePercentageRowValues(row, period);
                                    } else {
                                        values = PlanningRowHelper.calculateRowValues(row);
                                    }

                                    period.value += values.value;
                                    period.value_history += values.value_history;

                                    if (!period.categories[row.type]) period.categories[row.type] = { value: 0, value_history: 0 };

                                    period.categories[row.type].value += values.value;
                                    period.categories[row.type].value_history += values.value_history;
                                }
                            });
                        }
                    }
                }
            }

            return output;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    static async getAccountDeductionsInPeriod(id_account_plan, start, end, preLoadedRevenue, preLoadedRows) {
        const revenue = preLoadedRevenue ? preLoadedRevenue : await this.getAccountRevenuesInPeriod(id_account_plan, start, end);

        try {
            const query = preLoadedRows ? preLoadedRows : await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.minus.key).where('relationship.id_target_account_plan', '==', id_account_plan).orderBy('date', 'asc').get();

            let rows = query.docs;
            let output = PlanningRowHelper.getPeriodRowArray(start.toDate(), end.toDate());

            let methods = [
                [Methods.absolute.key, Methods.multiply.key],
                [Methods.percentage.key]
            ];

            for (let row of rows) {
                row = {...row.data(), id: row.id};
                if ((row.relationship && row.relationship.id_target_account_plan == id_account_plan) || (row.id_target_account_plan && row.id_target_account_plan == id_account_plan)) {
                    // if (PlanningRowHelper.rowIsInPeriod(start, end, row)) {
                        output.forEach((period, key) => {
                            for (let method of methods) {
                                if (period.date_label == row.date_label && method.includes(row.method)) {
                                    let values;
    
                                    if (row.method == Methods.percentage.key) {
                                        values = PlanningRowHelper.calculatePercentageRowValues(row, revenue[key]);
                                    } else {
                                        values = PlanningRowHelper.calculateRowValues(row);
                                    }
    
                                    period.value += values.value;
                                    period.value_history += values.value_history;
    
                                    if (!period.categories[row.type]) period.categories[row.type] = { value: 0, value_history: 0 };
    
                                    period.categories[row.type].value += values.value;
                                    period.categories[row.type].value_history += values.value_history;
                                }
                            }
                        });
                    // }
                }
            }

            return output;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    static async getAccountRevenuesInPeriod(id_account_plan, start, end, preLoadedRows) {
        try {
            const query = preLoadedRows ? preLoadedRows : await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('operator', '==', Operators.minus.key).where('relationship.id_target_account_plan', '==', id_account_plan).orderBy('date', 'asc').get();

            let rows = query.docs;
            let output = PlanningRowHelper.getPeriodRowArray(start.toDate(), end.toDate());

            let methods = [
                [Methods.absolute.key, Methods.multiply.key],
                [Methods.percentage.key]
            ];

            for (let method of methods) {
                for (let row of rows) {
                    row = {...row.data(), id: row.id};
                    if ((row.relationship && row.relationship.id_target_account_plan == id_account_plan) || (row.id_target_account_plan && row.id_target_account_plan == id_account_plan)) {
                        // if (PlanningRowHelper.rowIsInPeriod(start, end, row)) {                     
                            output.forEach(period => {
                                if (period.date_label == row.date_label && method.includes(row.method)) {
                                    let values;

                                    if (row.method == Methods.percentage.key) {
                                        values = PlanningRowHelper.calculatePercentageRowValues(row, period);
                                    } else {
                                        values = PlanningRowHelper.calculateRowValues(row);
                                    }

                                    period.value += values.value;
                                    period.value_history += values.value_history;

                                    if (!period.categories[row.type]) period.categories[row.type] = { value: 0, value_history: 0 };

                                    period.categories[row.type].value += values.value;
                                    period.categories[row.type].value_history += values.value_history;
                                }
                            });
                        // }
                    }
                }
            }

            return output;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    static async getAccountProducts(id_account_plan, preLoadedProducts = false) {
        let query = preLoadedProducts ? preLoadedProducts : await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).where('id_account_plan', '==', id_account_plan).get();
        let docs = [];
        let ids = [];

        if (query.docs.length > 0) {
            query.forEach(doc => {
                let product = { ...doc.data(), id: doc.id };

                if (product.id_account_plan && product.id_account_plan == id_account_plan) {
                    docs.push({ ...doc.data(), id: doc.id });
                    ids.push(doc.id);
                }
            });
        }

        return { docs, ids };
    }
}