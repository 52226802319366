import React, { Component } from 'react';
import Colors from '../constants/Colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default class NotFoundPage extends Component {

    render() {
        return (
            <div style={{ height: this.props.height ? this.props.height : '100vh', backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: Colors.primary, fontWeight: 'bold' }}>
                <img style={{ height: 350, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8, marginTop: '5%' }} src={process.env.PUBLIC_URL + '/404.png'} />
                <div style={{ fontSize: 25, marginTop: 20 }}>Oops - Página não encontrada</div>
                <div onClick={() => { window.location.href = '/'; }} style={{ paddingTop: 3, cursor: 'pointer', marginTop: 50, paddingBottom: 3, paddingLeft: 15, borderRadius: 8, paddingRight: 15, backgroundColor: Colors.primary, boxShadow: Colors.boxShadow, width: 180, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <ArrowBackIcon style={{ color: 'white', marginRight: 20 }} />
                    <p style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>{'VOLTAR'}</p>
                </div>
            </div>
        );
    }
}
