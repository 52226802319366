import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Box, Button, Divider, FormLabel, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tabs, Tab, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import KeyboardShortcutsTooltip from '../components/KeyboardShortcutsTooltip';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import moment from 'moment';
import 'moment/locale/pt-br';
import TableChartIcon from '@material-ui/icons/TableChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PeriodSelection from '../components/PeriodSelection';
import TableHelper from '../helper/TableHelper';
import TableEditingInput from '../components/TableEditingInput';
import QuantityVsPriceTable from '../components/QuantityVsPriceTable';
import ManualInputTable from '../components/ManualInputTable';
import PriceTable from '../components/PriceTable';
import QuantityTable from '../components/QuantityTable';
import SearchIcon from '@material-ui/icons/Search';
import PermissionHelper from '../helper/PermissionHelper';
import PlanningHelper from '../helper/PlanningHelper';
import Operators from '../constants/Operators';
import PlanningRowHelper from '../helper/PlanningRowHelper';
import DataHelper from '../helper/DataHelper';
import Methods from '../constants/Methods';

const TYPE = 'revenue_planning';
export default class RevenuePlanning extends Component {

    state = {
        tab: 0,
        docs: [],
        auxDocs: [],
        settings: [],
        userSettings: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        removeProductId: ``,
        removeResultCenterId: ``,
        removeProductModal: false,
        removeResultCenterModal: false,
        addProductModal: false,
        addProductModalDocs: [],
        addResultCenterModal: false,
        addResultCenterModalDocs: [],
        editId: null,
        editDoc: {},
        editing: false,
        selectedResultCenter: {},
        selectedProduct: {},
        resultCentersDocs: [],
        periodDocs: [],
        productsDocs: [],
        editInputIndex: 0,
        editInputValue: 0,
        editingData: false,
        savingEdit: false,
        editingDataDoc: {},
        editingField: ``,
        editingElement: ``,
        description: ``,
        setting: ``,
        loadingText: 'Carregando Planejamento de Receita...',
        start: moment(),
        end: moment().add(3, 'month'),
        id_period: null,
        crSearch: '',
        prodSearch: '',
        selectedPeriod: [],
        loadingTable: false,
        productList: [],
        loadingProducts: false,
    }

    async getSettings() {
        this.setState({ loading: true });

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');
        let query = Firestore.customQuery('revenue_planning_settings').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc');

        query = await query.get();

        let docs = [];
        let userSettingsDocs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);

            let hasResultCenter = false;

            data.result_centers.forEach((resultCenter, key) => {

                if (this.state.resultCentersDocs.find(item => item.id === resultCenter)) {
                    hasResultCenter = true;
                }
            });

            if (hasResultCenter || isSuperAdmin) {
                userSettingsDocs.push(data);
            }
        });

        this.setState({ settings: docs, userSettings: userSettingsDocs, loading: false });
    }

    async getPlanningElement() {
        this.setState({ loading: true });

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');
        let query = Firestore.customQuery('planning').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', TYPE);

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (this.state.userSettings.find(item => item.id === data.id_setting) || isSuperAdmin) {

                docs.push(data);
            }
        });

        this.setState({ docs: docs, auxDocs: docs, loading: false });

        await this.filterPlanningElement();
    }

    async filterPlanningElement() {

        this.setState({ loading: true });

        let docs = [];
        let start = null;
        let end = null;

        this.state.selectedPeriod.forEach((id, key) => {

            let period = this.state.periodDocs.find(item => item.id === id);
            let periodStart = moment(period.start.toDate ? period.start.toDate() : period.start).startOf('month').startOf('day');
            let periodEnd = moment(period.end.toDate ? period.end.toDate() : period.end).endOf('month').endOf('day');

            if (start === null || periodStart.isBefore(start)) {

                start = periodStart;
            }

            if (end === null || periodEnd.isAfter(end)) {

                end = periodEnd;
            }
        });

        if (start && end) {

            this.state.auxDocs.forEach((doc, key) => {

                let docStart = moment(doc.start.toDate ? doc.start.toDate() : doc.start);
                let docEnd = moment(doc.end.toDate ? doc.end.toDate() : doc.end);

                if (docStart.isSameOrAfter(start, 'date') && docEnd.isSameOrBefore(end, 'date')) {

                    docs.push(doc);
                }
            });

            this.setState({ docs, loading: false });

        } else {

            await this.getPlanningElement();
        }
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async getDocs() {
        await this.getProducts();
        await this.getResultCenters();
        await this.getSettings();
        await this.getPeriods();
        await this.getPlanningElement();
    }

    async getProducts() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ productsDocs: docs, loading: false });
    }

    async getPeriods() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        let selectedPeriod = [];
        selectedPeriod.push(docs[0].id);

        this.setState({ periodDocs: docs, loading: false, selectedPeriod });
    }

    async getResultCenters() {

        this.setState({ loading: true });

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        this.setState({ resultCentersDocs: docs, loading: false });
    }

    async addNewDoc() {
        if(this.state.loadingModal) return;

        if (this.state.description && this.state.setting && moment(this.state.start).isValid() && moment(this.state.end).isValid() && SessionHelper.getData().id_company) {
            this.setState({ loadingModal: true });

            let setting = this.state.settings.find(item => item.id === this.state.setting);

            const start = moment(this.state.start);
            const end = moment(this.state.end);

            let insertInfo = await PlanningHelper.insertPlanning(
                {
                    type: TYPE,
                    description: this.state.description,
                    start: start.toDate(),
                    end: end.toDate(),
                    operator: Operators.sum.key,
                    id_result_centers: setting.result_centers,
                    id_setting: this.state.setting,
                    table_type: TYPE,
                    setting,
                    id_period: this.state.id_period
                }
            );

            if (insertInfo.success) {
                setting.result_centers.forEach(id_result_center => {
                    setting.products.forEach(id_product => {
                        this.addProductToDataset(insertInfo.insertId, id_result_center, id_product, start, end);
                    });
                });
            }

            await this.getPlanningElement();
        } else {
            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false, addModal: false, description: ``, setting: ``, start: moment(), end: moment().add(3, 'month')
        });
    }

    addProductToDataset(id_parent, id_result_center, id_product, start, end) {
        let product = this.state.productsDocs.find(item => item.id === id_product);

        PlanningRowHelper.insertRow(
            {
                type: TYPE,
                operator: Operators.sum.key,
                method: product.planning_methodology,
                id_parent,
                relationship: {
                    id_product,
                    id_result_center,
                    id_account_plan: product.id_account_plan
                }
            },
            start,
            end
        );
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        disabled={false}
                        search={true}
                        searchField={'description'}
                        displayField={'description'}
                        valueField={'id'}
                        value={this.state.setting}
                        onChange={(v) => {
                            this.setState({ setting: v.target.value })
                        }}
                        docs={this.state.userSettings}
                        label={'Configuração de Planejamento de Receita (Modelo)*'}
                    />
                </div>

                <PeriodSelection periods={this.state.periodDocs} onStartChange={(v) => { this.setState({ start: v }) }} onEndChange={(v) => { this.setState({ end: v }) }} onDocChange={(id) => { this.setState({ id_period: id }) }}/>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, description: ``, setting: ``, start: moment(), end: moment().add(3, 'month') }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    getSettingLookup() {
        let docs = this.state.settings;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = doc.description;
        });

        return lookup;
    }

    async setEditingDocs(rowData, firstLoad = false) {
        if (firstLoad) this.setState({ loadingText: `Obtendo dados do registro...`, loading: true });

        this.setState({ editId: rowData.id, editDoc: rowData });
        if (firstLoad) this.setState({ loading: false, editing: true, editDocRows: [] });
    }

    renderProductNotSelected() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', alignItems: 'center', flex: 1, width: '100%', height: '100%' }}>
                <img style={{ height: window.screen.height < 1080 ? '15%' : 360, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }} src={process.env.PUBLIC_URL + '/spreadsheet.png'} />

                <div style={{ textAlign: 'center', fontSize: 20, color: Colors.primary, marginTop: 35, fontWeight: '600', maxWidth: '50%' }}>Selecione um Produto ou Serviço para editar e visualizar o Planejamento de Receitas</div>
            </div>
        )
    }

    renderCrList() {
        let existingResultCenters = [];

        if (this.state.editDoc.id_result_centers) {

            this.state.editDoc.id_result_centers.forEach(id => {
                existingResultCenters.push(id);
            });
        }

        let arrayToOrder = [];

        existingResultCenters.forEach(rc => {
            let resultCenter;

            this.state.resultCentersDocs.forEach(doc => {
                if (doc.id == rc) {
                    resultCenter = doc;
                }
            })

            if (resultCenter) {
                arrayToOrder.push(resultCenter);
            }
        });

        let arrayToRender = DataHelper.orderArrayToRender(arrayToOrder, 'description');

        if (this.state.crSearch && this.state.crSearch.length > 0) {
            let search = this.searchArray(arrayToOrder, 'description', this.state.crSearch);

            if (search.length > 0) {
                arrayToRender = search;
            } else {
                arrayToRender = [];
            }
        }

        return (
            <div>
                <div style={styles.listWrapper}>
                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                        <SearchIcon style={{ marginRight: 10, fontSize: 25 }} />
                        <DefaultInput
                            autoFocus={true}
                            label={'Centros de Resultado'}
                            type={'search'}
                            value={this.state.crSearch}
                            defaultValue={this.state.crSearch}
                            onChange={(value) => {
                                if (!value) this.setState({ crSearch: '', selectedProduct: {} });

                                this.setState({ crSearch: value, selectedProduct: {} });
                            }}
                        />
                    </div>
                    <List component="nav" aria-label="Centros de Resultado da Edição de Planejamento" style={{ height: 'calc(100vh - 380px)', overflowY: 'auto' }}>
                        {arrayToRender.map(resultCenter => {
                            let rc = resultCenter.id;
                            let selected = this.state.selectedResultCenter && this.state.selectedResultCenter.id == rc;
                            let text = resultCenter.description.length > 13 ? resultCenter.description.slice(0, 13) + '...' : resultCenter.description;

                            if (resultCenter) {
                                return (
                                    <Tooltip title={resultCenter.description}>
                                        <ListItem button selected={selected} style={selected ? { backgroundColor: Colors.primary } : null} onClick={ async () => {
                                        if (!this.state.selectedResultCenter || (this.state.selectedResultCenter && this.state.selectedResultCenter.id !== resultCenter.id)) {

                                            this.setState({ selectedResultCenter: resultCenter, selectedProduct: {}, productList: [], loadingProducts: true });
                                            let products = await PlanningHelper.getResultCenterProducts(this.state.editDoc, this.state.productsDocs, resultCenter.id);
                                            this.setState({ productList: products, loadingProducts: false });
                                        }
                                        }}>
                                        <ListItemIcon>
                                            <TrendingUpIcon style={selected ? { color: `#fff` } : null} />
                                        </ListItemIcon>
                                        <ListItemText secondaryTypographyProps={selected ? { color: `#fff` } : null} style={selected ? { color: `#fff` } : null} primary={text} secondary={resultCenter.code} />
                                        <ListItemSecondaryAction onClick={async () => { await this.setState({ removeResultCenterId: resultCenter.id, removeResultCenterModal: true }) }}>
                                            <IconButton edge="end" aria-label="Remover Item">
                                                <DeleteIcon style={selected ? { color: `#fff` } : null} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                        </ListItem>
                                    </Tooltip>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </List>
                    <Divider />
                    <List component="nav" aria-label="Adicionar novo centro de resultado">
                        <ListItem button onClick={() => { this.setState({ addResultCenterModal: true }) }}>
                            <ListItemIcon>
                                <AddIcon style={{ color: Colors.primary }} />
                            </ListItemIcon>
                            <ListItemText primary={'Adicionar'} style={{ color: Colors.primary }} />
                        </ListItem>
                    </List>
                </div>
            </div>

        );
    }

    searchArray(array, searchField, keyword) {
        let search = [];

        for (var j = 0; j < array.length; j++) {
            var regex = new RegExp(keyword.toLowerCase(), "gm");
            let string = array[j][searchField].toLowerCase();
            if (string.match(regex)) search.push(array[j]);
        }

        return search;
    }

    renderProductList() {

        let arrayToOrder = this.state.productList;
        let arrayToRender = DataHelper.orderArrayToRender(arrayToOrder, 'description');

        if (this.state.prodSearch && this.state.prodSearch.length > 0) {
            let search = this.searchArray(arrayToOrder, 'description', this.state.prodSearch);

            if (search.length > 0) {
                arrayToRender = search;
            } else {
                arrayToRender = [];
            }
        }

        if (this.state.loadingProducts) {

            return (

                <div style={styles.listWrapper}>
                    {this.renderLoadingTable()}
                </div>
            )
        }        

        return (
            <div style={{ marginLeft: 20 }}>
                <div style={styles.listWrapper}>
                    <div>
                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                            <SearchIcon style={{ marginRight: 10, fontSize: 25 }} />
                            <DefaultInput
                                autoFocus={true}
                                label={'Produtos e Serviços'}
                                type={'search'}
                                value={this.state.prodSearch}
                                defaultValue={this.state.prodSearch}
                                onChange={(value) => {
                                    if (!value) this.setState({ prodSearch: '', selectedProduct: {} });

                                    this.setState({ prodSearch: value, selectedProduct: {} });
                                }}
                            />
                        </div>
                    </div>
                    <List component="nav" aria-label="Centros de Resultado da Edição de Planejamento" style={{ height: 'calc(100vh - 380px)', overflowY: 'auto' }}>
                        {arrayToRender.map(product => {
                            let p = product.id;
                            let selected = this.state.selectedProduct && this.state.selectedProduct.id == p;
                            let text = product.description.length > 18 ? product.description.slice(0, 18) + '...' : product.description;

                            if (product) {
                                return (
                                    <Tooltip title={product.description}>
                                        <ListItem button selected={selected} style={selected ? { backgroundColor: Colors.primary } : null} onClick={ async () => {
                                        if (!this.state.selectedProduct || (this.state.selectedProduct && this.state.selectedProduct.id !== product.id)) {

                                            this.setState({ selectedProduct: product, tab: product.planning_methodology == 'multiply' ? 1 : 0, loadingTable: true });
                                            let editDocRows = await PlanningRowHelper.getRowsByParentId(this.state.editId, this.state.revenueRows, this.state.selectedResultCenter.id, product.id);
                                            this.setState({ editDocRows, loadingTable: false });
                                        }
                                        }}>
                                        <ListItemIcon>
                                            <LocalMallIcon style={selected ? { color: `#fff` } : null} />
                                        </ListItemIcon>
                                        <ListItemText secondaryTypographyProps={selected ? { color: `#fff` } : null} style={selected ? { color: `#fff` } : null} primary={text} secondary={`${product.code || ''} • ${product.type == 'product' ? 'Produto' : 'Serviço'}`} />
                                        <ListItemSecondaryAction onClick={async () => { await this.setState({ removeResultCenterId: this.state.selectedResultCenter.id, removeProductId: product.id, removeProductModal: true }) }}>
                                            <IconButton edge="end" aria-label="Remover Item">
                                                <DeleteIcon style={selected ? { color: `#fff` } : null} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                        </ListItem>
                                    </Tooltip>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </List>
                    <Divider />
                    <List component="nav" aria-label="Adicionar novo produto">
                        <ListItem button onClick={() => { this.setState({ addProductModal: true }) }}>
                            <ListItemIcon>
                                <AddIcon style={{ color: Colors.primary }} />
                            </ListItemIcon>
                            <ListItemText primary={'Adicionar'} style={{ color: Colors.primary }} />
                        </ListItem>
                    </List>
                </div>
            </div>

        );
    }

    getAddProductModalDocs = () => {
        let existingProducts = [];

        this.state.editDocRows.forEach(row => {
            let relationship = row.relationship;

            if (relationship.id_result_center == this.state.selectedResultCenter.id) {
                if (!existingProducts.includes(relationship.id_product)) existingProducts.push(relationship.id_product);
            }
        });

        let arrayToOrder = [];

        this.state.productsDocs.forEach(doc => {
            if (!existingProducts.includes(doc.id)) {
                arrayToOrder.push(doc);
            }
        });

        let arrayToRender = DataHelper.orderArrayToRender(arrayToOrder, 'description');

        return arrayToRender;
    }

    getAddResultCenterModalDocs = () => {
        let existingResultCenters = [];

        if (this.state.editDoc.id_result_centers) {

            this.state.editDoc.id_result_centers.forEach(id => {
                existingResultCenters.push(id);
            });    
        }
        
        let arrayToOrder = [];

        this.state.resultCentersDocs.forEach(doc => {
            if (!existingResultCenters.includes(doc.id)) {
                arrayToOrder.push(doc);
            }
        })

        let arrayToRender = DataHelper.orderArrayToRender(arrayToOrder, 'description');

        return arrayToRender;
    }

    addProduct = async () => {
        if (this.state.addProductModalDocs.length < 1) return;

        try {
            this.setState({ loadingModal: true });

            const start = moment(this.state.editDoc.start.toDate ? this.state.editDoc.start.toDate() : this.state.editDoc.start);
            const end = moment(this.state.editDoc.end.toDate ? this.state.editDoc.end.toDate() : this.state.editDoc.end);

            this.state.addProductModalDocs.forEach(id_product => {
                this.addProductToDataset(this.state.editDoc.id, this.state.selectedResultCenter.id, id_product, start, end);
            });

            setTimeout(async () => {
                await this.setEditingDocs(this.state.editDoc, true);
            }, 1000);

            this.setState({ loadingModal: false, addProductModal: false, addProductModalDocs: [] })

            toast.success("Adicionado com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    removeProduct = async () => {
        await this.setState({ selectedProduct: {} });

        try {
            this.setState({ loadingModal: true });

            this.state.editDocRows.forEach(row => {
                if (row.relationship.id_result_center == this.state.removeResultCenterId
                    && row.relationship.id_product == this.state.removeProductId) {
                    PlanningRowHelper.removeRowFromParent(row.id);
                }
            });

            setTimeout(async () => {
                await this.setEditingDocs(this.state.editDoc, true);
            }, 1000);

            this.setState({ loadingModal: false, removeProductModal: false });

            toast.success("Removido com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addResultCenter = async () => {
        if (this.state.addResultCenterModalDocs.length < 1) return;

        try {
            this.setState({ loadingModal: true });

            let id_result_centers = [...this.state.editDoc.id_result_centers, ...this.state.addResultCenterModalDocs];
            await Firestore.update({ id_result_centers }, 'planning', this.state.editDoc.id);

            this.setState({ loadingModal: false, editDoc: { ...this.state.editDoc, id_result_centers }, addResultCenterModal: false, addResultCenterModalDocs: [] })
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    removeResultCenter = async () => {
        await this.setState({ selectedProduct: {}, selectedResultCenter: {} });

        try {
            this.setState({ loadingModal: true });

            let id_result_centers = [...this.state.editDoc.id_result_centers];
            let removeIndex = id_result_centers.indexOf(this.state.removeResultCenterId);
            if (removeIndex > -1) id_result_centers.splice(removeIndex, 1);

            await Firestore.update({ id_result_centers }, 'planning', this.state.editDoc.id);

            this.state.editDocRows.forEach(row => {
                if (row.relationship.id_result_center == this.state.removeResultCenterId) {
                    PlanningRowHelper.removeRowFromParent(row.id);
                }
            });

            this.setState({ loadingModal: false, editDoc: { ...this.state.editDoc, id_result_centers }, removeResultCenterModal: false });

            toast.success("Removido com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addProductModal() {
        return (
            <div>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        disabled={false}
                        multiple={true}
                        search={true}
                        searchField={'description'}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.addProductModalDocs}
                        onChange={(v) => {
                            this.setState({ addProductModalDocs: v.target.value })
                        }}
                        docs={this.getAddProductModalDocs()}
                        label={'Produtos'}
                    />
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={this.addProduct} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'ADICIONAR E SALVAR'}</Button>
                    <Button onClick={() => { this.setState({ addProductModal: false, addProductModalDocs: [] }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    addResultCenterModal() {
        return (
            <div>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        disabled={false}
                        multiple={true}
                        search={true}
                        searchField={'description'}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.addResultCenterModalDocs}
                        onChange={(v) => {
                            this.setState({ addResultCenterModalDocs: v.target.value })
                        }}
                        docs={this.getAddResultCenterModalDocs()}
                        label={'Centros de Resultado'}
                    />
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={this.addResultCenter} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'ADICIONAR E SALVAR'}</Button>
                    <Button onClick={() => { this.setState({ addResultCenterModal: false, addResultCenterModalDocs: [] }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    removeResultCenterModal() {
        return (
            <div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                    <Button onClick={() => { this.removeResultCenter(this.state.removeResultCenterId) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'REMOVER'}</Button>
                    <Button onClick={() => { this.setState({ removeResultCenterModal: false, removeResultCenterId: `` }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    removeProductModal() {
        return (
            <div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                    <Button onClick={this.removeProduct} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'REMOVER'}</Button>
                    <Button onClick={() => { this.setState({ removeProductModal: false, removeResultCenterId: ``, removeProductId: `` }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    setParentState = data => {
        this.setState(data);
    }

    renderInputEditing = (defaultValue) => {
        return (
            <TableEditingInput state={this.state} setParentState={this.setParentState} defaultValue={defaultValue} percentage={false} saveEdit={this.saveEdit} type={'number'} />
        );
    }

    saveEdit = async (percentage, saveEntireRow) => {
        try {
            this.setState({ savingEdit: true });

            if (this.state.rowId) {
                let editInputValue = Number(this.state.editInputValue);

                let saveIds = [];

                if (saveEntireRow) {
                    this.state.editingRows.forEach(row => saveIds.push(row.id));
                } else {
                    saveIds = [this.state.rowId];
                }

                for (let id of saveIds) {
                    let data = this.state.editDocRows.find(item => item.id === id);
                    data[this.state.editingField] = editInputValue;

                    await PlanningRowHelper.updateRow(data, id);
                }

                this.setState({ editingData: false, editInputValue: editInputValue });
            } else {
                throw new Error();
            }
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        this.setState({ savingEdit: false });
    }

    renderQuantityTable(method, rows) {
        return (
            <div style={styles.tableContainer}>
                <QuantityTable rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} moreIsBetter={true} />
                {TableHelper.getTableFooter(Methods[method].label)}
            </div>
        );
    }

    renderPriceTable(method, rows) {
        return (
            <div style={styles.tableContainer}>
                <PriceTable rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} moreIsBetter={true} />
                {TableHelper.getTableFooter(Methods[method].label)}
            </div>
        );
    }

    renderRevenueTable(method, rows, canEdit) {
        let table;

        if (method == 'multiply') {
            table = <QuantityVsPriceTable rows={rows} moreIsBetter={true} />
        } else {
            table = <ManualInputTable field={'revenue'} rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} readOnly={canEdit} moreIsBetter={true} />
        }


        return (
            <div style={styles.tableContainer}>
                {table}
                {TableHelper.getTableFooter(Methods[method].label)}
            </div>
        );
    }

    renderLoadingTable() {
        return (
            <div style={styles.plannigContainer} className={'mobile-horizontal-table'}>
                <DefaultLoader loadingText={'Carregando...'} />
            </div>
        );
    }

    renderTableTabs() {
        let method = this.state.selectedProduct.planning_methodology;
        let rows = [];

        this.state.editDocRows.forEach(row => {
            let id_result_center = this.state.selectedResultCenter.id;
            let id_product = this.state.selectedProduct.id;
            let relationship = row.relationship;

            if (relationship.id_result_center == id_result_center && relationship.id_product == id_product) {
                rows.push(row);
            }
        });

        return (
            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <Box style={{ boxShadow: Colors.boxShadow }}>
                    <Tabs
                        style={{ backgroundColor: '#fff' }}
                        value={this.state.tab}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: Colors.primary } }}
                        onChange={(event, index) => { this.setState({ tab: index }) }}
                    >
                        <Tab disabled={false} label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary }}>Receita</span>} />
                        <Tab disabled={method === 'multiply' ? false : true}label={<span style={{ fontWeight: this.state.tab === 1 ? 'bold' : '', color: Colors.primary }}>Preço</span>} />
                        <Tab disabled={method === 'multiply' ? false : true} label={<span style={{ fontWeight: this.state.tab === 2 ? 'bold' : '', color: Colors.primary }}>Quantidade</span>} />
                    </Tabs>

                    <TableHelper.TabPanel value={this.state.tab} index={0}>
                        {this.renderRevenueTable(method, rows, method === 'absolute' ? false : true)}
                    </TableHelper.TabPanel>
                    <TableHelper.TabPanel value={this.state.tab} index={1}>
                        {this.renderPriceTable(method, rows)}
                    </TableHelper.TabPanel>
                    <TableHelper.TabPanel value={this.state.tab} index={2}>
                        {this.renderQuantityTable(method, rows)}
                    </TableHelper.TabPanel>

                </Box>
            </div>
        )
    }

    renderEditing() {
        return (
            <div style={styles.plannigContainerWrapper}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <DefaultButton onClick={() => { this.setState({ editId: null, editDoc: {}, editing: false, selectedProduct: {}, selectedResultCenter: {} }) }} title={'Voltar'} leftIcon={<ChevronLeftIcon />} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 200, marginBottom: 10, textAlign: 'center' }}>
                        <FormLabel style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1 }}>{this.state.editDoc.description}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 2, fontWeight: 200 }}>Planejamento de Receita</FormLabel>
                    </div>

                    <KeyboardShortcutsTooltip />
                </div>



                <div style={styles.plannigContainer} className={'mobile-horizontal-table'}>
                    <div style={styles.plannigContainerColumns}>
                        {this.renderCrList()}
                        {this.state.selectedResultCenter.id ? this.renderProductList() : null}
                    </div>
                    <div style={{ width: '100%', paddingLeft: 20, overflow: 'scroll' }}>
                        {this.state.selectedProduct && this.state.selectedProduct.id && !this.state.loadingTable ? this.renderTableTabs() : null}
                        {this.state.selectedProduct && this.state.selectedProduct.id && this.state.loadingTable ? this.renderLoadingTable() : null}
                        {!this.state.selectedProduct && !this.state.loadingTable ? this.renderProductNotSelected() : null}
                    </div>
                </div>

                <DefaultModal loading={this.state.loadingModal} content={this.addProductModal()} title={'Adicionar Produto a CR neste Planejamento de Receita'} onClose={() => { this.setState({ addProductModal: false }) }} open={this.state.addProductModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addResultCenterModal()} title={'Adicionar CR a este Planejamento de Receita'} onClose={() => { this.setState({ addResultCenterModal: false }) }} open={this.state.addResultCenterModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.removeResultCenterModal()} title={'Remover CR do planejamento? Essa ação é irreversível.'} onClose={() => { this.setState({ removeResultCenterModal: false }) }} open={this.state.removeResultCenterModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.removeProductModal()} title={'Remover produto deste CR neste planejamento? Essa ação é irreversível.'} onClose={() => { this.setState({ removeProductModal: false }) }} open={this.state.removeProductModal} />
            </div>
        )
    }

    renderGrid() {
        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        { PlanningHelper.renderPlanningPeriodSelection(this.state.periodDocs, this.state.selectedPeriod, async (selectedPeriod) => { await this.setState({ selectedPeriod }); await this.filterPlanningElement(); }) }
                    </div>
                </div> 

                <DefaultTable
                    title={'Planejamento de Receita'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Baseado no Modelo', field: 'id_setting', editable: false, lookup: this.getSettingLookup() },
                        { title: 'Sinal', field: 'operator', render: rowData => rowData.operator === Operators.sum.key ? Operators.sum.label : Operators.minus.label, lookup: PlanningRowHelper.getOperatorLookup() },
                        { title: 'Período Inícial', field: 'start', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                        { title: 'Período Final', field: 'end', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                    ]}
                    actions={[
                        {
                            icon: TableChartIcon,
                            tooltip: 'Abrir Planejamento',
                            onClick: async (event, rowData) => { await this.setEditingDocs(rowData, true) }
                        },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        
                        if (newData.start && newData.end) {
                            newData.start = newData.start.seconds ? new Date(newData.start.seconds * 1000) : newData.start.toDate ? newData.start.toDate() : newData.start;
                            newData.end = newData.end.seconds ? new Date(newData.end.seconds * 1000) : newData.end.toDate ? newData.end.toDate() : newData.end;
                        }

                        await PlanningHelper.updatePlanning({ description: newData.description, operator: newData.operator, start: newData.start, end: newData.end }, oldData.id);

                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);
                        prev[index] = { ...oldData, description: newData.description, operator: newData.operator, start: newData.start, end: newData.end };

                        this.setState({ docs: prev });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);
                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        await PlanningHelper.deletePlanning(oldData.id);
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Planejamento de Receita'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />

            </div>
        );
    }

    renderContent() {
        return this.state.editing ? this.renderEditing() : this.renderGrid();
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : this.renderContent();
    }
}

const styles = {
    container: {
        padding: 25,
    },
    plannigContainerWrapper: {
        width: '100%',
        flexDirection: 'column',
        flex: 1,
        padding: 25,
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainerColumns: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginTop: 5,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.background,
        overflow: 'hidden',
    },
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    },
    listWrapper: {
        width: 280,
        borderRadius: 5,
        maxHeight: '100%',
        backgroundColor: `#ffffff`,
        boxShadow: Colors.boxShadow,
    },
}
