import moment from "moment";
import Firestore from "../api/firebase/Firestore";
import SessionHelper from "./SessionHelper";
import PermissionHelper from "./PermissionHelper";
import Functions from "../api/firebase/Functions";

export default class ChartHelper {

    static getGaugeConfig() {

        return {
            circumference: Math.PI,
            rotation : Math.PI,
            cutoutPercentage : 90,
            plugins: {
                datalabels: {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        borderColor: '#ffffff',
                    color: function(context) {
                        return context.dataset.backgroundColor;
                    },
                    font: function(context) {
                        var w = context.chart.width;
                        return {
                            size: w < 512 ? 18 : 20
                        }
                    },
                    align: 'start',
                    anchor: 'start',
                    offset: 10,
                    borderRadius: 4,
                    borderWidth: 1,
                }
            },
        }
    }

    static getLabelsFromPeriod(start, end, type, chart, columns) {

        if (chart !== 'speedometer') {

            let momentStart = moment(start);
            let momentEnd = moment(end);
    
            if (type === 'monthly') {
    
                let labels = [];
    
                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {
                    
                    labels.push(momentStart.format('MMM/YYYY'));
                    momentStart.add(1,'month');
                }
    
                return labels;
    
            } else if (type === 'accumulated') {
    
                let label = `${momentStart.format('MMM/YYYY')} até ${momentEnd.format('MMM/YYYY')}`;
    
                return [label];
    
            } else {
    
                return [];
            }

        } else {

            let columnsArray = [...this.getColumns(), ...this.getColumns2()];
            let labels = [];

            columns.forEach((column, key) => {

                let label = columnsArray.find(item => item.value === column);
                labels.push(label.name);
            });

            return labels;
        }
    }

    static async getBudgetStructure() {

        let query = await Firestore.customQuery('budget_structure').where('id_company', '==', SessionHelper.getData().id_company).orderBy('order', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            data.description = data.name;

            docs.push(data);
        });

        return docs;
    }

    static async getAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        return docs;
    }

    static getColumnValue(account, column, monthIndex, verticalAnalysisAccountPlan, accountPlanDocs, budgetStructureDocs, inverted = false) {

        let value = 0;
        let verticalAccomplished = 0;
        let accomplished = { value: account.accomplished[monthIndex].value, history: account.accomplished[monthIndex].value_history};
        let plannedAndHistory = { planned: (account.revenue[monthIndex].value - account.deduction[monthIndex].value), history: (account.revenue[monthIndex].value_history - account.deduction[monthIndex].value_history)};

        if (account.isPercentage) {

            plannedAndHistory = { planned: account.percentage[monthIndex].value, history: account.percentage[monthIndex].value_history}
        }

        if (inverted) {

            if (accomplished.value < 0) {

                accomplished.value = accomplished.value * -1;
            }

            if (accomplished.history < 0) {

                accomplished.history = accomplished.history * -1;
            }

            if (plannedAndHistory.planned < 0) {

                plannedAndHistory.planned = plannedAndHistory.planned * -1;
            }

            if (plannedAndHistory.history < 0) {

                plannedAndHistory.history = plannedAndHistory.history * -1;
            }
        }

        if (verticalAnalysisAccountPlan) {

            let doc = accountPlanDocs.find(item => item.id === verticalAnalysisAccountPlan);

            if (!doc) {

                doc = budgetStructureDocs.find(item => item.id === verticalAnalysisAccountPlan);
            }

            verticalAccomplished = doc.accomplished[monthIndex];
        }

        if (column === 'budget') {

            value = plannedAndHistory.planned;

        } else if (column === 'accomplished') {

            value = accomplished.value;

        } else if (column === 'history') {

            value = accomplished.history;

        } else if (column === 'deviation_money') {

            value = accomplished.value - plannedAndHistory.planned;

        } else if (column === 'deviation_percentage') {

            value = plannedAndHistory.planned > 0 ? (((accomplished.value - plannedAndHistory.planned) / plannedAndHistory.planned) * 100) : 0;

        } else if (column === 'variation_money') {

            value = accomplished.value - accomplished.history;

        } else if (column === 'variation_percentage') {

            value = accomplished.history > 0 ? (((accomplished.value - accomplished.history) / accomplished.history) * 100) : 0;

        }  else if (column === 'vertical_analysis') {

            value = ((accomplished.value / verticalAccomplished.value) * 100) || 0;
        }

        return value;
    }

    static async buildDataset(start, end, columns, type, dataType, dataSource, periodType, filterResultCenters = [], verticalAnalysisAccount = null, inverted = false) {

        let columnsArray = [...this.getColumns(), ...this.getColumns2()];
        let datasets = [];

        let resultCenterDocs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid);
        let accounts = await this.getAccountPlanDocs();
        let budgetStructure = await this.getBudgetStructure();

        let momentStart = moment(start);
        let momentEnd = moment(end);
        let months = [];
        let parsedMonths = [];

        while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

            months.push(momentStart.toDate());
            momentStart.add(1, 'month');
        }

        if (filterResultCenters.length === resultCenterDocs.length) {

            filterResultCenters = [];
        }

        for (let index = 0; index < months.length; index++) {

            parsedMonths.push(months[index].toJSON());
        }

        let body = { data_type: dataType, data_source: dataSource, id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, id_result_centers: filterResultCenters, months: parsedMonths, columns: columns };
        let request = null;
        let tries = 0;

        while (request === null && tries <= 10) {

            request = await Functions.request('POST', 'getChart', body, true);
            tries ++;
        }

        if (request && request.accounts && request.budgetStructure) {
            
            accounts = request.accounts;
            budgetStructure = request.budgetStructure;

        } else {

            throw new Error('Request Error');
        }

        if (type !== 'speedometer') {

            for (let index = 0; index < columns.length; index++) {

                const column = columns[index];

                let monthIndex = 0;
                let data = [];
                let momentStart = moment(start);
                let momentEnd = moment(end);
    
                if (periodType === 'monthly') {

                    while (momentEnd >= momentStart || momentStart.format('M') === momentEnd.format('M')) {

                        let value = 0;

                        if (dataType === 'account') {

                            let account = accounts.find(item => item.id === dataSource);
                            value = this.getColumnValue(account, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                            
                        } else if (dataType === 'account_package') {

                            let query = await Firestore.getDoc('account_package', dataSource);

                            if (query.exists) {
                                
                                let data = { ...query.data(), id: query.id };
                                let accountPlans = data.account_plans;
            
                                if (accountPlans && accountPlans.length) {
                                    
                                    for (let index = 0; index < accountPlans.length; index++) {
    
                                        let account = accounts.find(item => item.id === accountPlans[index]);
                                        value += this.getColumnValue(account, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                                    }
                                }
                            }

                        } else {

                            let budgetStructureData = await Firestore.getDoc('budget_structure', dataSource);
                            budgetStructureData = {...budgetStructureData.data(), id: budgetStructureData.id};

                            budgetStructureData = budgetStructure.find(item => item.id === budgetStructureData.id);
                            value = this.getColumnValue(budgetStructureData, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                        }

                        data.push(value.toFixed(2));
                        momentStart.add(1,'month');
                        monthIndex ++;
                    }

                } else {

                    let value = 0;

                    while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {
    
                        if (dataType === 'account') {

                            let account = accounts.find(item => item.id === dataSource);
                            value += this.getColumnValue(account, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                            
                        } else if (dataType === 'account_package') {

                            let query = await Firestore.getDoc('account_package', dataSource);

                            if (query.exists) {
                                
                                let data = { ...query.data(), id: query.id };
                                let accountPlans = data.account_plans;
            
                                if (accountPlans && accountPlans.length) {
                                    
                                    for (let index = 0; index < accountPlans.length; index++) {
    
                                        let account = accounts.find(item => item.id === accountPlans[index]);
                                        value += this.getColumnValue(account, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                                    }
                                }
                            }

                        } else {

                            let budgetStructureData = await Firestore.getDoc('budget_structure', dataSource);
                            budgetStructureData = {...budgetStructureData.data(), id: budgetStructureData.id};

                            budgetStructureData = budgetStructure.find(item => item.id === budgetStructureData.id);
                            value += this.getColumnValue(budgetStructureData, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                        }
        
                        momentStart.add(1,'month');
                        monthIndex ++;
                    }

                    data.push(value.toFixed(2));
                }
    
                let label = columnsArray.find(item => item.value === column);
    
                datasets.push({
                    label: label.name,
                    backgroundColor: this.getDatasetColor(column, type),
                    borderWidth: 1,
                    borderColor: this.getDatasetColor(column, type, true),
                    pointBackgroundColor: this.getDatasetColor(column, type, true),
                    data: data,
                });
            }

        } else {

            let data = [];
            let colors = [];

            for (let index = 0; index < columns.length; index++) {

                let momentStart = moment(start);
                let momentEnd = moment(end);

                const column = columns[index];

                let monthIndex = 0;
                let value = 0;

                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

                    if (dataType === 'account') {

                        let account = accounts.find(item => item.id === dataSource);
                        value += this.getColumnValue(account, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                            
                    } else if (dataType === 'account_package') {

                        let query = await Firestore.getDoc('account_package', dataSource);

                        if (query.exists) {
                            
                            let data = { ...query.data(), id: query.id };
                            let accountPlans = data.account_plans;
        
                            if (accountPlans && accountPlans.length) {
                                
                                for (let index = 0; index < accountPlans.length; index++) {

                                    let account = accounts.find(item => item.id === accountPlans[index]);
                                    value += this.getColumnValue(account, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                                }
                            }
                        }

                    } else {

                        let budgetStructureData = await Firestore.getDoc('budget_structure', dataSource);
                        budgetStructureData = {...budgetStructureData.data(), id: budgetStructureData.id};

                        budgetStructureData = budgetStructure.find(item => item.id === budgetStructureData.id);
                        value += this.getColumnValue(budgetStructureData, column, monthIndex, verticalAnalysisAccount, accounts, budgetStructure, inverted);
                    }

                    momentStart.add(1,'month');
                    monthIndex ++;
                }

                data.push(value.toFixed(2));
                colors.push(this.getDatasetColor(column, type));
            }

            datasets.push({
                data: data,
                backgroundColor: colors,
            });
        }

        return datasets;
    }

    static getDatasetColor(column, type, stroke) {

        let alpha = type === 'line' && !stroke ? '0.2' : '1';
        let color = '';

        if (column === 'budget') {

            color = `rgba(231, 76, 60, ${alpha})`;
        
        } else if (column === 'accomplished') {

            color = `rgba(39, 174, 96, ${alpha})`;

        } else if (column === 'history') {

            color = `rgba(52, 152, 219, ${alpha})`;

        } else if (column === 'variation_money') {

            color = `rgba(142, 68, 173, ${alpha})`;

        } else if (column === 'variation_percentage') {

            color = `rgba(155, 89, 182, ${alpha})`;

        } else if (column === 'deviation_money') {

            color = `rgba(241, 196, 15, ${alpha})`;

        } else if (column === 'deviation_percentage') {

            color = `rgba(118, 215, 196, ${alpha})`;

        } else if (column === 'vertical_analysis') {

            color = `rgba(17, 120, 100, ${alpha})`;
        }

        return color;
    }

    static getCharts() {

        return [
            { name: 'Linha', value: 'line', img: process.env.PUBLIC_URL + '/charts/line-chart.png' },
            { name: 'Barra', value: 'bar', img: process.env.PUBLIC_URL + '/charts/bar-graph.png' },
            { name: 'Gauge', value: 'speedometer', img: process.env.PUBLIC_URL + '/charts/speedometer.png' },
        ];
    }
    
    static getColumns() {

        return [
            { name: 'Orçado', value: 'budget', checked: false, type: 'money', key: 0 },
            { name: 'Realizado', value: 'accomplished', checked: false, type: 'money',key: 1 },
            { name: 'Histórico', value: 'history', checked: false, type: 'money', key: 2 },
        ];
    }

    static getColumns2() {

        return [
            { name: 'Variação ($)', value: 'variation_money', checked: false , type: 'money', key: 3 },
            { name: 'Variação (%)', value: 'variation_percentage', checked: false, type: 'percentage', key: 4 },
            { name: 'Desvio ($)', value: 'deviation_money', checked: false, type: 'money', key: 5 },
            { name: 'Desvio (%)', value: 'deviation_percentage', checked: false, type: 'percentage', key: 6 },
            { name: 'Análise Vertical (%)', value: 'vertical_analysis', checked: false, type: 'percentage', key: 7 },
        ];
    }

    static getLabelFromColumn(column) {
        
        let columnsArray = [...this.getColumns(), ...this.getColumns2()];
        let index = columnsArray.findIndex(item => item.value === column);

        return columnsArray[index].name;
    }
}