import CurrencyHelper from "./CurrencyHelper";

export default class CalculusHelper {
    static mean(total, nOfElements) {
        return Number(total) / Number(nOfElements);
    }

    static variance(mean, arrayOfValues, nOfValues) {
        let sum = 0;

        arrayOfValues.forEach(val => {
            sum += Math.pow((Number(val) - mean), 2);
        });

        return sum / Number(nOfValues);
    }

    static variation(current, history, percentage) {
        let calcVar = (current - history);
        if (percentage == true) {
            if (history === 0) {
                history = current < 0 ? -current : current;
            }
            calcVar /= history;
            calcVar *= 100;
        }

        if (isNaN(calcVar)) calcVar = 0;

        return calcVar;
    }

    static formatVariation(variation, percentage, formatPercentage) {

        if (isNaN(variation)) {
            variation = 0;
        }

        let formatedVar = CalculusHelper.formatFloat(variation, formatPercentage);

        if (variation > 0) formatedVar = `+${formatedVar}`
        if (variation <= 0) formatedVar = formatedVar
        if (percentage == true) formatedVar = `${formatedVar}%`;

        return formatedVar;
    }

    static formatFloat(float, percentage = false) {
        let formattedFloat = float.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: percentage ? 4 : 2 });
        return formattedFloat;
    }

    static formatType(type, value) {
        switch (type) {
            case 'money':
                return `${value}`;
            case 'percentage':
                return `${value}%`;
            default:
                return value;
        }
    }

    static percentageHistory(accountRow, historyRow) {

        if (!accountRow || !historyRow) {
            return 0;
        }
        if (accountRow.value_history === 0) {
            return 0;
        }

        return (accountRow.value_history / historyRow.value_history) * 100;
    }

    static percentageHistoryTotal(rows, comparativeRows, valueField) {

        try {

            let formatted = 0;
            let rowTotal = 0;
            let historyTotal = 0;
            
            for (let index = 0; index < rows.length; index++) {
                
                const row = rows[index];
                const historyRow = comparativeRows[index];
    
                if (row[valueField]) {
    
                    if (valueField === 'value') {
    
                        rowTotal += row[valueField] * (historyRow[valueField] / 100);
    
                    } else {
    
                        rowTotal += row[valueField];
                    }
                }
    
                if (historyRow[valueField]) {
    
                    historyTotal += historyRow[valueField];
                }
            }
    
            formatted = Math.abs(historyTotal) > 0 ? ((rowTotal / historyTotal) * 100) : 0;
    
            return formatted;

        } catch (error) {

            return 0;
        }
    }
}