import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@material-ui/core';
import CalculusHelper from '../helper/CalculusHelper';
import TableHelper from '../helper/TableHelper';
import EditIcon from '@material-ui/icons/Edit';
import Colors from '../constants/Colors';
import PlanningRowHelper from '../helper/PlanningRowHelper';

export default class GeneralItemViewTable extends Component {
    render() {
        let table;

        let items = {};

        let totalizerRows = PlanningRowHelper.getPeriodRowArray(this.props.editDoc.start.toDate(), this.props.editDoc.end.toDate());
        let totalCounter = 0;

        for (let row of this.props.rows) {

            if (!items[row.relationship.id_component]) {
                const component = this.props.editDoc.components.find(item => row.relationship.id_component === item.id);
                items[row.relationship.id_component] = { id: component.id, description: component.description, rows: [] };
            }

            items[row.relationship.id_component].rows.push(row);

            const monthTotalizer = totalizerRows.find(item => item.date_label == row.date_label);
            monthTotalizer.value += row.value * row.multiplier;
        }

        let itemsArray = Object.keys(items).map(item => (items[item]));

        table = (
            <div style={styles.tableContainer}>
                <TableContainer style={{ height: 'auto', maxHeight: (window.screen.height) - 305, width: '100%' }}>
                    <Table id={'revenue-planning'} stickyHeader>
                        {TableHelper.getTableHeader(itemsArray[0].rows, true, true)}
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold', position: 'sticky', left: 0, backgroundColor: '#a4b3a7', zIndex: 4, minWidth: 180 }}>Total - Planejado (R$)</TableCell>
                                {totalizerRows.map((row) => {
                                    totalCounter += row.value;
                                    return <TableCell align="right">{CalculusHelper.formatFloat(row.value)}</TableCell>
                                })}

                                {TableHelper.getMeanCell('money', totalCounter, totalizerRows, 'value')}
                                {TableHelper.getTotalCell('money', totalCounter)}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {itemsArray.map(elem => {
                                let currentPlannedCounter = 0;

                                return (
                                    <TableRow>
                                        <TableCell align="left" style={{ fontWeight: 'bold', position: 'sticky', left: 0, backgroundColor: '#fff', zIndex: 2, minWidth: 180 }}>
                                            <IconButton onClick={() => { this.props.jumpToItem(elem) }} style={{ padding: 4, marginRight: 5 }}>
                                                <EditIcon style={{ color: Colors.primary, fontSize: 18 }} />
                                            </IconButton>

                                            {elem.description}
                                        </TableCell>
                                        {elem.rows.map((period, key) => {
                                            currentPlannedCounter += period.value * period.multiplier;
                                            return <TableCell align="right">{CalculusHelper.formatFloat(period.value * period.multiplier)}</TableCell>
                                        })}

                                        {TableHelper.getMeanCell('money', currentPlannedCounter, elem.rows, 'value')}
                                        {TableHelper.getTotalCell('money', currentPlannedCounter)}
                                    </TableRow>
                                );
                            })}

                        </TableBody>


                    </Table>
                </TableContainer>

            </div>
        );

        return table;
    }
}

const styles = {
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    }
}