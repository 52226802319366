import React from "react";
import DefaultLoader from "./DefaultLoader";
import DefaultButton from "./DefaultButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { InputLabel, TextField, TableCell, Menu, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import Colors from "../constants/Colors";
import Firestore from "../api/firebase/Firestore";
import SessionHelper from "../helper/SessionHelper";
import moment from "moment";
import BudgetStructureTable from "./BudgetStructureTable";
import PermissionHelper from "../helper/PermissionHelper";
import Functions from "../api/firebase/Functions";
import { toast } from "react-toastify";
import ActionPlanTasks from "./ActionPlanTasks";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import TextEditorTranslation from '../constants/TextEditorTranslation';
import FilePicker from "./FilePicker";
import DefaultSelect from "./DefaultSelect";
import DefaultInput from "./DefaultInput";
import TagSelect from "./TagSelect";
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import htmlToDraft from 'html-to-draftjs';
import CurrencyHelper from "../helper/CurrencyHelper";
import CurrencyInput from "./CurrencyInput";
import ActionPlanHelper from "../helper/ActionPlanHelper";
import Methods from '../constants/Methods';
import Operators from '../constants/Operators';
import AccountPlanHelper from "../helper/AccountPlanHelper";

export default class AddActionPlan extends React.Component {

    state = {
        loading: true,
        tagDocs: [],
        userDocs: [],
        resultCenterDocs: [],
        accountPackageDocs: [],
        accountPlanDocs: [],
        budgetStructureDocs: [],
        generatedReport: {},
        loadingText: '',
        loadingTable: false,
        description: '',
        tags: [],
        responsable: '',
        evaluator: '',
        participants: [],
        notifyParticipants: false,
        files: [],
        deviationAnalysis: EditorState.createEmpty(),
        deviationAnalysisHtml: '',
        tasks: [],
        budgetLinkPeriodStart: moment().startOf('day').startOf('month').subtract(1, 'month').toDate(),
        budgetLinkPeriodEnd: moment().startOf('day').startOf('month').subtract(1, 'month').toDate(),
        budgetLinkCheckPeriod: moment().startOf('day').startOf('month').subtract(1, 'month').toDate(),
        budgetLinkResultCenters: [],
        budgetLinkAccountPackages: [],
        budgetLinkAccountPlans: [],
        budgetLinkGoal: {},
        editId: this.props.editId,
        reportAccountPlans: [],
        reportBudgetStructure: [],
        menuAnchor: null,
        editMeta: false,
        editMetaData: {},
        budgetLinkGoalInput: '',
        budgetLinkGoalInputRaw: 0,
        evaluateDate: null,
        justification: '',
        certificated: false,
        certificateDeploy: null,
        certificateEfficiency: null,
        certificateRiskAndOpportunity: null,
        certificateChanges: null,
        certificateJustify: null,
        certificateDate: null,
        certificateUser: SessionHelper.getFirebaseAuth().uid,
    }

    async componentDidMount() {

        await this.getUsers();
        await this.getTags();
        await this.getResultCenters();
        await this.getAccountPlanDocs();
        await this.getAccountPackageDocs();
        await this.setBudgetLinkParams();

        if (this.state.editId) {

            await this.getDoc();
        }

        this.getPeriodData();

        this.setState({ loading: false });
    }

    async setBudgetLinkParams() {

        if (!this.state.editId && this.props.budgetLinkParams) {

            let params = this.props.budgetLinkParams;
            this.setState(params);

            if (this.props.clearBudgetLinkParams) {

                this.props.clearBudgetLinkParams();
            }
        }
    }

    async getDoc() {

        let query = await Firestore.getDoc('action_plan', this.state.editId);
        let doc = {...query.data(), id: query.id};

        if (doc) {

            let data = {
                description: doc.description,
                tags: doc.id_tags,
                responsable: doc.id_responsable,
                evaluator: doc.id_evaluator,
                participants: doc.id_participants,
                notifyParticipants: doc.notify_participants,
                files: doc.files,
                files: doc.files,
                deviationAnalysisHtml: doc.deviation_analysis,
                tasks: doc.tasks,
                budgetLinkPeriodStart: doc.budget_link_period_start.toDate(),
                budgetLinkPeriodEnd: doc.budget_link_period_end.toDate(),
                budgetLinkCheckPeriod: doc.budget_link_check_period.toDate(),
                budgetLinkResultCenters: doc.budget_link_result_center,
                budgetLinkAccountPackages: doc.budget_link_account_package,
                budgetLinkAccountPlans: doc.budget_link_account_plan,
                budgetLinkGoal: doc.budget_link_goal,
                evaluateStatus: doc.evaluate_status,
                justification: doc.justification,
                evaluateDate: doc.evaluate_date,
                certificated: doc.certificated,
                certificateDeploy: doc.deploy,
                certificateEfficiency: doc.efficiency,
                certificateRiskAndOpportunity: doc.risk_and_opportunity,
                certificateChanges: doc.changes,
                certificateJustify: doc.justify,
                certificateDate: doc.certificate_date,
                certificateUser: doc.certificate_id_user,
            };

            const blocksFromHtml = htmlToDraft(doc.deviation_analysis);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            data.deviationAnalysis = editorState;

            await this.setState(data);
        }
    }

    async getResultCenters() {

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid);
        this.setState({ resultCenterDocs: docs });
    }

    async getAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs });
    }

    async getAccountPackageDocs() {

        let query = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPackageDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getTags() {

        let query = await Firestore.customQuery('tag').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ tagDocs: docs });
    }

    async getPeriodData() {

        if (this.state.budgetLinkPeriodStart && this.state.budgetLinkPeriodEnd) {

            try {

                this.setState({ loadingTable: true, reportAccountPlans: [], reportBudgetStructure: [] });

                await this.getAccountPlanDocs();

                let months = [];
                let parsedMonths = [];
                let accounts = this.state.accountPlanDocs;
                let budgetStructure = this.state.budgetStructureDocs;
                let filterResultCenter = [...this.state.budgetLinkResultCenters];
                let filterAccountPlan = [...this.state.budgetLinkAccountPlans];
                let filterAccountPackage = [...this.state.budgetLinkAccountPackages];
                let columns = ['budget', 'accomplished'];

                if (filterResultCenter.length === this.state.resultCenterDocs.length) {

                    filterResultCenter = [];
                }

                let momentStart = moment(this.state.budgetLinkPeriodStart);
                let momentEnd = moment(this.state.budgetLinkPeriodEnd);

                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

                    parsedMonths.push(momentStart.toJSON());
                    months.push(momentStart.toDate());
                    momentStart.add(1, 'month');
                }

                let body = { open: true, id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, selected_account_packages: filterAccountPackage, selected_account_plans: filterAccountPlan, id_result_centers: filterResultCenter, months: parsedMonths, columns: columns };
                let request = await Functions.request('POST', 'getBudgetStructure', body);
                
                if (request && request.accounts && request.budgetStructure) {
                    
                    accounts = request.accounts;
                    budgetStructure = request.budgetStructure;

                } else {

                    throw new Error('Request Error');
                }

                let generatedReport = {
                    report: months.length > 1 ? 'accumulated' :  'monthly',
                    view: months.length > 1 ? 'monthly_and_accumulated' : 'monthly',
                    columns: columns,
                    months: months,
                    verticalAnalysisAccountPlan: null,
                    filterResultCenter: [],
                };

                this.setState({ reportAccountPlans: accounts, reportBudgetStructure: budgetStructure, renderTable: true, loadingTable: false, generatedReport: generatedReport, showOptions: false });

            } catch (error) {

                toast.error('Houve um problema ao buscar os dados do período');
            }
        }
    }

    async addActionPlan() {

        if (this.state.description && this.state.responsable) {

            try {

                this.setState({ loading: true });

                let id = this.state.editId ? this.state.editId : await Firestore.getId('action_plan');

                let data = {
                    description: this.state.description,
                    id_tags: this.state.tags,
                    id_responsable: this.state.responsable,
                    id_evaluator: this.state.evaluator,
                    id_participants: this.state.participants,
                    notify_participants: this.state.notifyParticipants === true ? true : false,
                    files: this.state.files,
                    deviation_analysis: this.state.deviationAnalysisHtml,
                    tasks: this.state.tasks,
                    budget_link_period_start: this.state.budgetLinkPeriodStart,
                    budget_link_period_end: this.state.budgetLinkPeriodEnd,
                    budget_link_check_period: this.state.budgetLinkCheckPeriod,
                    budget_link_result_center: this.state.budgetLinkResultCenters,
                    budget_link_account_plan: this.state.budgetLinkAccountPlans,
                    budget_link_account_package: this.state.budgetLinkAccountPackages,
                    budget_link_goal: this.state.budgetLinkGoal,
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                }

                if (this.state.editId) {

                    delete data.date;
                    await Firestore.update(data, 'action_plan', id);

                } else {

                    data.evaluate_status = this.state.evaluateDate;
                    data.justification = this.state.justification;
                    data.evaluate_date = this.state.evaluateDate;
                    data.certificated = this.state.certificated;
                    data.certificate_deploy = this.state.certificateDeploy;
                    data.certificate_efficiency = this.state.certificateEfficiency;
                    data.certificate_risk_and_opportunity = this.state.certificateRiskAndOpportunity;
                    data.certificate_changes = this.state.certificateChanges;
                    data.certificate_justify = this.state.certificateJustify;
                    data.certificate_date = this.state.certificateDate;
                    data.certificate_id_user = this.state.certificateUser;

                    await Firestore.insert(data, 'action_plan', id);

                    if (data.notify_participants) {
                        
                        await ActionPlanHelper.sendEmailParticipants(data.id_responsable, data.id_participants, id);
                    }

                    if (data.id_evaluator) {

                        await ActionPlanHelper.sendEmailAvaliador(data.id_evaluator, data.id_responsable, id);
                        await Firestore.update({ evaluate_status: 'not_answered' }, 'action_plan', id);
                        
                    } else {

                        await ActionPlanHelper.sendMailEtapas(id, data.id_user);
                    }
    
                    if (SessionHelper.getFirebaseAuth().uid != data.id_responsable) {

                        await ActionPlanHelper.sendMailResponsavel(data.id_responsable, data.id_user, id);
                    }
                }

                await this.props.onAdd(id);

            } catch (error) {

                toast.error(`Houve um problema ao ${this.state.editId ? 'editar' : 'cadastrar'} o plano de ação`);
                this.setState({ loading: false });
            }

        } else {

            toast.warn('Preencha todos os campos obrigatórios');
        }
    }

    filterResponsable(value, index) {

        if ([...this.state.participants, this.state.evaluator].includes(value.id)) {

            return false;
        }

        return true;
    }

    filterEvaluator(value, index) {

        if ([...this.state.participants, this.state.responsable].includes(value.id)) {

            return false;
        }

        return true;
    }

    filterParticipants(value, index) {

        if ([this.state.evaluator, this.state.responsable].includes(value.id)) {

            return false;
        }

        return true;
    }

    getAdditionalRows() {

        return [
            {
                title: 'Média Orçado',
                render: (account) => {

                    let value = 0;

                    for (let monthIndex = 0; monthIndex < account.revenue.length; monthIndex++) {
                        
                        value += (account.revenue[monthIndex].value - account.deduction[monthIndex].value);
                    }

                    value = value / account.revenue.length;

                    return <TableCell style={{ backgroundColor: 'white', textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
                showHeader: false,
            },
            {
                title: 'Média Realizado',
                render: (account) => {

                    let value = 0;

                    for (let monthIndex = 0; monthIndex < account.revenue.length; monthIndex++) {
                        
                        value += account.accomplished[monthIndex].value;
                    }

                    value = value / account.accomplished.length;

                    return <TableCell style={{ backgroundColor: 'white', textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
                showHeader: false,
            },
            {
                title: 'Meta de Variação (%)',
                render: (account) => {

                    let value = this.state.budgetLinkGoal[account.id] && this.state.budgetLinkGoal[account.id].goal ? this.state.budgetLinkGoal[account.id].goal : 0;
                    let color = Colors.report.default;
                    
                    if ((value >= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.sum.key) || (value <= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.minus.key)) {
    
                        color = Colors.report.green;
    
                    } else {
        
                        color = Colors.report.red;   
                    }

                    return <TableCell onClick={(evt) => this.setState({ budgetLinkGoalInput: value, budgetLinkGoalInputRaw: value, editMeta: true, menuAnchor: evt.target, editMetaData: { title: 'Meta de Variação (%)', account: account, type: 'percentage', operator: AccountPlanHelper.getAccountOperator(account) } })} style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold', cursor: 'pointer' }}>{value.toFixed(2).toString().replace('.', ',')}%</TableCell>
                },
            },
            {
                title: 'Novo Valor Projetado',
                render: (account) => {

                    let accomplished = 0;
                    let value = this.state.budgetLinkGoal[account.id] && this.state.budgetLinkGoal[account.id].value_projection ? this.state.budgetLinkGoal[account.id].value_projection : 0;
                    let color = Colors.report.default;

                    if ((value >= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.sum.key) || (value <= 0 && AccountPlanHelper.getAccountOperator(account) === Operators.minus.key)) {
    
                        color = Colors.report.green;
    
                    } else {
        
                        color = Colors.report.red;   
                    }

                    if (value === 0) {

                        for (let monthIndex = 0; monthIndex < account.revenue.length; monthIndex++) {
                            
                            accomplished += account.accomplished[monthIndex].value;
                        }
                    }

                    accomplished = accomplished / account.accomplished.length;

                    return <TableCell onClick={(evt) => this.setState({ budgetLinkGoalInput: value === 0 ? accomplished : value, budgetLinkGoalInputRaw: value === 0 ? accomplished : value, editMeta: true, menuAnchor: evt.target, editMetaData: { title: 'Novo Valor Projetado', account: account, type: 'money', operator: AccountPlanHelper.getAccountOperator(account) } })} style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold', cursor: 'pointer' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
            },
        ]
    }

    renderForm() {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
                    <div style={{ width: '50%' }}><DefaultInput value={this.state.description} required={true} label={'Descrição'} onChange={(v) => { this.setState({ description: v }) }} /></div>
                    <div style={{ width: '50%' }}><TagSelect value={this.state.tags} onChange={(v) => { this.setState({ tags: v }) }} docs={this.state.tagDocs} onAddTag={() => { this.getTags() }}/></div>
                </div>
                <div style={{ marginTop: 10 }}/>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 }}>
                    <DefaultSelect
                        required={true}
                        search={true}
                        searchField={'name'}
                        id={'responsable_select'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.responsable}
                        onChange={(v) => {
                            this.setState({ responsable: v.target.value })
                        }}
                        docs={this.state.userDocs.filter((value, index) => this.filterResponsable(value, index))}
                        label={'Responsável *'}
                    />
                    <DefaultSelect
                        search={true}
                        searchField={'name'}
                        id={'evaluator_select'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.evaluator}
                        onChange={(v) => {
                            this.setState({ evaluator: v.target.value })
                        }}
                        docs={this.state.userDocs.filter((value, index) => this.filterEvaluator(value, index))}
                        label={'Superior / Avaliador'}
                    />
                </div>
                <div style={{ marginTop: 20 }}/>
                <DefaultSelect
                    search={true}
                    multiple={true}
                    searchField={'name'}
                    id={'participants_select'}
                    valueField={'id'}
                    displayField={'name'}
                    value={this.state.participants}
                    onChange={(v) => {
                        this.setState({ participants: v.target.value })
                    }}
                    docs={this.state.userDocs.filter((value, index) => this.filterParticipants(value, index))}
                    label={'Participantes'}
                />
                {!this.state.editId ?
                
                    <FormControlLabel
                        control={<Checkbox style={{ color: Colors.primary }} checked={this.state.notifyParticipants} onChange={(v) => { this.setState({ notifyParticipants: v.target.checked }) }} />}
                        label={'Enviar email de notificação para os participantes.'}
                    />
                
                : null}
                {this.renderBudgetLink()}
                <InputLabel style={{ marginTop: 20, marginBottom: 20 }}>{'Análise de Desvio'}</InputLabel>
                <Editor editorState={this.state.deviationAnalysis} localization={{ translations: TextEditorTranslation }} editorStyle={{ minHeight: 300, border: '1px solid #F1F1F1' }} onEditorStateChange={(editor) => this.setState({ deviationAnalysis: editor, deviationAnalysisHtml: draftToHtml(convertToRaw(editor.getCurrentContent())) })} />
                <div style={{ marginTop: 20 }}/>
                <FilePicker onUpload={(files) => { this.setState({ files }) }} onUploadStart={() => { this.setState({ loadingModal: true }) }} onUploadEnd={() => { this.setState({ loadingModal: false }) }} files={this.state.files} multiple={true} label={'Anexos'} iconHeight={200} iconWidth={200}/>
                <div style={{ marginTop: 20 }}/>
                <InputLabel>{'Etapas'}</InputLabel>
                <ActionPlanTasks mode={'add'} idActionPlan={this.state.editId} tasks={this.state.tasks} evaluator={this.state.evaluator} onAddTask={(tasks) => { this.setState({ tasks }) }}/>
                <div style={{ marginTop: 50 }}/>
                <DefaultButton width={200} leftIcon={<CheckIcon/>} onClick={() => { this.addActionPlan() }} color={Colors.primary} loading={this.state.loading} title={'SALVAR'}/>
            </div>
        )
    }

    renderBudgetLink() {

        return (

            <div>
                <InputLabel style={{ marginTop: 20, marginBottom: 20 }}>{'Vinculo com Orçado X Realizado'}</InputLabel>
                <div style={{ display: 'flex', flexDirection: 'column', boxShadow: Colors.boxShadow, border: '0.3px solid lightgrey', padding: 20, borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80%', gap: 20, paddingRight: 20 }}>
                            <DefaultSelect
                                search={true}
                                searchField={['description', 'code']}
                                displayField={'description'}
                                secondaryDisplay={'code'}
                                valueField={'id'}
                                multiple={true}
                                value={this.state.budgetLinkResultCenters}
                                onChange={(v) => {
                                    this.setState({ budgetLinkResultCenters: v.target.value });
                                    this.getPeriodData();
                                }}
                                docs={this.state.resultCenterDocs}
                                label={'Centros de Resultado'}
                            />
                            <DefaultSelect
                                search={true}
                                searchField={['description']}
                                displayField={'description'}
                                valueField={'id'}
                                multiple={true}
                                value={this.state.budgetLinkAccountPackages}
                                onChange={(v) => {
                                    this.setState({ budgetLinkAccountPackages: v.target.value, budgetLinkAccountPlans: [] });
                                    this.getPeriodData();
                                }}
                                docs={this.state.accountPackageDocs}
                                label={'Pacotes'}
                            />
                            <DefaultSelect
                                search={true}
                                searchField={['description', 'code']}
                                displayField={'description'}
                                secondaryDisplay={'code'}
                                valueField={'id'}
                                multiple={true}
                                value={this.state.budgetLinkAccountPlans}
                                onChange={(v) => {
                                    this.setState({ budgetLinkAccountPlans: v.target.value, budgetLinkAccountPackages: [] });
                                    this.getPeriodData();
                                }}
                                docs={this.state.accountPlanDocs}
                                label={'Contas'}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '20%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        style={{ width: '100%', marginBottom: 20 }}
                                        invalidDateMessage={false}
                                        format={'MM/yyyy'}
                                        autoOk={true}
                                        label="De"
                                        views={["year", "month"]}
                                        openTo={'month'}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={ async (v) => {
                                            await this.setState({ budgetLinkPeriodStart: v });
                                            await this.getPeriodData();
                                        }}
                                        value={this.state.budgetLinkPeriodStart}
                                    />
                                    <KeyboardDatePicker
                                        style={{ width: '100%', marginBottom: 20 }}
                                        invalidDateMessage={false}
                                        format={'MM/yyyy'}
                                        autoOk={true}
                                        label="Até"
                                        views={["year", "month"]}
                                        openTo={'month'}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={ async (v) => {
                                            await this.setState({ budgetLinkPeriodEnd: v });
                                            await this.getPeriodData();
                                        }}
                                        value={this.state.budgetLinkPeriodEnd}
                                    />
                                    <KeyboardDatePicker
                                        style={{ width: '100%', marginBottom: 10 }}
                                        invalidDateMessage={false}
                                        format={'MM/yyyy'}
                                        autoOk={true}
                                        label="Período de Verificação"
                                        views={["year", "month"]}
                                        openTo={'month'}
                                        cancelLabel={'Cancelar'}
                                        okLabel={'Confirmar'}
                                        onChange={ async (v) => {
                                            await this.setState({ budgetLinkCheckPeriod: v });
                                        }}
                                        value={this.state.budgetLinkCheckPeriod}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>
                    <BudgetStructureTable periodRowColSpan={4} hideStructure={this.state.budgetLinkAccountPlans.length > 0 ? true : false} additionalRows={this.getAdditionalRows()} loadingTable={this.state.loadingTable} accountPlanDocs={this.state.reportAccountPlans} verticalAnalysisAccountPlan={null} variationFilter={[0, 0]} generatedReport={this.state.generatedReport} budgetStructureDocs={this.state.reportBudgetStructure} />
                </div>
            </div>
        )
    }

    renderEditMeta() {

        if (this.state.editMeta) {

            return (

                <Menu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    anchorEl={this.state.menuAnchor}
                    id={'edit-meta-menu'}
                    open={this.state.editMeta}
                    MenuListProps={{ style: { padding: 0 } }}
                    style={{ marginTop: 300, height: 'auto', boxShadow: Colors.boxShadow }}
                    onClose={() => { this.setState({ editMeta: false }) }} >
    
                        <div style={{ width: 300, padding: 0, margin: 0 }}>
                            <div style={{ borderBottom: '0.5px solid lightgrey', padding: 10, backgroundColor: '#f3f3f3f3' }}><b>{this.state.editMetaData.title}</b></div>
                            <div style={{ padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {
                                        Object.keys(Operators).map((operator, key) => {
                                            
                                            let selected = this.state.editMetaData.operator === Operators[operator].key;

                                            return (

                                                <Tooltip title={`Sinal ${Operators[operator].label}`}>
                                                        <div onClick={() => this.setState({ editMetaData: { ...this.state.editMetaData, operator: Operators[operator].key } })} style={{ cursor: 'pointer', borderTopRightRadius: key === Object.keys(Operators).length - 1 ? 5 : 0, borderBottomRightRadius: key === Object.keys(Operators).length - 1 ? 5 : 0, borderTopLeftRadius: key === 0 ? 5 : 0, borderBottomLeftRadius: key === 0 ? 5 : 0, width: 35, height: 35, fontSize: 20, fontWeight: 'bold', backgroundColor: selected ? Colors.success : 'lightgrey', color: selected ? 'white' : '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{Operators[operator].operation}</div>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </div>
                                <div style={{ width: '70%' }}>
                                    {
                                        this.state.editMetaData.type === 'percentage' ?

                                            <NumberFormat allowNegative={false} style={{ color: Colors.primary}} value={this.state.budgetLinkGoalInput} required={true} fullWidth={true} label={'Meta'} customInput={TextField} suffix={'%'} decimalScale={2} fixedDecimalScale={true} decimalSeparator={'.'} onValueChange={(e) => {

                                                if (e.floatValue) {

                                                    this.setState({ budgetLinkGoalInput: e.floatValue.toString(), budgetLinkGoalInputRaw: e.floatValue });
                                                }
                                            }}/>

                                        :
                                            <CurrencyInput allowNegative={false} noPadding={true} label={'Valor'} onChange={(values) => {

                                                const { formattedValue, value } = values;
                                                this.setState({ budgetLinkGoalInput: formattedValue, budgetLinkGoalInputRaw: value });

                                            }} value={this.state.budgetLinkGoalInput} />

                                    }
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, gap: 5, padding: 10 }}>
                                <DefaultButton leftIcon={<CheckIcon/>} onClick={() => {

                                        let budgetLinkGoal = this.state.budgetLinkGoal;
                                        let account = this.state.editMetaData.account;

                                        if (!budgetLinkGoal[account.id]) {

                                            budgetLinkGoal[account.id] = { goal: 0, value_projection: 0 };
                                        }

                                        if (this.state.editMetaData.type === 'percentage') {

                                            let accomplished = 0;
                                            let valueProjection = 0;
                                            
                                            budgetLinkGoal[account.id].goal = Math.abs(this.state.budgetLinkGoalInputRaw);

                                            for (let monthIndex = 0; monthIndex < account.accomplished.length; monthIndex++) {
                                                
                                                accomplished += account.accomplished[monthIndex].value;
                                            }

                                            accomplished = accomplished / account.accomplished.length;
                                            valueProjection = accomplished;

                                            if (this.state.editMetaData.operator === Operators.sum.key) {

                                                valueProjection += Methods.percentage.calculate(accomplished, budgetLinkGoal[account.id].goal);

                                            } else {

                                                valueProjection -= Methods.percentage.calculate(accomplished, budgetLinkGoal[account.id].goal);
                                            }

                                            budgetLinkGoal[account.id].goal = Math.abs(this.state.budgetLinkGoalInputRaw) * (this.state.editMetaData.operator === Operators.sum.key ? 1 : -1);
                                            budgetLinkGoal[account.id].value_projection = valueProjection;
                                        
                                        } else {

                                            let accomplished = 0;
                                            let goal = 0;

                                            budgetLinkGoal[account.id].value_projection = Math.abs(this.state.budgetLinkGoalInputRaw) * (this.state.editMetaData.operator === Operators.sum.key ? 1 : -1);

                                            for (let monthIndex = 0; monthIndex < account.accomplished.length; monthIndex++) {
                                                
                                                accomplished += account.accomplished[monthIndex].value;
                                            }

                                            accomplished = accomplished / account.accomplished.length;

                                            goal = Math.abs(budgetLinkGoal[account.id].value_projection) > 0 ? (((budgetLinkGoal[account.id].value_projection - accomplished) / accomplished) * 100) : 0;
                                            budgetLinkGoal[account.id].goal = goal;
                                        }

                                        this.setState({ budgetLinkGoal, budgetLinkGoalInput: '', budgetLinkGoalInputRaw: 0, editMeta: false });

                                }} color={Colors.success} loading={this.state.loading} title={''}/>
                                <DefaultButton leftIcon={<CancelIcon/>} onClick={() => { this.setState({ editMeta: false }) }} color={Colors.error} loading={this.state.loading} title={''}/>
                            </div>
                        </div>
                </Menu>
            )
        }
    }

    render() {

        if (this.state.loading) {

            return <div><DefaultLoader loadingText={this.state.loadingText} /></div>;
        }

        return (

            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<ArrowBackIcon />} onClick={() => { this.props.onBack() }} title={'Voltar'} />
                    </div>
                    <div style={{ fontWeight: 'bold', fontSize: 24 }}>{`${this.state.editId ? 'Editar' : 'Cadastrar'} Plano de Ação`}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5, marginTop: 10 }}>
                    {this.renderForm()}
                </div>
                {this.renderEditMeta()}
            </div>
        )
    }
}

const styles = {
    container: {
        padding: 25,
    }
}