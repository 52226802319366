import { Table, TableCell, TableHead, TableRow, Tooltip, TableBody } from "@material-ui/core";
import React from "react";
import ChartHelper from "../helper/ChartHelper";
import Colors from "../constants/Colors";
import moment from "moment";
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CurrencyHelper from "../helper/CurrencyHelper";
import ClipLoader from "react-spinners/ClipLoader";

export default class BudgetStructureTable extends React.Component {

    state = {
        openAll: this.props.openAll,
    }

    openAccount(hash, docs = this.props.budgetStructureDocs, recursive = false, open) {

        if (hash) {

            for (let index = 0; index < docs.length; index++) {

                if (docs[index].hash === hash) {
                    
                    docs[index].open = !open;  
                    break;

                } else {

                    if (docs[index].accounts && docs[index].accounts.length) {

                        docs[index].accounts = this.openAccount(hash, docs[index].accounts, true, open);
                    }
                }
            }

            if (recursive) {

                return docs;

            } else {

                this.setState({ budgetStructureDocs: docs });
            }
        }
    }

    childrenAccountHasValue(accounts) {

        let value = false;

        accounts.forEach((account, key) => {

            if (account.hasValue) {

                value = true;
                return;
            }
        });

        return value;
    }

    renderAccountChildren(childrens, structureIndex) {

        if (childrens && childrens.length) {

            return childrens.map((account, accountKey) => {

                let canShowRow = this.canShowRow(account);
                let isStructure = account.isStructure;
                let render = [];

                if (account.hasValue && canShowRow) {
                    
                    render.push(
        
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}>
        
                                { (account.children && account.children.length && this.childrenAccountHasValue(account.accounts)) || (isStructure && account.accounts && account.accounts.length && this.childrenAccountHasValue(account.accounts)) ?
        
                                    account.open ? <IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.props.budgetStructureDocs, false, account.open) }}/> : <AddBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.props.budgetStructureDocs, false, account.open) }}/>
        
                                : null }
        
                            </TableCell>
                            <TableCell style={{ backgroundColor: 'white' }}>{account.code}</TableCell>
                            <TableCell style={{ backgroundColor: 'white', position: 'sticky', left: 38, zIndex: 1 }}><Tooltip title={account.description}><div>{account.description.length < 25 ? account.description : account.description.slice(0, 25) + '...'}</div></Tooltip></TableCell>
                            {
                                this.props.generatedReport.report === 'monthly' || (this.props.generatedReport.report === 'accumulated' && this.props.generatedReport.view === 'monthly_and_accumulated') ?
        
                                    this.props.generatedReport['months'].map((month, monthIndex) => {
        
                                        return this.props.generatedReport['columns'].map((column, key) => {
        
                                            let value = this.getColumnValue(account, column, monthIndex);
                                            let color = this.getColumnColor(account, column, monthIndex);
        
                                            return <TableCell style={{ textAlign: 'center', backgroundColor: color.background, color: color.text }}>{value}</TableCell>
                                        })
                                    })
        
                                : null
                            }
                            {
                                this.props.generatedReport.report === 'accumulated' ? 
                                
                                    this.props.generatedReport['columns'].map((column, key) => {
        
                                        let value = this.getAllPeriodColumnValue(account, column);
                                        let color = this.getAllPeriodColumnColor(account, column);
        
                                        return <TableCell style={{ textAlign: 'center', backgroundColor: color.background, color: color.text }}>{value}</TableCell>
                                    })
        
                                : null 
                            }
                            {
                                this.props.additionalRows && this.props.additionalRows.length ?

                                    this.props.additionalRows.map((column, key) => {

                                        return column.render(account);
                                    })

                                : null
                            }
        
                        </TableRow>
                    );
                }

                if (account.open) {
    
                    let childrens = account.accounts;
                    render.push(this.renderAccountChildren(childrens, structureIndex))
                }
    
                return render;
            })
        }
    }

    filterColumn(id, month, plannedAndHistory, accomplished) {

        let canShow = true;

        if (this.props.variationFilter[0] !== 0 || this.props.variationFilter[1]) {

            let variation = Math.abs(plannedAndHistory.planned) > 0 ? (((accomplished.value - plannedAndHistory.planned) / plannedAndHistory.planned) * 100) : 0;

            if (variation >= this.props.variationFilter[0] && variation <= this.props.variationFilter[1]) {

                canShow = false;
            }
        }

        return canShow;
    }
    
    getAllPeriodColumnValue(account, column, raw = false) {

        let canShow = true;
        let value = 0;
        let accomplished = { value: account.accomplished.reduce((a, b) => +a + +b.value, 0), history: account.accomplished.reduce((a, b) => +a + +b.value_history, 0) };
        let verticalAccomplished = 0;
        let plannedAndHistory = { planned: (account.revenue.reduce((a, b) => +a + +b.value, 0) - account.deduction.reduce((a, b) => +a + +b.value, 0)), history: (account.revenue.reduce((a, b) => +a + +b.value_history, 0) - account.deduction.reduce((a, b) => +a + +b.value_history, 0))};

        if (account.isPercentage) {

            plannedAndHistory = { planned: (account.percentage.reduce((a, b) => +a + +b.value, 0)), history: (account.percentage.reduce((a, b) => +a + +b.value_history, 0))};
        }

        if (this.props.verticalAnalysisAccountPlan) {

            let doc = this.props.accountPlanDocs.find(item => item.id === this.props.verticalAnalysisAccountPlan);

            if (!doc) {

                doc = this.props.budgetStructureDocs.find(item => item.id === this.props.verticalAnalysisAccountPlan);
            }

            verticalAccomplished = { value: doc.accomplished.reduce((a, b) => +a + +b.value, 0), value_history: doc.accomplished.reduce((a, b) => +a + +b.value_history, 0) };
        }

        canShow = this.filterColumn(account.id, 'all', plannedAndHistory, accomplished);

        if (column === 'budget') {

            value = plannedAndHistory.planned;

        } else if (column === 'accomplished') {

            value = accomplished.value;

        } else if (column === 'history') {

            value = accomplished.history;

        } else if (column === 'deviation_money') {

            value = accomplished.value - plannedAndHistory.planned;

        } else if (column === 'deviation_percentage') {

            value = Math.abs(plannedAndHistory.planned) > 0 ? (((accomplished.value - plannedAndHistory.planned) / plannedAndHistory.planned) * 100) : 0;

        } else if (column === 'variation_money') {

            value = accomplished.value - accomplished.history;

        } else if (column === 'variation_percentage') {

            value =  Math.abs(accomplished.history) > 0 ? (((accomplished - accomplished.history) / accomplished.history) * 100) : 0;

        }  else if (column === 'vertical_analysis') {

            value = ((accomplished.value / verticalAccomplished.value) * 100) || 0;

            if (!Number.isFinite(value)) { value = 0 }
        }

        value = value.toFixed(2);
        

        if (column === 'deviation_percentage' || column === 'variation_percentage' || column === 'vertical_analysis' || account.isPercentage) {
            
            value = `${value.toString().replace('.', ',')}%`;

        } else {

            if (!raw) {

                value = CurrencyHelper.formatMoney(value, false);
            }
        }

        return canShow ? value : '';
    }

    getColumnValue(account, column, monthIndex, raw = false) {

        let canShow = true;
        let value = 0;
        let verticalAccomplished = 0;
        let accomplished = { value: account.accomplished[monthIndex].value, history: account.accomplished[monthIndex].value_history};
        let plannedAndHistory = { planned: (account.revenue[monthIndex].value - account.deduction[monthIndex].value), history: (account.revenue[monthIndex].value_history - account.deduction[monthIndex].value_history)};

        if (account.isPercentage) {

            plannedAndHistory = { planned: account.percentage[monthIndex].value, history: account.percentage[monthIndex].value_history}
        }

        if (this.props.verticalAnalysisAccountPlan) {

            let doc = this.props.accountPlanDocs.find(item => item.id === this.props.verticalAnalysisAccountPlan);

            if (!doc) {

                doc = this.props.budgetStructureDocs.find(item => item.id === this.props.verticalAnalysisAccountPlan);
            }

            verticalAccomplished = { value: doc.accomplished[monthIndex].value, value_history: doc.accomplished[monthIndex].value_history};
        }

        canShow = this.filterColumn(account.id, monthIndex, plannedAndHistory, accomplished);

        if (column === 'budget') {

            value = plannedAndHistory.planned;

        } else if (column === 'accomplished') {

            value = accomplished.value;

        } else if (column === 'history') {

            value = accomplished.history;

        } else if (column === 'deviation_money') {

            value = accomplished.value - plannedAndHistory.planned;

        } else if (column === 'deviation_percentage') {

            value =  Math.abs(plannedAndHistory.planned) > 0 ? (((accomplished.value - plannedAndHistory.planned) / plannedAndHistory.planned) * 100) : 0;

        } else if (column === 'variation_money') {

            value = accomplished.value - accomplished.history;

        } else if (column === 'variation_percentage') {

            value =  Math.abs(accomplished.history) > 0 ? (((accomplished.value - accomplished.history) / accomplished.history) * 100) : 0;

        }  else if (column === 'vertical_analysis') {

            value = ((accomplished.value / verticalAccomplished.value) * 100) || 0;
        }

        value = value.toFixed(2);

        if (column === 'deviation_percentage' || column === 'variation_percentage' || column === 'vertical_analysis' || account.isPercentage) {

            value = `${value.toString().replace('.', ',')}%`;

        } else {

            if (!raw) {

                value = CurrencyHelper.formatMoney(value, false);
            }
        }

        return canShow ? value : '';
    }

    getAllPeriodColumnColor(account, column, monthIndex) {

        let canShow = true;
        let color = Colors.report.default;

        if (column === 'deviation_money' || column === 'deviation_percentage' || column === 'variation_money' || column === 'variation_percentage') {

            let value = 0;
            let accomplished = { value: account.accomplished.reduce((a, b) => +a + +b.value, 0), history: account.accomplished.reduce((a, b) => +a + +b.value_history, 0) };
            let plannedAndHistory = { planned: (account.revenue.reduce((a, b) => +a + +b.value, 0) - account.deduction.reduce((a, b) => +a + +b.value, 0)), history: (account.revenue.reduce((a, b) => +a + +b.value_history, 0) - account.deduction.reduce((a, b) => +a + +b.value_history, 0))};
    
            if (account.isPercentage) {
    
                plannedAndHistory = { planned: (account.percentage.reduce((a, b) => +a + +b.value, 0)), history: (account.percentage.reduce((a, b) => +a + +b.value_history, 0))};
            }

            canShow = this.filterColumn(account.id, 'all', plannedAndHistory, accomplished);
    
            if (column === 'deviation_money' || column === 'deviation_percentage') {
    
                value = accomplished.value - plannedAndHistory.planned;
    
                if (value >= 0) {
    
                    color = Colors.report.green;

                } else {
    
                    color = Colors.report.red;   
                }

            } else if (column === 'variation_money' || column === 'variation_percentage') {
    
                value = accomplished.value - accomplished.history;

                if (value >= 0) {
    
                    color = Colors.report.green;

                } else {
    
                    color = Colors.report.red;   
                }
            }
    
            value = value.toFixed(2);
        }

        return canShow ? color : Colors.report.default;
    }

    getColumnColor(account, column, monthIndex) {

        let canShow = true;
        let color = Colors.report.default;

        if (column === 'deviation_money' || column === 'deviation_percentage' || column === 'variation_money' || column === 'variation_percentage') {

            let value = 0;
            let accomplished = { value: account.accomplished[monthIndex].value, history: account.accomplished[monthIndex].value_history};
            let plannedAndHistory = { planned: (account.revenue[monthIndex].value - account.deduction[monthIndex].value), history: (account.revenue[monthIndex].value_history - account.deduction[monthIndex].value_history)};

            canShow = this.filterColumn(account.id, monthIndex, plannedAndHistory, accomplished);

            if (account.isPercentage) {
    
                plannedAndHistory = { planned: account.percentage[monthIndex].value, history: account.percentage[monthIndex].value_history}
            }
    
            if (column === 'deviation_money' || column === 'deviation_percentage') {
    
                value = accomplished.value - plannedAndHistory.planned;
    
                if (value >= 0) {
    
                    color = Colors.report.green;

                } else {
    
                    color = Colors.report.red;   
                }

            } else if (column === 'variation_money' || column === 'variation_percentage') {
    
                value = accomplished.value - accomplished.history;

                if (value >= 0) {
    
                    color = Colors.report.green;

                } else {
    
                    color = Colors.report.red;   
                }
            }
    
            value = value.toFixed(2);
        }

        return canShow ? color : Colors.report.default;
    }

    renderPeriodCell() {

        let date = moment(this.props.generatedReport['months'][0]);
        let monthName = date.format('MMM');

        let dateEnd = moment(this.props.generatedReport['months'][this.props.generatedReport['months'].length - 1]);
        let monthNameEnd = dateEnd.format('MMM');

        return <TableCell colSpan={this.props.periodRowColSpan ? this.props.periodRowColSpan : this.props.generatedReport['columns'].length} style={{ fontWeight: 'bold', fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, zIndex: 1, textAlign: 'center', width: 'auto', whiteSpace: 'nowrap' }}>{`${monthName.charAt(0).toUpperCase() + monthName.substr(1)}/${date.format('YYYY')} até ${monthNameEnd.charAt(0).toUpperCase() + monthNameEnd.substr(1)}/${dateEnd.format('YYYY')}`}</TableCell>
    }

    openAllAccounts(flag, docs = this.props.budgetStructureDocs, recursive = false) {

        for (let index = 0; index < docs.length; index++) {

            docs[index].open = flag;

            if (docs[index].accounts && docs[index].accounts.length) {

                docs[index].accounts = this.openAllAccounts(flag, docs[index].accounts, true);
            }
        }

        if (!recursive) {

            this.setState({ budgetStructureDocs: docs, openAll: flag });

        } else {

            return docs;
        }
    }

    canShowRow(account) {

        let canShow = true;
        let hideCount = 0;

        if (this.props.variationFilter[0] !== 0 || this.props.variationFilter[1] !== 0) {

            for (let monthIndex = 0; monthIndex < this.props.generatedReport['months'].length; monthIndex++) {
                
                let accomplished = { value: account.accomplished[monthIndex].value, history: account.accomplished[monthIndex].value_history};
                let plannedAndHistory = { planned: (account.revenue[monthIndex].value - account.deduction[monthIndex].value), history: (account.revenue[monthIndex].value_history - account.deduction[monthIndex].value_history)};
                let variation = Math.abs(plannedAndHistory.planned) > 0 ? (((accomplished.value - plannedAndHistory.planned) / plannedAndHistory.planned) * 100) : 0;

                if (variation >= this.props.variationFilter[0] && variation <= this.props.variationFilter[1]) {

                    hideCount++;
                }
            }

            if (hideCount === this.props.generatedReport['months'].length) {

                canShow = false;
            }
        }

        return canShow;
    }

    renderBudgetStructure() {

        return this.props.budgetStructureDocs.map((doc, key) => {

            let canShowRow = this.canShowRow(doc);

            if (canShowRow) {

                return (
                    <TableBody>
                        {
                            this.props.hideStructure !== true ?
    
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}>
                                        {doc.accounts && doc.accounts.length && doc.hasValue ?
    
                                            doc.open ? <IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(doc.hash, this.props.budgetStructureDocs, false, doc.open) }}/> : <AddBoxIcon onClick={() => { this.openAccount(doc.hash, this.props.budgetStructureDocs, false, doc.open) }} style={{ color: 'grey', cursor: 'pointer' }}/>
    
                                        : null}
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}>{'-'}</TableCell>
                                    <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold', position: 'sticky', left: 38, zIndex: 1 }}><Tooltip title={doc.name}><div>{doc.name.length < 25 ? doc.name : doc.name.slice(0, 25) + '...'}</div></Tooltip></TableCell>
                                    {
                                        this.props.generatedReport.report === 'monthly' || (this.props.generatedReport.report === 'accumulated' && this.props.generatedReport.view === 'monthly_and_accumulated') ?
    
                                            this.props.generatedReport['months'].map((month, monthIndex) => {
    
                                                return this.props.generatedReport['columns'].map((column, key) => {
    
                                                    let value = this.getColumnValue(doc, column, monthIndex);
                                                    let color = this.getColumnColor(doc, column, monthIndex);
    
                                                    return <TableCell style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold' }}>{value}</TableCell>
                                                }) 
                                            })
    
                                        : null
                                    }
                                    {
                                        this.props.generatedReport.report === 'accumulated' ? 
                                        
                                        this.props.generatedReport['columns'].map((column, key) => {
    
                                            let value = this.getAllPeriodColumnValue(doc, column);
                                            let color = this.getAllPeriodColumnColor(doc, column);
    
                                            return <TableCell style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold' }}>{value}</TableCell>
                                        })
    
                                        : null 
                                    }
                                    {
                                        this.props.additionalRows && this.props.additionalRows.length ?
    
                                            this.props.additionalRows.map((column, key) => {
    
                                                return column.render(doc);
                                            })
    
                                        : null
                                    }
                                </TableRow>
    
                            : null
                        }
                        {
                            (doc.open || this.props.hideStructure === true) && doc.hasValue ? doc.accounts.map((account, accountKey) => {
    
                                let canShowRow = this.canShowRow(account);
                                let isStructure = account.isStructure;
                                let render = [];
    
                                if (!isStructure && canShowRow) {
    
                                    render.push(
    
                                        <TableRow>
                                            <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}>
        
                                                { (account.children && account.children.length && this.childrenAccountHasValue(account.accounts)) || (isStructure && account.accounts && account.accounts.length && this.childrenAccountHasValue(account.accounts)) ?
        
                                                    account.open ? <IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.props.budgetStructureDocs, false, account.open) }}/> : <AddBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.props.budgetStructureDocs, false, account.open) }}/>
        
                                                : null }
        
                                            </TableCell>
                                            <TableCell style={{ backgroundColor: 'white' }}>{account.code}</TableCell>
                                            <TableCell style={{ backgroundColor: 'white', position: 'sticky', left: 38, zIndex: 1 }}><Tooltip title={account.description}><div>{account.description.length < 25 ? account.description : account.description.slice(0, 25) + '...'}</div></Tooltip></TableCell>
                                            {
                                                this.props.generatedReport.report === 'monthly' || (this.props.generatedReport.report === 'accumulated' && this.props.generatedReport.view === 'monthly_and_accumulated') ?
        
                                                    this.props.generatedReport['months'].map((month, monthIndex) => {
        
                                                        return this.props.generatedReport['columns'].map((column, key) => {
        
                                                            let value = this.getColumnValue(account, column, monthIndex);
                                                            let color = this.getColumnColor(account, column, monthIndex);
        
                                                            return <TableCell style={{ textAlign: 'center', backgroundColor: color.background, color: color.text }}>{value}</TableCell>
                                                        })
                                                    })
        
                                                : null
                                            }
                                            {
                                                this.props.generatedReport.report === 'accumulated' ? 
                                                
                                                    this.props.generatedReport['columns'].map((column, key) => {
        
                                                        let value = this.getAllPeriodColumnValue(account, column);
                                                        let color = this.getAllPeriodColumnColor(account, column);
        
                                                        return <TableCell style={{ textAlign: 'center', backgroundColor: color.background, color: color.text }}>{value}</TableCell>
                                                    })
        
                                                : null 
                                            }
                                            {
                                                this.props.additionalRows && this.props.additionalRows.length ?
    
                                                    this.props.additionalRows.map((column, key) => {
    
                                                        return column.render(account);
                                                    })
    
                                                : null
                                            }
        
                                        </TableRow>
                                    );
    
                                    if (account.open) {
        
                                        let childrens = account.accounts;
                                        render.push(this.renderAccountChildren(childrens, key))
                                    }
                                }
    
                                return render;
                                
                            }) : null
                        }
                    </TableBody>
    
                )
            }
        });
    }

    renderTotalizer() {

        return (

            <TableRow>
                <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}></TableCell>
                <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}></TableCell>
                <TableCell style={{ backgroundColor: 'white', position: 'sticky', left: 38, zIndex: 1, textAlign: 'center', fontWeight: 'bold' }}>Total</TableCell>
                {
                    this.props.generatedReport.report === 'monthly' || (this.props.generatedReport.report === 'accumulated' && this.props.generatedReport.view === 'monthly_and_accumulated') ?

                        this.props.generatedReport['months'].map((month, monthIndex) => {

                            return this.props.generatedReport['columns'].map((column, key) => {

                                let total = 0;

                                for (let index = 0; index < this.props.budgetStructureDocs.length; index++) {
                                    
                                    let value = this.getColumnValue(this.props.budgetStructureDocs[index], column, monthIndex, true);
                                    total += parseFloat(value);
                                }

                                return <TableCell style={{ backgroundColor: Colors.report.default.background, color: Colors.report.default.text, textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(total, false)}</TableCell>
                            }) 
                        })

                    : null
                }
                {
                    this.props.generatedReport.report === 'accumulated' ? 
                    
                    this.props.generatedReport['columns'].map((column, key) => {

                        let total = 0;

                        for (let index = 0; index < this.props.budgetStructureDocs.length; index++) {
                            
                            let value = this.getAllPeriodColumnValue(this.props.budgetStructureDocs[index], column, true);
                            total += parseFloat(value);
                        }

                        return <TableCell style={{ backgroundColor: Colors.report.default.background, color: Colors.report.default.text, textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(total, false)}</TableCell>
                    })

                    : null 
                }
                {
                    this.props.additionalRows && this.props.additionalRows.length ?

                        this.props.additionalRows.map((column, key) => {

                            return column.renderTotalizer(this.props.budgetStructureDocs);
                        })

                    : null
                }
            </TableRow>
        )
    }

    renderLoading() {

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader
                size={25}
                color={Colors.primary}
                loading={true}
                />
            </div>
        )
    }

    render() {

        return this.props.loadingTable ? this.renderLoading() : (

            <div style={{ maxHeight: this.props.showOptions ? window.screen.height - 600 : window.screen.height - 325, overflow: 'scroll' }}>
                <Table id={'budget-summary-table'} style={{ boxShadow: Colors.boxShadow }}>
                    <TableHead>
                        <TableRow style={{ backgroundColor: 'white' }}>
                            <TableCell style={{ width: 40, whiteSpace: 'nowrap', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, left: 0, zIndex: 1000, textAlign: 'center' }}></TableCell>
                            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, zIndex: 999, width: '1%', whiteSpace: 'nowrap' }}></TableCell>
                            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, left: 38, zIndex: 999, width: '1%', whiteSpace: 'nowrap' }}></TableCell>
                            {
                                this.props.generatedReport.report === 'monthly' || (this.props.generatedReport.report === 'accumulated' && this.props.generatedReport.view === 'monthly_and_accumulated') ?

                                    this.props.generatedReport['months'].map((month, key) => {

                                        let date = moment(month);
                                        let monthName = date.format('MMM');

                                        return <TableCell colSpan={this.props.generatedReport['columns'].length} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, zIndex: 998, textAlign: 'center', width: 'auto', whiteSpace: 'nowrap' }}>{`${monthName.charAt(0).toUpperCase() + monthName.substr(1)}/${date.format('YYYY')}`}</TableCell>
                                    })

                                : null 
                            }
                            { this.props.generatedReport.report === 'accumulated' ? this.renderPeriodCell() : null }
                            {
                                this.props.additionalRows && this.props.additionalRows.length ?

                                    this.props.additionalRows.map((column, key) => {

                                        if (column.showHeader !== false) {

                                            return <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, zIndex: 998, textAlign: 'center', width: 'auto', whiteSpace: 'nowrap' }}></TableCell>
                                        }
                                    })

                                : null
                            }
                        </TableRow>
                    </TableHead>

                    <TableRow>
                        <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', top: 38, left: 0, zIndex: 1000 }}>
                            { this.state.openAll ? <Tooltip title={'Recolher Tudo'}><IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAllAccounts(false) }}/></Tooltip> : <Tooltip title={'Expandir Tudo'}><AddBoxIcon onClick={() => { this.openAllAccounts(true) }} style={{ color: 'grey', cursor: 'pointer' }}/></Tooltip> }
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 38, zIndex: 999, textAlign: 'center' }}>Código</TableCell>
                        <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 38, left: 38, zIndex: 999, textAlign: 'center' }}>Descrição</TableCell>
                        {
                            this.props.generatedReport.report === 'monthly' || (this.props.generatedReport.report === 'accumulated' && this.props.generatedReport.view === 'monthly_and_accumulated') ?

                                this.props.generatedReport['months'].map((month, key) => {

                                    return this.props.generatedReport['columns'].map((column, key) => {

                                        return <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold', position: 'sticky', top: 38, zIndex: 998, textAlign: 'center' }}>{ChartHelper.getLabelFromColumn(column)}</TableCell>
                                    })
                                })

                            : null 
                        }
                        {
                            this.props.generatedReport.report === 'accumulated' ? 
                            
                                this.props.generatedReport['columns'].map((column, key) => {

                                    return <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold', position: 'sticky', top: 38, zIndex: 998, textAlign: 'center' }}>{ChartHelper.getLabelFromColumn(column)}</TableCell>
                                })

                            : null 
                        }
                        {
                            this.props.additionalRows && this.props.additionalRows.length ?

                                this.props.additionalRows.map((column, key) => {

                                    return <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold', position: 'sticky', top: 38, zIndex: 998, textAlign: 'center' }}>{column.title}</TableCell>;
                                })

                            : null
                        }
                    </TableRow>
                    
                    { this.renderBudgetStructure() }
                    { this.props.showTotalizer ? this.renderTotalizer() : null }

                </Table>
            </div>
        )
    }
}