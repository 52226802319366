import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { FormLabel, FormControlLabel, Radio, InputLabel, Select, MenuItem, CircularProgress, Checkbox } from '@material-ui/core';
import DefaultButton from '../components/DefaultButton';
import SessionHelper from '../helper/SessionHelper';
import Colors from '../constants/Colors';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { toast } from 'react-toastify';
import Firestore from '../api/firebase/Firestore';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import DefaultSelect from '../components/DefaultSelect';
import PermissionHelper from '../helper/PermissionHelper';
import Functions from '../api/firebase/Functions';
import ReportFavorite from '../components/ReportFavorite';
import BudgetStructureTable from '../components/BudgetStructureTable';
import EmojiObjects from '@material-ui/icons/EmojiObjects';
import ReportPeriodSelection from '../components/ReportPeriodSelection';

export default class BudgetSummaryPage extends Component {

    state = {
        loading: true,
        loadingTable: false,
        loadingText: 'Carregando resumo do orçamento...',
        accountPlanDocs: [],
        resultCenterDocs: [],
        budgetStructureDocs: [],
        verticalAnalysisDocs: [],
        accountPackageDocs: [],
        userDocs: [],
        verticalAnalysisAccountPlan: '',
        start: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
        end: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
        months: [moment().startOf('month').startOf('day').toDate()],
        editingPeriod: false,
        report: 'monthly',
        view: '',
        renderTable: false,
        showOptions: true,
        generatedReport: {},
        filterResultCenter: [],
        columns: [{ name: 'Orçado', value: 'budget', checked: false, key: 0 }, { name: 'Histórico', value: 'history', checked: false, key: 1 }],
        columns2: [{ name: 'Variação ($)', value: 'variation_money', checked: false, key: 2 }, { name: 'Variação (%)', value: 'variation_percentage', checked: false, key: 3 }, { name: 'Análise Vertical (%)', value: 'vertical_analysis', checked: false, key: 4 },],
        selectedColumns: [],
        showFilters: false,
        filterAccountPlan: [],
        filterAccountPackage: [],
        filterUser: null,
        periodDocs: [],
        selectedPeriod: null,
        customPeriod: false,
    }

    async componentDidMount() {

        await this.getAccountPlanDocs();
        await this.getAccountPackageDocs();
        await this.getResultCenters();
        await this.getBudgetStructure();
        await this.getVerticalAnalysisDocs();
        await this.getUserDocs();
        await this.getPeriods();

        this.setState({ loading: false });
    }

    async getPeriods() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ periodDocs: docs, selectedPeriod: docs[0].id });
    }

    async getUserDocs() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getAccountPackageDocs() {

        let query = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPackageDocs: docs });
    }

    async getBudgetStructure() {

        let query = await Firestore.customQuery('budget_structure').where('id_company', '==', SessionHelper.getData().id_company).orderBy('order', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            data.description = data.name;

            docs.push(data);
        });

        this.setState({ budgetStructureDocs: docs });
    }

    async getVerticalAnalysisChildrens(docs, childrens, accountPlans) {

        for (const id of childrens) {

            let account = accountPlans.find(item => item.id === id);

            if (account) {

                docs.push(account);

                if (account.children && account.children.length) {

                    this.getVerticalAnalysisChildrens(docs, account.children, accountPlans);
                }
            }
        }
    }

    async getVerticalAnalysisDocs(docs = [], budgetStructure = [ ...this.state.budgetStructureDocs ], accountPlans = [ ...this.state.accountPlanDocs ]) {

        for (let structure of budgetStructure) {
            
            structure.structure = true;
            docs.push(structure);

            for (const op of structure.operations) {

                if (op.type === 'account' || !op.type) {

                    let account = accountPlans.find(item => item.id === op.account);

                    if (account) {

                        docs.push(account);

                        if (account.children && account.children.length) {

                            this.getVerticalAnalysisChildrens(docs, account.children, [...accountPlans]);
                        }
                    }

                } else {

                    let structure = this.state.budgetStructureDocs.find(item => item.id === op.account);

                    if (structure) {

                        structure.structure = true;
                        this.getVerticalAnalysisDocs(docs, [structure]);
                    }
                }
            }
        }

        this.setState({ verticalAnalysisDocs: docs });
    }

    async getResultCenters() {

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid);
        this.setState({ resultCenterDocs: docs });
    }

    async getAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs });
    }

    getColumns() {

        let columns = [...this.state.columns, ...this.state.columns2];
        let selectedColumns = [...this.state.selectedColumns];
        let result = [];

        selectedColumns.sort();
        
        selectedColumns.forEach((columnName, key) => {

            let column = columns.find(item => item.key === columnName);
            result.push(column.value);
        });

        return result;
    }

    async getPeriodData() {

        if (this.state.selectedColumns.length) {

            if (this.state.start && this.state.end) {

                try {

                    await this.setState({ loadingTable: true });

                    let periodValid = await this.handlePeriodChange(this.state.start, this.state.end);
    
                    if (periodValid) {

                        await this.getAccountPlanDocs();

                        let months = this.state.months;
                        let parsedMonths = [];
                        let accounts = this.state.accountPlanDocs;
                        let budgetStructure = this.state.budgetStructureDocs;
                        let filterResultCenter = [...this.state.filterResultCenter];
                        let filterAccountPlan = [...this.state.filterAccountPlan];
                        let filterAccountPackage = [...this.state.filterAccountPackage];
                        let filterUser = this.state.filterUser;
                        let columns = this.getColumns();

                        if (filterResultCenter.length === this.state.resultCenterDocs.length) {

                            filterResultCenter = [];
                        }

                        if (filterAccountPlan.length === this.state.accountPlanDocs.length) {

                            filterAccountPlan = [];
                        }

                        for (let index = 0; index < months.length; index++) {

                            parsedMonths.push(months[index].toJSON());
                        }

                        let body = { id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, id_account_packages: filterAccountPackage, id_account_plans: filterAccountPlan, id_result_centers: filterResultCenter, id_users: filterUser, months: parsedMonths, columns: columns, open: false };
                        let request = await Functions.request('POST', 'getBudgetStructure', body);

                        if (request && request.accounts && request.budgetStructure) {
                            
                            accounts = request.accounts;
                            budgetStructure = request.budgetStructure;

                        } else {

                            throw new Error('Request Error');
                        }

                        let generatedReport = {
                            report: this.state.report,
                            view: this.state.view,
                            columns: columns,
                            months: this.state.months,
                            verticalAnalysisAccountPlan: this.state.verticalAnalysisAccountPlan,
                            filterResultCenter: this.state.filterResultCenter,
                            filterAccountPlan: this.state.filterAccountPlan,
                            filterAccountPackage: this.state.filterAccountPackage,
                            start: this.state.start,
                            end: this.state.end,
                        };
                
                        this.setState({ accountPlanDocs: accounts, budgetStructureDocs: budgetStructure, renderTable: true, loadingTable: false, generatedReport: generatedReport, showOptions: false, openAll: false });
                    }

                } catch (error) {
                    console.log(error);
                    toast.error('Houve um problema ao buscar os dados do período');
                }

            } else {

                toast.warn('Selecione um período de exibição');
            }

        } else {

            toast.warn('Selecione ao menos uma coluna para ser exibida');
        }
    }

    handleColumnCheck(column, value) {

        if (value) {

            let columns = this.state.selectedColumns;
            columns.push(column.key);

            this.setState({ selectedColumns: columns });

        } else {

            let columns = this.state.selectedColumns;
            let index = columns.findIndex(item => item === column.key);
            
            columns.splice(index, 1);
            
            this.setState({ selectedColumns: columns });
        }
    }

    async handlePeriodChange(start, end) {

        try {

            let dateStart = moment(start).startOf('month').toDate();
            let dateEnd = moment(end).startOf('month').toDate();
            let state = { ...this.state };
            let months = [];

            state['start'] = dateStart;
            state['end'] = dateEnd;

            let momentStart = moment(state['start']);
            let momentEnd = moment(state['end']);

            if (momentStart.isAfter(momentEnd)) {
                
                toast.warn('O período selecionado é inválido');
                start['start'] = null;
                start['end'] = null;
                state['loadingTable'] = false;

                this.setState(state);

                return false;

            } else {

                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

                    months.push(momentStart.toDate());
                    momentStart.add(1, 'month');
                }
    
                state['months'] = months;
    
                this.setState(state);

                return true;
            }

        } catch (error) {

            toast.error('Houve um problema ao carregar os dados do período');
            return false;
        }
    }

    clear() {

        this.setState({
            report: 'monthly',
            view: '',
            selectedColumns: [],
            verticalAnalysisAccountPlan: '',
            start: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
            end: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
            months: [moment().subtract(1, 'month').startOf('month').startOf('day').toDate()],
            renderTable: false,
            showOptions: true,
            generatedReport: {},
            filterResultCenter: [],
            filterAccountPlan: [],
            filterAccountPackage: [],
            filterUser: null,
            selectedPeriod: this.state.periodDocs[0] ? this.state.periodDocs[0].id : null,
            customPeriod: false,
        });
    }

    handleCustomPeriodChange(v) {

        if (v) {

            let start = this.state.start;
            let end = this.state.end;

            if (this.state.selectedPeriod) {

                let period = this.state.periodDocs.find(item => item.id === this.state.selectedPeriod);

                if (period) {

                    start = Firestore.getDate(period.start);
                    end = Firestore.getDate(period.end);
                }
            }

            this.setState({ customPeriod: v, start, end });
        
        } else {

            this.setState({ customPeriod: v, selectedPeriod: this.state.periodDocs[0] ? this.state.periodDocs[0].id : null });
        }
    }

    renderOptions() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'flex-start', padding: 25, overflowX: 'scroll', gap: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '20%' }}>
                    <InputLabel id={'report'}>{'Relatório'}</InputLabel>
                    <Select style={{ width: 200 }} labelId={'report'} value={this.state.report} onChange={(v) => { this.setState({ report: v.target.value, view: v.target.value === 'accumulated' ? 'total_accumulated' : '' }) }}>
                        <MenuItem value={'monthly'}>{'Mensal'}</MenuItem>
                        <MenuItem value={'accumulated'}>{'Acumulado'}</MenuItem>
                    </Select>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 20 }}>
                        <FormLabel>Exibição</FormLabel>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel disabled={this.state.report === 'monthly'} style={{ marginTop: 8 }} onChange={() => { this.setState({ view: 'total_accumulated' }) }} checked={'total_accumulated' === this.state.view} value={'total_accumulated'} control={<Radio style={{ color: this.state.report === 'monthly' ? 'grey' : Colors.primary }} />} label={'Total Acumulado'} />
                            <FormControlLabel disabled={this.state.report === 'monthly'} onChange={() => { this.setState({ view: 'monthly_and_accumulated' }) }} checked={'monthly_and_accumulated' === this.state.view} value={'monthly_and_accumulated'} control={<Radio style={{ color: this.state.report === 'monthly' ? 'grey' : Colors.primary }} />} label={'Meses + Total Acumulado'} />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '20%' }}>
                    <InputLabel>{'Período de Exibição'}</InputLabel>
                    <ReportPeriodSelection start={this.state.start} end={this.state.end} customPeriod={this.state.customPeriod} label={''} doc={this.state.selectedPeriod} periods={this.state.periodDocs} onStartChange={(v) => { this.setState({ start: v }) }} onEndChange={(v) => { this.setState({ end: v }) }} onDocChange={(id) => { this.setState({ selectedPeriod: id }) }} onCustomPeriodChange={(v) => { this.handleCustomPeriodChange(v) }}/>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '25%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: 350 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormLabel>Exibir Colunas</FormLabel>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                            {
                                this.state.columns.map((column, key) => {
                                    return <FormControlLabel style={{ marginTop: 8 }} onChange={(v) => { this.handleColumnCheck(column, v.target.checked) }} checked={this.state.selectedColumns.includes(column.key)} control={<Checkbox style={{ color: Colors.primary }} />} label={column.name} />
                                })
                            }
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {
                                this.state.columns2.map((column, key) => {
                                    return <FormControlLabel style={{ width: 'auto' }} onChange={(v) => { this.handleColumnCheck(column, v.target.checked) }} checked={this.state.selectedColumns.includes(column.key)} control={<Checkbox style={{ color: Colors.primary }} />} label={column.name} />
                                })
                            }
                        </div>
                    </div>

                    {
                        this.state.selectedColumns.includes(4) ?

                            <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                                <DefaultSelect
                                    search={true}
                                    searchField={'description'}
                                    displayField={'description'}
                                    render={(doc) => `${doc.description} (${doc.structure ? 'Estrutura' : `Conta - ${doc.code}`})`}
                                    valueField={'id'}
                                    value={this.state.verticalAnalysisAccountPlan}
                                    onChange={(v) => {
                                        this.setState({ verticalAnalysisAccountPlan: v.target.value })
                                    }}
                                    docs={this.state.verticalAnalysisDocs}
                                    label={'Conta Contábil (Análise Vertical)'}
                                />
                            </div>

                        : null
                    }
                </div>
                { this.renderFilters() }
            </div>
        )
    }

    renderFilters() {

        return (

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '28%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: Colors.primary, fontWeight: 'bold', cursor: 'pointer', marginBottom: 10 }} onClick={() => { this.setState({ showFilters: !this.state.showFilters }) }}>
                    <div style={{ fontSize: 16 }}>{'Filtros'}</div>
                    { this.state.showFilters ? <ArrowDropDownIcon style={{ fontSize: 35 }}/> : <ArrowRightIcon style={{ fontSize: 35 }}/> }
                </div>
                {
                    this.state.showFilters ?

                        <div style={{ width: '100%', marginBottom: 20 }}>
                            <DefaultSelect
                                search={true}
                                searchField={['description', 'code']}
                                displayField={'description'}
                                secondaryDisplay={'code'}
                                valueField={'id'}
                                multiple={true}
                                value={this.state.filterResultCenter}
                                onChange={(v) => {
                                    this.setState({ filterResultCenter: v.target.value })
                                }}
                                docs={this.state.resultCenterDocs}
                                label={'Centros de Resultado'}
                            />
                            <div style={{ marginTop: 20 }}/>
                            <DefaultSelect
                                search={true}
                                searchField={['description']}
                                displayField={'description'}
                                valueField={'id'}
                                multiple={true}
                                value={this.state.filterAccountPackage}
                                onChange={(v) => {
                                    this.setState({ filterAccountPackage: v.target.value, filterAccountPlan: [] })
                                }}
                                docs={this.state.accountPackageDocs}
                                label={'Pacotes'}
                            />
                            <div style={{ marginTop: 20 }}/>
                            <DefaultSelect
                                search={true}
                                searchField={['description', 'code']}
                                displayField={'description'}
                                secondaryDisplay={'code'}
                                valueField={'id'}
                                multiple={true}
                                value={this.state.filterAccountPlan}
                                onChange={(v) => {
                                    this.setState({ filterAccountPlan: v.target.value, filterAccountPackage: [] })
                                }}
                                docs={this.state.accountPlanDocs}
                                label={'Contas'}
                            />
                            <div style={{ marginTop: 20 }}/>
                            <DefaultSelect
                                search={true}
                                searchField={['name']}
                                displayField={'name'}
                                valueField={'id'}
                                multiple={false}
                                value={this.state.filterUser}
                                onChange={(v) => {
                                    this.setState({ filterUser: v.target.value })
                                }}
                                docs={this.state.userDocs}
                                label={'Responsável'}
                            />
                        </div>

                    : null
                }
            </div>
        )
    }

    renderButtons() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-end', position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', marginBottom: 10 }}>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DefaultButton width={250} leftIcon={<DeleteIcon/>} color={Colors.notSelected} title={'Limpar'} onClick={() => { this.clear() }}/>
                    <div style={{ marginRight: 8 }}/>
                    <ReportFavorite fields={
                        {
                            'report': this.state.report,
                            'view': this.state.view,
                            'start': this.state.start,
                            'end': this.state.end,
                            'selectedColumns': this.state.selectedColumns,
                            'filterResultCenter': this.state.filterResultCenter,
                            'filterAccountPackage': this.state.filterAccountPackage,
                            'filterAccountPlan': this.state.filterAccountPlan,
                            'filterUser': this.state.filterUser,
                            'verticalAnalysisAccountPlan': this.state.verticalAnalysisAccountPlan,
                            'selectedPeriod': this.state.selectedPeriod,
                            'customPeriod': this.state.customPeriod,  
                        }
                    } report={'budget_summary'} onLoad={(fields) => { this.handleFavorite(fields) }}/>
                    <div style={{ marginRight: 8 }}/>
                    <DefaultButton width={250} leftIcon={this.state.loadingTable ? <CircularProgress style={{ marginRight: 15 }} color={'white'} size={17}/> : <SearchIcon style={{ marginRight: 15 }}/>} color={Colors.success} title={'Gerar Relatório'} onClick={() => { this.getPeriodData() }}/>
                </div>
            </div>
        )
    }

    renderHeader() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, textAlign: 'center', padding: 25, boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                <FormLabel style={{ fontWeight: 'bold', fontSize: 20, lineHeight: 1, color: Colors.primary }}>{'Resumo do Orçamento'}</FormLabel>
                
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: Colors.primary, fontWeight: 'bold', cursor: 'pointer', width: 200 }} onClick={() => { this.setState({ showOptions: !this.state.showOptions }) }}>
                    <div style={{ fontSize: 16 }}>{'Filtros e Opções'}</div>
                    { this.state.showOptions ? <ArrowDropDownIcon style={{ fontSize: 35 }}/> : <ArrowRightIcon style={{ fontSize: 35 }}/> }
                </div>

                { this.state.showOptions ? this.renderOptions() : null }
                { this.state.showOptions ? this.renderButtons() : null }

            </div>
        )
    }

    async handleFavorite(fields) {

        if (fields) {

            let state = this.state;

            Object.keys(fields).forEach((field, key) => {

                if (state[field] !== undefined) {

                    let data = fields[field];

                    if (field === 'start' || field === 'end') {

                        data = moment(data).toDate();
                    }

                    state[field] = data;
                }
            });
            
            await this.setState(state);
            await this.handlePeriodChange(this.state.start, this.state.end);
        }
    }

    addActionPlan() {

        let link = (window.location.hostname == 'localhost' ? 'http://localhost:3000' : 'http://go.grupoadvis.com.br') + '/action_plan';
        
        let url = new URL(link);
        url.searchParams.append('add', true);
        url.searchParams.append('budget_link_period_start', moment(this.state.generatedReport.start).unix());
        url.searchParams.append('budget_link_period_end', moment(this.state.generatedReport.end).unix());

        if (this.state.generatedReport.filterResultCenter && this.state.generatedReport.filterResultCenter) {

            this.state.generatedReport.filterResultCenter.forEach((result_center, key) => {

                url.searchParams.append('budget_link_result_center', result_center);       
            });   
        }

        if (this.state.generatedReport.filterAccountPlan && this.state.generatedReport.filterAccountPlan) {

            this.state.filterAccountPlan.forEach((account_plan, key) => {

                url.searchParams.append('budget_link_account_plan', account_plan);       
            });   
        }

        if (this.state.generatedReport.filterAccountPackage && this.state.generatedReport.filterAccountPackage) {

            this.state.generatedReport.filterAccountPackage.forEach((account_package, key) => {

                url.searchParams.append('budget_link_account_package', account_package);       
            });   
        }

        window.open(url.href, '_blank');
    }

    renderResultCenters() {

        let text = [];
        let resultCenters = this.state.generatedReport.filterResultCenter;

        if (!resultCenters.length || (resultCenters.length === this.state.resultCenterDocs.length)) {
            
            text.push('Todos os Centros de Resultado')
        
        } else {

            resultCenters.forEach((resultCenter, key) => {

                let doc = this.state.resultCenterDocs.find(item => item.id === resultCenter);
                text.push(doc.description);
            });
        }

        return (

            <div style={{ padding: 10, backgroundColor: 'white', border: '1px solid lightgrey', boxShadow: Colors.boxShadow, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>Exibindo dados de: <b>{text.join(', ')}</b>.</div>
                <DefaultButton onClick={() => this.addActionPlan()} tooltip={'Novo Plano de Ação'} leftIcon={<EmojiObjects style={{ marginLeft: 3 }}/>}/>
            </div>
        )
    }

    renderTable() {

        return <BudgetStructureTable showTotalizer={false} hideStructure={false} additionalRows={[]} loadingTable={false} accountPlanDocs={this.state.accountPlanDocs} verticalAnalysisAccountPlan={this.state.verticalAnalysisAccountPlan} variationFilter={[0, 0]} generatedReport={this.state.generatedReport} budgetStructureDocs={this.state.budgetStructureDocs} />
    }

    renderEmpty() {

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', alignItems: 'center', flex: 1, width: '100%', height: '100%', padding: 25, boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                <img style={{ height: 250, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }} src={process.env.PUBLIC_URL + '/spreadsheet.png'} />
                <div style={{ textAlign: 'center', fontSize: 20, color: Colors.primary, marginTop: 35, fontWeight: '600', maxWidth: '50%' }}>Nenhum dado a ser exibido.</div>
            </div>
        )
    }

    renderBudgetSummary() {
        return (
            <div style={{ padding: 25 }}>

                {this.renderHeader()}
                { this.state.renderTable ? this.renderResultCenters() : null }
                { this.state.renderTable ? this.renderTable() : this.renderEmpty() }

            </div>
                
        )
    }

    render() {

        return this.state.loading ? <DefaultLoader loadingText={this.state.loadingText} /> : this.renderBudgetSummary();
    }
}