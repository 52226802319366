import { toast } from "react-toastify";
import Firestore from "../api/firebase/Firestore";
import LogHelper from "./LogHelper";
import SessionHelper from "./SessionHelper";
import DefaultSelect from "../components/DefaultSelect";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import PlanningRowHelper from "./PlanningRowHelper";
import moment from "moment";
import DataHelper from "./DataHelper";
import RelationField from "../components/RelationField";

export default class PlanningHelper {
    static async insertPlanning(data, silent = false) {
        let output = {
            insertId: null,
            success: false
        }

        if (!data) return output;

        Object.keys(data).forEach((item, key) => {

            if (data[item] === undefined) {
                data[item] = null;
            }
        }); 

        let requiredFields = [
            'type',
            'description',
            'start',
            'end',
            'operator',
            'table_type'
        ];

        let canInsert = true;

        requiredFields.forEach(field => {
            if (!data[field]) canInsert = false;
        });

        if (!canInsert) return output;

        const collection = 'planning';
        const insertId = await Firestore.getId(collection);

        try {
            data.id_company = SessionHelper.getData().id_company;
            data.date = new Date();

            await Firestore.insert(data, collection, insertId);
            LogHelper.insertLog(insertId, collection, data);

            output = {
                insertId,
                success: true
            };

            if (!silent) {
                toast.success('Registro inserido com sucesso!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (e) {
            toast.error('Ocorreu um erro ao inserir o registro, tente novamente mais tarde.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        return output;
    }

    static async deletePlanning(id) {
        let output = {
            success: false
        }

        if (!id) return output;

        const collection = 'planning';
        const rowCollection = 'planning_row';

        try {
            await Firestore.delete(collection, id);
            LogHelper.deleteLog(id, collection);

            let children = await Firestore.customQuery(rowCollection).where('id_parent', '==', id).get();
            children.forEach(child => {
                Firestore.delete(rowCollection, child.id);
                LogHelper.deleteLog(child.id, rowCollection);
            });

            output.success = true;

            toast.success("Removido com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (e) {
            toast.error("Erro ao remover", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        return output;
    }

    static async updatePlanningRows(data, id) {

        let planning_rows = await Firestore.customQuery('planning_row').where('id_parent', '==', id).get();
        let docs = [];
            
        if (planning_rows.size > 0) {
            
            for (let index = 0; index < planning_rows.docs.length; index++) {
                
                let doc = {...planning_rows.docs[index].data(), id: planning_rows.docs[index].id};

                docs.push(doc);

                if (data.account_type) { doc.account_type = data.account_type };
                if (data.operator) { doc.operator = data.operator };
                if (data.method) { doc.method = data.method };
                if (data.table_type) { doc.table_type = data.table_type };
                if (data.id_target_account_plan !== undefined) { doc.id_target_account_plan = data.id_target_account_plan; doc.relationship.id_target_account_plan = data.id_target_account_plan };
                if (data.id_account_plan !== undefined) { doc.id_account_plan = data.id_account_plan; doc.relationship.id_account_plan = data.id_account_plan };
                if (data.id_result_center !== undefined) { doc.id_result_center = data.id_result_center; doc.relationship.id_result_center = data.id_result_center; doc.id_result_centers = [data.id_result_center] };
                if (data.id_result_centers !== undefined) { doc.id_result_centers = data.id_result_centers; doc.relationship.id_result_centers = data.id_result_centers; };
                if (data.id_product !== undefined) { doc.id_product = data.id_product; doc.relationship.id_product = data.id_product; doc.id_products = [data.id_product] };
                if (data.id_products !== undefined) { doc.id_products = data.id_products; doc.relationship.id_products = data.id_products; };
                if (data.start !== undefined) { doc.start = data.start.seconds ? new Date(data.start.seconds * 1000) : data.start.toDate ? data.start.toDate() : data.start; };
                if (data.end !== undefined) { doc.end = data.end.seconds ? new Date(data.end.seconds * 1000) : data.end.toDate ? data.end.toDate() : data.end; };

                await Firestore.update(doc, 'planning_row', doc.id);
            }
        }

        if (data.start && data.end) {

            data.start = data.start.seconds ? new Date(data.start.seconds * 1000) : data.start.toDate ? data.start.toDate() : data.start;
            data.end = data.end.seconds ? new Date(data.end.seconds * 1000) : data.end.toDate ? data.end.toDate() : data.end;

            let dateLabels = [];
            let momentStart = moment(data.start);
            let momentEnd = moment(data.end);

            while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {
                    
                let dateLabel = momentStart.format('MMM/YYYY');
                dateLabels.push(dateLabel);

                let element = docs.find(item => item.date_label === dateLabel);

                if (!element) {

                    let doc = {};
                    doc.id_parent = data.id;
                    doc.type = data.type;
                    doc.operator = data.operator;
                    doc.method = data.method;
                    doc.account_type = data.account_type;
                    doc.relationship = {};

                    if (data.account_type) { doc.account_type = data.account_type };
                    if (data.operator) { doc.operator = data.operator };
                    if (data.method) { doc.method = data.method };
                    if (data.table_type) { doc.table_type = data.table_type };
                    if (data.id_target_account_plan !== undefined) { doc.id_target_account_plan = data.id_target_account_plan; doc.relationship.id_target_account_plan = data.id_target_account_plan };
                    if (data.id_account_plan !== undefined) { doc.id_account_plan = data.id_account_plan; doc.relationship.id_account_plan = data.id_account_plan };
                    if (data.id_result_center !== undefined) { doc.id_result_center = data.id_result_center; doc.relationship.id_result_center = data.id_result_center; doc.id_result_centers = [data.id_result_center] };
                    if (data.id_result_centers !== undefined) { doc.id_result_centers = data.id_result_centers; doc.relationship.id_result_centers = data.id_result_centers; };
                    if (data.id_product !== undefined) { doc.id_product = data.id_product; doc.relationship.id_product = data.id_product; doc.id_products = [data.id_product] };
                    if (data.id_products !== undefined) { doc.id_products = data.id_products; doc.relationship.id_products = data.id_products; };
                    if (data.start !== undefined) { doc.start = data.start.seconds ? new Date(data.start.seconds * 1000) : data.start.toDate ? data.start.toDate() : data.start; };
                    if (data.end !== undefined) { doc.end = data.end.seconds ? new Date(data.end.seconds * 1000) : data.end.toDate ? data.end.toDate() : data.end; };

                    await PlanningRowHelper.insertRow(doc, momentStart.toDate(), momentStart.toDate());
                }

                momentStart.add(1,'month');
            }

            let rowsToDelete = [];

            docs.forEach((doc, key) => {

                if (!dateLabels.includes(doc.date_label)) {
                    rowsToDelete.push(doc.id);
                }
            });

            if (rowsToDelete.length > 0) {

                rowsToDelete.forEach( async (doc, key) => {
                    
                    await Firestore.delete('planning_row', doc);
                });
            }
        }

        if (data.id_result_centers) {

            data.start = data.start.seconds ? new Date(data.start.seconds * 1000) : data.start.toDate ? data.start.toDate() : data.start;
            data.end = data.end.seconds ? new Date(data.end.seconds * 1000) : data.end.toDate ? data.end.toDate() : data.end;

            for (let index = 0; index < data.id_result_centers.length; index++) {
                const id_result_center = data.id_result_centers[index];

                let element = docs.find(item => item.relationship.id_result_center === id_result_center);

                if (!element) {

                    let row = docs[0];
                    row.relationship.id_result_center = id_result_center;
                    
                    await PlanningRowHelper.insertRow(row, data.start, data.end);
                }
            }

            let rowsToDelete = [];

            docs.forEach((doc, key) => {

                if (!data.id_result_centers.includes(doc.relationship.id_result_center)) {
                    rowsToDelete.push(doc.id);
                }
            });

            if (rowsToDelete.length > 0) {

                rowsToDelete.forEach( async (doc, key) => {
                    
                    await Firestore.delete('planning_row', doc);
                });
            }
        }
    }

    static async updatePlanning(data, id, collection = 'planning') {
        let output = {
            success: false
        }

        if (!id || !data) return output;

        try {

            Object.keys(data).forEach((item, key) => {

                if (data[item] === undefined) {
                    delete data[item];
                }
            }); 

            await Firestore.update(data, collection, id);
            await this.updatePlanningRows(data, id);

            LogHelper.updateLog(id, collection, data);

            output.success = true;

            toast.success("Atualizado com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (e) {
            console.log(e);
            toast.error("Erro ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        return output;
    }

    static renderBudgetStructure(structure, budgetStructure) {
        let doc = budgetStructure.find(item => item.id === structure);
        let text = doc ? doc.name : '';

        return text;
    }

    static renderAccountPlan(account, accounts) {

        return <RelationField id={account} collection={'account_plan'} field={'description'} loading={false}/>;
    }

    static renderResultCenter(resultCenter, resultCenters) {

        return <RelationField id={resultCenter} collection={'result_center'} field={'description'} loading={false}/>;
    }

    static renderResultCenters(resultCenters, docs) {

        let text = [];

        if (resultCenters && resultCenters.length) {
            resultCenters.forEach((cr, key) => {
                let doc = docs.find(item => item.id === cr);
                if (doc) {
                    text.push(doc ? doc.description : '');
                }
            });
        }

        return text.join(', ');
    }

    static renderBudgetStructureEdit(props, docs) {

        return (

            <DefaultSelect
                width={300}
                search={true}
                searchField={['name']}
                displayField={'name'}
                valueField={'id'}
                value={props.value}
                onChange={(v) => { props.onChange(v.target.value) }}
                docs={docs}
                label={''}
            />
        )
    }

    static renderAccountEdit(props, docs) {

        return (

            <DefaultSelect
                width={300}
                search={true}
                searchField={['description', 'code']}
                displayField={'description'}
                secondaryDisplay={'code'}
                valueField={'id'}
                value={props.value}
                onChange={(v) => { props.onChange(v.target.value) }}
                docs={docs}
                label={''}
            />
        )
    }

    static renderBudgetStructureEdit(props, docs) {

        return (

            <DefaultSelect
                width={300}
                search={true}
                searchField={['name']}
                displayField={'name'}
                secondaryDisplay={'code'}
                valueField={'id'}
                value={props.value}
                onChange={(v) => { props.onChange(v.target.value) }}
                docs={docs}
                label={''}
            />
        )
    }

    static renderResultCenterEdit(props, docs) {

        return (

            <DefaultSelect
                width={300}
                search={true}
                searchField={['description', 'code']}
                displayField={'description'}
                secondaryDisplay={'code'}
                valueField={'id'}
                value={props.value}
                onChange={(v) => { props.onChange(v.target.value) }}
                docs={docs}
                label={''}
            />
        )
    }

    static renderResultCentersEdit(props, docs) {

        return (

            <DefaultSelect
                width={300}
                multiple={true}
                search={true}
                searchField={['description', 'code']}
                displayField={'description'}
                secondaryDisplay={'code'}
                valueField={'id'}
                value={props.value}
                onChange={(v) => { props.onChange(v.target.value) }}
                docs={docs}
                label={''}
            />
        )
    }

    static renderProductEdit(props, docs, multiple = false) {

        return (

            <DefaultSelect
                width={300}
                search={true}
                searchField={['description', 'code']}
                displayField={'description'}
                secondaryDisplay={'code'}
                valueField={'id'}
                value={props.value}
                onChange={(v) => { props.onChange(v.target.value) }}
                docs={docs}
                label={''}
                multiple={multiple}
            />
        )
    }
    
    static renderPeriodEdit(props) {

        let date = props.value === null ? null : props.value.seconds ? new Date(props.value.seconds * 1000) : props.value;

        return (

            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    invalidDateMessage={false}
                    format={'MM/yyyy'}
                    autoOk={true}
                    label=""
                    views={["year", "month"]}
                    openTo={'month'}
                    cancelLabel={'Cancelar'}
                    okLabel={'Confirmar'}
                    onChange={(v) => { props.onChange(v) }}
                    value={date}
                />
            </MuiPickersUtilsProvider>
        )
    }

    static async getParentRows(id) {

        let docs = [];
        let query = await Firestore.customQuery('planning_row').where('id_parent', '==', id).get();
        
        query.forEach((doc, key) => {

            docs.push({ ...doc.data(), id: doc.id });
        });

        return docs;
    }
    static renderPlanningPeriodSelection(periods, value, onPeriodChange) {

        return (

            <DefaultSelect
                multiple={true}
                width={250}
                value={value}
                displayField={'description'}
                search={true}
                searchField={'description'}
                valueField={'id'}
                onChange={(v) => {
                    
                    onPeriodChange(v.target.value);
                }}
                docs={periods}
                label={'Período de Planejamento'}
                render={(doc) => { return `${doc.description} (${moment(doc.start.toDate()).format('MM/YYYY')} até ${moment(doc.end.toDate()).format('MM/YYYY')})`}}
            />
        )
    }

    static getAccountTypeLookup() {

        return {
            'budget_structure': 'Estrutura do Orçamento',
            'account': 'Conta Contábil',
        };
    }

    static renderAccountTypeSelect(value, onChange) {

        let accountTypes = [
            { label: 'Estrutura do Orçamento', value: 'budget_structure' },
            { label: 'Conta Contábil', value: 'account' },
        ];

        return (

            <DefaultSelect
                search={true}
                searchField={['label', 'value']}
                displayField={'label'}
                valueField={'value'}
                value={value}
                onChange={(v) => { onChange(v.target.value) }}
                docs={accountTypes}
                label={'Tipo (% Aplicação)'}
            />
        )
    }

    static renderPercentageAccountSelect(accountType, value, accountPlanDocs, budgetStructureDocs, onChange) {

        if (accountType === 'account') {
            
            return (

                <DefaultSelect
                    search={true}
                    searchField={['description', 'code']}
                    displayField={'description'}
                    secondaryDisplay={'code'}
                    valueField={'id'}
                    value={value}
                    onChange={(v) => { onChange(v.target.value) }}
                    docs={accountPlanDocs}
                    label={'Conta Contábil (% Aplicação)'}
                />
            )

        } else {

            return (

                <DefaultSelect
                    value={value}
                    search={true}
                    searchField={'name'}
                    displayField={'name'}
                    valueField={'id'}
                    onChange={(v) => { onChange(v.target.value) }}
                    docs={budgetStructureDocs}
                    label={'Estrutura (% Aplicação)'}
                />
            )
        }     
    }

    static async getResultCenterProducts(doc, productsDocs, id_result_center) {

        let existingProducts = [];
        let result = [];
        let query = await Firestore.customQuery('planning_row').where('id_parent', '==', doc.id).orderBy('date', 'asc').get();
        let rows = [];

        for (let index = 0; index < query.size; index++) {
            const doc = query.docs[index];
            let data = { ...doc.data(), id: doc.id };
            rows.push(data);
        }

        rows.forEach(row => {
            let relationship = row.relationship;

            if (relationship.id_result_center == id_result_center) {
                if (!existingProducts.includes(relationship.id_product)) existingProducts.push(relationship.id_product);
            }
        });

        existingProducts.forEach(p => {
            let product;

            productsDocs.forEach(doc => {
                if (doc.id == p) {
                    product = doc;
                }
            })

            if (product) {
                result.push(product);
            }
        });

        return result;
    }
}