import React from "react";
import TableHelper from "../helper/TableHelper";
import CalculusHelper from "../helper/CalculusHelper";
import { TableCell } from "@material-ui/core";

export default class MeanCell extends React.Component {

    state = {
        rows: this.props.rows,
        nOfElements: 0,
        mean: 0,
    }

    async componentDidUpdate(prev) {
        
        if (this.props.rows != prev.rows) {

            await this.setState({ rows: this.props.rows });
            await this.getMean();
        }
    }

    async componentDidMount() {

        await this.getMean();
    }

    async getMean() {

        if (this.props.historyPercentage) {

            let formatted = 0;
            let rowTotal = 0;
            let historyTotal = 0;
            
            for (let index = 0; index < this.props.rows.length; index++) {
                
                const row = this.props.rows[index];
                const historyRow = this.props.comparativeRows[index];

                if (row && row[this.props.valueField]) {

                    if (this.props.valueField === 'value') {

                        rowTotal += row[this.props.valueField] * (historyRow[this.props.valueField] / 100);

                    } else {

                        rowTotal += row[this.props.valueField];
                    }
                }

                if (historyRow && historyRow[this.props.valueField]) {

                    historyTotal += historyRow[this.props.valueField];
                }
            }

            let mean = Math.abs(historyTotal) > 0 ? ((rowTotal / historyTotal) * 100) : 0;
            formatted = CalculusHelper.formatType(this.props.type, CalculusHelper.formatFloat(mean, this.props.percentage))

            this.setState({ mean: formatted });

        } else {

            let nOfElements = await TableHelper.getMeanCellNOfElements(this.props.rows, this.props.valueField);
            let mean = CalculusHelper.mean(this.props.counter, nOfElements);
            let formatted = CalculusHelper.formatType(this.props.type, CalculusHelper.formatFloat(mean, this.props.percentage));
    
            this.setState({ mean: formatted });
        }
    }

    render() {

        return <TableCell align="right" style={{ fontWeight: 'bold' }}>{this.state.mean}</TableCell>
    }
}