import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import NotificationsIcon from '@material-ui/icons/Notifications';

export default (color) => {

    const style = { border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 };

    return {

        icons: {
            'action_plan': <EmojiObjectsIcon style={style}/>,
            'defaut': <NotificationsIcon style={style}/>
        }
    }
}