export default {
    deploy: [
        { 'label': 'Implantado', value: 'deployed' },
        { 'label': 'Parcialmente Implantado', value: 'partial_deployed' },
        { 'label': 'Não Implantado', value: 'not_deployed' },
    ],
    efficiency: [
        { 'label': 'Eficaz', value: 'effective' },
        { 'label': 'Parcialmente Eficaz', value: 'partial_effective' },
        { 'label': 'Não Eficaz', value: 'not_effective' },
    ],
    risk_and_opportunity: [
        { 'label': 'Não se aplica', value: 'not_apply' },
        { 'label': 'Foi necessário avaliar riscos e oportunidades para realização das ações', value: 'necessary' },
        { 'label': 'Não foi necessário avaliar riscos e oportunidades para realização das ações', value: 'not_necessary' },
    ],
    changes: [
        { 'label': 'Não se aplica', value: 'not_apply' },
        { 'label': 'Há necessidade de realizar mudanças no Sistema de Gestão da Qualidade', value: 'necessary' },
        { 'label': 'Não há necessidade de realizar mudanças no Sistema de Gestão da Qualidade', value: 'not_necessary' },
    ],
    tasks: {
        priority: [
            { 'label': 'Baixa', value: 'low' },
            { 'label': 'Média', value: 'medium' },
            { 'label': 'Alta', value: 'high' },
            { 'label': 'Urgente', value: 'highest' },
        ],
        situation: [
            { 'label': 'Não Iniciada', value: 'not_started' },
            { 'label': 'Em Desenvolvimento', value: 'started' },
            { 'label': 'Cancelada', value: 'canceled' },
            { 'label': 'Concluída', value: 'finished' },
        ],
    }
}