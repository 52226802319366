import React from "react";

export default class Functions {
    static getEndPoint() {
        return (window.location.hostname == 'localhost') ? 'http://localhost:5001/advis-matrix-budget/us-central1/app' : 'https://us-central1-advis-matrix-budget.cloudfunctions.net/app';
    }

    static getPlatformKey() {
        return 'b17464b5496483379e614825dd0e1c82904afb4ef058b5b450f8ba51a8235418b92ce3c477cd9fdadb203e25364ac0ec';
    }
}