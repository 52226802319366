import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import { IconButton, Menu, FormLabel, Tooltip, Select, MenuItem, List } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import CancelIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Firestore from '../api/firebase/Firestore';
import { toast } from 'react-toastify';
import Colors from '../constants/Colors';
import DefaultSelect from '../components/DefaultSelect';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ReactDragListView from 'react-drag-listview/lib/index.js';

export default class BudgetStructurePage extends Component {

    state = {
        loading: true,
        budgetStructure: [],
        accountPlanDocs: [],
        addTotalizer: false,
        addBudgetStructure: false,
        menuAnchor: null,
        totalizerName: '',
        totalizeOperations: [],
        hoveredTotalizer: null,
        editTotalizer: null,
        auxTotalizer: null,
        auxTotalizerKey: null,
    }

    async componentDidMount() {
        
        await this.getAccountPlanDocs();
        await this.getBudgetStructure();

        this.setState({ loading: false });
    }

    async getBudgetStructure() {

        let query = await Firestore.customQuery('budget_structure').where('id_company', '==', SessionHelper.getData().id_company).orderBy('order', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };

            docs.push(data);
        });

        this.setState({ budgetStructure: docs });
    }

    async getAccountPlanDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs });
    }

    addOperation(editKey = null) {

        if (editKey === null) {

            let operations = this.state.totalizeOperations;
            operations.push({ account: null, op: null, type: 'account' });
    
            this.setState({ totalizeOperations: operations });
        
        } else {

            let budgetStructure = this.state.budgetStructure;
            budgetStructure[editKey].operations.push({ account: null, op: null, type: 'account' });

            this.setState({ budgetStructure: budgetStructure });
        }
    }

    async addTotalizer() {

        if (this.state.totalizerName && this.state.totalizeOperations.length) {

            let operatorsAreValid = true;

            this.state.totalizeOperations.forEach((operator, key) => {

                if (!operator.account || !operator.op) {

                    operatorsAreValid = false;

                    return;
                }
            });

            if (operatorsAreValid) {

                try {

                    this.setState({ loading: true });
    
                    let order = 0;

                    if (this.state.budgetStructure.length) {

                        order = this.state.budgetStructure[this.state.budgetStructure.length - 1].order + 1;
                    }
                    
                    let data = {
                        name: this.state.totalizerName,
                        operations: this.state.totalizeOperations,
                        id_company: SessionHelper.getData().id_company,
                        order: parseInt(order),
                        date: new Date(),
                    };
    
                    await Firestore.insert(data, 'budget_structure');
                    await this.getBudgetStructure();
    
                    toast.success('Totalizador adicionado');
                    this.setState({ addTotalizer: false, totalizerName: '', totalizeOperations: [], loading: false });
    
                } catch (error) {
                    
                    toast.error('Houve um problema ao adicionar o totalizador');
                    this.setState({ loading: false });
                }

            } else {

                toast.warn('Existem operadores com dados em branco');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    async removeTotalizer() {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            try {

                this.setState({ loading: true });

                await Firestore.delete('budget_structure', this.state.hoveredTotalizer);
                await this.getBudgetStructure();

                toast.success('Totalizador removido com sucesso');
                this.setState({ loading: false });

            } catch (error) {
                
                toast.error('Houve um problema ao remover o totalizador');
                this.setState({ loading: false });
            }
        }
    }

    async saveStructure() {

        try {

            this.setState({ loading: true });

            let editKey = this.state.editTotalizer;
            let docs = { ...this.state.budgetStructure };
    
            if (docs[editKey]) {

                if (docs[editKey].operations && docs[editKey].operations.length) {

                    let isValid = true;

                    for (let index = 0; index < docs[editKey].operations.length; index++) {

                        const operation = docs[editKey].operations[index];
                        
                        if (!operation.account || !operation.type || !operation.op) {

                            isValid = false;
                            toast.warn(`O operador ${index + 1} é inválido`);
                        }
                    }

                    if (isValid) {

                        const id = docs[editKey].id;
                        await Firestore.update({ name: docs[editKey].name, operations: docs[editKey].operations }, 'budget_structure', id);
    
                        await this.getBudgetStructure();
    
                        toast.success('Estrutura editada com sucesso');
                        this.setState({ loading: false, editTotalizer: null });
                    
                    } else {

                        this.setState({ loading: false });
                    }

                } else {

                    toast.warn('Adicione ao menos um operador para essa estrutura');
                    this.setState({ loading: false });
                }

            } else {

                throw new Error('not find error');
            }

        } catch (error) {

            toast.error('Houve um problema ao editar a estrutura');
            this.setState({ loading: false });
        }
    }

    handleCancelEdit() {

        let budgetStructure = this.state.budgetStructure;
        budgetStructure[this.state.auxTotalizerKey] = JSON.parse(this.state.auxTotalizer);

        this.setState({ editTotalizer: null, budgetStructure, auxTotalizerKey: null, auxTotalizer: null });
    }

    async reorderStructure(startIndex, endIndex) {

        try {

            this.setState({ loading: true });

            let list = this.state.budgetStructure;

            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
    
            for (let index = 0; index < result.length; index++) {
                
                const element = result[index];
                await Firestore.update({ order: index }, 'budget_structure', element.id);
            }
    
            toast.success('Reordenado com sucesso');
    
            this.setState({ budgetStructure: result, loading: false });

        } catch (error) {

            this.setState({ loading: false });
            toast.error('Houve um problema ao reordenar a estrutura');
        }
    };

    renderBudgetStructure() {

        return (
            <List style={{ padding: 25 }}>

                { !this.state.budgetStructure.length ? <p style={{ color: 'grey' }}>Nenhum totalizador cadastrado.</p> : null }

                <ReactDragListView onDragEnd={(from, to) => { this.reorderStructure(from, to) }} nodeSelector={'li'} handleSelector={'#category-drag'} lineClassName={'category-drag-line'}>

                    { 
                        this.state.budgetStructure.map((totalizer, key) => {

                            return (

                                <li style={{ padding: 25, boxShadow: Colors.boxShadow, border: '1px solid lightgrey', position: 'relative' }} onMouseEnter={() => { this.setState({ hoveredTotalizer: totalizer.id }) }} onMouseLeave={() => { this.setState({ hoveredTotalizer: null }) }}>
                                    <DefaultInput disabled={this.state.editTotalizer !== key} value={totalizer.name} onChange={(text) => { let budgetStructure = this.state.budgetStructure; budgetStructure[key].name = text; this.setState({ budgetStructure }) }} label={'Nome'} />

                                    {              
                                        totalizer.operations.map((operation, operationKey) => {

                                            return (
                                                <div style={{ paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ marginRight: 15 }}>
                                                            <FormLabel component="legend">Operador</FormLabel>
                                                            <Select
                                                                disabled={this.state.editTotalizer !== key}
                                                                value={operation.op}
                                                                style={{ width: 150 }}
                                                                onChange={(v) => {

                                                                    let budgetStructure = this.state.budgetStructure;
                                                                    budgetStructure[key].operations[operationKey].op = v.target.value;

                                                                    this.setState({ budgetStructure });
                                                                }}>
                                                                <MenuItem value={'sum'}>{'Soma (+)'}</MenuItem>
                                                                <MenuItem value={'subtract'}>{'Subtração (-)'}</MenuItem>
                                                                <MenuItem value={'multiplie'}>{'Multiplicação (*)'}</MenuItem>
                                                                <MenuItem value={'divide'}>{'Divisão (÷)'}</MenuItem>
                                                            </Select>
                                                        </div>
                                                        <div style={{ marginRight: 15 }}>
                                                            <FormLabel component="legend">Tipo</FormLabel>
                                                            <Select
                                                                disabled={this.state.editTotalizer !== key}
                                                                value={operation.type || 'account'}
                                                                style={{ width: 250 }}
                                                                onChange={(v) => {

                                                                    let budgetStructure = this.state.budgetStructure;
                                                                    budgetStructure[key].operations[operationKey].type = v.target.value;

                                                                    this.setState({ budgetStructure });
                                                                }}>
                                                                <MenuItem value={'account'}>{'Conta Contábil'}</MenuItem>
                                                                <MenuItem value={'budget_structure'}>{'Estrutura do Orçamento'}</MenuItem>
                                                            </Select>
                                                        </div>

                                                        {
                                                            operation.type === 'account' || !operation.type ?

                                                                <DefaultSelect
                                                                    disabled={this.state.editTotalizer !== key}
                                                                    value={operation.account}
                                                                    search={true}
                                                                    searchField={'description'}
                                                                    displayField={'description'}
                                                                    secondaryDisplay={'code'}
                                                                    valueField={'id'}
                                                                    onChange={(v) => {
                                                                        
                                                                        let budgetStructure = this.state.budgetStructure;
                                                                        budgetStructure[key].operations[operationKey].account = v.target.value;

                                                                        this.setState({ budgetStructure });
                                                                    }}
                                                                    docs={this.state.accountPlanDocs}
                                                                    label={'Conta Contábil'}
                                                                />

                                                        :
                                                        
                                                            <DefaultSelect
                                                                disabled={this.state.editTotalizer !== key}
                                                                value={operation.account}
                                                                search={true}
                                                                searchField={'name'}
                                                                displayField={'name'}
                                                                valueField={'id'}
                                                                onChange={(v) => {
                                                                    
                                                                    let budgetStructure = this.state.budgetStructure;
                                                                    budgetStructure[key].operations[operationKey].account = v.target.value;

                                                                    this.setState({ budgetStructure });
                                                                }}
                                                                docs={this.state.budgetStructure}
                                                                label={'Estrutura'}
                                                            />

                                                        }

                                                        {
                                                            this.state.editTotalizer !== null && this.state.editTotalizer == key ?

                                                                <Tooltip title={'Remover'}>
                                                                    <IconButton onClick={() => {

                                                                        let budgetStructure = this.state.budgetStructure;
                                                                        budgetStructure[key].operations.splice(operationKey, 1);

                                                                        this.setState({ budgetStructure });

                                                                    }} style={{ boxShadow: Colors.boxShadow, marginLeft: 15, border: '1px solid lightgrey' }}>
                                                                        <DeleteIcon style={{ color: Colors.primary }}/>
                                                                    </IconButton>
                                                                </Tooltip>

                                                            : null
                                                        }

                                                    </div>
                                                </div>      
                                            )
                                        })
                                    }

                                    { !totalizer.operations.length ? <p style={{ color: 'grey' }}>Nenhum operador adicionado.</p> : null }

                                    {
                                        this.state.editTotalizer !== null && this.state.editTotalizer == key ?

                                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                                <Tooltip title={'Adicionar Operador'}>
                                                    <IconButton onClick={() => { this.addOperation(key) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                                        <AddIcon style={{ color: Colors.primary }}/>
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title={'Replicar Outra Estrutura'}>
                                                    <IconButton onClick={(evt) => { this.setState({ addBudgetStructure: true, menuAnchor: evt.target }) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginLeft: 8 }}>
                                                        <FlashOnIcon style={{ color: Colors.primary }}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>

                                        : null
                                    }

                                    { this.state.hoveredTotalizer === totalizer.id || this.state.editTotalizer === key ? 

                                        <div style={{ position: 'absolute', right: 0, top: 0, marginTop: 15, marginRight: 15, display: 'flex', flexDirection: 'row' }}>
                                            {
                                                this.state.editTotalizer !== null && this.state.editTotalizer === key ? 
                                                
                                                    <Tooltip title={'Salvar'}>
                                                        <IconButton onClick={() => { this.saveStructure() }} style={{ boxShadow: Colors.boxShadow, marginLeft: 8, border: '1px solid lightgrey' }}>
                                                            <CheckIcon style={{ color: Colors.primary }}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                
                                                :
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Tooltip title={'Reordenar Estrutura (Arrastar e Soltar'}>
                                                            <IconButton id={'category-drag'} onClick={() => { }} style={{ boxShadow: Colors.boxShadow, marginLeft: 8, border: '1px solid lightgrey' }}>
                                                                <OpenWithIcon style={{ color: Colors.primary }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={'Editar'}>
                                                            <IconButton onClick={() => {

                                                                let aux = JSON.stringify(totalizer);

                                                                this.setState({ editTotalizer: key, auxTotalizer: aux, auxTotalizerKey: key })

                                                            }} style={{ boxShadow: Colors.boxShadow, marginLeft: 8, border: '1px solid lightgrey' }}>
                                                                <EditIcon style={{ color: Colors.primary }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                            }

                                            {
                                                this.state.editTotalizer !== null && this.state.editTotalizer === key ? 
                                                
                                                    <Tooltip title={'Cancelar'}>
                                                        <IconButton onClick={() => { this.handleCancelEdit() }} style={{ boxShadow: Colors.boxShadow, marginLeft: 8, border: '1px solid lightgrey' }}>
                                                            <CancelIcon style={{ color: Colors.primary }}/>
                                                        </IconButton>
                                                    </Tooltip> 
                                                    
                                                :

                                                    <Tooltip title={'Remover'}>
                                                        <IconButton onClick={() => { this.removeTotalizer() }} style={{ boxShadow: Colors.boxShadow, marginLeft: 8, border: '1px solid lightgrey' }}>
                                                            <DeleteIcon style={{ color: Colors.primary }}/>
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                        </div>

                                    : null }

                                </li>
                            )
                        })
                    }

                </ReactDragListView>

                { !this.state.addTotalizer ? 
                
                    <Tooltip title={'Adicionar Totalizador'}>
                        <IconButton onClick={() => { this.setState({ addTotalizer: true }) }} style={{ boxShadow: Colors.boxShadow, marginTop: 25, border: '1px solid lightgrey' }}>
                            <AddIcon style={{ color: Colors.primary }}/>
                        </IconButton>
                    </Tooltip>

                : null }

                { this.state.addTotalizer ?
                
                    <div style={{ padding: 25, boxShadow: Colors.boxShadow, border: '0.5px solid lightgrey' }}>
                        <div style={{ marginBottom: 25 }}>
                            <DefaultInput onChange={(text) => { this.setState({ totalizerName: text }) }} label={'Nome'} />

                            { !this.state.totalizeOperations.length ? <p style={{ color: 'grey' }}>Nenhum operador adicionado.</p> : null }

                            { 
                            
                                this.state.totalizeOperations.map((operation, key) => {

                                    return (
                                        <div style={{ paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ marginRight: 15 }}>
                                                <FormLabel component="legend">Operador</FormLabel>
                                                <Select
                                                    value={operation.op}
                                                    style={{ width: 150 }}
                                                    onChange={(v) => {

                                                        let totalizerOperations = this.state.totalizeOperations;
                                                        totalizerOperations[key].op = v.target.value;
    
                                                        this.setState({ totalizerOperations: totalizerOperations });
                                                    }}>
                                                    <MenuItem value={'sum'}>{'Soma (+)'}</MenuItem>
                                                    <MenuItem value={'subtract'}>{'Subtração (-)'}</MenuItem>
                                                    <MenuItem value={'multiplie'}>{'Multiplicação (*)'}</MenuItem>
                                                    <MenuItem value={'divide'}>{'Divisão (÷)'}</MenuItem>
                                                </Select>
                                            </div>
                                            <div style={{ marginRight: 15 }}>
                                                <FormLabel component="legend">Tipo</FormLabel>
                                                <Select
                                                    value={operation.type}
                                                    style={{ width: 250 }}
                                                    onChange={(v) => {

                                                        let totalizerOperations = this.state.totalizeOperations;
                                                        totalizerOperations[key].type = v.target.value;
    
                                                        this.setState({ totalizerOperations: totalizerOperations });
                                                    }}>
                                                    <MenuItem value={'account'}>{'Conta Contábil'}</MenuItem>
                                                    <MenuItem value={'budget_structure'}>{'Estrutura do Orçamento'}</MenuItem>
                                                </Select>
                                            </div>
                                            
                                            {
                                                operation.type === 'account' ?

                                                    <DefaultSelect
                                                        value={operation.account}
                                                        search={true}
                                                        searchField={'description'}
                                                        displayField={'description'}
                                                        secondaryDisplay={'code'}
                                                        valueField={'id'}
                                                        onChange={(v) => {
                                                            
                                                            let totalizerOperations = this.state.totalizeOperations;
                                                            totalizerOperations[key].account = v.target.value;

                                                            this.setState({ totalizerOperations: totalizerOperations });
                                                        }}
                                                        docs={this.state.accountPlanDocs}
                                                        label={'Conta Contábil'}
                                                    />

                                                :
                                                
                                                    <DefaultSelect
                                                        value={operation.account}
                                                        search={true}
                                                        searchField={'name'}
                                                        displayField={'name'}
                                                        valueField={'id'}
                                                        onChange={(v) => {
                                                            
                                                            let totalizerOperations = this.state.totalizeOperations;
                                                            totalizerOperations[key].account = v.target.value;

                                                            this.setState({ totalizerOperations: totalizerOperations });
                                                        }}
                                                        docs={this.state.budgetStructure}
                                                        label={'Estrutura'}
                                                    />
                                                
                                                }

                                            <Tooltip title={'Remover'}>
                                                <IconButton onClick={() => { let totalizerOperations = this.state.totalizeOperations; totalizerOperations.splice(key, 1); this.setState({ totalizerOperations }); }} style={{ boxShadow: Colors.boxShadow, marginLeft: 15, border: '1px solid lightgrey' }}>
                                                    <DeleteIcon style={{ color: Colors.primary }}/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                })

                            }

                            <Tooltip title={'Adicionar Operador'}>
                                <IconButton onClick={() => { this.addOperation(null) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                    <AddIcon style={{ color: Colors.primary }}/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={'Replicar Outra Estrutura'}>
                                <IconButton onClick={(evt) => { this.setState({ addBudgetStructure: true, menuAnchor: evt.target }) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey', marginLeft: 8 }}>
                                    <FlashOnIcon style={{ color: Colors.primary }}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Tooltip title={'Salvar'}>
                                <IconButton onClick={() => { this.addTotalizer() }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                    <CheckIcon style={{ color: Colors.primary }}/>
                                </IconButton>
                            </Tooltip>
                            <div style={{ marginLeft: 3, marginRight: 3 }}/>
                            <Tooltip title={'Cancelar'}>
                                <IconButton onClick={() => { this.setState({ addTotalizer: false }) }} style={{ boxShadow: Colors.boxShadow, border: '1px solid lightgrey' }}>
                                    <CancelIcon style={{ color: Colors.primary }}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>    

                : null }

            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={this.state.menuAnchor}
                id={'primary-search-account-menu'}
                open={this.state.addBudgetStructure}
                style={{ marginTop: 40, height: 'auto' }}
                onClose={() => { this.setState({ addBudgetStructure: false }) }} >

                    <div style={{ padding: 25, width: 450 }}>
                        <DefaultSelect
                            search={true}
                            searchField={'name'}
                            displayField={'name'}
                            valueField={'id'}
                            onChange={(v) => {
                                
                                let structure = this.state.budgetStructure.find(item => item.id === v.target.value);

                                if (structure && structure.operations.length) {
                                    
                                    if (this.state.editTotalizer !== null) {

                                        let budgetStructure = this.state.budgetStructure;
                                        budgetStructure[this.state.editTotalizer].operations = [...budgetStructure[this.state.editTotalizer].operations, ...structure.operations];
    
                                        this.setState({ budgetStructure: budgetStructure, addBudgetStructure: false });

                                    } else {

                                        let operations = this.state.totalizeOperations;
                                        operations = [...operations, ...structure.operations];
    
                                        this.setState({ totalizeOperations: operations, addBudgetStructure: false });
                                    }
                                }
                                
                            }}
                            docs={this.state.budgetStructure}
                            label={'Estrutura'}
                        />
                    </div>
                </Menu>
            </List>
        )
    }

    render() {

        return this.state.loading ? <DefaultLoader/> : this.renderBudgetStructure();
    }
}