import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import CloseIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ToastContainer, toast } from 'react-toastify';
import DefaultLoader from '../components/DefaultLoader';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth'
import Firestore from '../api/firebase/Firestore';
import Functions from '../api/firebase/Functions';

export default class UserManagement extends Component {

    state = {
        loading: true,
        oobCode: null,
        mode: null,
        success: null,
        uid: null,
        email: '',
        password: '',
        passwordConfirm: '',
    }

    async componentDidMount() {

        await this.getParams();
        await this.validateRequest();

        this.setState({ loading: false });
    }

    async validateRequest() {

        try {
            
            if (this.state.mode === 'resetPassword') {

                let email = await firebase.auth().verifyPasswordResetCode(this.state.oobCode);
                this.setState({ email });
            
            } else if (this.state.mode === 'createPassword') {

                let hashQuery = await Firestore.getDoc('user_hash', this.state.oobCode);
                let hash = { ...hashQuery.data(), id: hashQuery.id };
                
                if (moment().isAfter(moment(hash.expire_date.toDate()))) {

                    throw new Error('Request expired');
                }

                this.setState({ uid: hash.uid });
            }

        } catch (error) {

            this.setState({ success: false });
        }
    }

    async handleResetPassword() {

        if (this.state.password.length && this.state.passwordConfirm.length) {

            if (this.state.password === this.state.passwordConfirm) {

                if (this.validatePassword()) {

                    this.setState({ loading: true });

                    if (this.state.mode === 'resetPassword') {

                        await firebase.auth().verifyPasswordResetCode(this.state.oobCode).then( async (email) => {

                            await firebase.auth().confirmPasswordReset(this.state.oobCode, this.state.password).then((resp) => {
                              
                              this.setState({ success: true, loading: false });
                  
                            }).catch((error) => {
                              
                              this.setState({ success: false, loading: false });
                            });
        
                          }).catch((error) => {
                  
                              this.setState({ success: false, loading: false });
                          });

                    } else {

                        let response = await Functions.request('POST', 'user/createPassword', { password: this.state.password, hash: this.state.oobCode });

                        if (response.status == 200) {

                            this.setState({ success: true, loading: false });

                        } else {

                            this.setState({ success: false, loading: false });
                        }
                    }

                } else {

                    toast.warn('A sua senha é muito fraca');
                }

            } else {

                toast.warn('As senhas são diferentes!');
            }

        } else {
            
            toast.warn('Preencha todos os campos!');
        }
    }

    async getParams() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let oobCode = urlParams.get('oobCode');
        let mode = urlParams.get('mode');

        this.setState({ oobCode, mode });
    }

    validatePassword() {

        let password = this.state.password;
        let array = [];
        let sum = 0;

        array[0] = password.match(/[A-Z]/);
        array[1] = password.match(/[a-z]/);
        array[2] = password.match(/\d/);
        array[3] = password.match(/[!_.-]/);
        array[4] = password.length >= 7 ? true : false;

        for (let i = 0; i < array.length; i++) {

            sum += array[i] ? 1 : 0;
        }

        if (sum === 4) {

            return true;

        } else {

            return false;
        }
    }

    renderLoading() {

        return <DefaultLoader css={{ position: 'absolute', top: '38vh', left: '49%' }} />
    }

    renderSuccess() {

        return (
            <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', width: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{paddingBottom: 70, paddingTop: 15, height: 64}} src={`${process.env.PUBLIC_URL}/logo-dark.png`} /> 
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold', color: 'green' }}>
                    <CheckCircleIcon style={{ marginRight: 5, fontSize: 26 }}/>
                    <div style={{ fontSize: 20 }}>{this.state.mode === 'resetPassword' ? 'Senha alterada com sucesso!' : 'Cadastro finalizado com sucesso!'}</div>
                </div>
                <p style={{ color: 'grey', fontSize: 14 }}>{'Volte para a tela de login clicando no botão abaixo.'}</p>
                <Button onClick={() => { window.location.href = '/' }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >VOLTAR</Button>
            </Card>
        )
    }

    handleShortcut = (evt) => {
        if (evt.key === "Enter") {
            this.handleResetPassword();
        }
    }

    renderForm() {

        if (this.state.success === false) {

            return (
                <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', width: 400, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                    <img style={{paddingBottom: 70, paddingTop: 15, height: 64}} src={`${process.env.PUBLIC_URL}/logo-dark.png`} /> 
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold', color: Colors.error }}>
                        <CloseIcon style={{ marginRight: 5, fontSize: 26 }}/>
                        <div style={{ fontSize: 20 }}>{'Solicitação Inválida!'}</div>
                    </div>
                    <p style={{ color: 'grey', fontSize: 14 }}>{'Verifique se o seu link não expirou, ou tente novamente.'}</p>
                    <Button onClick={() => { window.location.href = '/' }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >VOLTAR</Button>
                </Card>
            )

        } else {

            return (
                <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', width: 400, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                    <img style={{ paddingBottom: 40, paddingTop: 15, height: 64, }} src={`${process.env.PUBLIC_URL}/logo-dark.png`} />
                    <div style={{ marginBottom: 20, boxShadow: Colors.boxShadow, padding: 20, border: '0.5px solid lightgrey', borderRadius: 8, width: 360 }}>
                        <div style={{ fontWeight: 'bold', color: Colors.primary }}>Sua nova senha deve conter ao menos:</div>
                        <ul>
                            <li>8 caracteres</li>
                            <li>1 letra maiúscula</li>
                            <li>1 letra minúscula</li>
                            <li>1 número</li>
                            <li>1 caractere especial (!@#$%&*)</li>
                        </ul>
                    </div>
                    <form style={{ width: 400 }}>
                        <DefaultInput type={'password'} onKeyDown={this.handleShortcut} onError={(error, msg) => { this.setState({ error: error }) }} required={true} onBlur={(text) => { this.setState({ password: text }) }} label={'Nova Senha'} />
                        <DefaultInput type={'password'} onKeyDown={this.handleShortcut} onError={(error, msg) => { this.setState({ error: error }) }} required={true} onBlur={(text) => { this.setState({ passwordConfirm: text }) }} label={'Confirme a Senha'} />
                        <Button onClick={() => { this.handleResetPassword() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >REDEFINIR SENHA</Button>
                    </form>
                    <a href={'/'} className={'register-link'}>{'Voltar ao Login'}</a>
                </Card>
            )
        }
    }

    renderPasswordReset() {
        
        return (

            <Container style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'auto' }} maxWidth={false}>
                <div style={{height: '100vh'}}>
                    {this.state.success ? this.renderSuccess() : this.renderForm()}
                    {this.state.loading ? this.renderLoading() : null}
                </div>
                <ToastContainer style={{}}/>
            </Container>
        )
    }

    render() {

        switch (this.state.mode) {
            
            case 'resetPassword':
                
                return this.renderPasswordReset();

            case 'createPassword':
                
                return this.renderPasswordReset();

            default:
                
                return <div/>;
        }
    }
}