import React, { Component } from 'react';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import Colors from '../constants/Colors';

export default class PeriodSelection extends Component {

    state = {
        doc: null,
        start: undefined,
        end: undefined,
        docs: this.props.periods,
        customPeriod: false,
        loading: true,
    }

    onStartChange(v) {

        this.setState({ start: v });

        if (this.props.onStartChange) {

            this.props.onStartChange(v);
        }
    }

    onEndChange(v) {

        this.setState({ end: v });

        if (this.props.onEndChange) {

            this.props.onEndChange(v);
        }
    }

    renderCustomPeriod() {

        return (
            <div>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%', marginBottom: 15 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        label="Início do Período"
                        views={["year", "month"]}
                        openTo={'month'}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => { this.onStartChange(v) }}
                        value={this.state.start}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%',marginBottom: 15 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        openTo={'month'}
                        views={["year", "month"]}
                        label="Fim do Período"
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => { this.onEndChange(v) }}
                        value={this.state.end}
                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    renderPeriodSelection() {

        return (
            <DefaultSelect
                value={this.state.doc}
                displayField={'description'}
                search={true}
                searchField={'description'}
                valueField={'id'}
                onChange={(v) => {
                    
                    let doc = this.state.docs.find(item => item.id === v.target.value);

                    if (doc) {

                        this.onStartChange(doc.start.toDate());
                        this.onEndChange(doc.end.toDate());
    
                        if (this.props.onDocChange) {
    
                            this.props.onDocChange(doc.id);
                        }

                    } else {

                        this.onStartChange(null); 
                        this.onEndChange(null);

                        if (this.props.onDocChange) {
                            
                            this.props.onDocChange(null);
                        }
                    }

                    this.setState({ doc: v.target.value });
                }}
                docs={this.state.docs}
                label={'Período'}
                render={(doc) => { return `${doc.description} (${moment(doc.start.toDate()).format('MM/YYYY')} até ${moment(doc.end.toDate()).format('MM/YYYY')})`}}
            />
        )
    }

    render() {

        return (
            <div style={{ marginTop: 15 }}>
                { this.state.customPeriod ? this.renderCustomPeriod() : this.renderPeriodSelection() }
                <FormControlLabel
                    style={{ marginTop: 8 }}
                    control={<Checkbox checked={this.state.customPeriod} onChange={(v) => {
                        this.setState({ customPeriod: v.target.checked, start: undefined, end: undefined, doc: null });
                        
                        this.onStartChange(null); 
                        this.onEndChange(null);

                        if (this.props.onDocChange) {
                            
                            this.props.onDocChange(null);
                        }

                    }} />}
                    label={'Período personalizado'}
                />
            </div>
        )
    }
}
