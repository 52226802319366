import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Platform from '../constants/Platform';
import { Divider, Drawer, Hidden, Collapse, Button, TextareaAutosize, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import { Switch, Route, Link, BrowserRouter, Redirect } from "react-router-dom";
import Colors from '../constants/Colors';
import HomePage from '../page/HomePage';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import UserPage from "../page/UserPage";
import PermissionPage from "../page/PermissionPage";
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import NotificationCenter from "../components/NotificationCenter";
import ProfileCard from "../components/ProfileCard";
import CompanyPage from "../page/CompanyPage";
import ResultCenterPage from "../page/ResultCenterPage";
import AccountPlanPage from "../page/AccountPlanPage";
import AccountPackagePage from "../page/AccountPackagePage"
import ProductPage from "../page/ProductPage";
import RevenuePlanningSettings from "../page/RevenuePlanningSettings";
import RevenuePlanning from "../page/RevenuePlanning";
import ResponsabilityMatrixPage from "../page/ResponsabilityMatrixPage";
import DeductionPage from "../page/DeductionPage";
import PeriodPage from "../page/PeriodPage";
import transitions from "@material-ui/core/styles/transitions";
import NotFoundPage from '../page/NotFoundPage';
import RolePage from '../page/RolePage';
import CostsPage from "../page/CostsPage";
import EmployeePage from "../page/EmployeePage";
import ExpensesPage from "../page/ExpensesPage";
import PeopleExpensesPage from "../page/PeopleExpensesPage";
import DissidioPage from "../page/DissidioPage";
import ExpensesAndRevenuePage from "../page/ExpensesAndRevenuePage";
import OtherExpensesAndRevenuePage from "../page/OtherExpensesAndRevenuePage";
import TaxPage from "../page/TaxPage";
import BalancetePage from "../page/BalancetePage";
import BudgetStructurePage from "../page/BudgetStructurePage";
import ChartBuilderPage from "../page/ChartBuilderPage";

//Icons
import DescriptionIcon from "@material-ui/icons/Description";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import LockIcon from "@material-ui/icons/Lock";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PeopleIcon from "@material-ui/icons/People";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BusinessIcon from '@material-ui/icons/Business';
import MenuIcon from '@material-ui/icons/Menu';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import GridOnIcon from '@material-ui/icons/GridOn';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import WorkIcon from '@material-ui/icons/Work';
import BuildIcon from '@material-ui/icons/Build';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PaymentIcon from '@material-ui/icons/Payment';
import GavelIcon from '@material-ui/icons/Gavel';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoneyIcon from '@material-ui/icons/Money';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LayersIcon from '@material-ui/icons/Layers';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import BudgetSummaryPage from "../page/BudgetSummaryPage";
import BudgetVersusAccomplishedPage from "../page/BudgetVersusAccomplishedPage";
import Functions from "../api/firebase/Functions";
import BudgetImportPage from "../page/BudgetImportPage";
import ActionPlanPage from "../page/ActionPlanPage";
import TagPage from "../page/TagPage";
import ActionPlanEvaluatePage from "../page/ActionPlanEvaluatePage";

const drawerWidth = 240;
const closedDrawerWidth = window.screen.width < 1080 ? 0 : 60;

export default class navigation extends React.Component {

    state = {
        actualRoute: null,
        settingsMenuOpen: false,
        accountsMenuOpen: false,
        planningMenuOpen: false,
        budgetManagementMenuOpen: false,
        analysisMenuOpen: false,
        aboutModal: false,
        helpModal: false,
        subject: '',
        message: '',
        plan: SessionHelper.getData().plan || null,
        openDrawer: true,
        buttonHeight: 'auto',
        loadingModal: false,
    }

    async componentDidMount() {

        this.setState({ actualRoute: window.location.pathname });

        await this.getCompanyColor();
        await this.setDrawerBasedOnDevice();
    }

    async getCompanyColor() {

        if (SessionHelper.getData().company.color) {

            Colors.primary = SessionHelper.getData().company.color;
            Colors.contrast = this.hexToRGB(SessionHelper.getData().company.color, 0.2);
        }
    }

    async setDrawerBasedOnDevice() {
        if (window.screen.width < 1360) this.setState({ openDrawer: false, buttonHeight: 95 })
    }

    hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
    
        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    handleDrawer = () => {
        this.setState({ openDrawer: !this.state.openDrawer })
    }

    handleRouteChange() {
        setTimeout(() => {
            this.setState({ actualRoute: window.location.pathname, openDrawer: window.screen.width < 1080 && this.state.openDrawer == true ? false : this.state.openDrawer });
            sessionStorage.setItem(`grid_search_${window.location.pathname.replace('/', '')}`, '');
            sessionStorage.setItem(`grid_order_${window.location.pathname.replace('/', '')}`, '');
        }, 10)
    }

    handleClick(menu) {
        let state = this.state;
        state[menu] = !state[menu];

        this.setState(state);
    }

    paymentCheck() {
        return SessionHelper.getData().company.subscription.status === 'canceled' || (SessionHelper.getData().company.subscription.current_transaction.status === 'canceled' || SessionHelper.getData().company.subscription.current_transaction.status === 'failed') ? false : true;
    }

    getRoutePermission(hasPermition, route) {
        let granted = null;

        let permConditions = SessionHelper.getData().type === 'admin' || SessionHelper.getData().type === 'consultant' || (SessionHelper.getData().permission && SessionHelper.getData().permission.routes[route]);

        if (!hasPermition || permConditions) {
            granted = true
        }

        return granted;
    }

    getDrawerListItemPermission(hasPermition, route, iconComponent, label, level) {
        if (!level) level = 1;

        let component = null;

        let permConditions = SessionHelper.getData().type === 'admin' || SessionHelper.getData().type === 'consultant' || (SessionHelper.getData().permission && SessionHelper.getData().permission.routes[route]);

        if (!hasPermition || permConditions) {
            component = (
                <ListItem className={this.state.actualRoute === `/${route}` ? 'menuSelected' : 'menuNotSelected'} onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === `/${route}` && true} component={Link} button to={`/${route}`} style={this.state.actualRoute === `/${route}` ? { backgroundColor: Colors.primary, color: Colors.selected, paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight } : { color: Colors.notSelected, paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight }}>
                    <ListItemIcon><SvgIcon component={iconComponent} style={this.state.actualRoute === `/${route}` ? { color: Colors.selected } : { color: Colors.notSelected }} /></ListItemIcon>
                    <ListItemText style={this.state.actualRoute === `/${route}` ? { color: Colors.selected } : { color: Colors.notSelected }} primary={this.state.openDrawer ? label : ''} />
                </ListItem>
            );
        }

        return component;
    }

    getLinkListItem(onClick, iconComponent, label, level, image) {
        if (!level) level = 1;

        let component = (
            <ListItem onClick={onClick} style={{ paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight, color: Colors.notSelected }} component={Link} button>
                <ListItemIcon>{!image && iconComponent ? <SvgIcon component={iconComponent} style={{ color: Colors.notSelected }} /> : <img src={process.env.PUBLIC_URL + image} style={{ height: 24 }}></img>}</ListItemIcon>
                <ListItemText primary={this.state.openDrawer ? label : ''} />
            </ListItem>
        );

        return component;
    }

    getCollapseListPermission(hasPermition, routes, iconComponent, stateControl, label, level) {
        if (!level) level = 1;

        let component = null;

        let baseConditions = SessionHelper.getData().type === 'admin' || SessionHelper.getData().type === 'consultant' || SessionHelper.getData().permission;
        let hasPermInAtLeastOneRoute = false;

        if (hasPermition) {
            routes.forEach(route => {
                if (SessionHelper.getData().permission && SessionHelper.getData().permission.routes[route]) hasPermInAtLeastOneRoute = true;
            });
        }

        if (!hasPermition || (baseConditions && hasPermInAtLeastOneRoute)) {
            component = (
                <ListItem style={{ color: Colors.notSelected, paddingLeft: this.state.openDrawer ? 16 * level : 16, height: this.state.buttonHeight, backgroundColor: this.state[stateControl] ? Colors.contrast : Colors.dark, color: this.state[stateControl] ? Colors.selected : Colors.notSelected }} selected={this.state[stateControl] ? true : false} button onClick={() => { this.handleClick(stateControl) }}>
                    <ListItemIcon>
                        <SvgIcon component={iconComponent} style={{ color: this.state[stateControl] ? Colors.selected : Colors.notSelected }} />
                    </ListItemIcon>
                    <ListItemText primary={this.state.openDrawer ? label : ``} />
                    {this.state[stateControl] ? <ExpandMore /> : <ChevronRight />}
                </ListItem>
            );
        }

        return component;
    }

    aboutModal() {
        if (this.state.aboutModal) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img style={{ height: 65, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8 }} src={process.env.PUBLIC_URL + '/logo-dark.png'} />
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 10 }}>
                            {`Licensiado para `}
                            <strong>{SessionHelper.getData().company.name}</strong>
                            {/* <div style={{ paddingBottom: 15 }}>Plano <strong>{SessionHelper.getData().plan.name}</strong></div> */}
                            <div style={{ borderBottom: '1px solid lightgrey', marginLeft: 'auto', marginRight: 'auto', paddingBottom: 15, width: '70%' }}></div>
                        </div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 25 }}>Orçamento Matricial | Versão {Platform.version}</div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>Copyright © 2021 Todos os direitos reservados</div>

                        <div style={{ fontSize: 11, color: 'grey', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>Desenvolvido com <FavoriteIcon style={{ marginLeft: 5, marginRight: 5, color: Colors.contrast }} /> por <a target={'_blank'} href={'https://easydata.net.br/'} style={{ paddingLeft: 3, color: 'blue', color: Colors.primary }}>EasyData Softwares LTDA</a></div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>CNPJ 35.698.234/0001-39</div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ aboutModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async sendMessage() {

        try {

            this.setState({ loadingModal: true });

            let body = {
                "id_company": SessionHelper.getData().id_company,
                "id_user": SessionHelper.getFirebaseAuth().uid,
                "subject": this.state.subject,
                "message": this.state.message,
                "files": [],
            };
            
            await Functions.request('POST', 'support/sendMessage', body);

            toast.success('Recebemos a sua mensagem! Vamos lhe retornar o mais breve possível.');
            this.setState({ loadingModal: false, helpModal: false });

        } catch (error) {

            this.setState({ loadingModal: true });
            toast.error('Houve um problema ao enviar a mensagem');
        }
    }

    helpModal() {
        if (this.state.helpModal) {
            return (
                <div>
                    <div style={{ paddingBottom: 20 }}>{'Envie a sua mensagem e em breve entraremos em contato para solucionar o seu problema.'}</div>
                    <DefaultInput onChange={(text) => { this.setState({ subject: text }) }} label={'Assunto'} />
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginTop: 20 }} rowsMax={8} rowsMin={8} onChange={(v) => { this.setState({ message: v.target.value }) }} placeholder="Escreva sua mensagem..." />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.sendMessage() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'ENVIAR MENSAGEM'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" style={
                    this.state.openDrawer == false ?
                    {
                        zIndex: 100,
                        justifyContent: 'space-between',
                        width: `100%`,
                        marginLeft: 0,
                        backgroundColor: Colors.primary,
                        transition: transitions.create(['width', 'margin'], {
                            easing: transitions.easing.sharp,
                            duration: transitions.duration.leavingScreen,
                        }),
                    } :
                    {
                        zIndex: 100,
                        justifyContent: 'space-between',
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: drawerWidth,
                        backgroundColor: Colors.primary,
                        transition: transitions.create(['width', 'margin'], {
                            easing: transitions.easing.sharp,
                            duration: transitions.duration.enteringScreen,
                        })
                    }
                }>
                    <Toolbar style={{ width: '100%', justifyContent: 'space-between', height: 64 }}>
                        <IconButton
                            aria-label="Gaveta Aberta"
                            onClick={this.handleDrawer}
                            edge="start"
                            style={styles.iconButtonAppBar}
                        >
                            {this.state.openDrawer ? <ChevronLeft /> : <MenuIcon />}
                        </IconButton>
                        {SessionHelper.getData().company.logo && <img style={{ height: 38 }} src={process.env.PUBLIC_URL + '/logo.png'} />}
                        <div style={{ display: "flex", flexDirection: 'row', marginRight: -15 }}>
                            { SessionHelper.getData().type === 'admin' || SessionHelper.getData().type === 'consultant' ? <Tooltip title={'Trocar Empresa'}><IconButton onClick={() => { localStorage.removeItem('id_company'); window.location.reload(); }}><SwapHorizIcon style={{ color: '#fff' }} /></IconButton></Tooltip> : null }
                            <NotificationCenter />
                            <ProfileCard showLogout={true} />
                        </div>
                    </Toolbar>
                </AppBar>
                <BrowserRouter forceRefresh={false}>
                    <nav id={'navbar'} className={this.state.openDrawer ? 'menu-open' : 'menu-closed'} style={{ width: this.state.openDrawer ? drawerWidth : closedDrawerWidth, flexShrink: 0, }} aria-label="mailbox folders">
                        <Drawer
                            PaperProps={{ style: this.state.openDrawer ? styles.drawerOpen : styles.drawerClose }}
                            variant="permanent"

                        >
                            <div>
                                <div />
                                <Divider />
                                <List>
                                    <ListItem style={{ justifyContent: 'center', position: 'fixed', top: 0, left: 0, width: 240, height: 64, backgroundColor: `rgba(34, 38, 46, 0.85)`, zIndex: 99 }} onClick={() => { this.handleRouteChange() }} component={Link} button to={'/'}>
                                        {!SessionHelper.getData().company.logo ? <img style={{ height: 38 }} src={process.env.PUBLIC_URL + '/logo.png'} /> : <img style={{ height: 38 }} src={SessionHelper.getData().company.logo} />}
                                    </ListItem>
                                    <div style={{ height: 64 }} />

                                    {this.getDrawerListItemPermission(false, ``, DashboardIcon, `Dashboard`)}
                                    {this.getDrawerListItemPermission(true, `responsability_matrix`, GridOnIcon, `Matriz de Responsabilidades`)}
                                    {this.getDrawerListItemPermission(true, `chart_builder`, DonutSmallIcon, `Gerador de Gráficos`)}

                                    {this.getCollapseListPermission(false, [], AssessmentIcon, 'planningMenuOpen', 'Planejamento')}
                                    <Collapse in={this.state.planningMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `period`, DateRangeIcon, `Períodos`, 2)}
                                            {this.getDrawerListItemPermission(true, `revenue_planning_settings`, ListAltIcon, `Modelos de Receitas`, 2)}
                                            {this.getDrawerListItemPermission(true, `revenue_planning`, AttachMoneyIcon, `Receitas`, 2)}
                                            {this.getDrawerListItemPermission(true, `deductions`, MoneyOffIcon, `Deduções`, 2)}
                                            {this.getDrawerListItemPermission(true, `costs`, LocalAtmIcon, `Custos`, 2)}
                                            {this.getDrawerListItemPermission(true, `expenses`, PaymentIcon, `Despesas`, 2)}
                                            {this.getDrawerListItemPermission(true, `people_expenses`, RecentActorsIcon, `Despesas de Pessoal`, 2)}
                                            {this.getDrawerListItemPermission(true, `expenses_and_revenue`, MonetizationOnIcon, `Despesas e Receitas Financeiras`, 2)}
                                            {this.getDrawerListItemPermission(true, `other_expenses_and_revenue`, MoneyIcon, `Outras Despesas e Receitas`, 2)}
                                            {/* TODO: Routes and permissions */}
                                            {this.getDrawerListItemPermission(true, `taxes`, GavelIcon, `Impostos`, 2)}
                                            {/* {this.getDrawerListItemPermission(false, `investments`, TimelineIcon, `Investimentos`, 2)} */}
                                            {this.getDrawerListItemPermission(true, `budget_resume`, BubbleChartIcon, `Resumo do Orçamento`, 2)}
                                            {/* {this.getDrawerListItemPermission(false, `scenarios`, PlaylistPlayIcon, `Cenários`, 2)}
                                            {this.getDrawerListItemPermission(false, `approval`, AssignmentTurnedInIcon, `Aprovação`, 2)} */}
                                            {/* END TODO */}
                                        </List>
                                    </Collapse>

                                    {/* TODO: Routes and permissions */}
                                    {this.getCollapseListPermission(false, [], DonutLargeIcon, 'budgetManagementMenuOpen', 'Gestão Orçamentária')}
                                    <Collapse in={this.state.budgetManagementMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `budget_import`, DescriptionIcon, `Importar Orçamento`, 2)}
                                            {this.getDrawerListItemPermission(true, `balancete`, DescriptionIcon, `Balancete`, 2)}
                                            {this.getDrawerListItemPermission(true, `orcado_versus_accomplished`, CompareArrowsIcon, `Orçado X Realizado`, 2)}
                                            {this.getDrawerListItemPermission(true, `action_plan`, EmojiObjectsIcon, `Planos de Ação`, 2)}
                                        </List>
                                    </Collapse>
                                    {/* END TODO */}

                                    {this.getCollapseListPermission(false, [], SettingsIcon, 'settingsMenuOpen', 'Configurações')}
                                    <Collapse in={this.state.settingsMenuOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {this.getDrawerListItemPermission(true, `company`, BusinessIcon, `Empresa`, 2)}
                                            {this.getDrawerListItemPermission(true, `permission`, LockIcon, `Permissões`, 2)}
                                            {this.getDrawerListItemPermission(true, `user`, PeopleIcon, `Usuários`, 2)}
                                            {this.getDrawerListItemPermission(true, `result_center`, TrendingUpIcon, `Centros de Resultado`, 2)}

                                            {this.getCollapseListPermission(false, ['account_plan', 'account_package'], AccountBalanceIcon, 'accountsMenuOpen', 'Contas', 2)}
                                            <Collapse in={this.state.accountsMenuOpen} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {this.getDrawerListItemPermission(true, `account_plan`, AccountTreeIcon, `Plano de Contas`, 3)}
                                                    {this.getDrawerListItemPermission(true, `account_package`, AccountBalanceWalletIcon, `Pacotes de Contas`, 3)}
                                                    {this.getDrawerListItemPermission(true, `budget_structure`, LayersIcon, `Estrutura do Orçamento`, 3)}
                                                </List>
                                            </Collapse>

                                            {this.getDrawerListItemPermission(true, `product`, LocalMallIcon, `Produtos e Serviços`, 2)}
                                            {this.getDrawerListItemPermission(true, `employee`, WorkIcon, `Colaboradores`, 2)}
                                            {this.getDrawerListItemPermission(true, `role`, BuildIcon, `Cargos`, 2)}
                                            {this.getDrawerListItemPermission(true, `dissidio`, EventNoteIcon, `Dissídio`, 2)}
                                            {this.getDrawerListItemPermission(true, `tag`, LocalOfferIcon, `Tags`, 2)}
                                        </List>
                                    </Collapse>

                                    {this.getLinkListItem(() => { this.setState({ aboutModal: true }) }, InfoIcon, `Sobre`)}
                                    {this.getLinkListItem(() => { this.setState({ helpModal: true }) }, HeadsetMicIcon, `Suporte`)}
                                </List>
                            </div>
                        </Drawer>
                    </nav>

                    <main style={{ flexGrow: 1, paddingTop: 60 }}>
                        <Switch>
                            <Route exact path="/" render={() => <HomePage />} />
                            {this.getRoutePermission(true, 'user') ? <Route path="/user" render={() => <UserPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'company') ? <Route path="/company" render={() => <CompanyPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'result_center') ? <Route path="/result_center" render={() => <ResultCenterPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'revenue_planning') ? <Route path="/revenue_planning" render={() => <RevenuePlanning menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'product') ? <Route path="/product" render={() => <ProductPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'account_plan') ? <Route path="/account_plan" render={() => <AccountPlanPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'account_package') ? <Route path="/account_package" render={() => <AccountPackagePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'permission') ? <Route path="/permission" render={() => <PermissionPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'revenue_planning_settings') ? <Route path="/revenue_planning_settings" render={() => <RevenuePlanningSettings menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'responsability_matrix') ? <Route path="/responsability_matrix" render={() => <ResponsabilityMatrixPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'deductions') ? <Route path="/deductions" render={() => <DeductionPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'costs') ? <Route path="/costs" render={() => <CostsPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'expenses') ? <Route path="/expenses" render={() => <ExpensesPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'people_expenses') ? <Route path="/people_expenses" render={() => <PeopleExpensesPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'expenses_and_revenue') ? <Route path="/expenses_and_revenue" render={() => <ExpensesAndRevenuePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'other_expenses_and_revenue') ? <Route path="/other_expenses_and_revenue" render={() => <OtherExpensesAndRevenuePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'period') ? <Route path="/period" render={() => <PeriodPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'role') ? <Route path="/role" render={() => <RolePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'employee') ? <Route path="/employee" render={() => <EmployeePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'dissidio') ? <Route path="/dissidio" render={() => <DissidioPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'taxes') ? <Route path="/taxes" render={() => <TaxPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'balancete') ? <Route path="/balancete" render={() => <BalancetePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'budget_import') ? <Route path="/budget_import" render={() => <BudgetImportPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'chart_builder') ? <Route path="/chart_builder" render={() => <ChartBuilderPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'budget_structure') ? <Route path="/budget_structure" render={() => <BudgetStructurePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'budget_resume') ? <Route path="/budget_resume" render={() => <BudgetSummaryPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'orcado_versus_accomplished') ? <Route path="/orcado_versus_accomplished" render={() => <BudgetVersusAccomplishedPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'action_plan') ? <Route path="/action_plan" render={() => <ActionPlanPage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(false, 'action_plan_evaluate') ? <Route path="/action_plan_evaluate" render={() => <ActionPlanEvaluatePage menuOpen={this.state.openDrawer} />} /> : null}
                            {this.getRoutePermission(true, 'tag') ? <Route path="/tag" render={() => <TagPage menuOpen={this.state.openDrawer} />} /> : null}

                            <Route path="/404" render={() => <NotFoundPage height={'calc(100vh - 60px)'} />} />
                            <Redirect to="/404" />
                        </Switch>
                    </main>
                </BrowserRouter>
                <ToastContainer style={{ paddingTop: 100 }} />
                <DefaultModal loading={this.state.loadingModal} content={this.aboutModal()} title={'Sobre'} onClose={() => { this.setState({ aboutModal: false }) }} open={this.state.aboutModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.helpModal()} title={'Suporte'} onClose={() => { this.setState({ helpModal: false }) }} open={this.state.helpModal} />
            </div>
        )
    }
}

const styles = {
    iconButtonAppBar: {
        color: Colors.secondary,
    },
    drawerOpen: {
        zIndex: 99,
        width: drawerWidth,
        backgroundColor: Colors.dark,
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        zIndex: 99,
        backgroundColor: Colors.dark,
        transition: transitions.create('width', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: closedDrawerWidth
    }
}
