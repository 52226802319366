import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import LoginPage from '../page/LoginPage';
import PasswordResetPage from '../page/PasswordResetPage';
import NotFoundPage from '../page/NotFoundPage';
export default class LoginNavigator extends Component {
    render() {
        return (
            <BrowserRouter forceRefresh={true}>
                <Switch>
                    <Route exact path="/" render={() => <LoginPage />} />
                    <Route path="/password_reset" render={() => <PasswordResetPage />} />
                    <Route path="/404" render={() => <NotFoundPage height={'calc(100vh - 60px)'} />} />
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        )
    }
}
