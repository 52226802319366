import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { FormLabel, FormGroup, Select, MenuItem, Input } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';
import SessionHelper from '../helper/SessionHelper';
import Functions from '../api/firebase/Functions'
import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';

export default class UserPage extends Component {

    state = {
        docs: [],
        permissionDocs: [],
        employeeDocs: [],
        name: '',
        email: '',
        employee: null,
        id_permission_group: null,
        loading: true,
        loadingModal: false,
        addModal: false,
        editId: null,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getPermissions() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ permissionDocs: docs, loading: false });
    }

    async getEmployee() {
        let query = Firestore.customQuery('employee').where('id_company', '==', SessionHelper.getData().id_company).where('active', '==', true).orderBy('name', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ employeeDocs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
        await this.getPermissions();
        await this.getEmployee();
    }

    async checkEmailExists(email) {
        
        const query = await Firestore.customQuery('user').where('email', '==', email).get();

        if (query.docs.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    async addUser() {

        if (this.state.name && this.state.email && this.state.id_permission_group) {

            if (!(await this.checkEmailExists(this.state.email))) {

                let data = {
                    name: this.state.name,
                    email: this.state.email.replace(/\s/g, ""),
                    employee: this.state.employee,
                    id_permission_group: this.state.id_permission_group,
                    type: 'admin',
                    id_company: SessionHelper.getData().id_company,
                    active: false,
                };
    
                try {
    
                    this.setState({ loadingModal: true });
    
                    let response = await Functions.request('POST', 'user/register', data);

                    if (response.status == 200 && response.uid) {

                        let emailResponse = await Functions.request('POST', 'user/sendPasswordResetEmail', { uid: response.uid });
    
                        if (emailResponse.status === 200) {

                            toast.success("Usuário cadastrado com sucesso. Enviado e-mail para definição da senha.", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                        } else {

                            throw new Error(emailResponse.message);
                        }

                        await this.setState({ loadingModal: false, addModal: false, name: '', email: '', employee: null, id_permission_group: '' });
                        await this.getDocs();
    
                    } else {
                        
                        throw new Error(response.message);
                    }
    
                } catch (error) {
                    toast.error("Houve um problema ao cadastrar", {
                        position: toast.POSITION.TOP_RIGHT
                    });
    
                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('O e-mail informado já existe em outro usuário');
            }

        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    getPermissionLookup() {
        let permissions = this.state.permissionDocs;
        let lookup = {};

        permissions.forEach((permission, key) => {
            lookup[permission.id] = permission.name;
        });

        return lookup;
    }

    getEmployeeLokup() {
        let employees = this.state.employeeDocs;
        let lookup = {};

        employees.forEach((employee, key) => {
            lookup[employee.id] = employee.name;
        });

        return lookup;
    }

    addModal() {
        return (
            <div>
                <DefaultInput label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                <DefaultInput label={'E-mail'} onBlur={(v) => { this.setState({ email: v }) }} />
                <div style={{ marginTop: 10 }}>
                    <DefaultSelect
                        search={true}
                        searchField={'name'}
                        id={'employee_select'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.employee}
                        onChange={(v) => {
                            this.setState({ employee: v.target.value })
                        }}
                        docs={this.state.employeeDocs}
                        label={'Colaborador'}
                    />
                </div>
                <div style={{ paddingTop: 18, paddingBottom: 8 }}>
                    <FormLabel component="legend">Grupo de Permissão</FormLabel>
                    <FormGroup>
                        <Select
                            onChange={(evt) => { this.setState({ id_permission_group: evt.target.value }) }}
                            input={<Input />}>

                            {this.state.permissionDocs.map((permission, key) => (
                                <MenuItem key={permission.id} value={permission.id}>
                                    {permission.name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormGroup>
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addUser() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    async sendPasswordEmail(id) {

        let confirm = window.confirm('Tem certeza que deseja enviar?');

        if (confirm) {

            this.setState({ loading: true });

            let emailResponse = await Functions.request('POST', 'user/sendPasswordResetEmail', { uid: id });
    
            if (emailResponse.status === 200) {
    
                toast.success("E-mail enviado com sucesso.", {
                    position: toast.POSITION.TOP_RIGHT
                });
    
            } else {
    
                toast.error('Houve um problema ao enviar o e-mail');
            }
    
            this.setState({ loading: false });
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Usuários'}
                    marginTop={10}
                    actions={[
                        rowData => rowData.active ? null : {
                            icon: EmailIcon,
                            tooltip: 'Enviar e-mail para finalização de cadastro',
                            onClick: async (event, rowData) => { this.sendPasswordEmail(rowData.id) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'E-mail', field: 'email', editable: false },
                        { title: 'Colaborador', field: 'employee', lookup: this.getEmployeeLokup() },
                        { title: 'Grupo de Permissão', field: 'id_permission_group', lookup: this.getPermissionLookup() },
                        { title: 'Ativo', field: 'active', type: 'boolean', },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;

                        try {
                            if (oldData.id) {

                                let data = {
                                    name: newData.name,
                                    email: newData.email,
                                    employee: newData.employee,
                                    id_permission_group: newData.id_permission_group,
                                    active: newData.active,
                                };

                                await Firestore.update(data, 'user', oldData.id);
                            }

                            toast.success("Editado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            prev[prev.indexOf(oldData)] = newData;

                            this.setState({ docs: prev });
                        } catch (e) {
                            toast.error("Erro ao Editar", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let response = await Functions.request('POST', 'deleteUser', { uid: oldData.id });

                            if (response.status == 200) {
                                await Firestore.delete('user', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;

                            } else {

                                toast.error("Erro ao remover", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Usuário'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
