export default {
    // Generic
    "generic.add": "Adicionar",
    "generic.cancel": "Cancelar",
  
    // BlockType
    "components.controls.blocktype.h1": "H1",
    "components.controls.blocktype.h2": "H2",
    "components.controls.blocktype.h3": "H3",
    "components.controls.blocktype.h4": "H4",
    "components.controls.blocktype.h5": "H5",
    "components.controls.blocktype.h6": "H6",
    "components.controls.blocktype.blockquote": "Citação",
    "components.controls.blocktype.code": "Código",
    "components.controls.blocktype.blocktype": "Tipo do Bloco",
    "components.controls.blocktype.normal": "Normal",
  
    // Color Picker
    "components.controls.colorpicker.colorpicker": "Seletor de Cor",
    "components.controls.colorpicker.text": "Texto",
    "components.controls.colorpicker.background": "Destaque",
  
    // Embedded
    "components.controls.embedded.embedded": "Integrado",
    "components.controls.embedded.embeddedlink": "Link Integrado",
    "components.controls.embedded.enterlink": "Link",
  
    // Emoji
    "components.controls.emoji.emoji": "Emoji",
  
    // FontFamily
    "components.controls.fontfamily.fontfamily": "Fonte",
  
    // FontSize
    "components.controls.fontsize.fontsize": "Tamanho da Fonte",
  
    // History
    "components.controls.history.history": "Histórico",
    "components.controls.history.undo": "Desfazer",
    "components.controls.history.redo": "Refazer",
  
    // Image
    "components.controls.image.image": "Imagem",
    "components.controls.image.fileUpload": "Upload de Arquivo",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText": "Arraste o arquivo ou clique para fazer o upload",
  
    // Inline
    "components.controls.inline.bold": "Negrito",
    "components.controls.inline.italic": "Italico",
    "components.controls.inline.underline": "Sublinhado",
    "components.controls.inline.strikethrough": "Tachado",
    "components.controls.inline.monospace": "Código",
    "components.controls.inline.superscript": "Sobrescrito",
    "components.controls.inline.subscript": "Subscrito",
  
    // Link
    "components.controls.link.linkTitle": "Título do Link",
    "components.controls.link.linkTarget": "Abrir link em",
    "components.controls.link.linkTargetOption": "Abrir link em uma nova janela",
    "components.controls.link.link": "Link",
    "components.controls.link.unlink": "Remover link",
  
    // List
    "components.controls.list.list": "Lista",
    "components.controls.list.unordered": "Lista",
    "components.controls.list.ordered": "Lista Numérica",
    "components.controls.list.indent": "Identar",
    "components.controls.list.outdent": "Desidentar",
  
    // Remove
    "components.controls.remove.remove": "Remover",
  
    // TextAlign
    "components.controls.textalign.textalign": "Alinhamento",
    "components.controls.textalign.left": "Esquerda",
    "components.controls.textalign.center": "Centro",
    "components.controls.textalign.right": "Direita",
    "components.controls.textalign.justify": "Justificar"
  };