import React, { Component, forwardRef } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DeleteModal from '../components/DeleteModal';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';
import CurrencyHelper from '../helper/CurrencyHelper';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';

export default class PermissionPage extends Component {

    state = {
        docs: [],
        name: '',
        routes: {
            responsability_matrix: true,
            period: true,
            revenue_planning_settings: true,
            revenue_planning: true,
            deductions: true,
            costs: true,
            expenses: true,
            people_expenses: true,
            expenses_and_revenue: true,
            other_expenses_and_revenue: true,
            taxes: true,
            budget_resume: true,
            budget_import: true,
            balancete: true,
            orcado_versus_accomplished: true,
            action_plan: true,
            chart_builder: true,
            company: true,
            permission: true,
            user: true,
            result_center: true,
            account_plan: true,
            account_package: true,
            budget_structure: true,
            product: true,
            role: true,
            employee: true,
            dissidio: true,
            tag: true,
        },
        routesSubtitle: {
            responsability_matrix: "Matriz de Responsabilidade",
            period: "Planejamento ↳ Períodos",
            revenue_planning_settings: "Planejamento ↳ Modelos de Receitas",
            revenue_planning: "Planejamento ↳ Receitas",
            deductions: "Planejamento ↳ Deduções",
            costs: "Planejamento ↳ Custos",
            expenses: "Planejamento ↳ Despesas",
            people_expenses: "Planejamento ↳ Despesas de Pessoal",
            expenses_and_revenue: "Planejamento ↳ Despesas e Receitas Financeiras",
            other_expenses_and_revenue: "Planejamento ↳ Outras Despesas e Receitas",
            taxes: "Planejamento ↳ Impostos",
            budget_resume: "Planejamento ↳ Resumo do Orçamento",
            budget_import: "Gestão Orçamentária ↳ Importar Orçamento",
            balancete: "Gestão Orçamentária ↳ Balancete",
            orcado_versus_accomplished: "Gestão Orçamentária ↳ Orçado X Realizado",
            action_plan: "Gestão Orçamentária ↳ Planos de Ação",
            chart_builder: "Gráfico e Análises ↳ Gerador de Gráficos",
            company: "Configurações ↳ Empresa",
            user: "Configurações ↳ Usuário",
            permission: "Configurações ↳ Contas ↳  Permissões",
            result_center: "Configurações ↳ Centros de Resultado",
            account_plan: "Configurações ↳ Contas ↳ Plano de Contas",
            account_package: "Configurações ↳ Contas ↳ Pacotes de Contas",
            budget_structure: "Configurações ↳ Contas ↳ Estrutura do Orçamento",
            product: "Configurações ↳ Produtos e Serviços",
            employee: "Configurações ↳ Colaboradores",
            role: "Configurações ↳ Cargos",
            dissidio: "Configurações ↳ Dissídio",
            tag: "Configurações ↳ Tags",
        },
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let routes = doc.data().routes;

            let data = {
                name: doc.data().name,
                id: doc.id
            };

            Object.keys(this.state.routes).forEach(key => {
                data[key] = routes[key]
            })

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addPermission() {

        if (this.state.name && this.state.routes) {

            let data = {
                name: this.state.name,
                routes: this.state.routes,
                id_company: SessionHelper.getData().id_company,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'permission_group');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: '' });
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                <div style={{ paddingTop: 18 }}>
                    <FormLabel component="legend">Menus</FormLabel>
                    <FormGroup>
                        {Object.keys(this.state.routes).map(key =>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.routes[key]} onChange={(v) => { let routes = this.state.routes; routes[key] = v.target.checked; this.setState({ routes: routes }) }} />}
                                label={this.state.routesSubtitle[key]}
                            />
                        )}
                    </FormGroup>
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addPermission() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    getColumns() {
        let columns = [
            { title: 'Id', field: 'id', hidden: true },
            { title: 'Nome', field: 'name' }
        ];

        Object.keys(this.state.routes).forEach(key => {
            columns.push({ title: this.state.routesSubtitle[key], type: 'boolean', field: key });
        });

        return columns;
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Grupo de Permissões'}
                    marginTop={10}
                    columns={this.getColumns()}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        let prev = this.state.docs;
                        const index = prev.findIndex(item => item.id === oldData.id);
                        prev[index] = newData;

                        this.setState({ docs: prev });
                        this.forceUpdate();

                        if (oldData.id) {

                            let data = {
                                name: newData.name,
                                routes: {}
                            };

                            Object.keys(this.state.routes).forEach(key => {
                                data.routes[key] = newData[key] ? newData[key] : false;
                            });

                            await Firestore.update(data, 'permission_group', oldData.id);

                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {
                            await Firestore.delete('permission_group', oldData.id);
                        }

                        this.forceUpdate();

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Grupo de Permissão'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
