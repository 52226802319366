import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CalculusHelper from '../helper/CalculusHelper';
import TableHelper from '../helper/TableHelper';

export default class ManualInputTable extends Component {

    getCellType() {

        const path = window.location.pathname.replace('/', '');

        let text = 'Valor';

        if (path === 'revenue_planning') {

            text = 'Receita';
        
        } else if (path === 'deductions') {

            text = 'Dedução';

        } else if (path === 'costs') {

            text = 'Custo';

        } else if (path === 'expenses') {

            text = 'Despesa';

        } else if (path === 'people_expenses') {

            text = 'Despesa de Pessoal';

        } else if (path === 'expenses_and_revenue') {

            text = 'Despesa';

        } else if (path === 'other_expenses_and_revenue') {

            if (this.props.moreIsBetter) {
             
                text = 'Receita';

            } else {
                
                text = 'Despesa';
            }
        }

        return text;
    }

    render() {
        let table;

        let rows = this.props.rows;

        let plannedCounter = 0;
        let historyCounter = 0;

        table = (
            <div style={styles.tableContainer}>
                <TableContainer>
                    <Table id={'revenue-planning'}>
                        {TableHelper.getTableHeader(rows, true, true)}
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>{this.getCellType()} (R$)</TableCell>
                                {rows.map((period, key) => {
                                    plannedCounter += period.value;
                                    return <TableCell align={this.props.state.editingData && this.props.state.rowId == period.id ? "center" : "right"} onDoubleClick={!this.props.readOnly ? () => { this.props.setParentState({ editingData: true, rowId: period.id, editingRows: rows, editingField: 'value', editInputValue: period.value }); } : () => { }}>
                                        {this.props.state.editingData && this.props.state.rowId == period.id && this.props.state.editingField == 'value' ? this.props.renderInputEditing(period.value) : CalculusHelper.formatFloat(period.value)}
                                    </TableCell>
                                })}
                                {TableHelper.getMeanCell('money', plannedCounter, rows, 'value')}
                                {TableHelper.getTotalCell('money', plannedCounter)}
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Histórico (R$)</TableCell>
                                {rows.map((period, key) => {
                                    historyCounter += period.value_history;
                                    return <TableCell align={this.props.state.editingData && this.props.state.rowId == period.id ? "center" : "right"}>
                                        {this.props.state.editingData && this.props.state.rowId == period.id && this.props.state.editingField == 'value_history' ? this.props.renderInputEditing(period.value_history) : CalculusHelper.formatFloat(period.value_history)}
                                    </TableCell>
                                })}
                                {TableHelper.getMeanCell('money', historyCounter, rows, 'value_history')}
                                {TableHelper.getTotalCell('money', historyCounter)}
                            </TableRow>

                            {TableHelper.getVariationRow(
                                rows,
                                false,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.value,
                                        period.value_history,
                                        false
                                    );
                                },
                                true,
                                true
                            )}
                            {TableHelper.getVariationRow(
                                rows,
                                true,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.value,
                                        period.value_history,
                                        true
                                    );
                                },
                                true,
                                true
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        )

        return table;
    }
}

const styles = {
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    }
}