import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Box, Button, FormLabel, FormControlLabel, Tabs, Tab, Checkbox, List, ListItem, IconButton, Divider, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import moment from 'moment';
import 'moment/locale/pt-br';
import TableChartIcon from '@material-ui/icons/TableChart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PeriodSelection from '../components/PeriodSelection';
import KeyboardShortcutsTooltip from '../components/KeyboardShortcutsTooltip';
import DatasetHelper from '../helper/DatasetHelper';
import TableHelper from '../helper/TableHelper';
import PercentageTable from '../components/PercentageTable';
import TableEditingInput from '../components/TableEditingInput';
import CostsTable from '../components/CostsTable';
import ManualInputTable from '../components/ManualInputTable';
import RevenueTable from '../components/RevenueTable';
import PermissionHelper from '../helper/PermissionHelper';
import DataHelper from '../helper/DataHelper';
import Operators from '../constants/Operators';
import Methods from '../constants/Methods';
import PlanningHelper from '../helper/PlanningHelper';
import PlanningRowHelper from '../helper/PlanningRowHelper';
import AddIcon from '@material-ui/icons/Add';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import Functions from '../api/firebase/Functions';

const TYPE = 'expenses';
export default class ExpensesPage extends Component {
    state = {
        tab: 0,
        docs: [],
        auxDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        editId: null,
        editDoc: {},
        editing: false,
        accountPlanDocs: [],
        resultCenterDocs: [],
        productsDocs: [],
        periodDocs: [],
        editInputIndex: 0,
        editInputValue: 0,
        editingData: false,
        savingEdit: false,
        operator: Operators.minus.key,
        editingDataDoc: {},
        editingField: ``,
        description: ``,
        method: ``,
        loadingText: 'Carregando Despesas...',
        start: moment(),
        end: moment().add(3, 'month'),
        id_period: null,
        applyToAllMonths: false,
        targetAccountPlan: null,
        products: [],
        expensesData: {},
        resultCenter: [],
        removeResultCenterId: ``,
        removeResultCenterModal: false,
        addResultCenterModal: false,
        addResultCenterModalDocs: [],
        crSearch: '',
        accountType: 'budget_structure',
        budgetStructureDocs: [],
        selectedPeriod: [],
        loadingTable: false,
    }

    async getPlanningElement() {
        this.setState({ loading: true });

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');
        let query = Firestore.customQuery('planning').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', TYPE);

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (!data.account_type) {
                data.account_type = 'account';
            }

            if (data.id_result_center && !data.id_result_centers) {
                data.id_result_centers = [data.id_result_center];
            }

            let hasResultCenter = false;

            if (data.id_result_centers) {

                data.id_result_centers.forEach((resultCenter, key) => {

                    if (this.state.resultCenterDocs.find(item => item.id === resultCenter)) {
                        hasResultCenter = true;
                    }
                });
            }

            if (hasResultCenter || this.state.accountPlanDocs.find(item => item.id === data.id_target_account_plan) || isSuperAdmin) {
                docs.push(data);
            }
        });

        this.setState({ docs: docs, auxDocs: docs, loading: false });

        await this.filterPlanningElement();
    }

    async filterPlanningElement() {

        this.setState({ loading: true });

        let docs = [];
        let start = null;
        let end = null;

        this.state.selectedPeriod.forEach((id, key) => {

            let period = this.state.periodDocs.find(item => item.id === id);
            let periodStart = moment(period.start.toDate ? period.start.toDate() : period.start).startOf('month').startOf('day');
            let periodEnd = moment(period.end.toDate ? period.end.toDate() : period.end).endOf('month').endOf('day');

            if (start === null || periodStart.isBefore(start)) {

                start = periodStart;
            }

            if (end === null || periodEnd.isAfter(end)) {

                end = periodEnd;
            }
        });

        if (start && end) {

            this.state.auxDocs.forEach((doc, key) => {

                let docStart = moment(doc.start.toDate ? doc.start.toDate() : doc.start);
                let docEnd = moment(doc.end.toDate ? doc.end.toDate() : doc.end);

                if (docStart.isSameOrAfter(start, 'date') && docEnd.isSameOrBefore(end, 'date')) {

                    docs.push(doc);
                }
            });

            this.setState({ docs, loading: false });

        } else {

            await this.getPlanningElement();
        }
    }

    async getPeriods() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        let selectedPeriod = [];
        selectedPeriod.push(docs[0].id);

        this.setState({ periodDocs: docs, loading: false, selectedPeriod });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async getBudgetStructure() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('budget_structure').where('id_company', '==', SessionHelper.getData().id_company).orderBy('order', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };

            docs.push(data);
        });

        this.setState({ budgetStructureDocs: docs });
    }

    async getAccountPlanDocs() {

        this.setState({ loading: true });

        let docs = await PermissionHelper.getUserAccountPlans(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        this.setState({ accountPlanDocs: docs, loading: false });
    }

    async getResultCenterDocs() {

        this.setState({ loading: true });

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        this.setState({ resultCenterDocs: docs, loading: false });
    }

    async getProductsDocs() {
        this.setState({ loading: true });

        let docs = await PermissionHelper.getUserProducts(SessionHelper.getFirebaseAuth().uid);

        this.setState({ productsDocs: docs, loading: false });
    }

    async getRevenueData(silent) {
        this.setState({ loading: silent ? false : true, loadingText: `Carregando...` });

        let revenueRows = [];

        if (this.state.editDoc.method == 'percentage') {

            let momentStart = moment(this.state.editDoc.start.toDate ? this.state.editDoc.start.toDate() : this.state.editDoc.start);
            let momentEnd = moment(this.state.editDoc.end.toDate ? this.state.editDoc.end.toDate() : this.state.editDoc.end);
            let months = [];

            while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {
                    
                months.push(momentStart.toDate());
                momentStart.add(1,'month');
            }

            let body = { id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, months: months, columns: ['budget', 'history'], account_type: this.state.editDoc.account_type, id_account_plan: this.state.editDoc.id_account_plan };
            let request = await Functions.request('POST', 'getAccountData', body);
            
            if (request) {

                if (this.state.editDoc.account_type === 'account') {
                    
                    let account = request.accounts.find(item => item.id === this.state.editDoc.id_account_plan);

                    if (account) {

                        for (let index = 0; index < months.length; index++) {
                                          
                            let value = account.revenue[index].value - account.deduction[index].value;
                            let operatorValue = PlanningRowHelper.getOperator(this.state.editDoc.type, value);
                            
                            let history = account.accomplished[index].value_history;
                            let operatorHistory = PlanningRowHelper.getOperator(this.state.editDoc.type, history);

                            history = operatorHistory === Operators.minus.key ? history * -1 : history * 1;
                            value = operatorValue === Operators.minus.key ? value * -1 : value * 1;

                            revenueRows.push({
                                date_label: moment(months[index]).format('MMM/YYYY'),
                                value: value,
                                value_history: history,
                            });
                        }
                    }

                } else if (this.state.editDoc.account_type === 'budget_structure') {

                    if (request.budgetStructure) {

                        let structure = request.budgetStructure.find(item => item.id === this.state.editDoc.id_account_plan);

                        if (structure) {

                            for (let index = 0; index < months.length; index++) {
                                
                                let value = structure.revenue[index].value - structure.deduction[index].value;
                                let operatorValue = PlanningRowHelper.getOperator(this.state.editDoc.type, value);
                                
                                let history = structure.accomplished[index].value_history;
                                let operatorHistory = PlanningRowHelper.getOperator(this.state.editDoc.type, history);
    
                                history = operatorHistory === Operators.minus.key ? history * -1 : history * 1;
                                value = operatorValue === Operators.minus.key ? value * -1 : value * 1;

                                revenueRows.push({
                                    date_label: moment(months[index]).format('MMM/YYYY'),
                                    value: value,
                                    value_history: history,
                                });
                            }
                        }
                    }
                }
            }
        }

        this.setState({ revenueRows, loading: false, loadingText: `Carregando...`, editing: true });
    }


    async getDocs() {
        await this.getBudgetStructure();
        await this.getAccountPlanDocs();
        await this.getResultCenterDocs();
        await this.getProductsDocs();
        await this.getPeriods();
        await this.getPlanningElement();
    }

    async addNewDoc() {
        if (this.state.loadingModal) return;

        if (this.state.description && moment(this.state.start).isValid() && moment(this.state.end).isValid() && SessionHelper.getData().id_company) {
            this.setState({ loadingModal: true });

            const start = moment(this.state.start);
            const end = moment(this.state.end);

            let insertInfo = await PlanningHelper.insertPlanning(
                {
                    type: TYPE,
                    description: this.state.description,
                    start: start.toDate(),
                    end: end.toDate(),
                    operator: this.state.operator,
                    table_type: TYPE,
                    method: this.state.method,
                    id_products: this.state.products,
                    id_target_account_plan: this.state.targetAccountPlan,
                    id_result_centers: this.state.resultCenter,
                    id_account_plan: this.state.accountPlan,
                    id_period: this.state.id_period,
                    account_type: this.state.accountType,
                }
            );

            if (insertInfo.success) {
                this.state.resultCenter.forEach((cr, key) => {
                    PlanningRowHelper.insertRow(
                        {
                            type: TYPE,
                            operator: this.state.operator,
                            method: this.state.method,
                            id_parent: insertInfo.insertId,
                            account_type: this.state.accountType,
                            relationship: {
                                id_target_account_plan: this.state.targetAccountPlan,
                                id_result_center: cr,
                                id_account_plan: this.state.accountPlan,
                                id_products: this.state.products
                            }
                        },
                        start,
                        end
                    );
                });
            }

            await this.getPlanningElement();
        } else {
            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false, addModal: false, description: ``, start: moment(), end: moment().add(3, 'month'), accountPlans: [], method: ''
        });
    }

    getAddResultCenterModalDocs = () => {
        let existingResultCenters = [];

        if (this.state.editDoc.id_result_centers) {

            this.state.editDoc.id_result_centers.forEach(id => {
                existingResultCenters.push(id);
            });    
        }
        
        let arrayToOrder = [];

        this.state.resultCenterDocs.forEach(doc => {
            if (!existingResultCenters.includes(doc.id)) {
                arrayToOrder.push(doc);
            }
        })

        let arrayToRender = DataHelper.orderArrayToRender(arrayToOrder, 'description');

        return arrayToRender;
    }

    addResultCenter = async () => {
        if (this.state.addResultCenterModalDocs.length < 1) return;

        try {
            this.setState({ loadingModal: true });

            let existingResultCenters = this.state.editDoc.id_result_centers ? this.state.editDoc.id_result_centers : [];
            let id_result_centers = [...existingResultCenters, ...this.state.addResultCenterModalDocs];
            await Firestore.update({ id_result_centers }, 'planning', this.state.editDoc.id);

            const start = moment(this.state.editDoc.start.toDate ? this.state.editDoc.start.toDate() : this.state.editDoc.start);
            const end = moment(this.state.editDoc.end.toDate ? this.state.editDoc.end.toDate() : this.state.editDoc.end);

            this.state.addResultCenterModalDocs.forEach(id_result_center => {
                this.addResultCenterToDataset(this.state.editDoc.id, id_result_center, start, end);
            });

            setTimeout(async () => {
                await this.setEditingDocs(this.state.editDoc, true);
            }, 1000);

            this.setState({ loadingModal: false, editDoc: { ...this.state.editDoc, id_result_centers }, addResultCenterModal: false, addResultCenterModalDocs: [] })
        } catch (e) {
            console.log(e)
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addResultCenterToDataset(id_parent, id_result_center, start, end) {

        PlanningRowHelper.insertRow(
            {
                type: TYPE,
                operator: this.state.editDoc.operator,
                method: this.state.editDoc.method,
                id_parent: id_parent,
                account_type: this.state.editDoc.account_type,
                relationship: {
                    id_target_account_plan: this.state.editDoc.id_target_account_plan,
                    id_account_plan: this.state.editDoc.id_account_plan,
                    id_result_center,
                    id_products: this.state.editDoc.id_products,
                }
            },
            start,
            end
        );
    }

    removeResultCenter = async () => {
        await this.setState({ selectedProduct: {}, selectedResultCenter: {} });

        try {
            this.setState({ loadingModal: true });

            let id_result_centers = [...this.state.editDoc.id_result_centers];
            let removeIndex = id_result_centers.indexOf(this.state.removeResultCenterId);
            if (removeIndex > -1) id_result_centers.splice(removeIndex, 1);

            await Firestore.update({ id_result_centers }, 'planning', this.state.editDoc.id);

            this.state.editDocRows.forEach(row => {
                if (row.relationship.id_result_center == this.state.removeResultCenterId) {
                    PlanningRowHelper.removeRowFromParent(row.id);
                }
            });

            this.setState({ loadingModal: false, editDoc: { ...this.state.editDoc, id_result_centers }, removeResultCenterModal: false });

            toast.success("Removido com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    renderProductNotSelected() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', alignItems: 'center', flex: 1, width: '100%', height: '100%' }}>
                <img style={{ height: 360, marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }} src={process.env.PUBLIC_URL + '/spreadsheet.png'} />

                <div style={{ textAlign: 'center', fontSize: 18, color: Colors.primary, marginTop: 35, fontWeight: '600', maxWidth: '50%' }}>Selecione, ou adicione um novo centro de resultado em "<AddIcon style={{ color: Colors.primary, fontSize: 18 }} /> Adicionar" para editar e visualizar as despesas.</div>
                <div style={{ textAlign: 'center', fontSize: 16, color: Colors.primary, marginTop: 10, fontWeight: '400', maxWidth: '50%' }}>Clique em "<TableChartIcon style={{ color: Colors.primary, fontSize: 16 }} /> Resumo de Custos" para ter uma visão geral dos custos.</div>
            </div>
        )
    }

    renderLoadingTable() {
        return (
            <div style={styles.plannigContainer} className={'mobile-horizontal-table'}>
                <DefaultLoader loadingText={'Carregando...'} />
            </div>
        );
    }

    searchArray(array, searchField, keyword) {
        let search = [];

        for (var j = 0; j < array.length; j++) {
            var regex = new RegExp(keyword.toLowerCase(), "gm");
            let string = array[j][searchField].toLowerCase();
            if (string.match(regex)) search.push(array[j]);
        }

        return search;
    }

    renderCrList() {
        let existingResultCenters = [];

        if (this.state.editDoc.id_result_centers) {

            this.state.editDoc.id_result_centers.forEach(id => {
                existingResultCenters.push(id);
            });
        }

        let arrayToOrder = [];

        existingResultCenters.forEach(rc => {
            let resultCenter;

            this.state.resultCenterDocs.forEach(doc => {
                if (doc.id == rc) {
                    resultCenter = doc;
                }
            })

            if (resultCenter) {
                arrayToOrder.push(resultCenter);
            }
        });

        let arrayToRender = DataHelper.orderArrayToRender(arrayToOrder, 'description');

        if (this.state.crSearch && this.state.crSearch.length > 0) {
            let search = this.searchArray(arrayToOrder, 'description', this.state.crSearch);

            if (search.length > 0) {
                arrayToRender = search;
            } else {
                arrayToRender = [];
            }
        }

        return (
            <div>
                <div style={styles.listWrapper}>
                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                        <SearchIcon style={{ marginRight: 10, fontSize: 25 }} />
                        <DefaultInput
                            autoFocus={true}
                            label={'Centros de Resultado'}
                            type={'search'}
                            value={this.state.crSearch}
                            defaultValue={this.state.crSearch}
                            onChange={(value) => {
                                if (!value) this.setState({ crSearch: '', selectedResultCenter: {} });

                                this.setState({ crSearch: value, selectedResultCenter: {} });
                            }}
                        />
                    </div>
                    <List component="nav" aria-label="Centros de Resultado da Edição de Planejamento" style={{ height: 'calc(100vh - 380px)', overflowY: 'auto' }}>
                        {arrayToRender.map(resultCenter => {
                            let rc = resultCenter.id;
                            let selected = this.state.selectedResultCenter && this.state.selectedResultCenter.id == rc;
                            let text = resultCenter.description.length > 13 ? resultCenter.description.slice(0, 13) + '...' : resultCenter.description;

                            if (resultCenter) {
                                return (
                                    <Tooltip title={resultCenter.description}>
                                        <ListItem button selected={selected} style={selected ? { backgroundColor: Colors.primary } : null} onClick={ async () => {
                                        if (!this.state.selectedResultCenter || (this.state.selectedResultCenter && this.state.selectedResultCenter.id !== resultCenter.id)) {

                                            this.setState({ selectedResultCenter: resultCenter, loadingTable: true });
                                            let editDocRows = await PlanningRowHelper.getRowsByParentId(this.state.editDoc.id, this.state.revenueRows, resultCenter.id, null);
                                            this.setState({ editDocRows: editDocRows, loadingTable: false });
                                        }
                                        }}>
                                        <ListItemIcon>
                                            <TrendingUpIcon style={selected ? { color: `#fff` } : null} />
                                        </ListItemIcon>
                                        <ListItemText secondaryTypographyProps={selected ? { color: `#fff` } : null} style={selected ? { color: `#fff` } : null} primary={text} secondary={resultCenter.code} />
                                        <ListItemSecondaryAction onClick={async () => { await this.setState({ removeResultCenterId: resultCenter.id, removeResultCenterModal: true }) }}>
                                            <IconButton edge="end" aria-label="Remover Item">
                                                <DeleteIcon style={selected ? { color: `#fff` } : null} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                        </ListItem>
                                    </Tooltip>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </List>
                    <Divider />
                    <List component="nav" aria-label="Adicionar novo centro de resultado">
                        <ListItem button onClick={() => { this.setState({ addResultCenterModal: true }) }}>
                            <ListItemIcon>
                                <AddIcon style={{ color: Colors.primary }} />
                            </ListItemIcon>
                            <ListItemText primary={'Adicionar'} style={{ color: Colors.primary }} />
                        </ListItem>
                    </List>
                </div>
            </div>

        );
    }

    addModal = () => {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.method}
                        onChange={(v) => {
                            this.setState({ method: v.target.value });
                        }}
                        docs={Object.keys(this.getMethodologyLookup()).map(key => ({ name: this.getMethodologyLookup()[key], key }))}
                        label={'Metodologia de Planejamento*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.targetAccountPlan}
                        onChange={(v) => {
                            this.setState({ targetAccountPlan: v.target.value })
                        }}
                        docs={this.state.accountPlanDocs}
                        label={'Conta Contábil (Destino)'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    { PlanningHelper.renderAccountTypeSelect(this.state.accountType, (v) => this.setState({ accountType: v })) }
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    { PlanningHelper.renderPercentageAccountSelect(this.state.accountType, this.state.accountPlan, this.state.accountPlanDocs, this.state.budgetStructureDocs, (v) => this.setState({ accountPlan: v })) }
                </div>

                <div style={{ marginTop: 15, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.products}
                        onChange={(v) => {
                            this.setState({ products: v.target.value })
                        }}
                        docs={this.state.productsDocs}
                        label={'Produtos e Serviços'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={true}
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.resultCenter}
                        onChange={(v) => {
                            this.setState({ resultCenter: v.target.value })
                        }}
                        docs={this.state.resultCenterDocs}
                        label={'Centros de Resultado'}
                    />
                </div>

                <PeriodSelection periods={this.state.periodDocs} onStartChange={(v) => { this.setState({ start: v }) }} onEndChange={(v) => { this.setState({ end: v }) }} onDocChange={(id) => { this.setState({ id_period: id }) }}/>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.addNewDoc() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false, description: ``, start: moment(), end: moment().add(3, 'month') }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    removeResultCenterModal() {
        return (
            <div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                    <Button onClick={() => { this.removeResultCenter(this.state.removeResultCenterId) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'REMOVER'}</Button>
                    <Button onClick={() => { this.setState({ removeResultCenterModal: false, removeResultCenterId: `` }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    addResultCenterModal() {
        return (
            <div>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        disabled={false}
                        multiple={true}
                        search={true}
                        searchField={'description'}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.addResultCenterModalDocs}
                        onChange={(v) => {
                            this.setState({ addResultCenterModalDocs: v.target.value })
                        }}
                        docs={this.getAddResultCenterModalDocs()}
                        label={'Centros de Resultado'}
                    />
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={this.addResultCenter} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'ADICIONAR E SALVAR'}</Button>
                    <Button onClick={() => { this.setState({ addResultCenterModal: false, addResultCenterModalDocs: [] }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    getMethodologyLookup() {
        return {
            'absolute': Methods.absolute.label,
            'percentage': Methods.percentage.label,
        }
    }

    getOperatorLookup() {
        return {
            'minus': 'Despesa',
            'sum': 'Receita',
        }
    }

    renderAccountPlan(account) {

        let doc = this.state.accountPlanDocs.find(item => item.id === account);
        let text = doc ? doc.description : '';

        return text;
    }

    getProductsLookup(hideCode) {
        let docs = this.state.productsDocs;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = `${doc.description}${hideCode ? `` : ` (${doc.code})`}`;
        });

        return lookup;
    }

    async setEditingDocs(rowData, firstLoad = false) {
        if (firstLoad) this.setState({ loadingText: `Obtendo dados do registro...`, loading: true });

        await this.setState({ editId: rowData.id, editDoc: rowData, editDocRows: [] });
        await this.getRevenueData(!firstLoad);

        if (firstLoad) this.setState({ tab: rowData.method == 'percentage' ? 0 : 2, loading: false, editing: true });
    }

    typeSort(a, b) {

        return a.operator < b.operator;
    } 

    renderGrid() {
        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        { PlanningHelper.renderPlanningPeriodSelection(this.state.periodDocs, this.state.selectedPeriod, async (selectedPeriod) => { await this.setState({ selectedPeriod }); await this.filterPlanningElement(); }) }
                    </div>
                </div> 

                <DefaultTable
                    title={'Despesas'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Tipo', field: 'type', editable: false, render: rowData => rowData.operator == 'sum' ? <div style={{ fontWeight: 'bold', color: 'green' }}>Receita</div> : <div style={{ fontWeight: 'bold', color: 'red' }}>Despesa</div>, customSort: (row, value) => this.typeSort(row, value) },
                        { title: 'Metodologia de Planejamento', field: 'method', lookup: this.getMethodologyLookup() },
                        { title: 'Conta Contábil (Destino)', field: 'id_target_account_plan', editComponent: props => PlanningHelper.renderAccountEdit(props, this.state.accountPlanDocs), render: rowData => <div>{PlanningHelper.renderAccountPlan(rowData.id_target_account_plan, this.state.accountPlanDocs)}</div> },
                        { title: 'Tipo (% Aplicação)', field: 'account_type', lookup: PlanningHelper.getAccountTypeLookup() },
                        { title: 'Conta Contábil (% Aplicação)', field: 'id_account_plan', editComponent: props => props.rowData.account_type === 'budget_structure' ? PlanningHelper.renderBudgetStructureEdit(props, this.state.budgetStructureDocs) : PlanningHelper.renderAccountEdit(props, this.state.accountPlanDocs), render: rowData => <div>{rowData.account_type === 'budget_structure' ? PlanningHelper.renderBudgetStructure(rowData.id_account_plan, this.state.budgetStructureDocs) : PlanningHelper.renderAccountPlan(rowData.id_account_plan, this.state.accountPlanDocs)}</div> },
                        { title: 'Centros de Resultado', field: 'id_result_centers', editComponent: props => PlanningHelper.renderResultCentersEdit(props, this.state.resultCenterDocs), render: rowData => <div>{PlanningHelper.renderResultCenters(rowData.id_result_centers, this.state.resultCenterDocs)}</div> },
                        { title: 'Produtos e Serviços', field: 'id_products', editComponent: props => PlanningHelper.renderProductEdit(props, this.state.productsDocs, true), render: rowData => rowData.id_products && rowData.id_products.length > 0 ? rowData.id_products.length > 5 ? <div>{rowData.id_products.length} Produtos</div> : <div> {rowData.id_products.map((id, key) => `${this.getProductsLookup()[id]}${key < rowData.id_products.length - 1 ? `, ` : ``}`)}</div> : <div>Todos no Período</div> },
                        { title: 'Sinal', field: 'operator', render: rowData => rowData.operator === Operators.sum.key ? Operators.sum.label : Operators.minus.label, lookup: PlanningRowHelper.getOperatorLookup() },
                        { title: 'Período Inícial', field: 'start', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                        { title: 'Período Final', field: 'end', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                    ]}
                    actions={[
                        {
                            icon: TableChartIcon,
                            tooltip: 'Abrir Despesa',
                            onClick: async (event, rowData) => { await this.setEditingDocs(rowData, true); }
                        },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if (newData.start && newData.end) {
                            newData.start = newData.start.seconds ? new Date(newData.start.seconds * 1000) : newData.start.toDate ? newData.start.toDate() : newData.start;
                            newData.end = newData.end.seconds ? new Date(newData.end.seconds * 1000) : newData.end.toDate ? newData.end.toDate() : newData.end;
                        }

                        if (newData.id_target_account_plan) {

                            await PlanningHelper.updatePlanning(newData, oldData.id);

                            let prev = this.state.docs;
                            const index = prev.indexOf(oldData);
                            prev[index] = newData;
                            
                            this.setState({ docs: prev });
    
                            return prev;

                        } else {

                            toast.warn('A Conta de Destino não pode ser vazia');
                            return;
                        }
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);
                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        await PlanningHelper.deletePlanning(oldData.id);
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Despesa ou Receita'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />

            </div>
        );
    }

    renderDeductionsTable(rows, method) {
        let table;

        if (method == 'percentage') {
            table = <CostsTable rows={rows} comparativeRows={this.state.revenueRows} moreIsBetter={this.state.editDoc.operator == Operators.sum.key} />
        } else {
            table = <ManualInputTable rows={rows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} moreIsBetter={this.state.editDoc.operator == Operators.sum.key} />
        }

        return (
            <div style={styles.tableContainer}>
                {table}
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderPercentageOverRevenueTable(rows, method) {
        return (
            <div style={styles.tableContainer}>
                <PercentageTable rows={rows} comparativeRows={this.state.revenueRows} state={this.state} setParentState={this.setParentState} renderInputEditing={this.renderInputEditing} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderRevenueTable(method) {
        return (
            <div style={styles.tableContainer}>
                <RevenueTable rows={this.state.revenueRows} moreIsBetter={true} />
                {TableHelper.getTableFooter(this.getMethodologyLookup()[method])}
            </div>
        );
    }

    renderTableTabs() {

        let rows = [];

        this.state.editDocRows.forEach(row => {
            let id_result_center = this.state.selectedResultCenter.id;
            let relationship = row.relationship;

            if (relationship.id_result_center == id_result_center) {
                rows.push(row);
            }
        });

        return (
            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <Box style={{ boxShadow: Colors.boxShadow }}>
                    <Tabs
                        style={{ backgroundColor: '#fff' }}
                        value={this.state.tab}
                        variant="fullWidth"
                        TabIndicatorProps={{ style: { background: Colors.primary } }}
                        onChange={(event, index) => { this.setState({ tab: index }) }}
                    >
                        <Tab disabled={this.state.editDoc.method == 'percentage' ? false : true} label={<span style={{ fontWeight: this.state.tab === 0 ? 'bold' : '', color: Colors.primary }}>%</span>} />
                        <Tab disabled={this.state.editDoc.method == 'percentage' ? false : true} label={<span style={{ fontWeight: this.state.tab === 1 ? 'bold' : '', color: Colors.primary }}>Conta Contábil (% Aplicação)</span>} />
                        <Tab label={<span style={{ fontWeight: this.state.tab === 2 ? 'bold' : '', color: Colors.primary }}>{this.getOperatorLookup()[this.state.editDoc.operator]}</span>} />
                    </Tabs>

                    <TableHelper.TabPanel value={this.state.tab} index={0}>
                        {this.state.editDoc.method == 'percentage' ? this.renderPercentageOverRevenueTable(rows, this.state.editDoc.method) : null}
                    </TableHelper.TabPanel>
                    <TableHelper.TabPanel value={this.state.tab} index={1}>
                        {this.state.editDoc.method == 'percentage' ? this.renderRevenueTable(this.state.editDoc.method) : null}
                    </TableHelper.TabPanel>
                    <TableHelper.TabPanel value={this.state.tab} index={2}>
                        {this.renderDeductionsTable(rows, this.state.editDoc.method)}
                    </TableHelper.TabPanel>
                </Box>
            </div>
        )
    }

    renderEditing() {
        return (
            <div style={styles.plannigContainerWrapper}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 20, justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <DefaultButton onClick={() => { this.setState({ editId: null, editDoc: {}, editing: false, selectedDeduction: {}, tab: 0, selectedResultCenter: null }); this.getPlanningElement(); }} title={'Voltar'} leftIcon={<ChevronLeftIcon />} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300, textAlign: 'center' }}>
                        <FormLabel style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1 }}>{this.state.editDoc.description}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 2, fontWeight: 200 }}>Despesas</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Conta Contábil (Destino): {this.renderAccountPlan(this.state.editDoc.id_target_account_plan)}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Produtos ou Serviços:{` `}
                            {this.state.editDoc.id_products && this.state.editDoc.id_products.length > 0 ?
                                this.state.editDoc.id_products.length > 50 ? `${this.state.editDoc.id_products.length} Produtos` :
                                    `${this.state.editDoc.id_products.map((id, key) =>
                                        ` ${this.getProductsLookup(true)[id]}`)}`
                                : `Todos no Período`}</FormLabel>
                    </div>

                    <KeyboardShortcutsTooltip />
                </div>

                <div style={styles.plannigContainer} className={'mobile-horizontal-table'}>
                    <div style={styles.plannigContainerColumns}>
                        {this.renderCrList()}
                    </div>
                    <div style={{ width: '100%', paddingLeft: 20, overflow: 'scroll' }}>
                        {this.state.selectedResultCenter && this.state.selectedResultCenter.id && !this.state.loadingTable ? this.renderTableTabs() : null}
                        {this.state.selectedResultCenter && this.state.selectedResultCenter.id && this.state.loadingTable ? this.renderLoadingTable() : null}
                        {!this.state.selectedResultCenter && !this.state.loadingTable ? this.renderProductNotSelected() : null}
                    </div>
                </div>
                <DefaultModal loading={this.state.loadingModal} content={this.addResultCenterModal()} title={'Adicionar CR a este Planejamento de Receita'} onClose={() => { this.setState({ addResultCenterModal: false }) }} open={this.state.addResultCenterModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.removeResultCenterModal()} title={'Remover CR do planejamento? Essa ação é irreversível.'} onClose={() => { this.setState({ removeResultCenterModal: false }) }} open={this.state.removeResultCenterModal} />
            </div>
        )
    }

    saveEdit = async (percentage, saveEntireRow) => {
        try {
            this.setState({ savingEdit: true });

            if (this.state.rowId) {
                let editInputValue = Number(this.state.editInputValue);

                if (percentage == true) {
                    if (editInputValue > 100) editInputValue = 100;
                }

                let saveIds = [];

                if (saveEntireRow) {
                    this.state.editingRows.forEach(row => saveIds.push(row.id));
                } else {
                    saveIds = [this.state.rowId];
                }

                for (let id of saveIds) {
                    let data = this.state.editDocRows.find(item => item.id === id);
                    data[this.state.editingField] = editInputValue;

                    await PlanningRowHelper.updateRow(data, id);
                }

                this.setState({ editingData: false, editInputValue: editInputValue });
            } else {
                throw new Error();
            }
        } catch (e) {
            toast.error("Houve um problema ao atualizar", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        await this.getRevenueData(true);

        this.setState({ savingEdit: false });
    }

    renderInputEditing = (defaultValue, percentage) => {
        return (
            <TableEditingInput state={this.state} setParentState={this.setParentState} defaultValue={defaultValue} percentage={percentage} saveEdit={this.saveEdit} />
        );
    }

    setParentState = data => {
        this.setState(data);
    }
    renderContent() {
        return this.state.editing ? this.renderEditing() : this.renderGrid();
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : this.renderContent();
    }
}

const styles = {
    container: {
        padding: 25,
    },
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    },
    plannigContainerWrapper: {
        width: '100%',
        flexDirection: 'column',
        flex: 1,
        padding: 25,
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainerColumns: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: 0,
        width: '100%',
        height: '100%',
        backgroundColor: Colors.background,
        overflow: 'hidden',
    },
    listWrapper: {
        width: 280,
        borderRadius: 5,
        maxHeight: '100%',
        backgroundColor: `#ffffff`,
        boxShadow: Colors.boxShadow,
    },
}
