import React from "react";
import TableHelper from "../helper/TableHelper";
import CalculusHelper from "../helper/CalculusHelper";

export default class MeanCellVariation extends React.Component {

    state = {
        rows: this.props.rows,
        nOfElements: 0,
        variation: 0,
    }

    async componentDidUpdate(prev) {
        
        if (this.props.rows != prev.rows) {

            await this.setState({ rows: this.props.rows });
            await this.getVariation();
        }
    }

    async componentDidMount() {

        await this.getVariation();
    }

    async getVariation() {

        let valueMean = await this.getMean('value');
        let valueHistoryMean = await this.getMean('value_history');
        this.setState({ variation: CalculusHelper.formatType(this.props.type, CalculusHelper.formatFloat(CalculusHelper.variation(valueMean, valueHistoryMean, this.props.percentage))) })
    }

    async getMean(field) {

        let nOfElements = await TableHelper.getMeanCellNOfElements(this.props.rows, field);
        let counter = 0;

        for (let index = 0; index < this.props.rows.length; index++) {
            counter += this.props.rows[index][field];
        }

        let mean = CalculusHelper.mean(counter, nOfElements);
        return mean;
    }

    render() {

        return this.state.variation;
    }
}