import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import RelationField from '../components/RelationField';
import ActionPlanHelper from '../helper/ActionPlanHelper';
import ActionPlanView from '../components/ActionPlanView';
import AddActionPlan from '../components/AddActionPlan';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';

export default class ActionPlanPage extends Component {

    state = {
        docs: [],
        tagDocs: [],
        userDocs: [],
        loading: true,
        editModal: false,
        editId: null,
        openActionPlan: null,
        addActionPlan: false,
        startFilter: moment().toDate(),
        endFilter: moment().toDate(),
        budgetLinkParams: null,
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getTags();
        await this.getUsers();
        await this.getActionPlanParams();

        this.setState({ loading: false });
    }

    async getActionPlanParams() {

        try {

            let url = new URL(window.location.href);
            let id = url.searchParams.get('id');
            let add = url.searchParams.get('add');
            
            if (id) {
                
                let actionPlan = this.state.docs.find(item => item.id === id);

                if (actionPlan) {

                    this.openActionPlan(actionPlan);
                }

                return;
            }

            if (add) {

                let budgetLinkParams = {};

                budgetLinkParams.budgetLinkPeriodStart = url.searchParams.get('budget_link_period_start');

                if (budgetLinkParams.budgetLinkPeriodStart) {

                    budgetLinkParams.budgetLinkPeriodStart = moment.unix(budgetLinkParams.budgetLinkPeriodStart).toDate();
                }

                budgetLinkParams.budgetLinkPeriodEnd = url.searchParams.get('budget_link_period_end');

                if (budgetLinkParams.budgetLinkPeriodEnd) {

                    budgetLinkParams.budgetLinkPeriodEnd = moment.unix(budgetLinkParams.budgetLinkPeriodEnd).toDate();
                }

                budgetLinkParams.budgetLinkResultCenters = url.searchParams.getAll('budget_link_result_center');

                if (!budgetLinkParams.budgetLinkResultCenters) {

                    budgetLinkParams.budgetLinkResultCenters = [];
                }

                budgetLinkParams.budgetLinkAccountPlans = url.searchParams.getAll('budget_link_account_plan');

                if (!budgetLinkParams.budgetLinkAccountPlans) {

                    budgetLinkParams.budgetLinkAccountPlans = [];
                }

                budgetLinkParams.budgetLinkAccountPackages = url.searchParams.getAll('budget_link_account_package');

                if (!budgetLinkParams.budgetLinkAccountPackages) {

                    budgetLinkParams.budgetLinkAccountPackages = [];
                }

                this.setState({ addActionPlan: true, budgetLinkParams });
                return;
            }

        } catch (error) {

        }
    }

    async getDocs() {

        let query = await Firestore.customQuery('action_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc').get();
        let docs = [];

        let start = moment(this.state.startFilter);
        let end = moment(this.state.endFilter);

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;
            data.status = ActionPlanHelper.getStatus(data.tasks, true);

            let date = moment(data.date.toDate());

            if (start.isBefore(date, 'date')) {

                start = date;
            }

            if (end.isAfter(date, 'date')) {

                end = date;
            }

            docs.push(data);
        });

        this.setState({ docs: docs, startFilter: end, endFilter: start });
    }

    async getTags() {

        let query = await Firestore.customQuery('tag').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ tagDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async handleAdd(id) {

        let query = await Firestore.getDoc('action_plan', id);
        let actionPlan = {...query.data(), id: query.id};
        
        await this.getDocs();

        this.setState({ addActionPlan: false, openActionPlan: this.state.editId ? null : actionPlan });
        toast.success(`Plano de Ação ${this.state.editId ? 'editado' : 'cadastrado'} com sucesso`)
    }

    async handleBack() {

        this.setState({ addActionPlan: false, openActionPlan: null, loading: true });

        await this.getDocs();

        this.setState({ loading: false });
    }

    openActionPlan(doc) {

        this.setState({ openActionPlan: doc });
    }

    renderEmpty() {

        return (

            <div style={{ marginTop: 10 }}>
                <Card style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40, height: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow }}>
                    <img style={{ height: 350 }} src={process.env.PUBLIC_URL + '/lightbulb_moment.png'} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                        <p style={{ fontSize: 25, fontWeight: 'bold' }}>{`Nenhum plano de ação foi cadastrado ainda.`}</p>
                        <div style={{ color: 'grey', fontSize: 16 }}>Clique em "+ Plano de Ação" para começar.</div>
                    </div>
                </Card>
            </div>
        )
    }

    renderTags(id_tags) {

        let tags = [];

        for (let index = 0; index < id_tags.length; index++) {
            
            let tag = this.state.tagDocs.find(item => item.id === id_tags[index]);

            if (tag) {

                tags.push(tag.description);
            }
        }

        return tags.length > 0 ? tags.join(', ') : '';
    }

    renderParticipants(id_participants) {

        let users = [];

        for (let index = 0; index < id_participants.length; index++) {
            
            let user = this.state.userDocs.find(item => item.id === id_participants[index]);

            if (user) {

                users.push(user.name);
            }
        }

        return users.length > 0 ? users.join(', ') : '';
    }

    getUserLookup() {

        let lookup = {};

        this.state.userDocs.forEach((user, key) => {

            lookup[user.id] = user.name;
        });

        return lookup;
    }

    getTagLookup() {

        let lookup = {};

        this.state.tagDocs.forEach((tag, key) => {

            lookup[tag.id] = tag.description;
        });

        return lookup;
    }

    filterArray(term, data) {

        if (Array.isArray(term)) {

            if (!term.length) {

                return true;
            }
    
            let valid = false;
    
            term.forEach((item, key) => {
    
                if (data.includes(item)) {
    
                    valid = true;
                    return;
                }
            });
    
            return valid;
        }

        return false;
    }

    filterPeriod(term, data) {

        if (term) {

            let start = moment(this.state.startFilter);
            let end = moment(this.state.endFilter);
            let date = moment(data.toDate ? data.toDate() : data);
            
            if (date.isSameOrAfter(start, 'date') && date.isSameOrBefore(end, 'date')) {

                return true;
            }
        }

        return false;
    }

    getPeriodFiltering(props, id) {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: 150 }}
                        invalidDateMessage={false}
                        format={'dd/MM/yyyy'}
                        autoOk={true}
                        label="Início"
                        views={["year", "month", "date"]}
                        openTo={'date'}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => {
                            this.setState({ startFilter: v });
                            props.onFilterChanged(id, v);
                        }}
                        value={this.state.startFilter}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: 150 }}
                        invalidDateMessage={false}
                        format={'dd/MM/yyyy'}
                        autoOk={true}
                        openTo={'date'}
                        views={["year", "month", "date"]}
                        label="Fim"
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => {
                            this.setState({ endFilter: v });
                            props.onFilterChanged(id, v);
                        }}
                        value={this.state.endFilter}
                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    renderGrid() {

        return (

            <DefaultTable
                filtering={true}
                search={false}
                title={'Planos de Ação'}
                marginTop={10}
                actions={[
                    {
                        tooltip: 'Visualizar',
                        icon: () => <VisibilityIcon />,
                        onClick: (event, rowData) => { this.openActionPlan(rowData) },
                    },
                    {
                        tooltip: 'Editar',
                        icon: () => <EditIcon style={{ color: 'green' }}/>,
                        onClick: (event, rowData) => { this.setState({ addActionPlan: true, editId: rowData.id }) },
                    }

                ]}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Status', field: 'status', render: rowData => ActionPlanHelper.getStatus(rowData.tasks), lookup: ActionPlanHelper.getStatuses() },
                    { title: 'Descrição', field: 'description' },
                    { title: 'Responsável', field: 'id_responsable', render: rowData => <RelationField loading={false} collection={'user'} field={'name'} id={rowData.id_responsable}/>, lookup: this.getUserLookup() },
                    { title: 'Superior', field: 'id_evaluator', render: rowData => <RelationField loading={false} collection={'user'} field={'name'} id={rowData.id_evaluator}/>, lookup: this.getUserLookup() },
                    { title: 'Participantes', field: 'id_participants', render: rowData => this.renderParticipants(rowData.id_participants), lookup: this.getUserLookup(), customFilterAndSearch: (term, rowData) => this.filterArray(term, rowData.id_participants) },
                    { title: 'Tags', field: 'id_tags', render: rowData => this.renderTags(rowData.id_tags), lookup: this.getTagLookup(), customFilterAndSearch: (term, rowData) => this.filterArray(term, rowData.id_tags) },
                    { title: 'Etapas', field: 'tasks', render: rowData => <div>{rowData.tasks ? rowData.tasks.length : 0}</div>, filtering: false },
                    { title: 'Data de Início', type: 'date', field: 'date', render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY')}</div>, filterComponent: (props) => this.getPeriodFiltering(props, 8), customFilterAndSearch: (term, rowData) => this.filterPeriod(term, rowData.date) },
                ]}
                data={this.state.docs}
                onRowDelete={async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);

                    this.setState({ docs: prev });

                    if (oldData.id) {

                        await Firestore.delete('action_plan', oldData.id);

                        toast.success("Removido com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }
                }}
            />
        )
    }

    render() {

        if (this.state.loading) {

            return <div><DefaultLoader loadingText={this.state.loadingText} /></div>;
        }

        if (this.state.addActionPlan) {

            return <AddActionPlan budgetLinkParams={this.state.budgetLinkParams} clearBudgetLinkParams={() => this.setState({ budgetLinkParams: null })} onAdd={(id) => { this.handleAdd(id) }} onBack={() => { this.handleBack() }} editId={this.state.editId}/>
        }

        if (this.state.openActionPlan) {

            return <ActionPlanView onEdit={() => { this.editItem(this.state.openActionPlan) }} onBack={() => { this.handleBack() }} doc={this.state.openActionPlan}/>
        }

        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addActionPlan: true, editId: null }) }} title={'Plano de Ação'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>
                {!this.state.docs.length ? this.renderEmpty() : this.renderGrid()}
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
