import Firestore from "../api/firebase/Firestore";
import Functions from "../api/firebase/Functions";
import RelationField from "../components/RelationField";
import Colors from "../constants/Colors";
import SessionHelper from "./SessionHelper";

export default class NotificationHelper {

    static async notifyMentions(comment, module, title, emailTitle, emailBody, id) {

        if (comment && comment.length) {
            
            let users = [];
            let mentions = comment.match(/@\[(.*?)\]\((.*?)\)/g);
            
            if (mentions && mentions.length) {

                mentions.forEach((mention, key) => {

                    mention = mention.split('](')[1];
                    mention = mention.split(')')[0];
                    
                    users.push(mention);
                });
            }

            let sendedIds = [];

            users.forEach( async (user, key) => {

                if (user !== SessionHelper.getFirebaseAuth().uid && !sendedIds.includes(user)) {
                 
                    let notification = {
                        title: title,
                        body: '',
                        data: {
                            color: null,
                            icon: module,
                            link: `/${module}?id=${id}`,
                        },
                        id_company: SessionHelper.getData().id_company,
                        id_user: user,
                        date: new Date(),
                    }

                    let parsedComment = this.parseComment(comment, true);  
                    notification.body = parsedComment;
                    
                    await this.add(notification);

                    Functions.request('POST', 'notification/sendEmailMention', { id_user: SessionHelper.getFirebaseAuth().uid, id_user_mention: user, parsedComment, title: notification.title, emailTitle, body: emailBody, module, id });
                    
                    sendedIds.push(user);
                }
            });
        }
    }

    static async add(data) {

        try {

            data.viewed = false;
            await Firestore.insert(data, 'notification');

        } catch (error) {

            console.log('Notif Error: ' + error);
        }
    }

    static parseComment(comment, forEmail = false) {

        let result = comment.replace(/@\[(.*?)\]\((.*?)\)/g, (match, contents, offset, input) => {

            let id = match;
            id = id.split('](')[1];
            id = id.split(')')[0];

            let name = match;
            name = name.split('@[')[1];
            name = name.split(']')[0];
            
            return `<div style="background: rgb(218, 244, 250); margin-left: 3px; margin-right: 3px;">@${name}</div>`;
        });

        if (forEmail) {

            result = `<div style="display: flex; flex-direction: row; flex-wrap: wrap;">${result}</div>`;
        }

        return result;
    }
}