import React, { Component } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Colors from '../constants/Colors';

export default class DefaultLoader extends Component {
  render() {
    return (
      <div style={this.props.css ? this.props.css : { display: 'flex', margin: 'auto', marginTop: '22%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <ClipLoader
          size={this.props.size ? this.props.size : 50}
          color={this.props.color ? this.props.color : Colors.primary}
          loading={true}
        />
        {this.props.loadingText ? <h2 style={{color: Colors.primary}}>{this.props.loadingText}</h2> : null}
      </div>
    );
  }
}
