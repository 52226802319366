import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import CurrencyInput from '../components/CurrencyInput';
import DefaultSelect from '../components/DefaultSelect';
import ExcelModal from '../components/ExcelModal';
import SessionHelper from '../helper/SessionHelper';
import { Checkbox, FormControlLabel, FormLabel, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import MeasurementUnits from '../constants/MeasurementUnits';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import ExcelImportValidators from '../constants/ExcelImportValidators';
import DataHelper from '../helper/DataHelper';

export default class EmployeePage extends Component {

    state = {
        docs: [],
        roleDocs: [],
        resultCenterDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        name: '',
        salary: null,
        resultCenter: null,
        rawSalary: null,
        role: null,
        active: true,
        loadingText: 'Carregando colaboradores...',
        importVariablesModal: false,
        editId: null,
        excelModal: false,
        duplicates: [],
        duplicateModal: false,
        insertedCounter: 0,
        colsub: [
            { name: 'name', alias: 'Nome', required: true, example: 'Nome. Qualquer' },
            { name: 'salary', alias: 'Salário (Apenas Números)', example: '3.500,00' },
            { name: 'role', alias: 'Cargo (Descrição)', example: 'Cargo Qualquer' },
            { name: 'result_center', alias: 'Centro de Resultado (Código)', example: '2.1.1.1', validators: [ExcelImportValidators.trim] },
            { name: 'active', alias: 'Ativo ("S" ou "N")', example: 'S', validators: [ExcelImportValidators.trim] }
        ],
    }

    async getDocs() {

        let query = Firestore.customQuery('employee').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc');;

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            data.salary = data.salary / 100;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getRoles() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('role').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ roleDocs: docs });
    }

    async getResultCenters() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('result_center').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ resultCenterDocs: docs });
    }

    async componentDidMount() {

        await this.getDocs();
        await this.getRoles();
        await this.getResultCenters();

        this.setState({ loading: false });
    }

    async addNewDoc() {

        if (this.state.name && SessionHelper.getData().id_company) {

            try {
                this.setState({ loadingModal: true });

                let data = {
                    name: this.state.name,
                    role: this.state.role,
                    result_center: this.state.resultCenter,
                    active: this.state.active,
                    id_company: SessionHelper.getData().id_company,
                    salary: this.state.rawSalary * 100,
                    date: new Date(),
                };

                await Firestore.insert(data, 'employee');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.getDocs();

            } catch (error) {

                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false,
            addModal: false,
            name: '',
            role: '',
            resultCenter: null,
            salary: null,
            rawSalary: null,
            active: true,
        });
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                <CurrencyInput label={'Salário'} onChange={(values) => { const { formattedValue, value } = values; this.setState({ salary: formattedValue, rawSalary: value }) }} value={this.state.salary} />
                <div style={{ marginTop: 10 }}>
                    <DefaultSelect
                        search={true}
                        searchField={'description'}
                        id={'role_select'}
                        valueField={'id'}
                        displayField={'description'}
                        value={this.state.role}
                        onChange={(v) => {
                            this.setState({ role: v.target.value })
                        }}
                        docs={this.state.roleDocs}
                        label={'Cargo'}
                    />
                </div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        id={'result_center_select'}
                        valueField={'id'}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        value={this.state.resultCenter}
                        onChange={(v) => {
                            this.setState({ resultCenter: v.target.value })
                        }}
                        docs={this.state.resultCenterDocs}
                        label={'Centro de Resultado'}
                    />
                </div>
                <FormControlLabel
                    style={{ marginTop: 8 }}
                    control={<Checkbox checked={this.state.active} onChange={(v) => { this.setState({ active: v.target.checked }) }} />}
                    label={'Ativo'}
                />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, name: '', role: '', active: true }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    userExists = async (doc) => {
        let exists = false;
        let duplicates = this.state.duplicates;

        if (doc.name) {
            const query = await Firestore.customQuery('employee').where('id_company', '==', doc.id_company).where('name', '==', doc.name).limit(1).get();

            if (query.docs.length > 0) {
                exists = true;
                duplicates.push(doc);
            }
        } else {
            exists = true;
            duplicates.push(doc);
        }

        await this.setState({ duplicates });

        return exists;
    }

    handleExcelImport = async (data) => {
        if (data.length > 0) {
            this.setState({ loading: true });

            try {

                let roleDocs = DataHelper.createObjectFromArray(this.state.roleDocs, 'description', (item) => item.toUpperCase());
                let resultCenterDocs = DataHelper.createObjectFromArray(this.state.resultCenterDocs, 'code');
                let chunks = DataHelper.createSubArrays(data, 500);
                let count = 0;

                await Promise.all(chunks.map( async (chunk) => {
                
                    for (let i = 0; i < chunk.length; i++) {

                        if ((await this.userExists(chunk[i])) == false) {
    
                            if (chunk[i].role) {
    
                                let roleDoc = roleDocs[chunk[i].role.toUpperCase()];
    
                                if (roleDoc && roleDoc.id) {
    
                                    chunk[i].role = roleDoc.id;
    
                                } else {
    
                                    let roleId = await Firestore.getId('role');
    
                                    let roleData = {
                                        description: chunk[i].role,
                                        active: true,
                                        id_company: SessionHelper.getData().id_company,
                                        date: new Date(),
                                    }
    
                                    await Firestore.insert(roleData, 'role', roleId);
                                    chunk[i].role = roleId;
    
                                    let roleDocs = this.state.roleDocs;
                                    roleDocs.push({ ...roleData, id: roleId });
    
                                    await this.setState({ roleDocs });
                                }
                            }
    
                            if (chunk[i].result_center) {
    
                                let resultCenterDoc = resultCenterDocs[chunk[i].result_center];
    
                                if (resultCenterDoc && resultCenterDoc.id) {
    
                                    chunk[i].result_center = resultCenterDoc.id;
    
                                } else {
    
                                    chunk[i].result_center = '';
                                }
                            }
    
                            if (chunk[i].salary) {
    
                                let salary = chunk[i].salary;
    
                                if (!Number.isNaN(parseFloat(salary))) {
    
                                    chunk[i].salary = salary * 100;
    
                                } else {
    
                                    chunk[i].salary = '';
                                }
                            }
    
                            if (chunk[i].active) {
    
                                let value = chunk[i].active === 'S' ? true : false;
                                chunk[i].active = value;
                            }
    
                            await Firestore.insert(chunk[i], 'employee');
                        }
    
                        count++;
                        this.setState({ loadingText: `Inserindo ${count} de ${data.length}` });
                    }
                }));

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                this.setState({ insertedCounter: data.length - this.state.duplicates.length });

                toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });


            } catch (error) {
                console.log(error)
                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            await this.getDocs();

            this.setState({ loading: false, loadingText: `` });
        }
    }

    getRoleLookup() {

        let lookup = {};

        this.state.roleDocs.forEach((role, key) => {

            lookup[role.id] = role.description;
        });

        return lookup;
    }

    getResultCenterLookup() {

        let lookup = {};

        this.state.resultCenterDocs.forEach((resultCenter, key) => {

            lookup[resultCenter.id] = resultCenter.description;
        });

        return lookup;
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <div style={{ marginRight: 10 }}>
                            <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                        </div>
                        <div style={{}}>
                            <DefaultButton leftIcon={<DescriptionIcon />} onClick={() => { this.setState({ excelModal: true }) }} title={'Importar do Excel (.xlsx)'} width={290} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>

                <DefaultTable
                    title={'Colaboradores'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'Salário', field: 'salary', type: 'currency', currencySetting: { locale: 'brl', currencyCode: 'brl' }, editComponent: props => (<CurrencyInput value={props.value} onChange={(e) => { props.onChange(e.floatValue) }} />) },
                        { title: 'Cargo', field: 'role', lookup: this.getRoleLookup() },
                        { title: 'Centro de Resultado', field: 'result_center', lookup: this.getResultCenterLookup() },
                        { title: 'Ativo', field: 'active', type: 'boolean' },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;

                        if (oldData.id) {

                            let data = {
                                name: newData.name,
                                role: newData.role,
                                result_center: newData.result_center,
                                active: newData.active,
                                salary: newData.salary * 100,
                            };

                            await Firestore.update(data, 'employee', oldData.id);
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        prev[prev.indexOf(oldData)] = newData;

                        this.setState({ docs: prev });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            await Firestore.delete('employee', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            return prev;
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Colaborador'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} width={'80%'} />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
