import Firestore from "../api/firebase/Firestore";
import SessionHelper from "./SessionHelper";

export default class LogHelper {
    static insertLog(id, collection, data) {
        try {
            let log = {
                type: `insert`,
                id_doc: id,
                collection,
                date: new Date(),
                id_user: SessionHelper.getFirebaseAuth().uid,
                id_company: SessionHelper.getData().id_company,
                data
            }

            Firestore.insert(log, `log`, id);
        } catch (e) {
            return;
        }
    }

    static deleteLog(id, collection) {
        try {
            let log = {
                type: `delete`,
                id_doc: id,
                collection,
                date: new Date(),
                id_user: SessionHelper.getFirebaseAuth().uid,
                id_company: SessionHelper.getData().id_company
            }

            Firestore.insert(log, `log`, id);
        } catch (e) {
            return;
        }
    }

    static updateLog(id, collection, data) {
        try {
            let log = {
                type: `update`,
                id_doc: id,
                collection,
                date: new Date(),
                id_user: SessionHelper.getFirebaseAuth().uid,
                id_company: SessionHelper.getData().id_company,
                data
            }

            Firestore.insert(log, `log`, id);
        } catch (e) {
            return;
        }
    }
}