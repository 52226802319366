import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, Stepper, Step, StepLabel, FormControlLabel, Radio, FormLabel, Checkbox } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import DefaultSelect from '../components/DefaultSelect';
import SessionHelper from '../helper/SessionHelper';
import moment from 'moment';
import PieChartIcon from '@material-ui/icons/PieChart';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PermissionHelper from '../helper/PermissionHelper';
import ChartHelper from '../helper/ChartHelper';
import CompanyChart from '../components/CompanyChart';
import AddIcon from '@material-ui/icons/Add';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export default class ChartBuilderPage extends Component {

    state = {
        docs: [],
        accountPlanDocs: [],
        accountPackageDocs: [],
        budgetStructureDocs: [],
        resultCenterDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        chartModal: false,
        chartId: null,
        //Chart fields;
        description: '',
        selectedChart: 'line',
        selectedColumns: [],
        selectedType: 'monthly',
        start: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
        end: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
        horizontalAnalysisAccountPlan: '',
        verticalAnalysisAccountPlan: '',
        selectedDataType: 'account',
        dataSource: '',
        filterResultCenter: [],
        saveChart: true,
        //Chart model;
        charts: ChartHelper.getCharts(),
        chartColumns: ChartHelper.getColumns(),
        chartColumns2: ChartHelper.getColumns2(),
        activeStep: 0,
        steps: ['Seleção de Gráfico', 'Configuração', 'Fonte de Dados', 'Filtros', 'Visualização'],
        loadingText: 'Carregando gráficos...',
        editId: null,
        companyChart: false,
        isAdmin: false,
        inverted: false,
    }

    async getDocs() {

        let query = await Firestore.customQuery('charts').where('id_company', '==', SessionHelper.getData().id_company).where('id_user', '==', SessionHelper.getFirebaseAuth().uid).orderBy('description', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs });
    }

    async getBudgetStructure() {

        let query = await Firestore.customQuery('budget_structure').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };

            docs.push(data);
        });

        this.setState({ budgetStructureDocs: docs });
    }

    async getAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });
        this.setState({ accountPlanDocs: docs });
    }

    async getResultCenterDocs() {

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid);

        this.setState({ resultCenterDocs: docs });
    }

    async getAccountPackageDocs() {

        let query = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        await this.setState({ accountPackageDocs: docs });
    }

    async componentDidMount() {

        let isAdmin = await PermissionHelper.isSuperAdmin('result_center');

        await this.getAccountPlanDocs();
        await this.getResultCenterDocs();
        await this.getAccountPackageDocs();
        await this.getBudgetStructure();
        await this.getDocs();

        this.setState({ loading: false, isAdmin });
    }

    async handleNext() {

        let isValid = true;
        let step = this.state.activeStep;

        this.setState({ loadingModal: true });

        if (step === 1) {

            isValid = await this.validateChartConfig();

        } else if (step === 2) {

            isValid = await this.validateDataSource();
            
        } else if (step === 3) {

            isValid = true;

            if (this.state.editId) {

                await this.finish(true);
            }

        } else if (step === 4) {

            isValid = await this.finish();
        }

        if (isValid) { step ++ }

        this.setState({ activeStep: step, loadingModal: false });
    };

    handleBack() {

        let step = this.state.activeStep;
        step --;

        this.setState({ activeStep: step, inputError: false });
    };

    renderIcon(step) {

        return (
            <div style={{backgroundColor: this.state.activeStep < step ? 'lightgrey' : Colors.primary, width:30, height: 30, padding: 5, fontSize: 14, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{ color: '#FFFFFF' }}>{this.state.activeStep < step + 1 ? (step + 1) : <CheckIcon style={{ fontSize: 20, paddingTop: 3 }}/>}</div>
            </div>
        )
    }

    async validateDataSource() {

        let flag = true;

        if (!this.state.dataSource) {

            toast.warn('Selecione a fonte de dados do gráfico');
            flag = false;
        }

        return flag;
    }

    async validateChartConfig() {

        let flag = true;

        if (!this.state.description) {

            toast.warn('Preencha o nome do gráfico');
            flag = false;
        }

        if (!this.state.selectedColumns.length) {

            toast.warn('Selecione ao menos uma coluna para exibir');
            flag = false;
        }

        if (this.state.selectedColumns.includes('vertical_analysis') && !this.state.verticalAnalysisAccountPlan) {

            toast.warn('Selecione a conta contábil da análise vertical');
            flag = false;
        }

        if (!this.state.start) {

            toast.warn('Selecione o período inicial');
            flag = false;
        }

        if (!this.state.end) {

            toast.warn('Selecione o período final');
            flag = false;
        }

        return flag;
    }

    async finish(editing = false) {

        try {

            this.setState({ loadingModal: true });

            if (this.state.saveChart) {

                let data = {
                    description: this.state.description,
                    chart: this.state.selectedChart,
                    columns: this.state.selectedColumns,
                    period_type: this.state.selectedType,
                    start: this.state.start,
                    end: this.state.end,
                    vertical_analysis_account_plan: this.state.verticalAnalysisAccountPlan || '',
                    data_type: this.state.selectedDataType,
                    data_source: this.state.dataSource,
                    filter_result_centers: this.state.filterResultCenter,                    
                    id_company: SessionHelper.getData().id_company, 
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    company_chart: this.state.companyChart,
                    inverted: this.state.inverted,
                    date: new Date(),
                };

                if (this.state.editId) {
                    
                    await Firestore.update(data, 'charts', this.state.editId);

                } else {

                    await Firestore.insert(data, 'charts');    
                }

                await this.getDocs();

                toast.success('Gráfico salvo com sucesso');
            }

            if (!editing) {

                this.setState({ addModal: false });
            }

            return true;

        } catch (error) {

            toast.error('Houve um problema ao salvar o gráfico');
            return false;
        }
    }

    renderChartOptions() {

        return (

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel style={{ marginTop: 25 }} onChange={(v) => { this.setState({ saveChart: v.target.checked }) }} checked={this.state.saveChart} control={<Checkbox style={{ color: Colors.primary }} />} label={'Salvar gráfico ao finalizar'} />
                { this.state.saveChart && this.state.isAdmin ? <FormControlLabel style={{ }} onChange={(v) => { this.setState({ companyChart: v.target.checked }) }} checked={this.state.companyChart} control={<Checkbox style={{ color: Colors.primary }} />} label={'Salvar como gráfico geral da empresa'} /> : null }
            </div>
        )
    }

    addModal() {

        if (this.state.addModal) {

            return (
                <div>
                    <Stepper style={{ width: '100%' }} activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map((label, key) => (
                            <Step key={label}>
                                <StepLabel icon={this.renderIcon(key)}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    { this.state.activeStep === 0 && this.renderChartSelection() }
                    { this.state.activeStep === 1 && this.renderChartconfig() }
                    { this.state.activeStep === 2 && this.renderDataSource() }
                    { this.state.activeStep === 3 && this.renderFilters() }
                    { this.state.activeStep === 4 && this.renderChart() }
                    { this.state.activeStep === 4 && this.renderChartOptions() }

                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton leftIcon={<NavigateBeforeIcon/>} onClick={() => { this.handleBack() }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'Voltar'} disabled={this.state.activeStep === 0}/>
                        <DefaultButton rightIcon={<NavigateNextIcon/>} onClick={() => { this.handleNext() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={this.state.activeStep === this.state.steps.length - 1 ? 'Finalizar' : 'Avançar'} />
                    </div>
                </div>
            )
        }
    }

    chartModal() {

        if (this.state.chartModal && this.state.chartId) {

            return (
                <div>
                    
                    <CompanyChart id={this.state.chartId}/>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Button style={{ width: '100%', backgroundColor: Colors.primary, fontWeight: 'bold' }} variant="contained" color="primary" onClick={() => { this.setState({ chartModal: false, chartId: null }) }}>{'Fechar'}</Button>
                    </div>
                </div>
            )
        }
    }

    handleColumnCheck(column, value) {

        if (value) {

            let allColumns = [...this.state.chartColumns, ...this.state.chartColumns2];
            let type = column.type;
            let columns = this.state.selectedColumns;
            let valid = true;
            
            columns.forEach((value, key) => {

                let column = allColumns.find(item => item.value === value);

                if (column.type !== type) {

                    valid = false;
                }
            });

            if (valid) {

                columns.push(column.value);
                this.setState({ selectedColumns: columns });
            
            } else {

                toast.warn('Selecione apenas colunas com o mesmo tipo de unidade');
            }

        } else {

            let columns = this.state.selectedColumns;
            let index = columns.findIndex(item => item === column.value);
            
            columns.splice(index, 1);
            
            this.setState({ selectedColumns: columns });
        }
    }

    renderChart() {

        return (
            <CompanyChart
                id={null}
                doc={{
                    description: this.state.description,
                    chart: this.state.selectedChart,
                    columns: this.state.selectedColumns,
                    period_type: this.state.selectedType,
                    start: this.state.start,
                    end: this.state.end,
                    vertical_analysis_account_plan: this.state.verticalAnalysisAccountPlan,
                    data_type: this.state.selectedDataType,
                    data_source: this.state.dataSource,
                    filter_result_centers: this.state.filterResultCenter,
                    inverted: this.state.inverted, 
                }}
            />
        )
    }

    renderChartSelection() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25, marginBottom: 50, justifyContent: 'center' }}>
                {
                    this.state.charts.map((chart, key) => {
                        
                        return (
                            <div onClick={() => { this.setState({ selectedChart: chart.value }) }} style={{ display: 'flex', flexDirection: 'column', boxShadow: Colors.boxShadow, width: 250, height: 250, alignItems: 'center', justifyContent: 'center', border: this.state.selectedChart === chart.value ? `4px solid ${Colors.primary}` : '0.3px solid lightgrey', borderRadius: 3, marginLeft: 15, cursor: 'pointer' }}>
                                <img style={{ height: 64, width: 64 }} src={chart.img}/>
                                <FormControlLabel style={{ marginTop: 25 }} onChange={() => { this.setState({ selectedChart: chart.value }) }} checked={chart.value === this.state.selectedChart} value={chart.value} control={<Radio style={{ color: Colors.primary }} />} label={chart.name} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    async handlePeriodChange(type, value) {

        try {

            let date = moment(value).startOf('month').toDate();
            let state = { ...this.state };

            state[type] = date;

            let momentStart = moment(state['start']);
            let momentEnd = moment(state['end']);

            if (momentStart.isAfter(momentEnd)) {
                
                toast.warn('O período selecionado é inválido');

            } else {
    
                this.setState(state);
            }

        } catch (error) {

            toast.error('Houve um problema ao carregar os dados do período');
        }
    }

    renderChartconfig() {

        return (
            <div style={{ marginTop: 25, marginBottom: 50, justifyContent: 'center' }}>
                <DefaultInput defaultValue={this.state.description} required={true} label={'Nome do Gráfico'} onBlur={(v) => { this.setState({ description: v }) }} />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <FormLabel style={{ fontSize: 18 }}>Colunas</FormLabel>
                    <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'row' }}>
                        {
                            this.state.chartColumns.map((column, key) => {
                                return <FormControlLabel style={{ marginTop: 25 }} onChange={(v) => { this.handleColumnCheck(column, v.target.checked) }} checked={this.state.selectedColumns.includes(column.value)} control={<Checkbox style={{ color: Colors.primary }} />} label={column.name} />
                            })
                        }
                    </div>
                    <div style={{ marginLeft: 8, display: 'flex', flexDirection: 'row' }}>
                        {
                            this.state.chartColumns2.map((column, key) => {
                                return <FormControlLabel style={{ marginTop: 25 }} onChange={(v) => { this.handleColumnCheck(column, v.target.checked) }} checked={this.state.selectedColumns.includes(column.value)} control={<Checkbox style={{ color: Colors.primary }} />} label={column.name} />
                            })
                        }
                    </div>
                </div>

                {
                    this.state.selectedColumns.includes('vertical_analysis') ?

                        <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                            <DefaultSelect
                                search={true}
                                searchField={'description'}
                                displayField={'description'}
                                secondaryDisplay={'code'}
                                valueField={'id'}
                                value={this.state.verticalAnalysisAccountPlan}
                                onChange={(v) => {
                                    this.setState({ verticalAnalysisAccountPlan: v.target.value })
                                }}
                                docs={this.state.accountPlanDocs}
                                label={'Conta Contábil (Análise Vertical)'}
                            />
                        </div>

                    : null
                }

                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
                    <FormLabel style={{ fontSize: 18 }}>Exibição</FormLabel>
                    <div style={{ marginLeft: 8 }}>
                        <FormControlLabel style={{ marginTop: 25 }} onChange={() => { this.setState({ selectedType: 'monthly' }) }} checked={'monthly' === this.state.selectedType} value={'monthly'} control={<Radio style={{ color: Colors.primary }} />} label={'Mensal'} />
                        <FormControlLabel style={{ marginTop: 25 }} onChange={() => { this.setState({ selectedType: 'accumulated' }) }} checked={'accumulated' === this.state.selectedType} value={'accumulated'} control={<Radio style={{ color: Colors.primary }} />} label={'Período Acumulado'} />
                    </div>
                    <div style={{ marginLeft: 8, marginTop: 25 }}>
                        <FormControlLabel style={{  }} onChange={(v) => { this.setState({ inverted: v.target.checked }) }} checked={this.state.inverted} control={<Checkbox style={{ color: Colors.primary }} />} label={'Inverter Sinal'} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <FormLabel style={{ fontSize: 18 }}>Período de Exibição</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 8 }}>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'MM/yyyy'}
                                autoOk={true}
                                label="Início"
                                views={["year", "month"]}
                                openTo={'month'}
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                onChange={async (v) => { this.handlePeriodChange('start', v) }}
                                value={this.state.start}
                            />
                        </MuiPickersUtilsProvider>
                        <div style={{ marginLeft: 8, marginRight: 8 }}/>
                        <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
                                invalidDateMessage={false}
                                format={'MM/yyyy'}
                                autoOk={true}
                                openTo={'month'}
                                views={["year", "month"]}
                                label="Fim"
                                cancelLabel={'Cancelar'}
                                okLabel={'Confirmar'}
                                minDate={this.state.start}
                                onChange={async (v) => { this.handlePeriodChange('end', v) }}
                                value={this.state.end}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
        )
    }

    renderFilters() {

        return (
            <div style={{ marginTop: 25, marginBottom: 50, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
                    <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                        <DefaultSelect
                            search={true}
                            searchField={['description', 'code']}
                            displayField={'description'}
                            secondaryDisplay={'code'}
                            valueField={'id'}
                            multiple={true}
                            value={this.state.filterResultCenter}
                            onChange={(v) => {
                                this.setState({ filterResultCenter: v.target.value })
                            }}
                            docs={this.state.resultCenterDocs}
                            label={'Centros de Resultado'}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderDataSource() {
        
        return (
            <div style={{ marginTop: 25, marginBottom: 50, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 8 }}>
                    <FormLabel style={{ fontSize: 18 }}>Selecione a Fonte de Dados</FormLabel>
                    <div style={{ marginLeft: 20 }}>
                        <FormControlLabel style={{ marginTop: 25 }} onChange={() => { this.setState({ selectedDataType: 'account', dataSource: '' }) }} checked={'account' === this.state.selectedDataType} value={'account'} control={<Radio style={{ color: Colors.primary }} />} label={'Conta Contábil'} />
                        <FormControlLabel style={{ marginTop: 25 }} onChange={() => { this.setState({ selectedDataType: 'account_package', dataSource: '' }) }} checked={'account_package' === this.state.selectedDataType} value={'account_package'} control={<Radio style={{ color: Colors.primary }} />} label={'Pacote de Contas'} />
                        <FormControlLabel style={{ marginTop: 25 }} onChange={() => { this.setState({ selectedDataType: 'budget_structure', dataSource: '' }) }} checked={'budget_structure' === this.state.selectedDataType} value={'budget_structure'} control={<Radio style={{ color: Colors.primary }} />} label={'Estrutura do Orçamento'} />
                    </div>
                    
                        { this.state.selectedDataType === 'account' ?

                            <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                                <DefaultSelect
                                    search={true}
                                    searchField={['description', 'code']}
                                    displayField={'description'}
                                    secondaryDisplay={'code'}
                                    valueField={'id'}
                                    value={this.state.dataSource}
                                    onChange={(v) => {
                                        this.setState({ dataSource: v.target.value })
                                    }}
                                    docs={this.state.accountPlanDocs}
                                    label={'Conta Contábil'}
                                />
                            </div>

                        : null }
                    
                        { this.state.selectedDataType === 'account_package' ?

                            <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                                <DefaultSelect
                                    search={true}
                                    searchField={'description'}
                                    displayField={'description'}
                                    secondaryDisplay={'code'}
                                    valueField={'id'}
                                    value={this.state.dataSource}
                                    onChange={(v) => {
                                        this.setState({ dataSource: v.target.value })
                                    }}
                                    docs={this.state.accountPackageDocs}
                                    label={'Pacote de Contas'}
                                />
                            </div>

                        : null }

                        { this.state.selectedDataType === 'budget_structure' ?

                            <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                                <DefaultSelect
                                    search={true}
                                    searchField={'name'}
                                    displayField={'name'}
                                    valueField={'id'}
                                    value={this.state.dataSource}
                                    onChange={(v) => {
                                        this.setState({ dataSource: v.target.value })
                                    }}
                                    docs={this.state.budgetStructureDocs}
                                    label={'Estrutura do Orçamento'}
                                />
                            </div>

                        : null }
                </div>
            </div>
        )   
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    <DefaultButton leftIcon={<AddIcon/>} onClick={() => {

                        this.setState({
                            addModal: true,
                            description: '',
                            selectedChart: 'line',
                            selectedColumns: [],
                            selectedType: 'monthly',
                            start: moment().subtract(1, 'month').startOf('month').startOf('day').toDate(),
                            end: moment().subtract(1, 'month').endOf('month').endOf('day').toDate(),
                            horizontalAnalysisAccountPlan: '',
                            verticalAnalysisAccountPlan: '',
                            selectedDataType: 'account',
                            dataSource: '',
                            filterResultCenter: [],
                            activeStep: 0,
                            editId: null,
                        })

                        }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Gerador de Gráficos'}
                    marginTop={10}
                    actions={[
                        {
                            icon: PieChartIcon,
                            tooltip: 'Visualizar Gráfico',
                            onClick: async (event, rowData) => { this.setState({ chartModal: true, chartId: rowData.id }) }
                        },
                        {
                            icon: EditIcon,
                            tooltip: 'Editar Gráfico',
                            onClick: async (event, rowData) => { 
                                this.setState({
                                    activeStep: 0,
                                    addModal: true,
                                    editId: rowData.id,
                                    description: rowData.description,
                                    selectedChart: rowData.chart,
                                    selectedColumns: rowData.columns,
                                    selectedType: rowData.period_type,
                                    start: rowData.start.toDate(),
                                    end: rowData.end.toDate(),
                                    verticalAnalysisAccountPlan: rowData.vertical_analysis_account_plan || '',
                                    selectedDataType: rowData.data_type,
                                    dataSource: rowData.data_source,
                                    filterResultCenter: rowData.filter_result_centers || [],
                                    inverted: rowData.inverted ? true : false,
                                    companyChart: rowData.company_chart ? true : false,
                                })
                            }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description', editable: false },
                        { title: 'Data de Criação', editable: false, field: 'date', render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm')}</div> },
                    ]}
                    data={this.state.docs}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {
                            await Firestore.delete('charts', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.addModal()} title={`${this.state.editId ? 'Editar' : 'Novo'} Gráfico`} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal width={window.screen.width - (window.screen.width * 0.15)} loading={this.state.loadingModal} content={this.chartModal()} title={'Visualizar Gráfico'} onClose={() => { this.setState({ chartModal: false }) }} open={this.state.chartModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
