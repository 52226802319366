import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import { List, ListItem, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SessionHelper from '../helper/SessionHelper';
import firebase from 'firebase/app';
import { ToastContainer } from 'react-toastify';
import DefaultButton from '../components/DefaultButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

moment.locale('pt-br');

export default class ConsultantPage extends Component {

    state = {
        loading: false,
        docs: [],
        auxDocs: [],
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getDocs();

        await this.setState({ loading: false });
    }

    async getDocs() {

        let docs = [];

        if (SessionHelper.getData().companies && SessionHelper.getData().companies.length) {

            let query = await Firestore.customQuery('company').where(firebase.firestore.FieldPath.documentId(), 'in', SessionHelper.getData().companies).get();
    
            query.forEach((doc, key) => {
    
                let data = doc.data();
                data.id = doc.id;
    
                docs.push(data);
            });
        }

        this.setState({ docs: docs, auxDocs: docs });
    }

    async handleSearch(text) {

        let search = text.toLowerCase();

        await this.setState({ search: search });

        if (this.state.search.toString().length > 2) {

            let result = [];

            if (this.state.auxDocs.length) {

                let array = this.state.auxDocs;

                result = array.filter((company) => {

                    const name = company.name ? company.name.toLowerCase() : ''.toLowerCase();
                    const cnpj = company.cnpj ? company.cnpj.toString().replace(/\D+/g, '').toLowerCase() : ''.toLowerCase();

                    const search = this.state.search.toString().toLowerCase();

                    if (name.indexOf(search) > -1) {

                        return true;
                    }

                    if (cnpj.indexOf(search) > -1) {

                        return true;
                    }
                });
            }

            this.setState({ docs: result });

        } else if (this.state.search.toString().length === 0) {

            this.getDocs();
        }
    }

    enterCompany(doc) {

        if (doc) {

            let session = SessionHelper.getData();
            session.id_company = doc.id;
            session.company = doc;

            SessionHelper.setData(session);

            this.props.app.forceUpdate();
        }
    }

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    renderCompanies() {

        return this.state.docs.map((doc, key) => {

            return (
                <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: this.state.docs[key + 1] ? '1px solid lightgrey' : '', padding: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '60%' }}>
                        <div style={{ minWidth: 250 }}>
                            <img style={{ height: 80, marginRight: 20, width: 250 }} src={doc.logo ? doc.logo : 'http://placehold.it/900x300'}/>
                        </div>
                        <div style={{ }}>
                            <div style={{ fontWeight: 'bold' }}>{`${doc.name} • ${doc.cnpj}`}</div>
                            <div style={{ color: 'grey', marginTop: 8 }}>{`${doc.street}, ${doc.number}${doc.complemento}, ${doc.neighborhood}. ${doc.city} - ${doc.state}, ${doc.zipcode}.`}</div>
                            <div style={{ color: 'grey' }}>{`${doc.email} • ${doc.phone ? doc.phone : 'Sem Telefone'}`}</div>
                            <div style={{ color: 'grey', fontSize: 12, marginTop: 8 }}>{`Na plataforma desde ${moment(doc.created_at.toDate()).format('DD/MM/YYYY')}`}</div>
                        </div>
                    </div>
                    <div>
                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title={'Acessar Empresa'}>
                            <div>
                                <DefaultButton onClick={() => { this.enterCompany(doc) }} color={Colors.primary} loading={this.state.loadingModal} title={''} leftIcon={<ExitToAppIcon />}/>
                            </div>
                        </Tooltip>                       
                    </div>  
                </ListItem>
            )
        });
    }

    renderEmpty() {

        return (
            <p style={{ textAlign: 'center', color: 'grey' }}>{'Nenhuma empresa encontrada.'}</p>
        )
    }

    renderSearch() {

        return (
            <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20, borderBottom: '1px solid lightgrey' }}>
                <input value={this.state.search} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{ width: '100%', border: '1px solid lightgrey', padding: 20, borderRadius: 10, backgroundColor: 'white', boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', fontSize: 16 }} placeholder={'Busque por nome e cnpj...'}/>
                { this.state.search ? <ClearIcon onClick={() => { this.setState({ search: '' }); this.getDocs(); }} style={{ fontSize: 34, cursor: 'pointer', position: 'absolute', right: 0, marginRight: 40  }} /> : <SearchIcon style={{ fontSize: 34, cursor: 'pointer', position: 'absolute', right: 0, marginRight: 40  }} /> }
            </ListItem>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ backgroundColor: Colors.primary, padding: 50, height: '100vh' }}>
                <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, marginBottom: 15 }}>
                    <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                            {'Seja bem vindo, '}
                            <div style={{ fontWeight: 'bold', paddingLeft: 5 }}>{SessionHelper.getData().name}.</div>
                        </div>
                        <a style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { this.logout() }}>Sair</a>
                    </ListItem>
                </List>
                <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5 }}>
                    { this.renderSearch() }
                    { this.renderCompanies() }
                    { !this.state.docs.length && this.renderEmpty() }
                </List>
                <ToastContainer />
            </div>
        );
    }
}
