import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CalculusHelper from '../helper/CalculusHelper';
import TableHelper from '../helper/TableHelper';

export default class QuantityTable extends Component {
    render() {
        let table;

        let rows = this.props.rows;

        let plannedCounter = 0;
        let historyCounter = 0;

        table = (
            <div style={styles.tableContainer}>
                <TableContainer>
                    <Table id={'revenue-planning'}>
                        {TableHelper.getTableHeader(rows, true, !this.props.hideTotal)}
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Quantidade</TableCell>
                                {rows.map((period, key) => {
                                    plannedCounter += period.multiplier;
                                    return <TableCell align={this.props.state.editingData && this.props.state.rowId == period.id ? "center" : "right"} onDoubleClick={!this.props.readOnly ? () => { this.props.setParentState({ editingData: true, rowId: period.id, editingRows: rows, editingField: 'multiplier', editInputValue: period.multiplier }); } : () => { }}>
                                        {this.props.state.editingData && this.props.state.rowId == period.id && this.props.state.editingField == 'multiplier' ? this.props.renderInputEditing(period.multiplier) : CalculusHelper.formatFloat(period.multiplier)}
                                    </TableCell>
                                })}
                                {TableHelper.getMeanCell('money', plannedCounter, rows, 'multiplier')}
                                {this.props.hideTotal ? null :
                                    TableHelper.getTotalCell('money', plannedCounter)
                                }
                            </TableRow>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Histórico</TableCell>
                                {rows.map((period, key) => {
                                    historyCounter += period.multiplier_history;
                                    return <TableCell align={this.props.state.editingData && this.props.state.rowId == period.id ? "center" : "right"} onDoubleClick={!this.props.readOnly ? () => { this.props.setParentState({ editingData: true, rowId: period.id, editingRows: rows, editingField: 'multiplier_history', editInputValue: period.multiplier_history }); } : () => { }}>
                                        {this.props.state.editingData && this.props.state.rowId == period.id && this.props.state.editingField == 'multiplier_history' ? this.props.renderInputEditing(period.multiplier_history) : CalculusHelper.formatFloat(period.multiplier_history)}
                                    </TableCell>
                                })}
                                {TableHelper.getMeanCell('money', historyCounter, rows, 'multiplier_history')}
                                {this.props.hideTotal ? null :
                                    TableHelper.getTotalCell('money', historyCounter)
                                }
                            </TableRow>

                            {TableHelper.getVariationRow(
                                rows,
                                false,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.multiplier,
                                        period.multiplier_history,
                                        false
                                    );
                                },
                                true,
                                !this.props.hideTotal
                            )}
                            {TableHelper.getVariationRow(
                                rows,
                                true,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.multiplier,
                                        period.multiplier_history,
                                        true
                                    );
                                },
                                true,
                                !this.props.hideTotal
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        );

        return table;
    }
}

const styles = {
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    }
}