import React, { Component } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DefaultLoader from '../components/DefaultLoader';
import DefaultInput from '../components/DefaultInput';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import brLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
export default class TableEditingInput extends Component {
    handleShortcut = (evt, percentage) => {
        if (this.props.state.savingEdit == false) {
            if (evt.key === "Enter" && evt.shiftKey == false) {
                this.props.saveEdit(percentage);
            } else if (evt.key === "Enter" && evt.shiftKey == true) {
                this.props.saveEdit(percentage, true);
            } else if (evt.key === "Escape") {
                evt.preventDefault();
                this.cancelEdit();
            }
        }
    }

    cancelEdit() {
        this.props.setParentState({ editingData: false, editInputValue: this.props.defaultValue, editInputIndex: 0 });
    }

    render() {
        let defaultValue = this.props.defaultValue;
        let percentage = this.props.percentage;

        return (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: 250, justifyContent: 'space-evenly' }}>
                {this.props.type && this.props.type == 'date' ?
                    <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                        <DatePicker
                            minDate={this.props.minDate ? this.props.minDate.toDate() : undefined}
                            maxDate={this.props.maxDate ? this.props.maxDate.toDate() : undefined}
                            maxDateMessage={'Fora do perídodo máximo do registro'}
                            minDateMessage={'Fora do perídodo mínimo do registro'}
                            style={{ maxWidth: 120, minWidth: 120 }}
                            invalidDateMessage={false}
                            clearable
                            autoFocus
                            format={'MM/yyyy'}
                            autoOk={true}
                            openTo={'month'}
                            views={["year", "month"]}
                            clearLabel={'Limpar'}
                            cancelLabel={'Cancelar'}
                            okLabel={'Confirmar'}
                            onChange={(value) => { this.props.setParentState({ editInputValue: value != null ? (moment(value).startOf('month')).toDate() : null }); setTimeout(() => { this.dateInput.focus() }, 100); }}
                            value={this.props.state.editInputValue != null && this.props.state.editInputValue.toDate ? this.props.state.editInputValue.toDate() : this.props.state.editInputValue}
                            TextFieldComponent={props => (
                                <TextField
                                    {...props}
                                    inputRef={(ref) => { this.dateInput = ref }}
                                    onKeyDown={(evt) => { this.handleShortcut(evt, percentage) }}
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                    :
                    <div style={{ minWidth: 100, maxWidth: 200 }}>
                        <DefaultInput
                            InputProps={percentage == true ? { inputProps: { min: 0, max: 100 } } : {}}
                            autoFocus={true}
                            width={50}
                            onKeyDown={(evt) => { this.handleShortcut(evt, percentage) }}
                            type={this.props.type ? this.props.type : 'number'}
                            defaultValue={defaultValue}
                            value={this.props.state.editInputValue}
                            onChange={(value) => {
                                this.props.setParentState({ editInputValue: value })
                            }}
                        />
                    </div>
                }

                {this.props.state.savingEdit == false ?
                    <div style={{ display: 'flex', flexDirection: 'row', maxWidth: 60 }} className={'header-actions-buttons'}>
                        <IconButton onClick={() => { this.props.saveEdit(percentage) }}>
                            <CheckIcon style={{ color: 'green' }} />
                        </IconButton>
                        <IconButton onClick={() => { this.cancelEdit() }}>
                            <ClearIcon style={{ color: 'red' }} />
                        </IconButton>
                    </div>
                    :
                    <div style={{ width: 80, paddingTop: 10 }}>
                        <DefaultLoader size={20} />
                    </div>
                }

            </div>
        );
    }
}