import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Input, TextareaAutosize, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import ExcelModal from '../components/ExcelModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import Functions from '../api/firebase/Functions'
import Add from '@material-ui/icons/AddBox';
import Publish from '@material-ui/icons/Publish';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import firebase from 'firebase/app'
import 'firebase/firestore';
import ExcelImportValidators from '../constants/ExcelImportValidators';
import DataHelper from '../helper/DataHelper';

export default class AccountPlanPage extends Component {

    state = {
        docs: [],
        permissionDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        confirmModal: false,
        editId: null,
        description: null,
        code: null,
        observation: '',
        loadingText: '',
        parentId: false,
        parentName: '',
        initialRecursiveId: '',
        excelModal: false,
        duplicates: [],
        codesIndex: [],
        duplicateModal: false,
        insertedCounter: 0,
        colsub: [
            { name: 'parent_id', alias: 'Conta Pai', required: false, example: '3.1 (Deixe o campo vazio caso esta seja uma Conta Pai)' },
            { name: 'description', alias: 'Descrição', required: true, example: 'Venda de Mercadorias' },
            { name: 'code', alias: 'Código', required: true, example: '3.1.1', validators: [ExcelImportValidators.trim] },
            { name: 'observation', alias: 'Observação', required: true, example: 'Receitas' }
        ],
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    codeExists = async (code) => {
        let exists = false;

        if (code) {
            const query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).where('code', '==', code).limit(1).get();

            if (query.docs.length > 0) {
                exists = true;
            }
        } else {
            exists = true;
        }


        return exists;
    }

    excelModalCodeExists = async (doc) => {
        let exists = false;
        let duplicates = this.state.duplicates;
        let codesIndex = this.state.codesIndex;

        if (doc.code) {
            if (codesIndex.length < 1) {
                const query = await Firestore.customQuery('account_plan').where('id_company', '==', doc.id_company).get();

                if (query.docs && query.docs.length > 0) {
                    for (let document of query.docs) {
                        if (document.data().code) {
                            if (!codesIndex.includes(document.data().code)) codesIndex.push(document.data().code);
                        }
                    }
                }
            }

            if (codesIndex.includes(`${doc.code}`) || codesIndex.includes(doc.code)) {
                exists = true;
                duplicates.push(doc);
            } else {
                exists = false;
                codesIndex.push(doc.code);
            }

        } else {
            exists = true;
            duplicates.push(doc);
        }

        await this.setState({ duplicates, codesIndex });

        return exists;
    }

    async addAccount(parentId) {

        if (this.state.code && this.state.description && SessionHelper.getData().id_company) {

            let data = {
                description: this.state.description,
                code: this.state.code,
                observation: this.state.observation,
                id_company: SessionHelper.getData().id_company
            };

            if (parentId) {
                data.parent_id = parentId;
            }

            try {
                if ((await this.codeExists(data.code)) == false) {
                    this.setState({ loadingModal: true });
                    let childId;

                    if (parentId) {
                        childId = await Firestore.getId('account_plan');

                        await Firestore.insert(data, 'account_plan', childId);
                    } else {
                        await Firestore.insert(data, 'account_plan');
                    }

                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    if (parentId && childId) {
                        await this.addChildToParent(parentId, childId);
                    }

                    await this.getDocs();
                } else {
                    toast.error("Este código já existe", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return;
                }
            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {
            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });

            return;
        }

        this.setState({ loadingModal: false, addModal: false, description: null, code: null, observation: '', codesIndex: [] });
    }

    async insertChild(parentId, parentName) {
        this.setState({ addModal: true, parentId, parentName });
    }

    async addChildToParent(parentId, childId) {
        let parent = (await Firestore.getDoc('account_plan', parentId)).data();

        if (!parent.children) parent.children = [];

        parent.children.push(childId);

        await Firestore.update(parent, 'account_plan', parentId);
    }

    async addChildToDefinedParent(parentId, childId) {
        await Firestore.update({ children: firebase.firestore.FieldValue.arrayUnion(childId) }, 'account_plan', parentId);
    }

    async removeChildFromParent(parentId, childId) {
        let parent = (await Firestore.getDoc('account_plan', parentId)).data();

        if (parent.children) {
            if (parent.children.includes(childId)) {
                const index = parent.children.indexOf(childId);

                parent.children.splice(index, 1);

                await Firestore.update(parent, 'account_plan', parentId);
            }
        }
    }

    async recursiveDeleteChildren(childrenNode) {
        if (childrenNode == null) {
            return;
        }

        let nextChildrenNode = null;

        this.setState({ loading: true });

        for (let i = 0; i < childrenNode.length; i++) {
            let children = (await Firestore.getDoc('account_plan', childrenNode[i])).data();

            await Firestore.delete('account_plan', childrenNode[i]);

            if (children && children.children) {
                if (children.children.length > 0) {
                    nextChildrenNode = children.children;
                }
            }
        }

        await this.recursiveDeleteChildren(nextChildrenNode);
    }

    async getAccountParent(parent) {

        let account = await Firestore.customQuery('account_plan').where('code', '==', parent).where('id_company', '==', SessionHelper.getData().id_company).limit(1).get();

        if (account.size === 1) {

            return account.docs[0].id;
        }

        return null;
    }

    async excelModalParentExists(doc) {

        let account = await Firestore.customQuery('account_plan').where('code', '==', doc.parent_id).where('id_company', '==', SessionHelper.getData().id_company).limit(1).get();
        let duplicates = this.state.duplicates;

        if (account.size === 1) {

            return true;
        }

        duplicates.push(doc);
        await this.setState({ duplicates });

        return false;
    }

    handleExcelImport = async (data) => {
        if (data.length > 0) {
            this.setState({ loading: true });

            try {

                let chunks = DataHelper.createSubArrays(data, 500);
                let count = 0;

                await Promise.all(chunks.map( async (chunk) => {

                    for (let i = 0; i < chunk.length; i++) {
    
                        if (!chunk[i].parent_id || (chunk[i].parent_id && await this.excelModalParentExists(chunk[i])) == true) {
    
                            if (chunk[i].parent_id) {
    
                                chunk[i].parent_id = await this.getAccountParent(chunk[i].parent_id);
                            }
        
                            if ((await this.excelModalCodeExists(chunk[i])) == false) {
                                
                                let generatedId = await Firestore.getId('account_plan');

                                chunk[i].code = chunk[i].code.toString();
                                await Firestore.insert(chunk[i], 'account_plan', generatedId);
        
                                if (chunk[i].parent_id) {
                                    
                                    await this.addChildToDefinedParent(chunk[i].parent_id, generatedId);
                                }
                            }
                        }

                        count++;
                        this.setState({ loadingText: `Inserindo ${count} de ${data.length}` })
                    }
                }));

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                this.setState({ insertedCounter: data.length - this.state.duplicates.length });

                toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });


            } catch (error) {

                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(error);
            }

            await this.getDocs();

            this.setState({ loading: false, loadingText: `` });
        }

        this.setState({ codesIndex: [] });
    }

    filterAccountCode(term, code) {



        return code.toString().indexOf(term.toString()) === 0 ? true : false;
    }

    addModal() {
        return (
            <div>
                {this.state.parentId && this.state.parentName ? <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 18 }} component="legend">Conta Filha de {this.state.parentName}</FormLabel> : null}
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <DefaultInput required={true} label={'Código'} onBlur={(v) => { this.setState({ code: v }) }} />
                <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Observação</FormLabel>
                <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.observation} onChange={(v) => { this.setState({ observation: v.target.value }) }} placeholder="Escreva uma descrição..." />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addAccount(this.state.parentId) }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'}/>
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'}/>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true, parentId: false }) }} title={'Adicionar'} />
                        <div style={{ paddingLeft: 10 }}/>
                        <DefaultButton leftIcon={<DescriptionIcon/>} onClick={() => { this.setState({ excelModal: true }) }} title={'Importar do Excel (.xlsx)'} width={290} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>

                <DefaultTable
                    title={'Plano de Contas'}
                    marginTop={10}
                    filtering={true}
                    search={false}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Código', field: 'code', customFilterAndSearch: (term, rowData) => this.filterAccountCode(term, rowData.code) },
                        { title: 'Observação', field: 'observation' }
                    ]}
                    actions={[
                        {
                            icon: Add,
                            tooltip: 'Adicionar Conta Filha',
                            onClick: async (event, rowData) => {
                                await this.insertChild(rowData.id, rowData.description);
                            }
                        },
                    ]}
                    rowStyle={(rowData, index, level) => ({ borderInlineStart: `${level * 10}px solid ${Colors.primary}` })}
                    deleteText={'Apagar essa conta apagará todas as contas filhas também, tem certeza que deseja apagar?'}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if (oldData.code != newData.code && await this.codeExists(newData)) {
                            toast.warn("O código informado já existe", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return;
                        }

                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev[index] = newData;

                        this.setState({ docs: prev })
                        if (oldData.id) {

                            let data = {};
                            Object.assign(data, newData);
                            if (data.tableData) {
                                delete data['tableData'];
                            }

                            await Firestore.update(data, 'account_plan', oldData.id);

                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.forceUpdate();

                        return prev;
                    }}
                    treeView={true}
                    onRowDelete={async (oldData) => {
                        if (oldData.id) {
                            this.setState({ loading: true });
                            if (!oldData.children && !oldData.parent_id) {
                                //No nodes under it
                                await Firestore.delete('account_plan', oldData.id);
                            } else if (oldData.children && oldData.children.length > 0) {
                                //Has nodes under it
                                await this.recursiveDeleteChildren(oldData.children);
                                if (oldData.parent_id) {
                                    //Has a parent
                                    await this.removeChildFromParent(oldData.parent_id, oldData.id);
                                }
                                await Firestore.delete('account_plan', oldData.id);
                            } else {
                                await Firestore.delete('account_plan', oldData.id);
                            }

                            await this.getDocs();

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Conta Pai'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
