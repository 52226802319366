import moment from "moment";
import Colors from "../constants/Colors";
import Functions from "../api/firebase/Functions";
import PermissionHelper from "./PermissionHelper";
import SessionHelper from "./SessionHelper";

export default class ActionPlanHelper {

    static getStatus(tasks, raw = false) {

        let color = '';
        let value = '';
        let ocorrendo = false;
        let statusEtapas = [];

        if (!tasks.length) {

            value = 'not_started'; 
            color = 'lightgrey';
        }

        statusEtapas = tasks.map((task, key) => {
            
            return this.etapaAtrasada(task) && ['finished', 'canceled'].includes(task.situation) ? -1 : task.situation;
        });

        if (statusEtapas.includes(-1)) {

            value = 'late';
            color = Colors.danger;
        }

        statusEtapas = statusEtapas.filter((status, key) => {

            return status != -1 && status != 'canceled';
        });

        if (!statusEtapas.length && !ocorrendo) {

            value = 'canceled';
            color = Colors.error;
        }

        ocorrendo = statusEtapas.includes('finished');
        
        statusEtapas = statusEtapas.filter((status, key) => {

            return status != 'finished';
        });

        if (!statusEtapas.length) {

            value = 'finished';
            color = Colors.success;
        }

        statusEtapas = statusEtapas.filter((status, key) => {

            return status != -1 && status != 'not_started';
        });

        if (!statusEtapas.length && !ocorrendo) {

            value = 'not_started';
            color = 'lightgrey';
        }

        if (value === '') {

            value = 'started';
            color = Colors.info;
        }

        if (raw) {

            return value;
        }

        return <div style={{ backgroundColor: color, color: 'white', fontWeight: 'bold', textAlign: 'center', width: 170, padding: 3, borderRadius: 5 }}>{this.getStatuses()[value]}</div>;
    }

    static etapaAtrasada(task) {

        let end = moment(task.end.toDate ? task.end.toDate() : task.end);

        if (moment().startOf('day').isAfter(end.startOf('day'))) {

            return true;
        }

        return false;
    }

    static getStatuses() {

        return {
            'not_started': 'Não Iniciado',
            'late': 'Atrasado',
            'started': 'Em Desenvolvimento',
            'canceled': 'Cancelado',
            'finished': 'Concluído',
        };
    }

    static getTaskStatuses() {

        return {
            'not_started': 'Não Iniciada',
            'started': 'Em Desenvolvimento',
            'canceled': 'Cancelada',
            'finished': 'Concluída',
        };
    }

    static getTaskSituation(situation) {

        return this.getTaskStatuses[situation];
    }

    static getTaskBackgroundColor(task, alpha = 0.2) {

        let color = '';

        if (task.situation === 'finished') {

            color = `rgba(89, 166, 61, ${alpha})`;

        } else if (task.situation === 'not_started') {

            let end = moment(task.end.toDate ? task.end.toDate() : task.end);
            
            if (moment().startOf('day').isAfter(end.startOf('day'))) {

                color = `rgba(215, 77, 77, ${alpha})`;

            } else {

                color = 'white';
            }
        
        } else if (task.situation === 'started') {
         
            color = `rgba(245, 190, 64, ${alpha})`;
        }
        
        return color;
    }

    static async sendEmailParticipants(id_responsable, id_participants, id_action_plan) {

        try {

            await Functions.request('POST', 'actionPlan/sendEmailParticipants', { id_participants, id_responsable, id_action_plan });

            return true;

        } catch (error) {

            return false;
        }
    }
    
    static async sendEmailAvaliador(id_evaluator, id_responsable, id_action_plan) {

        try {

            await Functions.request('POST', 'actionPlan/sendEmailAvaliador', { id_evaluator, id_responsable, id_action_plan });

            return true;

        } catch (error) {

            return false;
        }
    }

    static async sendMailEtapas(id_action_plan, id_user, filter_tasks = []) {

        try {

            await Functions.request('POST', 'actionPlan/sendMailEtapas', { id_user, id_action_plan, filter_tasks });

            return true;

        } catch (error) {

            return false;
        }
    }

    static async sendMailResponsavel(id_responsable, id_user, id_action_plan) {

        try {

            await Functions.request('POST', 'actionPlan/sendMailResponsable', { id_responsable, id_user, id_action_plan });

            return true;

        } catch (error) {

            return false;
        }
    }

    static async sendMailEtapaConcluida(status, id_action_plan, task, action_plan_status) {

        try {

            await Functions.request('POST', 'actionPlan/sendMailResponsable', { status, id_action_plan, task, action_plan_status });

            return true;

        } catch (error) {

            return false;
        }
    }

    static async sendMailPlanoAprovado(id_action_plan) {

        try {

            await Functions.request('POST', 'actionPlan/sendMailPlanoAprovado', { id_action_plan });

            return true;

        } catch (error) {

            return false;
        }
    }

    static async sendMailPlanoRejeitado(id_action_plan) {

        try {

            await Functions.request('POST', 'actionPlan/sendMailPlanoRejeitado', { id_action_plan });

            return true;

        } catch (error) {

            return false;
        }
    }

    static async sendMailCertificacao(id_action_plan) {

        try {

            await Functions.request('POST', 'actionPlan/sendMailCertificacao', { id_action_plan });

            return true;

        } catch (error) {

            return false;
        }
    }

    static canCertificate(actionPlan) {

        let tasks = actionPlan.tasks;

        if (tasks.length <= 0) {

            return false;
        
        } else {

            let result = true;

            tasks.forEach((task, key) => {

                if (task.situation !== 'finished') {

                    result = false;
                    return;
                }
            });

            return result;
        }
    }

    static async canApplyCertification(actionPlan) {

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');

        if (!this.canCertificate(actionPlan)) {

            return false;
        }

        if (actionPlan.certificated) {
            
            return false;
        }

        if (actionPlan.evaluate_date) {

            return actionPlan.id_evaluator === SessionHelper.getFirebaseAuth().uid || isSuperAdmin;

        } else {

            return actionPlan.id_responsable == SessionHelper.getFirebaseAuth().uid || isSuperAdmin;
        }
    }
}