import React, { Component } from 'react';
import { Button, InputLabel, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import Colors from '../constants/Colors';
import { toast } from 'react-toastify';
import Storage from '../api/firebase/Storage';

export default class FilePicker extends Component {

    state = {
        files: this.props.files || [],
        photoBlob: null,
        hover: false,
    }

    async upload(files) {

        try {

            if (this.props.onUploadStart) {
                this.props.onUploadStart();
            }

            let filesArray = Array.from(files);
            let links = this.state.files;

            for (let index = 0; index < filesArray.length; index++) {
                
                if (filesArray[index].size <= 50000000) {

                    let ref = await Storage.uploadFile(this.props.folder || '', filesArray[index]);
                    let downloadPath = await Storage.getFileFromRef(ref);
            
                    if (!downloadPath) {
                        throw new Error('upload error');
                    }
    
                    let data = {
                        name: filesArray[index].name,
                        size: filesArray[index].size,
                        link: downloadPath,
                        date: new Date(),
                    } 
    
                    links.push(data);
        
                    toast.success("Arquivo anexado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });   
                
                } else {

                    toast.warn('O arquivo selecionado é maior do que 50MB');
                }
            }

            if (this.props.onUpload) {
                this.props.onUpload(links);
            }

            await this.setState({ files: links });

            if (this.props.onUploadEnd) {
                this.props.onUploadEnd();
            }

        } catch (error) {

            toast.error("Houve um problema ao anexar o arquivo", {
                position: toast.POSITION.TOP_RIGHT
            });

            if (this.props.onUploadEnd) {
                this.props.onUploadEnd();
            }
        }
    }

    async delete(file, key) {

        let confirm = window.confirm('Tem certeza que deseja remover?');

        if (confirm) {

            if (this.state.files.includes(file)) {

                try {
    
                    if (this.props.onUploadStart) {
                        this.props.onUploadStart();
                    }
    
                    await Storage.removeFile(file.link);
    
                    let files = this.state.files;
                    files.splice(key, 1);
    
                    if (this.props.onUpload) {
                        this.props.onUpload(files);
                    }
        
                    await this.setState({ files: files });
        
                    if (this.props.onUploadEnd) {
                        this.props.onUploadEnd();
                    }
    
                    toast.success("Anexo removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });  
    
                } catch (error) {
    
                    toast.error("Houve um problema ao remover o anexo", {
                        position: toast.POSITION.TOP_RIGHT
                    });
        
                    if (this.props.onUploadEnd) {
                        this.props.onUploadEnd();
                    }
                }
            }
        }
    }

    renderFiles() {
        return this.state.files.map((file, key) => {
            return (

                <div style={{ position: 'relative', cursor: 'pointer' }} onMouseOver={() => { this.setState({ hover: true }) }} onMouseLeave={() => { this.setState({ hover: false }) }}>
                    { this.state.hover && <DeleteIcon onClick={() => { this.delete(file, key) }} style={{ color: '#FFF', cursor: 'pointer', position: 'absolute', top: 0, left: 0, margin: 5, marginTop: 15 }} /> }
                    <div onClick={() => { window.open(file.link, '_blank') }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: this.props.iconHeight ? this.props.iconHeight : 250, width: this.props.iconWidth ? this.props.iconWidth : 150, backgroundColor: Colors.primary, borderRadius: 5, marginRight: 10, marginTop: 10 }}>
                        <DescriptionIcon style={{ fontSize: 40, color: 'white' }}/>
                        <div style={{ color: 'white', marginTop: 10, padding: 20 }}>{file.name}</div>
                    </div>
                </div>
            )
        });
    }

    render() {

        return (
            <div>
                { this.props.label && <InputLabel>{this.props.label}</InputLabel> }
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, flexWrap: 'wrap' }}>
                    { this.renderFiles() }
                    <input
                    accept={'.jpg,.gif,.png,.doc,.pdf,.xls,.ppt,.txt'}
                    multiple={this.props.multiple}
                    style={{display: 'none'}}
                    id="file-upload"
                    type="file"
                    onChange={(v) => { this.upload(v.target.files) }}
                    />
                    <label htmlFor="file-upload">
                        <div style={{ height: this.props.iconHeight ? this.props.iconHeight : 250, width: this.props.iconWidth ? this.props.iconWidth : 150, backgroundColor: '#ededed', borderRadius: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginTop: 10 }}>
                            <AddIcon style={{ fontSize: 40, color: 'grey' }}/>
                            <div style={{ color: 'grey', fontSize: 11, textAlign: 'center', padding: 10 }}>{'Formatos suportados: .jpg, .gif, .png, .doc, .pdf, .xls, .ppt e .txt.'}</div>
                            <div style={{ color: 'grey', fontSize: 11, textAlign: 'center', marginTop: 5 }}>{'Limite máximo: 50Mb.'}</div>
                        </div>
                    </label>
                </div>
            </div>
        )
    }
}