import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';

export default class PermissionHelper {

    static async getUserAccountPlans(idUser, permissions = ['read', 'write', 'owner']) {

        let result = [];
        let accountPlanQuery = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();

        if (idUser) {

            let isSuperAdmin = await this.isSuperAdmin('account_package');

            if (!isSuperAdmin) {

                let accountPackageQuery = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).get();
                let userAccountPlans = [];
    
                if (accountPackageQuery.size > 0) {
    
                    accountPackageQuery.forEach((doc, key) => {
    
                        let accountPackage = { ...doc.data(), id: doc.id };
    
                        if (!isSuperAdmin) {
    
                            if (permissions.includes('owner') && ((typeof accountPackage.responsable === 'object' && accountPackage.responsable.includes(idUser)) || accountPackage.responsable === idUser)) {
    
                                if (accountPackage.account_plans && accountPackage.account_plans.length) {
        
                                    accountPackage.account_plans.forEach((accountPlan, key) => {
        
                                        if (!userAccountPlans.includes(accountPlan)) {
                                            userAccountPlans.push(accountPlan);
                                        }
                                    });
                                }
        
                            } else if (permissions.includes('write') && (accountPackage.writeUsers && accountPackage.writeUsers.length && accountPackage.writeUsers.includes(idUser))) {
        
                                accountPackage.account_plans.forEach((accountPlan, key) => {
        
                                        if (!userAccountPlans.includes(accountPlan)) {
                                            userAccountPlans.push(accountPlan);
                                        }
                                });
        
                            } else if (permissions.includes('read') && (accountPackage.readUsers && accountPackage.readUsers.length && accountPackage.readUsers.includes(idUser))) {
        
                                accountPackage.account_plans.forEach((accountPlan, key) => {
        
                                    if (!userAccountPlans.includes(accountPlan)) {
                                        userAccountPlans.push(accountPlan);
                                    }
                                });
                            }
    
                        } else {
    
                            accountPackage.account_plans.forEach((accountPlan, key) => {
        
                                if (!userAccountPlans.includes(accountPlan)) {
                                    userAccountPlans.push(accountPlan);
                                }
                            });
                        }
                    });
    
                    if (userAccountPlans.length > 0) {
    
                        if (accountPlanQuery.size > 0) {
    
                            accountPlanQuery.forEach((doc, key) => {
    
                                let accountPlan = { ...doc.data(), id: doc.id };
    
                                if (userAccountPlans.includes(accountPlan.id)) {
    
                                    result.push(accountPlan);
    
                                    if (accountPlan.children && accountPlan.children.length) {
                                        
                                        accountPlan.children.forEach((children, key) => {
    
                                            let doc = accountPlanQuery.docs.find(item => item.id === children);
    
                                            if (doc && !result.includes(doc)) {
                                                result.push({ ...doc.data(), id: doc.id });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    } 
                }

            } else {

                if (accountPlanQuery.size > 0) {
            
                    accountPlanQuery.forEach((doc, key) => {
                        result.push({ ...doc.data(), id: doc.id });
                    });
                }
            }
        }

        return result;
    }

    static async isSuperAdmin(route) {

        let isSuperAdmin = false;

        let user = SessionHelper.getData();

        if (user.type === 'admin' || user.type === 'consultant') {

            isSuperAdmin = true;
        }

        if (user.permission) {

            if (user.permission.routes[route] === true) {

                isSuperAdmin = true;
            }
        }

        return isSuperAdmin;
    }

    static async getUserResultCenters(idUser, permissions = ['read', 'write', 'owner']) {

        let result = [];

        if (idUser) {

            let isSuperAdmin = false;

            let userQuery = await Firestore.getDoc('user', idUser);

            if (userQuery.exists) {

                let user = { ...userQuery.data(), id: userQuery.id };

                if (user.type === 'admin' || user.type === 'consultant') {

                    isSuperAdmin = true;
                }

                if (user.id_permission_group) {

                    let permissionQuery = await Firestore.getDoc('permission_group', user.id_permission_group);

                    if (permissionQuery) {
    
                        let permission = { ...permissionQuery.data(), id: permissionQuery.id };
    
                        if (permission.routes['result_center'] === true) {
    
                            isSuperAdmin = true;
                        }
                    }
                }
            }

            let resultCenterQuery = await Firestore.customQuery('result_center').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();

            if (resultCenterQuery.size > 0) {

                resultCenterQuery.forEach((doc, key) => {

                    let resultCenter = { ...doc.data(), id: doc.id };

                    if (!isSuperAdmin) {

                        if (permissions.includes('owner') && ((typeof resultCenter.responsable === 'object' && resultCenter.responsable.includes(idUser)) || resultCenter.responsable === idUser)) {

                            if (!result.includes(resultCenter)) {
                                result.push(resultCenter);
                            }
    
                        } else if (permissions.includes('write') && (resultCenter.writeUsers && resultCenter.writeUsers.length && resultCenter.writeUsers.includes(idUser))) {
    
                            if (!result.includes(resultCenter)) {
                                result.push(resultCenter);
                            }
    
                        } else if (permissions.includes('read') && (resultCenter.readUsers && resultCenter.readUsers.length && resultCenter.readUsers.includes(idUser))) {
    
                            if (!result.includes(resultCenter)) {
                                result.push(resultCenter);
                            }
                        }

                    } else {

                        if (!result.includes(resultCenter)) {
                            result.push(resultCenter);
                        }
                    }
                });
            }
        }

        return result;
    }

    static async getUserProducts(idUser) {

        let result = [];

        if (idUser) {

            let accountPlans = await this.getUserAccountPlans(idUser);
            let productQuery = await Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();

            if (productQuery.size > 0 && accountPlans.length > 0) {

                productQuery.forEach((doc, key) => {

                    let product = { ...doc.data(), id: doc.id };

                    // if (accountPlans.find(item => item.id === product.id_account_plan)) {
                    //     result.push(product);
                    // }

                    result.push(product);
                });
            }
        }

        return result;
    }
}