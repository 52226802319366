import Firestore from "../api/firebase/Firestore";
import SessionHelper from "./SessionHelper";
import moment from 'moment';
import 'moment/locale/pt-br';
import DataHelper from "./DataHelper";
import Methods from "../constants/Methods";
import LogHelper from "./LogHelper";
import Operators from "../constants/Operators";
import CalculusHelper from "./CalculusHelper";

export default class PlanningRowHelper {
    static async insertRow(data, start, end) {
        let output = {
            success: false,
            rows: [],
        }

        if (!data || !start || !end) return output;

        Object.keys(data).forEach((item, key) => {

            if (data[item] === undefined) {
                data[item] = null;
            }
        }); 

        if (data.relationship) {

            Object.keys(data.relationship).forEach((item, key) => {

                if (data.relationship[item] === undefined) {
                    data.relationship[item] = null;
                }
            }); 
        }

        let requiredFields = [
            'type',
            'operator',
            'method',
            'id_parent',
        ];

        let canInsert = true;

        requiredFields.forEach(field => {
            if (!data[field]) canInsert = false;
        });

        if (!canInsert) return output;

        const collection = 'planning_row';
        const periodDurationInMonths = moment(end).diff(start, 'months');

        data.id_company = SessionHelper.getData().id_company;

        for (let i = 0; i <= periodDurationInMonths; i++) {
            const date = (moment(start).startOf('month').add(i, 'month')).toDate();
            const date_label = moment(date).format('MMM/YYYY').toString();

            const rowModel = {
                ...data,
                date,
                date_label,
                multiplier: 0,
                multiplier_history: 0,
                value: 0,
                value_history: 0,
            }

            const insertId = await Firestore.getId(collection);
            await Firestore.insert(rowModel, collection, insertId);
            LogHelper.insertLog(insertId, collection, rowModel);

            output.rows.push(insertId);
        }

        output.success = true;

        return output;
    }

    static async updateRow(data, id) {
        if (!data || !id) throw new Error(`Invalid query parameters`);

        await Firestore.update(data, 'planning_row', id);
        LogHelper.updateLog(id, 'planning_row', data);
    }

    static async removeRowFromParent(idRow) {
        if (!idRow) return;
        await Firestore.delete('planning_row', idRow);
        LogHelper.deleteLog(idRow, 'planning_row');
    }

    static async getRowsByParentId(id, revenueRows, selectedResultCenter = null, selectedProduct = null) {
        if (!id) return [];

        let rows = await Firestore.customQuery('planning_row').where('id_parent', '==', id).orderBy('date', 'asc').get();
        let docs = [];

        for (let index = 0; index < rows.size; index++) {
            const doc = rows.docs[index];
            let data = { ...doc.data(), id: doc.id };

            if (((selectedResultCenter && data.relationship.id_result_center == selectedResultCenter) || !selectedResultCenter) && ((selectedProduct && data.relationship.id_product == selectedProduct) || !selectedProduct)) {
                let revenue = null;

                if (revenueRows) {
                    
                    revenue = revenueRows.find(item => item.date_label === data.date_label);
                }
    
                data.value_history = await this.getRowHistory(data, revenue);
                data.value_history_percentage = 0;
    
                if (data.type === 'costs' && data.method === Methods.multiply.key) {
    
                    data.multiplier = await this.getRowMultiplierFromRevenue(data);
                }

                if (data.method === Methods.percentage.key && revenue) {
    
                    data.value_history_percentage = CalculusHelper.percentageHistory(data, revenue);
                }
    
                docs.push(data);
            }
        }

        return docs;
    }

    static getPeriodRowArray(start, end) {
        const periodDurationInMonths = moment(end).diff(start, 'months');

        let rows = [];

        for (let i = 0; i <= periodDurationInMonths; i++) {
            const date = (moment(start).startOf('month').add(i, 'month')).toDate();
            const date_label = moment(date).format('MMM/YYYY').toString();

            rows.push({
                date,
                date_label,
                categories: {},
                multiplier: 0,
                multiplier_history: 0,
                value: 0,
                value_history: 0
            });
        }

        return rows;
    }

    static getMonthLabelArray(start, end) {
        const periodDurationInMonths = moment(end).diff(start, 'months');

        let rows = [];

        for (let i = 0; i <= periodDurationInMonths; i++) {
            const date = (moment(start).startOf('month').add(i, 'month')).toDate();
            const date_label = moment(date).format('MMM/YYYY').toString();

            rows.push(date_label);
        }

        return rows;
    }

    static calculateRowValues(row) {
        let value = {
            value: 0,
            value_history: 0
        };

        if (row.method == Methods.absolute.key) {
            value = {
                value: Methods.absolute.calculate(row.value),
                value_history: Methods.absolute.calculate(row.value_history)
            };
        } else if (row.method == Methods.multiply.key) {
            value = {
                value: Methods.multiply.calculate(row.value, row.multiplier),
                value_history: Methods.multiply.calculate(row.value_history, row.multiplier_history)
            };
        }

        return value;
    }

    static calculatePercentageRowValues(row, cumulativeTotal) {
        let value = {
            value: 0,
            value_history: 0
        };

        if (row.method == Methods.percentage.key) {
            value = {
                value: Methods.percentage.calculate(cumulativeTotal.value, row.value),
                value_history: Methods.percentage.calculate(cumulativeTotal.value_history, row.value_history)
            };
        }

        return value;
    }

    static rowIsInPeriod(start, end, row) {
        let isBetween = moment(row.date.toDate()).isBetween(start.toDate(), end.toDate(), 'days', '[]');
        return isBetween;
    }

    static productHasRelationToRow(id_products, row) {
        let rowIds = [];

        if (row.relationship.id_product) rowIds.push(row.relationship.id_product);
        if (row.relationship.id_products) rowIds = [...row.relationship.id_products];

        let arraysShareValues = DataHelper.findCommonElementsInArrays(id_products, rowIds);

        return arraysShareValues;
    }

    static getOperatorLookup() {
        let lookup = {};

        Object.keys(Operators).forEach(item => {
            lookup[Operators[item].key] = Operators[item].label;
        });

        return lookup;
    }

    static async getRowHistory(row, revenueRows) {

        try {
            let history = 0;
            let dateLabel = row.date_label.split('/');
            let historyDateLabel = `${dateLabel[0]}/${(parseInt(dateLabel[1]) - 1).toString()}`;
            let query = Firestore.customQuery('balancete_row').where('date_label', '==', historyDateLabel);

            if (row.relationship.id_product && row.relationship.id_account_plan && !row.relationship.id_target_account_plan) {
                query = query.where('account', '==', row.relationship.id_account_plan);
            } else if (row.relationship.id_target_account_plan){
                query = query.where('account', '==', row.relationship.id_target_account_plan);
            }
    
            let docs = await query.orderBy('date', 'asc').get();
            
            if (docs.size) {
                docs.forEach((doc, key) => {
                    let data = doc.data();
                    let operator = this.getOperator(row.type, data.value); 
                    let value = operator === Operators.minus.key ? data.value * -1 : data.value * 1;
                    if (row.relationship.id_result_center && row.relationship.id_result_center === data.result_center) {
                        history += value;    
                    } else if (!row.relationship.id_result_center) {
                        history += value; 
                    }
                });
            }
            return history;
        } catch (error) {
            return 0;
        }
    }

    static async getRowMultiplierFromRevenue(row) {

        try {

            if (!row.relationship.id_product) {
                throw new Error('Missing id_product');
            }

            let multiplier = 0;
            let dateLabel = row.date_label;
            let docs = await Firestore.customQuery('planning_row').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', 'revenue_planning').where('date_label', '==', dateLabel).where('relationship.id_product', '==', row.relationship.id_product).orderBy('date', 'asc').get();

            if (docs.size) {
                for (let index = 0; index < docs.docs.length; index++) {
                    let data = docs.docs[index].data();
                    multiplier += data.multiplier;
                    await Firestore.update({ multiplier }, 'planning_row', row.id);
                }
            }
            return multiplier;
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    static getOperator(label, value) {

        let response = '';

        if (label === 'revenue_planning') {

            response = 'sum';

        } else if (label === 'costs') {

            response = 'minus';

        } else if (label === 'people_expenses') {

            response = 'minus';

        } else if (label === 'expenses') {

            response = 'minus';

        } else if (label === 'expenses_and_revenue') {

            response = value >= 0 ? 'sum' : 'minus';

        } else if (label === 'other_expenses_and_revenue') {

            response = value >= 0 ? 'sum' : 'minus';

        } else if (label === 'taxes') {

            response = 'minus';

        } else if (label === 'deduction') {

            response = 'minus';
        }

        return response;
    }
}