import React from "react";
import DefaultLoader from "../components/DefaultLoader";
import ReorderIcon from '@material-ui/icons/Reorder';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Menu, TextareaAutosize, FormLabel, RadioGroup, FormControlLabel, Radio, Card } from "@material-ui/core";
import Colors from "../constants/Colors";
import Firestore from "../api/firebase/Firestore";
import SessionHelper from "../helper/SessionHelper";
import moment from "moment";
import ActionPlanHelper from "../helper/ActionPlanHelper";
import RelationField from "../components/RelationField";
import BudgetStructureTable from "../components/BudgetStructureTable";
import PermissionHelper from "../helper/PermissionHelper";
import Functions from "../api/firebase/Functions";
import { toast } from "react-toastify";
import ActionPlanTasks from "../components/ActionPlanTasks";
import IosSwitch from "../components/IosSwitch";
import Timeline, { DateHeader, TimelineHeaders, SidebarHeader } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css';
import CurrencyHelper from "../helper/CurrencyHelper";
import DefaultButton from '../components/DefaultButton';

export default class ActionPlanEvaluatePage extends React.Component {

    state = {
        loading: true,
        loadingEvaluate: false,
        error: false,
        tagDocs: [],
        userDocs: [],
        resultCenterDocs: [],
        accountPackageDocs: [],
        accountPlanDocs: [],
        budgetStructureDocs: [],
        generatedReport: {},
        loadingText: '',
        actionPlan: this.props.doc,
        loadingTable: false,
        showGantt: false,
        ganttSelectedItem: null,
        menuAnchor: null,
        ganttGroups: [],
        ganttItems: [],
        editId: null,
        reportAccountPlans: [],
        reportBudgetStructure: [],
        justification: '',
        answer: '',
        answered: false,
    }

    async componentDidMount() {

        await this.getActionPlan();

        if (!this.state.error) {

            await this.getUsers();
            await this.getTags();
            await this.getResultCenters();
            await this.getAccountPlanDocs();
            await this.getAccountPackageDocs();
    
            this.setState({ loading: false });
    
            await this.getPeriodData();
            
        } else {

            this.setState({ loading: false });
        }
    }

    async getActionPlan() {

        let url = new URL(window.location.href);
        let id = url.searchParams.get('id');

        if (id) {

            let query = await Firestore.getDoc('action_plan', id);

            if (query.exists) {
    
                let doc = { ...query.data(), id: query.id };
                let error = false;

                if (doc.evaluate_status !== 'not_answered') {

                    error = true;
                }

                this.setState({ actionPlan: doc, error });

            } else {

                this.setState({ error: true });
            }
            
        } else {

            this.setState({ error: true });
        }
    }

    async getResultCenters() {

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid);
        this.setState({ resultCenterDocs: docs });
    }

    async getAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs });
    }

    async getAccountPackageDocs() {

        let query = await Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPackageDocs: docs });
    }

    async getUsers() {

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ userDocs: docs });
    }

    async getTags() {

        let query = await Firestore.customQuery('tag').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ tagDocs: docs });
    }

    async getPeriodData() {

        if (this.state.actionPlan.budget_link_period_start && this.state.actionPlan.budget_link_period_end) {

            try {

                this.setState({ loadingTable: true, reportAccountPlans: [], reportBudgetStructure: [] });

                await this.getAccountPlanDocs();

                let months = [];
                let parsedMonths = [];
                let accounts = this.state.accountPlanDocs;
                let budgetStructure = this.state.budgetStructureDocs;
                let filterResultCenter = [...this.state.actionPlan.budget_link_result_center];
                let filterAccountPlan = [...this.state.actionPlan.budget_link_account_plan];
                let filterAccountPackage = [...this.state.actionPlan.budget_link_account_package];
                let columns = ['budget', 'accomplished'];

                if (filterResultCenter.length === this.state.resultCenterDocs.length) {

                    filterResultCenter = [];
                }

                let momentStart = moment(this.state.actionPlan.budget_link_period_start.toDate ? this.state.actionPlan.budget_link_period_start.toDate() : this.state.actionPlan.budget_link_period_start);
                let momentEnd = moment(this.state.actionPlan.budget_link_period_end.toDate ? this.state.actionPlan.budget_link_period_end.toDate() : this.state.actionPlan.budget_link_period_end);

                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

                    parsedMonths.push(momentStart.toJSON());
                    months.push(momentStart.toDate());
                    momentStart.add(1, 'month');
                }

                let body = { open: true, id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, selected_account_packages: filterAccountPackage, selected_account_plans: filterAccountPlan, id_result_centers: filterResultCenter, months: parsedMonths, columns: columns };
                let request = await Functions.request('POST', 'getBudgetStructure', body);
                
                if (request && request.accounts && request.budgetStructure) {
                    
                    accounts = request.accounts;
                    budgetStructure = request.budgetStructure;

                } else {

                    throw new Error('Request Error');
                }

                let generatedReport = {
                    report: 'monthly',
                    view: '',
                    columns: columns,
                    months: months,
                    verticalAnalysisAccountPlan: null,
                    filterResultCenter: [],
                };

                this.setState({ reportAccountPlans: accounts, reportBudgetStructure: budgetStructure, renderTable: true, loadingTable: false, generatedReport: generatedReport, showOptions: false });

            } catch (error) {

                toast.error('Houve um problema ao buscar os dados do período');
            }
        }
    }

    async evaluate() {

        if (this.state.answer === 'rejected' && !this.state.justification.length) {
            
            toast.warn('Informe a justificativa');
            return;
        }
        
        this.setState({ loadingEvaluate: true });

        try {

            let status = this.state.answer;
            let justification = this.state.justification;

            if (this.state.answer === 'approved') {

                justification = '';
            }

            await Firestore.update({ justification: justification, evaluate_status: status, evaluate_date: new Date() }, 'action_plan', this.state.actionPlan.id);

            if (status === 'approved') {

                await ActionPlanHelper.sendMailPlanoAprovado(this.state.actionPlan.id);

            } else {

                await ActionPlanHelper.sendMailPlanoRejeitado(this.state.actionPlan.id);
            }

            this.setState({ loadingEvaluate: false, answered: true });
            toast.success('Avaliação salva com sucesso!');

        } catch (error) {

            this.setState({ loadingEvaluate: false });
            toast.error('Houve um problema ao salvar a avaliação');
        }
    }

    renderResultCenters() {

        let id_result_centers = this.state.actionPlan.budget_link_result_center;
        let resultCenters = [];

        for (let index = 0; index < id_result_centers.length; index++) {
            
            let resultCenter = this.state.resultCenterDocs.find(item => item.id === id_result_centers[index]);

            if (resultCenter) {

                resultCenters.push(resultCenter.description);
            }
        }

        if (!resultCenters.length) {

            resultCenters.push('Nenhum selecionado.');
        }

        return resultCenters.join(', ');
    }

    renderAccountPackages() {

        let id_account_packages = this.state.actionPlan.budget_link_account_package;
        let accountPackages = [];

        for (let index = 0; index < id_account_packages.length; index++) {
            
            let accountPackage = this.state.accountPackageDocs.find(item => item.id === id_account_packages[index]);

            if (accountPackage) {

                accountPackages.push(accountPackage.description);
            }
        }

        if (!accountPackages.length) {

            accountPackages.push('Nenhum selecionado.');
        }

        return accountPackages.join(', ');
    }

    renderAccountPlans() {

        let id_account_plans = this.state.actionPlan.budget_link_account_plan;
        let accountPlans = [];

        for (let index = 0; index < id_account_plans.length; index++) {
            
            let accountPlan = this.state.accountPlanDocs.find(item => item.id === id_account_plans[index]);

            if (accountPlan) {

                accountPlans.push(accountPlan.description);
            }
        }

        if (!accountPlans.length) {

            accountPlans.push('Nenhum selecionado.');
        }

        return accountPlans.join(', ');
    }

    renderTags() {

        let id_tags = this.state.actionPlan.id_tags;
        let tags = [];

        for (let index = 0; index < id_tags.length; index++) {
            
            let tag = this.state.tagDocs.find(item => item.id === id_tags[index]);

            if (tag) {

                tags.push(tag.description);
            }
        }

        if (tags.length) {

            return tags.map((tag, key) => {

                return <div style={{ backgroundColor: Colors.info, color: 'white', fontWeight: 'bold', textAlign: 'center', display: 'inline', paddingTop: 3, paddingBottom: 3, paddingLeft: 8, paddingRight: 8, borderRadius: 5, marginRight: 10 }}>{tag}</div>
            });
        }

        return '';
    }

    renderParticipants() {

        let id_participants = this.state.actionPlan.id_participants;
        let users = [];

        for (let index = 0; index < id_participants.length; index++) {
            
            let user = this.state.userDocs.find(item => item.id === id_participants[index]);

            if (user) {

                users.push(user.name);
            }
        }

        return users.join(', ');
    }

    getPeriod() {

        let tasks = this.state.actionPlan.tasks || [];
        let start = '';
        let end = '';

        if (tasks.length) {

            start = moment(tasks[0].start.toDate ? tasks[0].start.toDate() : tasks[0].start).format('DD/MM/YYYY');
            end = moment(tasks[tasks.length - 1].end.toDate ? tasks[tasks.length - 1].end.toDate() : tasks[tasks.length - 1].end).format('DD/MM/YYYY');
        
            return `${start} → ${end}`;
        }

        return '';
    }

    renderBudgetLink() {

        return (

            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', border: '0.3px solid lightgrey' }}>
                <b style={{ backgroundColor: '#F6F6F6F6', padding: 15, textAlign: 'center' }}>Orçado x Realizado</b>
                <div style={{ padding: 10 }}>
                    <Table style={{ marginBottom: 10 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Centros de Resultado</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderResultCenters()}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Pacotes</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderAccountPackages()}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Contas</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderAccountPlans()}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Período de Referência</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{moment(this.state.actionPlan.budget_link_period_start.toDate ? this.state.actionPlan.budget_link_period_start.toDate() : this.state.actionPlan.budget_link_period_start).format('MM/YYYY')} → {moment(this.state.actionPlan.budget_link_period_end.toDate ? this.state.actionPlan.budget_link_period_end.toDate() : this.state.actionPlan.budget_link_period_end).format('MM/YYYY')}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50, textAlign: 'center' }} colSpan={2} rowSpan={2}>
                                    <BudgetStructureTable showTotalizer={true} hideStructure={this.state.actionPlan.budget_link_account_plan.length > 0 ? true : false} additionalRows={this.getAdditionalRows()} loadingTable={this.state.loadingTable} accountPlanDocs={this.state.reportAccountPlans} verticalAnalysisAccountPlan={null} variationFilter={[0, 0]} generatedReport={this.state.generatedReport} budgetStructureDocs={this.state.reportBudgetStructure} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', height: 50 }}>
                                    <b>Período de Verificação</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.state.actionPlan.budget_link_check_period ? moment(this.state.actionPlan.budget_link_check_period.toDate ? this.state.actionPlan.budget_link_check_period.toDate() : this.state.actionPlan.budget_link_check_period).format('MM/YYYY') : 'Não Informado'}</div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    }

    renderData() {

        return (

            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#F6F6F6F6', border: '0.3px solid lightgrey', height: 50 }} colSpan={'100%'}>{this.renderTags()}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={'100%'} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <b>Nome do Plano</b>
                                    <div>{this.state.actionPlan.description}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', width: '40%' }}>
                                    <b>Duração</b>
                                    <div>{this.getPeriod()}</div>
                                </TableCell>
                                <TableCell style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', width: '10%' }}>{ActionPlanHelper.getStatus(this.state.actionPlan.tasks)}</TableCell>
                                <TableCell rowSpan={2} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey', width: '50%' }}>
                                    <b>Participantes</b>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>{this.renderParticipants()}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <b>Responsável</b>
                                    <div><RelationField loading={false} collection={'user'} field={'name'} id={this.state.actionPlan.id_responsable}/></div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={'100%'} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <b>Superior / Avaliador</b>
                                    <div><RelationField loading={false} collection={'user'} field={'name'} id={this.state.actionPlan.id_evaluator}/></div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={'100%'} style={{ backgroundColor: 'white', border: '0.3px solid lightgrey' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20 }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', border: '0.3px solid lightgrey' }}>
                                            <b style={{ backgroundColor: '#F6F6F6F6', padding: 15, textAlign: 'center' }}>Análise de Desvio</b>
                                            <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: this.state.actionPlan.deviation_analysis }}></div>
                                        </div>
                                        {this.renderBudgetLink()}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    }

    handleGanttSelect(id, evt) {

        let task = this.state.actionPlan.tasks[id - 1];

        if (task) {

            this.setState({ ganttSelectedItem: task, menuAnchor: evt.target });
        }
    }

    handleGantt(v) {

        let groups = [];
        let items = [];

        if (v) {

            this.state.actionPlan.tasks.forEach((task, key) => {

                groups.push({
                    id: key + 1,
                    title: task.task,
                });
    
                items.push({
                    id: key + 1,
                    group: key + 1,
                    title: ActionPlanHelper.getTaskSituation(task.situation),
                    start_time: moment(task.start.toDate ? task.start.toDate() : task.start),
                    end_time: moment(task.end.toDate ? task.end.toDate() : task.end),
                    itemProps: {
                        style: {
                            background: ActionPlanHelper.getTaskBackgroundColor(task, 1),
                        }
                    }
                });
            });
        }

        this.setState({ showGantt: v, ganttGroups: groups, ganttItems: items });
    }

    getAdditionalRows() {

        return [
            {
                title: 'Meta de Impacto',
                render: (account) => {

                    let value = this.state.actionPlan.budget_link_goal[account.id] && this.state.actionPlan.budget_link_goal[account.id].goal ? this.state.actionPlan.budget_link_goal[account.id].goal : 0;
                    return <TableCell style={{ backgroundColor: 'white', textAlign: 'center', fontWeight: 'bold' }}>{value.toFixed(2).toString().replace('.', ',')}%</TableCell>
                },
                renderTotalizer: () => {

                    let goals = this.state.actionPlan.budget_link_goal;
                    let sum = 0;

                    Object.keys(goals).forEach((account, key) => {

                        if (goals[account] && goals[account].goal) {

                            sum += goals[account].goal;
                        }
                    });

                    return <TableCell style={{ backgroundColor: Colors.report.default.background, color: Colors.report.default.text, textAlign: 'center', fontWeight: 'bold' }}>{sum.toFixed(2).toString().replace('.', ',')}%</TableCell>
                }
            },
            {
                title: 'Novo Valor Projetado',
                render: (account) => {

                    let value = this.state.actionPlan.budget_link_goal[account.id] && this.state.actionPlan.budget_link_goal[account.id].value_projection ? this.state.actionPlan.budget_link_goal[account.id].value_projection : 0;
                    return <TableCell style={{ backgroundColor: 'white', textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(value, false)}</TableCell>
                },
                renderTotalizer: () => {

                    let goals = this.state.actionPlan.budget_link_goal;
                    let sum = 0;

                    Object.keys(goals).forEach((account, key) => {

                        if (goals[account] && goals[account].value_projection) {

                            sum += goals[account].value_projection;
                        }
                    });

                    return <TableCell style={{ backgroundColor: Colors.report.default.background, color: Colors.report.default.text, textAlign: 'center', fontWeight: 'bold' }}>{CurrencyHelper.formatMoney(sum, false)}</TableCell>
                }
            },
            {
                title: 'Variação (%)',
                render: (account) => {

                    let projection = this.state.actionPlan.budget_link_goal[account.id] && this.state.actionPlan.budget_link_goal[account.id].value_projection ? this.state.actionPlan.budget_link_goal[account.id].value_projection : 0;
                    let accomplished = { value: account.accomplished[account.accomplished.length - 1].value, history: account.accomplished[account.accomplished.length - 1].value_history};
                    let value = Math.abs(projection) > 0 ? (((projection - accomplished.value) / accomplished.value) * 100) : 0;
                    let color = Colors.report.default;

                    if (value >= 0) {
    
                        color = Colors.report.green;
    
                    } else {
        
                        color = Colors.report.red;   
                    }

                    return <TableCell style={{ backgroundColor: color.background, textAlign: 'center', fontWeight: 'bold', color: color.text }}>{value.toFixed(2).toString().replace('.', ',')}%</TableCell>
                },
                renderTotalizer: (docs) => {

                    let sum = 0;

                    docs.forEach((account, key) => {

                        let projection = this.state.actionPlan.budget_link_goal[account.id] && this.state.actionPlan.budget_link_goal[account.id].value_projection ? this.state.actionPlan.budget_link_goal[account.id].value_projection : 0;
                        let accomplished = { value: account.accomplished[account.accomplished.length - 1].value, history: account.accomplished[account.accomplished.length - 1].value_history};
                        let value = Math.abs(projection) > 0 ? (((projection - accomplished.value) / accomplished.value) * 100) : 0;

                        sum += value;
                    });

                    let color = Colors.report.default;

                    if (sum >= 0) {
    
                        color = Colors.report.green;
    
                    } else {
        
                        color = Colors.report.red;   
                    }

                    return <TableCell style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold' }}>{sum.toFixed(2).toString().replace('.', ',')}%</TableCell>
                }
            },
        ]
    }

    renderTasks() {

        return (

            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <b>Etapas</b>
                        <Tooltip title={'Clique para visualizar o gráfico Gantt'}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ReorderIcon/>
                            <IosSwitch margin={0} label={''} value={'showGantt'} checked={this.state.showGantt} onChange={(v) => { this.handleGantt(v) }} />
                            <ClearAllIcon/>
                        </div>
                        </Tooltip>
                    </div>
                    { this.state.showGantt ? this.renderGantt() : <ActionPlanTasks mode={'view'} readOnly={true} idActionPlan={this.state.actionPlan.id} tasks={this.state.actionPlan.tasks} evaluator={this.state.actionPlan.id_evaluator} onAddTask={(tasks) => { }}/> }
                </div>
            </div>
        )
    }

    renderGantt() {

        return (

            <div style={{ boxShadow: Colors.boxShadow }}>
                <Timeline
                    onItemClick={(id, evt) => { this.handleGanttSelect(id, evt) }}
                    onItemSelect={(id, evt) => { this.handleGanttSelect(id, evt) }}
                    groups={this.state.ganttGroups}
                    items={this.state.ganttItems}
                    defaultTimeStart={moment().add(-360, 'hour')}
                    defaultTimeEnd={moment().add(360, 'hour')}
                    canMove={false}
                    canChangeGroup={false}
                    canResize={false}
                    timeSteps={{
                        second: 1,
                        minute: 1,
                        hour: 24,
                        day: 1,
                        month: 1,
                        year: 1
                    }}
                >
                    <TimelineHeaders >
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div {...getRootProps()} style={{...getRootProps().style, backgroundColor: '#f0f0f0', borderTop: '1px solid #bbb', borderLeft: '1px solid #bbb' }} ></div>
                        }}
                    </SidebarHeader>
                        <DateHeader unit="month" />
                        <DateHeader unit="day" />
                    </TimelineHeaders>
                </Timeline>
            </div>
        )
    }

    renderGanttSelectedItem() {

        if (this.state.ganttSelectedItem) {

            return (

                <Menu
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    anchorEl={this.state.menuAnchor}
                    id={'gantt-selected-item-menu'}
                    open={this.state.ganttSelectedItem}
                    style={{ marginTop: 40, height: 'auto' }}
                    onClose={() => { this.setState({ ganttSelectedItem: null }) }} >
    
                        <div style={{ width: 450, padding: 0 }}>
                            <div style={{ borderBottom: '0.5px solid lightgrey', padding: 10, backgroundColor: '#f3f3f3f3' }}>{ActionPlanHelper.getTaskSituation(this.state.ganttSelectedItem.situation)}</div>
                            <div style={{ padding: 10 }}>
                                {this.state.ganttSelectedItem.task} <br/>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
                                    <b>Responsável:</b>
                                    <RelationField loading={false} collection={'user'} field={'name'} id={this.state.ganttSelectedItem.id_responsable}/>
                                </div>
                            </div>
                        </div>
                </Menu>
            )
        }
    }

    renderAnswer() {

        return (

            <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5, gap: 10 }}>
                    <FormLabel>Resposta</FormLabel>
                    <RadioGroup onChange={(evt, value) => this.setState({ answer: value })} row defaultValue="">
                        <FormControlLabel value="approved" control={<Radio style={{ color: Colors.primary }} />} label="Aprovar" />
                        <FormControlLabel value="rejected" control={<Radio style={{ color: Colors.primary }} />} label="Rejeitar" />
                    </RadioGroup>
                    {this.state.answer === 'rejected' ? this.renderJustificativa() : null}
                </div>
            </div>
        )
    }

    renderJustificativa() {

        return (

            <div>
                <b>Justificativa</b>
                <TextareaAutosize onBlur={(v) => { this.setState({ justification: v.target.value }) }} placeholder="Digite algo..." style={{ height: 300, width: '100%', borderRadius: 5, border: '0.5px solid grey', resize: 'none', padding: 10, fontSize: 14, marginTop: 20 }} />
            </div>
        )
    }

    renderError() {

        return (

            <div style={{ padding: 40 }}>
                <Card style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40, height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow }}>
                    <img style={{ height: 350 }} src={process.env.PUBLIC_URL + '/spreadsheet.png'} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                        <p style={{ fontSize: 25, fontWeight: 'bold' }}>{`Plano de Ação não encontrado ou já avaliado.`}</p>
                        <div style={{ color: 'grey', fontSize: 16 }}>Visualizar <a href={'/action_plan'}>planos de ação</a>.</div>
                    </div>
                </Card>
            </div>
        )
    }

    render() {

        if (this.state.loading) {

            return <div><DefaultLoader loadingText={this.state.loadingText} /></div>;
        }

        if (this.state.error) {

            return this.renderError();
        }

        return (

            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    </div>
                    <div style={{ fontWeight: 'bold', fontSize: 24 }}>Avaliação de Plano de Ação</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                    </div>
                </div>
                {this.renderData()}
                {this.renderTasks()}
                {this.renderGanttSelectedItem()}
                {this.renderAnswer()}
                <div style={{ marginTop: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5, gap: 10 }}>
                        <DefaultButton disabled={this.state.answered} loading={this.state.loadingEvaluate} width={'100%'} color={Colors.success} leftIcon={<CheckCircleIcon />} onClick={() => { this.evaluate() }} title={'Salvar'} />
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        padding: 25,
    }
}