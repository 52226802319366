import React, { Component } from 'react';
import { Button, CircularProgress, Menu, IconButton, MenuItem } from '@material-ui/core';
import Colors from '../constants/Colors';
import DefaultButton from './DefaultButton';
import DefaultInput from './DefaultInput';
import StarIcon from '@material-ui/icons/Star';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { toast } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';

export default class ReportFavorite extends Component {

    state = {
        loading: true,
        docs: [],
        menuAnchor: null,
        showAdd: false,
        showSelect: false,
        name: '',
        hover: null,
    }

    async componentDidMount() {

        await this.getReports();

        this.setState({ loading: false });
    }

    async getReports() {

        let docs = [];
        let query = await Firestore.customQuery(`${this.props.report}_favorite`).where('id_user', '==', SessionHelper.getFirebaseAuth().uid).where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc').get();

        if (query.size > 0) {

            query.forEach((doc, key) => {

                docs.push({ ...doc.data(), id: doc.id });
            });
        }

        this.setState({ docs });
    }

    async save() {

        try {

            if (this.state.name) {

                this.setState({ loading: true });

                let fields = this.props.fields;

                let data = {
                    name: this.state.name,
                    fields: JSON.stringify(fields),
                    id_user: SessionHelper.getFirebaseAuth().uid,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                }
    
                await Firestore.insert(data, `${this.props.report}_favorite`);
                await this.getReports();
    
                this.setState({ loading: false, showAdd: false, menuAnchor: null });
                toast.success('Favorito salvo com sucesso');

            } else {
                
                toast.warn('Preencha o nome do favorito');
            }

        } catch (error) {
            
            toast.error('Houve um problema ao salvar o favorito');
        }
    }

    async remove(id) {

        if (id) {

            let confirm = window.confirm('Tem certeza que deseja remover?');

            if (confirm) {
             
                try {
    
                    this.setState({ loading: true });
    
                    await Firestore.delete(`${this.props.report}_favorite`, id);
                    await this.getReports();
        
                    this.setState({ loading: false });
                    toast.success('Favorito removido com sucesso');
        
                } catch (error) {
                    
                    toast.error('Houve um problema ao remover o favorito');
                }
            }
        }
    }

    async loadFavorite(doc) {

        try {

            let fields = doc.fields;
            fields = JSON.parse(fields);
    
            await this.props.onLoad(fields);
    
            this.setState({ showSelect: false, menuAnchor: null });
            toast.success('Favorito carregado com sucesso');

        } catch (error) {

            toast.error('Houve um problema ao carregar o favorito');
        }
    }

    renderAdd() {
        
        return (

            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                anchorEl={this.state.menuAnchor}
                id={'primary-search-account-menu'}
                open={this.state.showAdd}
                onClose={() => { this.setState({ showAdd: false }) }} >
                    <div style={{ margin: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 20 }}>
                        <DefaultInput onChange={(text) => { this.setState({ name: text }) }} label={'Nome'} />
                        <DefaultButton width={40} leftIcon={this.state.loading ? <CircularProgress style={{ marginRight: 15 }} color={'white'} size={17}/> : <CheckIcon />} color={Colors.success} title={''} onClick={(evt) => { this.save() }}/>
                    </div>
            </Menu>
        )
    }

    renderSelect() {

        return (

            <Menu
                anchorReference='anchorEl'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                anchorEl={this.state.menuAnchor}
                anchorPosition={'center'}
                id={'primary-search-account-menu'}
                open={this.state.showSelect}
                onClose={() => { this.setState({ showSelect: false }) }} >

                    {
                        this.state.docs.map((doc, key) => {

                            return (
                
                                <MenuItem onClick={() => { this.loadFavorite(doc) }} onMouseEnter={() => { this.setState({ hover: key }) }} onMouseLeave={() => { this.setState({ hover: null }) }} style={{ width: 300, position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                                    <div>{doc.name}</div>
                                    { this.state.hover === key ? <DeleteIcon onClick={(evt) => { evt.stopPropagation(); this.remove(doc.id) }} style={{ color: Colors.error}}/> : null }
                                </MenuItem>
                            )
                        })
                    }
                    { !this.state.docs.length ? <div style={{ height: 150, color: 'grey', padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Nenhum favorito encontrado.</div> : null }
            </Menu>
        )
    }

    render() {

        return (
            
            <div style={{ display: 'flex', flexDirection: 'row', maxHeight: 36 }}>
                <DefaultButton borderRadius={'4px 0px 0px 4px'} width={186} leftIcon={this.state.loading ? <CircularProgress style={{ marginRight: 15 }} color={'white'} size={17}/> : <StarIcon style={{ marginRight: 15 }}/>} color={Colors.danger} title={'Favoritos'} onClick={(evt) => { this.setState({ showAdd: true, menuAnchor: evt.target }) }}/>
                <div style={{ borderLeft: '1px solid #ed9f32', height: '100%' }}/>
                <Button onClick={(evt) => { this.setState({ showSelect: true, menuAnchor: evt.target }) }} style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, width: 40, backgroundColor: Colors.danger, color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: window.screen.width < 1360 ? 12 : 0, height: window.screen.width < 1080 ? 64 : 36 }}>
                    <ArrowDropDownIcon />
                </Button>
                {this.renderAdd()}
                {this.renderSelect()}
            </div>
        )
    }
}
