import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, Card, FormLabel, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import moment from 'moment';
import 'moment/locale/pt-br';
import TableChartIcon from '@material-ui/icons/TableChart';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PeriodSelection from '../components/PeriodSelection';
import PermissionHelper from '../helper/PermissionHelper';
import DataHelper from '../helper/DataHelper';
import PlanningHelper from '../helper/PlanningHelper';
import Operators from '../constants/Operators';
import Methods from '../constants/Methods';
import PlanningRowHelper from '../helper/PlanningRowHelper';
import KeyboardShortcutsTooltip from '../components/KeyboardShortcutsTooltip';
import Functions from '../api/firebase/Functions';
import CurrencyHelper from '../helper/CurrencyHelper';
import ChartHelper from '../helper/ChartHelper';

const TYPE = 'taxes';

export default class TaxPage extends Component {
    state = {
        docs: [],
        auxDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        editId: null,
        editDoc: {},
        editing: false,
        accountPlanDocs: [],
        resultCenterDocs: [],
        periodDocs: [],
        description: ``,
        tax_type: ``,
        loadingText: 'Carregando Impostos...',
        start: moment(),
        end: moment().add(3, 'month'),
        id_period: null,
        targetAccountPlan: null,
        regime_tributario: SessionHelper.getData().company.regime_tributario || '',
        resultCenter: null,
        accountTypes: [
            { label: 'Estrutura do Orçamento', value: 'budget_structure' },
            { label: 'Conta Contábil', value: 'account' },
        ],
        accountType: 'budget_structure',
        accountPlan: null,
        budgetStructure: [],
        budgetStructureDocs: [],
        selectedColumns: ['budget'],
        allAccountPlanDocs: [],
        generatedReport: {},
        selectedPeriod: [],
    }

    async getPlanningElement() {

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');
        let query = Firestore.customQuery('planning').where('id_company', '==', SessionHelper.getData().id_company).where('type', '==', TYPE);

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            if (isSuperAdmin || this.state.accountPlanDocs.find(item => item.id === data.id_target_account_plan) || (data.id_result_center && this.state.resultCenterDocs.find(item => item.id === data.id_result_center))) {       
                docs.push(data);
            }
        });

        this.setState({ docs: docs, auxDocs: docs });

        await this.filterPlanningElement();
    }

    async filterPlanningElement() {

        let docs = [];
        let start = null;
        let end = null;

        this.state.selectedPeriod.forEach((id, key) => {

            let period = this.state.periodDocs.find(item => item.id === id);
            let periodStart = moment(period.start.toDate ? period.start.toDate() : period.start).startOf('month').startOf('day');
            let periodEnd = moment(period.end.toDate ? period.end.toDate() : period.end).endOf('month').endOf('day');

            if (start === null || periodStart.isBefore(start)) {

                start = periodStart;
            }

            if (end === null || periodEnd.isAfter(end)) {

                end = periodEnd;
            }
        });

        if (start && end) {

            this.state.auxDocs.forEach((doc, key) => {

                let docStart = moment(doc.start.toDate ? doc.start.toDate() : doc.start);
                let docEnd = moment(doc.end.toDate ? doc.end.toDate() : doc.end);

                if (docStart.isSameOrAfter(start, 'date') && docEnd.isSameOrBefore(end, 'date')) {

                    docs.push(doc);
                }
            });

            this.setState({ docs });

        } else {

            await this.getPlanningElement();
        }
    }

    async getPeriods() {

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        let selectedPeriod = [];
        selectedPeriod.push(docs[0].id);

        this.setState({ periodDocs: docs, loading: false, selectedPeriod });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async getAccountPlanDocs() {

        let docs = await PermissionHelper.getUserAccountPlans(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        this.setState({ accountPlanDocs: docs });
    }

    async getResultCenterDocs() {

        let docs = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);
        
        this.setState({ resultCenterDocs: docs });
    }

    async getDocs() {
        await this.getAccountPlanDocs();
        await this.getBudgetStructure();
        await this.getResultCenterDocs();
        await this.getPeriods();
        await this.getPlanningElement();

        this.setState({ loading: false });
    }

    async getAllAccountPlanDocs() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ allAccountPlanDocs: docs });
    }

    async getBudgetStructure() {

        let query = await Firestore.customQuery('budget_structure').where('id_company', '==', SessionHelper.getData().id_company).orderBy('order', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };

            docs.push(data);
        });

        this.setState({ budgetStructure: docs });
    }

    async addNewDoc() {
        if (this.state.description && moment(this.state.start).isValid() && moment(this.state.end).isValid() && SessionHelper.getData().id_company) {

            this.setState({ loadingModal: true });

            const start = moment(this.state.start);
            const end = moment(this.state.end);

            let data = {
                type: TYPE,
                description: this.state.description,
                start: start.toDate(),
                end: end.toDate(),
                operator: Operators.minus.key,
                table_type: TYPE,
                method: Methods.absolute.key,
                id_target_account_plan: this.state.targetAccountPlan,
                id_result_center: this.state.resultCenter,
                id_account_plan: this.state.accountPlan,
                id_period: this.state.id_period,
                tax_type: this.state.tax_type,
                account_type: this.state.accountType,
                last_update: new Date(),
            };

            let insertInfo = await PlanningHelper.insertPlanning(data, true);

            if (insertInfo.success) {
                let response = await PlanningRowHelper.insertRow(
                    {
                        type: TYPE,
                        operator: Operators.minus.key,
                        method: Methods.absolute.key,
                        id_parent: insertInfo.insertId,
                        relationship: {
                            id_target_account_plan: this.state.targetAccountPlan,
                            id_result_center: this.state.resultCenter,
                            id_account_plan: this.state.accountPlan,
                        }
                    },
                    start,
                    end
                );

                let rows = response.rows;
                let taxes = await DataHelper.calculateTaxes(data.start, data.end, data.tax_type, data.id_account_plan, data.account_type);

                if (taxes && taxes.length) {

                    for (let index = 0; index < rows.length; index++) {

                        let id = rows[index];
                        let query = await Firestore.getDoc('planning_row', id);
                        let row = query.data();

                        for (let taxesIndex = 0; taxesIndex < taxes.length; taxesIndex++) {

                            if (row.date_label === taxes[taxesIndex].date_label) {
                                
                                await Firestore.update({ value: taxes[index].value }, 'planning_row', id);
                            }
                        }
                    }
                }
            }

            await this.getPlanningElement();

            toast.success('Registro inserido com sucesso!', {
                position: toast.POSITION.TOP_RIGHT
            });

        } else {
            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false, addModal: false, description: ``, start: moment(), end: moment().add(3, 'month'), accountPlans: [], planning_methodology: '',
        });
    }

    renderAccountSelection() {

        if (this.state.accountType === 'account') {
            
            return (

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.accountPlan}
                        onChange={(v) => {
                            this.setState({ accountPlan: v.target.value })
                        }}
                        docs={this.state.accountPlanDocs}
                        label={'Conta Contábil (% Aplicação)'}
                    />
                </div>
            )

        } else {

            return (

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        value={this.state.accountPlan}
                        search={true}
                        searchField={'name'}
                        displayField={'name'}
                        valueField={'id'}
                        onChange={(v) => {
                            this.setState({ accountPlan: v.target.value });
                        }}
                        docs={this.state.budgetStructure}
                        label={'Estrutura (% Aplicação)'}
                    />
                </div>
            )
        }     
    }

    addModal = () => {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['label', 'value']}
                        displayField={'label'}
                        valueField={'value'}
                        value={this.state.accountType}
                        onChange={(v) => {
                            this.setState({ accountType: v.target.value })
                        }}
                        docs={this.state.accountTypes}
                        label={'Tipo (% Aplicação)'}
                    />
                </div>
                
                { this.state.accountType ? this.renderAccountSelection() : null }
                
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.targetAccountPlan}
                        onChange={(v) => {
                            this.setState({ targetAccountPlan: v.target.value })
                        }}
                        docs={this.state.accountPlanDocs}
                        label={'Conta Contábil (Destino)'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.resultCenter}
                        onChange={(v) => {
                            this.setState({ resultCenter: v.target.value })
                        }}
                        docs={this.state.resultCenterDocs}
                        label={'Centro de Resultado'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={false}
                        disabled={false}
                        displayField={'name'}
                        valueField={'key'}
                        value={this.state.tax_type}
                        onChange={(v) => {
                            this.setState({ tax_type: v.target.value });
                        }}
                        docs={Object.keys(this.getTaxTypeLookup()).map(key => ({ name: this.getTaxTypeLookup()[key], key }))}
                        label={'Tipo de Imposto*'}
                    />
                </div>

                <PeriodSelection periods={this.state.periodDocs} onStartChange={(v) => { this.setState({ start: v }) }} onEndChange={(v) => { this.setState({ end: v }) }} onDocChange={(id) => { this.setState({ id_period: id }) }}/>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.addNewDoc() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false, description: ``, start: moment(), end: moment().add(3, 'month') }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    getAccountTypeLookup() {
        return {
            'budget_structure': 'Estrutura do Orçamento',
            'account': 'Conta Contábil',
        }
    }

    getTaxTypeLookup() {
        return {
            'irpj': 'IRPJ',
            'csll': 'CSLL',
        }
    }

    getTributaryRuleLookup() {
        return {
            'lucro_real': 'Lucro Real',
            'lucro_presumido': 'Lucro Presumido',
        }
    }

    getPlanningTypeLookup() {
        return {
            'deduction': 'Deduções',
            'revenue_planning': 'Receita Bruta',
            'costs': 'Custos',
            'expenses': 'Despesas',
            'expenses_and_revenue': 'Despesas e Receitas',
            'other_expenses_and_revenue': 'Outras Despesas e Receitas',
            'people_expenses': 'Despesas de Pessoal',
            'beforeTaxes': 'Resultado antes de Imposto',
            'liquidRevenue': 'Receita Líquida',
            'bruteProfit': 'Lucro Bruto',
            'taxAmount': 'Impostos',
            'additionalTaxAmount': 'Impostos Adicionais',
        }
    }

    async updateValues(rowData) {
        
        try {
          
            this.setState({ loading: true, loadingText: 'Atualizando Valores...' });
            
            let rows = await PlanningHelper.getParentRows(rowData.id);
            let taxes = await DataHelper.calculateTaxes(rowData.start.toDate(), rowData.end.toDate(), rowData.tax_type, rowData.id_account_plan, rowData.account_type);

            if (taxes && taxes.length) {

                for (let index = 0; index < rows.length; index++) {

                    let row = rows[index];
                    
                    for (let taxesIndex = 0; taxesIndex < taxes.length; taxesIndex++) {

                        if (row.date_label === taxes[taxesIndex].date_label) {

                            await Firestore.update({ value: taxes[taxesIndex].value }, 'planning_row', row.id);
                        }
                    }
                }
            }
            
            await Firestore.update({ last_update: new Date() }, 'planning', rowData.id);
            await this.getPlanningElement();

            this.setState({ loading: false, loadingText: '' });

            toast.success('Valores atualizados com sucesso');

        } catch (error) {

            toast.error('Houve um problema ao atualizar os valores');
        }
    }

    async getPeriodData(rowData) {

        if (this.state.selectedColumns.length) {

            try {

                await this.setState({ loading: true });
                await this.getAllAccountPlanDocs();

                let momentStart = moment(rowData.start.toDate());
                let momentEnd = moment(rowData.end.toDate());
                let months = [];

                while (momentEnd > momentStart || momentStart.format('M') === momentEnd.format('M')) {

                    months.push(momentStart.toDate());
                    momentStart.add(1, 'month');
                }

                let parsedMonths = [];
                let accounts = this.state.allAccountPlanDocs;
                let budgetStructure = this.state.budgetStructure;
                let filterResultCenter = [];
                let columns = ['budget'];

                for (let index = 0; index < months.length; index++) {

                    parsedMonths.push(months[index].toJSON());
                }

                let body = { id_company: SessionHelper.getData().id_company, id_user: SessionHelper.getFirebaseAuth().uid, id_result_centers: filterResultCenter, months: parsedMonths, columns: columns, open: false };
                let request = await Functions.request('POST', 'getBudgetStructure', body);

                if (request && request.accounts && request.budgetStructure) {
                    
                    accounts = request.accounts;
                    budgetStructure = request.budgetStructure;

                } else {

                    throw new Error('Request Error');
                }

                let generatedReport = {
                    report: this.state.report,
                    view: this.state.view,
                    columns: columns,
                    months: months,
                    verticalAnalysisAccountPlan: this.state.verticalAnalysisAccountPlan,
                    filterResultCenter: this.state.filterResultCenter,
                };

                this.setState({ allAccountPlanDocs: accounts, editing: true, loading: false, generatedReport: generatedReport, budgetStructureDocs: budgetStructure, editDoc: rowData });

            } catch (error) {

                toast.error('Houve um problema ao buscar os dados do período');
            }

        } else {

            toast.warn('Selecione ao menos uma coluna para ser exibida');
        }
    }

    getColumnValue(account, column, monthIndex) {

        let value = 0;
        let verticalAccomplished = 0;
        let accomplished = { value: account.accomplished[monthIndex].value, history: account.accomplished[monthIndex].value_history};
        let plannedAndHistory = { planned: (account.revenue[monthIndex].value - account.deduction[monthIndex].value), history: (account.revenue[monthIndex].value_history - account.deduction[monthIndex].value_history)};

        if (account.isPercentage) {

            plannedAndHistory = { planned: account.percentage[monthIndex].value, history: account.percentage[monthIndex].value_history}
        }

        if (this.state.verticalAnalysisAccountPlan) {

            let doc = this.state.accountPlanDocs.find(item => item.id === this.state.verticalAnalysisAccountPlan);

            if (!doc) {

                doc = this.state.budgetStructureDocs.find(item => item.id === this.state.verticalAnalysisAccountPlan);
            }

            verticalAccomplished = { value: doc.accomplished[monthIndex].value, value_history: doc.accomplished[monthIndex].value_history};
        }

        if (column === 'budget') {

            value = plannedAndHistory.planned;

        } else if (column === 'accomplished') {

            value = accomplished.value;

        } else if (column === 'history') {

            value = accomplished.history;

        } else if (column === 'deviation_money') {

            value = accomplished.value - plannedAndHistory.planned;

        } else if (column === 'deviation_percentage') {

            value =  Math.abs(plannedAndHistory.planned) > 0 ? (((accomplished.value - plannedAndHistory.planned) / plannedAndHistory.planned) * 100) : 0;

        } else if (column === 'variation_money') {

            value = accomplished.value - accomplished.history;

        } else if (column === 'variation_percentage') {

            value =  Math.abs(accomplished.history) > 0 ? (((accomplished.value - accomplished.history) / accomplished.history) * 100) : 0;

        }  else if (column === 'vertical_analysis') {

            value = ((accomplished.value / verticalAccomplished.value) * 100) || 0;
        }

        value = value.toFixed(2);

        if (column === 'deviation_percentage' || column === 'variation_percentage' || column === 'vertical_analysis' || account.isPercentage) {

            value = `${value.toString().replace('.', ',')}%`;

        } else {

            value = CurrencyHelper.formatMoney(value, false);
        }

        return value;
    }

    getColumnColor(account, column, monthIndex) {

        let color = Colors.report.default;

        if (column === 'deviation_money' || column === 'deviation_percentage' || column === 'variation_money' || column === 'variation_percentage') {

            let value = 0;
            let accomplished = { value: account.accomplished[monthIndex].value, history: account.accomplished[monthIndex].value_history};
            let plannedAndHistory = { planned: (account.revenue[monthIndex].value - account.deduction[monthIndex].value), history: (account.revenue[monthIndex].value_history - account.deduction[monthIndex].value_history)};

            if (account.isPercentage) {
    
                plannedAndHistory = { planned: account.percentage[monthIndex].value, history: account.percentage[monthIndex].value_history}
            }
    
            if (column === 'deviation_money' || column === 'deviation_percentage') {
    
                value = accomplished.value - plannedAndHistory.planned;
    
                if (value >= 0) {
    
                    color = Colors.report.green;

                } else {
    
                    color = Colors.report.red;   
                }

            } else if (column === 'variation_money' || column === 'variation_percentage') {
    
                value = accomplished.value - accomplished.history;

                if (value >= 0) {
    
                    color = Colors.report.green;

                } else {
    
                    color = Colors.report.red;   
                }
            }
    
            value = value.toFixed(2);
        }

        return color;
    }

    childrenAccountHasValue(accounts) {

        let value = false;

        accounts.forEach((account, key) => {

            if (account.hasValue) {

                value = true;
                return;
            }
        });

        return value;
    }

    openAccount(hash, docs = this.state.budgetStructureDocs, recursive = false, open) {

        if (hash) {

            for (let index = 0; index < docs.length; index++) {

                if (docs[index].hash === hash) {
                    
                    docs[index].open = !open;  
                    break;

                } else {

                    if (docs[index].accounts && docs[index].accounts.length) {

                        docs[index].accounts = this.openAccount(hash, docs[index].accounts, true, open);
                    }
                }
            }

            if (recursive) {

                return docs;

            } else {

                this.setState({ budgetStructureDocs: docs });
            }
        }
    }

    renderGrid() {
        return (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={'header-actions-buttons'}>
                        { PlanningHelper.renderPlanningPeriodSelection(this.state.periodDocs, this.state.selectedPeriod, async (selectedPeriod) => { await this.setState({ selectedPeriod }); await this.filterPlanningElement(); }) }
                    </div>
                </div>

                <DefaultTable
                    title={'Impostos'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Tipo de Imposto', field: 'tax_type', lookup: this.getTaxTypeLookup() },
                        { title: 'Tipo de Conta', field: 'account_type', lookup: this.getAccountTypeLookup() },
                        { title: 'Conta Contábil (% Aplicação)', field: 'id_account_plan', editComponent: props => props.rowData.account_type === 'budget_structure' ? PlanningHelper.renderBudgetStructureEdit(props, this.state.budgetStructure) : PlanningHelper.renderAccountEdit(props, this.state.accountPlanDocs), render: rowData => <div>{rowData.account_type === 'budget_structure' ? PlanningHelper.renderBudgetStructure(rowData.id_account_plan, this.state.budgetStructure) : PlanningHelper.renderAccountPlan(rowData.id_account_plan, this.state.accountPlanDocs)}</div> },
                        { title: 'Conta Contábil (Destino)', field: 'id_target_account_plan', editComponent: props => PlanningHelper.renderAccountEdit(props, this.state.accountPlanDocs), render: rowData => <div>{PlanningHelper.renderAccountPlan(rowData.id_target_account_plan, this.state.accountPlanDocs)}</div> },
                        { title: 'Centro de Resultado', field: 'id_result_center', editComponent: props => PlanningHelper.renderResultCenterEdit(props, this.state.resultCenterDocs), render: rowData => <div>{PlanningHelper.renderResultCenter(rowData.id_result_center, this.state.resultCenterDocs)}</div> },
                        { title: 'Período Inícial', field: 'start', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.start ? moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                        { title: 'Período Final', field: 'end', editComponent: props => PlanningHelper.renderPeriodEdit(props), render: rowData => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.end ? moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                        { title: 'Última Atualização', field: 'last_update', editable: false, render: rowData => <div>{rowData.last_update ? moment(rowData.last_update.toDate ? rowData.last_update.toDate() : rowData.last_update).format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm')}</div> },
                    ]}
                    actions={[
                        {
                            icon: SyncIcon,
                            tooltip: 'Atualizar Valores',
                            onClick: (event, rowData) => { this.updateValues(rowData); }
                        },
                        {
                            icon: TableChartIcon,
                            tooltip: 'Visualizar Estrutura do Orçamento',
                            onClick: async (event, rowData) => { await this.getPeriodData(rowData); }
                        },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if (newData.start && newData.end) {
                            newData.start = newData.start.seconds ? new Date(newData.start.seconds * 1000) : newData.start.toDate ? newData.start.toDate() : newData.start;
                            newData.end = newData.end.seconds ? new Date(newData.end.seconds * 1000) : newData.end.toDate ? newData.end.toDate() : newData.end;
                        }

                        if (newData.id_target_account_plan) {

                            newData.id_result_center = newData.id_result_center ? newData.id_result_center : null;

                            await PlanningHelper.updatePlanning(newData, oldData.id, 'planning');

                            let prev = this.state.docs;
                            const index = prev.indexOf(oldData);
                            prev[index] = { ...oldData, description: newData.description, id_target_account_plan: newData.id_target_account_plan, id_result_center: newData.id_result_center, start: newData.start, end: newData.end };
                            
                            this.setState({ docs: prev });
    
                            return prev;

                        } else {

                            toast.warn('A Conta de Destino não pode ser vazia');
                            return;
                        }
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);
                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        await PlanningHelper.deletePlanning(oldData.id);
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Imposto'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />

            </div>
        );
    }

    renderEditing() {
        return (
            <div style={styles.plannigContainerWrapper}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 20, justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <DefaultButton onClick={() => { this.setState({ editId: null, editDoc: {}, editing: false }) }} title={'Voltar'} leftIcon={<ChevronLeftIcon />} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300, textAlign: 'center' }}>
                        <FormLabel style={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1 }}>{this.state.editDoc.description}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 2, fontWeight: 200 }}>{`Visualização de Impostos (${this.state.editDoc.tax_type === 'irpj' ? 'IRPJ' : 'CSLL'})`}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Tipo (% Aplicação): {this.getAccountTypeLookup()[this.state.editDoc.account_type]}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Conta Contábil (% Aplicação): {this.state.editDoc.account_type === 'budget_structure' ? PlanningHelper.renderBudgetStructure(this.state.editDoc.id_account_plan, this.state.budgetStructure) : PlanningHelper.renderAccountPlan(this.state.editDoc.id_account_plan, this.state.accountPlanDocs)}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Conta Contábil (Destino): {PlanningHelper.renderAccountPlan(this.state.editDoc.id_target_account_plan, this.state.accountPlanDocs)}</FormLabel>
                        <FormLabel style={{ fontSize: 12, lineHeight: 1.2, fontWeight: 400 }}>Centro de Resultado: {PlanningHelper.renderResultCenter(this.state.editDoc.id_result_center, this.state.resultCenterDocs)}</FormLabel>
                    </div>

                    <KeyboardShortcutsTooltip />
                </div>

                {this.renderTable()}
            </div>
        )
    }

    renderTable() {

        return (
            <div style={{ maxHeight: this.state.showOptions ? window.screen.height - 600 : window.screen.height - 325, overflow: 'scroll' }}>
                <Table id={'budget-summary-table'} style={{ boxShadow: Colors.boxShadow }}>
                    <TableHead>
                        <TableRow style={{ backgroundColor: 'white' }}>
                            <TableCell style={{ width: 40, whiteSpace: 'nowrap', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, left: 0, zIndex: 1000, textAlign: 'center' }}></TableCell>
                            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, zIndex: 999, width: '1%', whiteSpace: 'nowrap' }}></TableCell>
                            <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, left: 38, zIndex: 999, width: '1%', whiteSpace: 'nowrap' }}></TableCell>
                            {
                                this.state.generatedReport['months'].map((month, key) => {

                                    let date = moment(month);
                                    let monthName = date.format('MMM');

                                    return <TableCell colSpan={this.state.generatedReport['columns'].length} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 0, zIndex: 998, textAlign: 'center', width: 'auto', whiteSpace: 'nowrap' }}>{`${monthName.charAt(0).toUpperCase() + monthName.substr(1)}/${date.format('YYYY')}`}</TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>

                    <TableRow>
                        <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', top: 38, left: 0, zIndex: 1000, textAlign: 'center' }}></TableCell>
                        <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 38, zIndex: 999, textAlign: 'center' }}>Código</TableCell>
                        <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', position: 'sticky', top: 38, left: 38, zIndex: 999, textAlign: 'center' }}>Descrição</TableCell>
                        {
                            this.state.generatedReport['months'].map((month, key) => {

                                return this.state.selectedColumns.map((column, key) => {

                                    return <TableCell style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold', position: 'sticky', top: 38, zIndex: 998, textAlign: 'center' }}>{ChartHelper.getLabelFromColumn(column)}</TableCell>
                                })
                            })
                        }
                    </TableRow>
                    
                    { this.renderBudgetStructure() }

                </Table>
            </div>
        )
    }

    renderBudgetStructure() {

        return this.state.budgetStructureDocs.map((doc, key) => {

            return (
                <TableBody>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}>
                            {doc.accounts && doc.accounts.length && doc.hasValue ?

                                doc.open ? <IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(doc.hash, this.state.budgetStructureDocs, false, doc.open) }}/> : <AddBoxIcon onClick={() => { this.openAccount(doc.hash, this.state.budgetStructureDocs, false, doc.open) }} style={{ color: 'grey', cursor: 'pointer' }}/>

                            : null}
                        </TableCell>
                        <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}>{'-'}</TableCell>
                        <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold', position: 'sticky', left: 38, zIndex: 1 }}><Tooltip title={doc.name}><div>{doc.name.length < 25 ? doc.name : doc.name.slice(0, 25) + '...'}</div></Tooltip></TableCell>
                        {
                            this.state.generatedReport['months'].map((month, monthIndex) => {

                                return this.state.generatedReport['columns'].map((column, key) => {

                                    let value = this.getColumnValue(doc, column, monthIndex);
                                    let color = this.getColumnColor(doc, column, monthIndex);

                                    return <TableCell style={{ backgroundColor: color.background, color: color.text, textAlign: 'center', fontWeight: 'bold' }}>{value}</TableCell>
                                }) 
                            })
                        }
                    </TableRow>
                    {
                        doc.open && doc.hasValue ? doc.accounts.map((account, accountKey) => {

                            let isStructure = account.isStructure;
                            let render = [];

                            if (!isStructure) {

                                render.push(

                                    <TableRow>
                                        <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}>
    
                                            { (account.children && account.children.length && this.childrenAccountHasValue(account.accounts)) || (isStructure && account.accounts && account.accounts.length && this.childrenAccountHasValue(account.accounts)) ?
    
                                                account.open ? <IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.state.budgetStructureDocs, false, account.open) }}/> : <AddBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.state.budgetStructureDocs, false, account.open) }}/>
    
                                            : null }
    
                                        </TableCell>
                                        <TableCell style={{ backgroundColor: 'white' }}>{account.code}</TableCell>
                                        <TableCell style={{ backgroundColor: 'white', position: 'sticky', left: 38, zIndex: 1 }}><Tooltip title={account.description}><div>{account.description.length < 25 ? account.description : account.description.slice(0, 25) + '...'}</div></Tooltip></TableCell>
                                        {
                                            this.state.generatedReport['months'].map((month, monthIndex) => {
    
                                                return this.state.generatedReport['columns'].map((column, key) => {

                                                    let value = this.getColumnValue(account, column, monthIndex);
                                                    let color = this.getColumnColor(account, column, monthIndex);

                                                    return <TableCell style={{ textAlign: 'center', backgroundColor: color.background, color: color.text }}>{value}</TableCell>
                                                })
                                            })
                                        }
                                    </TableRow>
                                );

                                if (account.open) {
    
                                    let childrens = account.accounts;
                                    render.push(this.renderAccountChildren(childrens, key))
                                }
                            }

                            return render;
                            
                        }) : null
                    }
                </TableBody>

            )
        });
    }

    renderAccountChildren(childrens, structureIndex) {

        if (childrens && childrens.length) {

            return childrens.map((account, accountKey) => {

                let isStructure = account.isStructure;
                let render = [];

                if (account.hasValue) {
                    
                    render.push(
        
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#f0f0f0', position: 'sticky', left: 0, zIndex: 1 }}>
        
                                { (account.children && account.children.length && this.childrenAccountHasValue(account.accounts)) || (isStructure && account.accounts && account.accounts.length && this.childrenAccountHasValue(account.accounts)) ?
        
                                    account.open ? <IndeterminateCheckBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.state.budgetStructureDocs, false, account.open) }}/> : <AddBoxIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.openAccount(account.hash, this.state.budgetStructureDocs, false, account.open) }}/>
        
                                : null }
        
                            </TableCell>
                            <TableCell style={{ backgroundColor: 'white' }}>{account.code}</TableCell>
                            <TableCell style={{ backgroundColor: 'white', position: 'sticky', left: 38, zIndex: 1 }}><Tooltip title={account.description}><div>{account.description.length < 25 ? account.description : account.description.slice(0, 25) + '...'}</div></Tooltip></TableCell>
                            {
                                this.state.generatedReport['months'].map((month, monthIndex) => {
        
                                    return this.state.generatedReport['columns'].map((column, key) => {
    
                                        let value = this.getColumnValue(account, column, monthIndex);
                                        let color = this.getColumnColor(account, column, monthIndex);
    
                                        return <TableCell style={{ textAlign: 'center', backgroundColor: color.background, color: color.text }}>{value}</TableCell>
                                    })
                                })
                            }
                        </TableRow>
                    );
                }

                if (account.open) {
    
                    let childrens = account.accounts;
                    render.push(this.renderAccountChildren(childrens, structureIndex))
                }
    
                return render;
            })
        }
    }

    renderAlternativeView() {

        return (
            <div style={{ padding: 40 }}>
                <Card style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 15, paddingBottom: 40, height: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxShadow: Colors.boxShadow }}>
                    <img style={{ height: 350 }} src={process.env.PUBLIC_URL + '/spreadsheet.png'} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                        <p style={{ fontSize: 25, fontWeight: 'bold' }}>{`Sua empresa é optante pelo SIMPLES.`}</p>
                        <div style={{ color: 'grey', fontSize: 16 }}>Os impostos estão calculados em <a href={'/deductions'}>deduções</a>.</div>
                    </div>
                </Card>
            </div>
        )
    }

    getMethodologyLookup() {
        return {
            'absolute': Methods.absolute.label,
            'percentage': Methods.percentage.label
        }
    }

    renderContent() {
        return this.state.regime_tributario === 'simples' ? this.renderAlternativeView() : this.state.editing ? this.renderEditing() : this.renderGrid();
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : this.renderContent();
    }
}

const styles = {
    container: {
        padding: 25,
    },
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        borderRadius: 5
    },
    plannigContainerWrapper: {
        width: '100%',
        flexDirection: 'column',
        flex: 1,
        padding: 25,
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainerColumns: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: Colors.background
    },
    plannigContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: 0,
        marginTop: 30,
        width: '100%',
        maxHeight: '100%',
        height: 'auto',
        backgroundColor: Colors.background
    },
    listWrapper: {
        width: 280,
        borderRadius: 5,
        maxHeight: '100%',
        backgroundColor: `#ffffff`
    },
}
