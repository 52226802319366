import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Table, TableBody, TableHead, TableRow, TableCell, Button, FormLabel, TableContainer } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import ExcelModal from '../components/ExcelModal';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import moment from 'moment';
import ClipLoader from "react-spinners/ClipLoader";
import CurrencyHelper from '../helper/CurrencyHelper';
import DefaultInput from '../components/DefaultInput';
import DefaultModal from '../components/DefaultModal';
import AddIcon from '@material-ui/icons/Add';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Methods from '../constants/Methods';
import Operators from '../constants/Operators';
import DataHelper from '../helper/DataHelper';
import ExcelImportValidators from '../constants/ExcelImportValidators';

export default class BalancetePage extends Component {

    state = {
        docs: [],
        periodDocs: [],
        accountPlanDocs: [],
        resultCenterDocs: [],
        loading: true,
        loadingPanel: false,
        fetchedBalancete: false,
        loadingModal: false,
        description: '',
        date: moment().startOf('month').toDate(),
        addModal: false,
        excelModal: false,
        loadingText: 'Carregando...',
        insertedCounter: 0,
        duplicates: [],
        duplicateModal: false,
        balanceteRows: [],
        editId: null,
        colsub: [
            { name: 'result_center', alias: 'Centro de Resultado', required: true, example: '1.2.1.10001', validators: [ExcelImportValidators.trim] },
            { name: 'result_center_description', alias: 'Descrição do Centro de Resultado', required: true, example: 'TELEVENDAS' },
            { name: 'account', alias: 'Conta', example: '3.1.1.01.0101001', validators: [ExcelImportValidators.trim] },
            { name: 'account_description', alias: 'Descrição da Conta', required: true, example: 'RECEITA VENDAS PRODUTOS' },
            { name: 'debit', alias: 'Débito', required: true, example: '0', money: true, },
            { name: 'credit', alias: 'Crédito', required: true, example: '2.539.200,25', money: true, },
            { name: 'period_movement', alias: 'Movimento do Período', required: true, example: '2.539.200,25', money: true, },
        ],
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('balancete').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;
            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getAccountPlans() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ accountPlanDocs: docs, loading: false });
    }

    async getResultCenters() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('result_center').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ resultCenterDocs: docs, loading: false });
    }

    async getPeriods() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('start', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ periodDocs: docs, loading: false });
    }

    async removeBalanceteChunk(chunk) {

        for (let row of chunk) {

            await Firestore.delete('balancete_row', row.id);
        }
    }

    async loadBalancete(id) {

        await this.setState({ loadingPanel: true, balanceteRows: [] });

        let query = await Firestore.customQuery('balancete_row').where('id_balancete', '==', id).orderBy('index', 'asc').get();
        let rows = [];

        query.forEach((row, key) => {

            let data = { ...row.data(), id: row.id };
            rows.push(data);
        });

        await this.setState({ loadingPanel: false, balanceteRows: rows, editId: id });
    }

    async componentDidMount() {
        await this.getAccountPlans();
        await this.getResultCenters();
        await this.getDocs();
        await this.getPeriods();
    }

    handleExcelImport = async (data, fileName) => {
        if (data.length > 0) {
            this.setState({ loading: true });

            try {

                let accountDocs = DataHelper.createObjectFromArray(this.state.accountPlanDocs, 'code');
                let resultCenterDocs = DataHelper.createObjectFromArray(this.state.resultCenterDocs, 'code');
                let month = moment(this.state.date).format('MMM/YYYY');
                let date = moment(this.state.date).startOf('month').startOf('day').toDate();
                let count = 0;

                let balanceteDoc = await Firestore.getId('balancete');
                let chunks = DataHelper.createSubArrays(data, 500);
                
                await Promise.all(chunks.map( async (chunk) => {

                    for (let i = 0; i < chunk.length; i++) {
    
                        chunk[i].debit = chunk[i].debit ? parseFloat(chunk[i].debit) : 0;
                        chunk[i].credit = chunk[i].credit ? parseFloat(chunk[i].credit) : 0;
                        chunk[i].value = chunk[i].period_movement ? parseFloat(chunk[i].period_movement) : 0;
                        chunk[i].id_balancete = balanceteDoc;
                        chunk[i].index = i;
                        chunk[i].id_company = SessionHelper.getData().id_company;
                        chunk[i].date = date;
                        chunk[i].date_label = month;
                        chunk[i].method = Methods.absolute.key;
                        chunk[i].multiplier = 0;
                        chunk[i].operator = Operators.sum.key;
    
                        let findAccount = true;
                        let accountDoc = null;
                        let resultCenterDoc = null;
    
                        if (chunk[i].account) {
    
                            accountDoc = accountDocs[chunk[i].account];
    
                            if (accountDoc && accountDoc.id) {
    
                                chunk[i].account = accountDoc.id;
    
                            } else {
    
                                findAccount = false;
                            }
                            
                        } else {
    
                            findAccount = false;
                        }
    
                        if (chunk[i].result_center) {
    
                            resultCenterDoc = resultCenterDocs[chunk[i].result_center];
    
                            if (resultCenterDoc && resultCenterDoc.id) {
    
                                chunk[i].result_center = resultCenterDoc.id;
    
                            } else {
    
                                chunk[i].result_center = null;
                            }
                        }
                        
                        if (findAccount) {
    
                            await Firestore.insert(chunk[i], 'balancete_row');
    
                        } else {
    
                            let duplicates = this.state.duplicates;
                            duplicates.push(chunk[i]);
    
                            await this.setState({ duplicates });
                        }

                        count++;
                        this.setState({ loadingText: `Inserindo ${count} de ${data.length}` })
                    }
                }));

                let balanceteData = {
                    description: this.state.description,
                    id_company: SessionHelper.getData().id_company,
                    date: this.state.date,
                    created_at: new Date(),
                }
    
                await Firestore.insert(balanceteData, 'balancete', balanceteDoc);

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                this.setState({ insertedCounter: data.length - this.state.duplicates.length });

                toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });

            } catch (error) {

                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            await this.getDocs();

            this.setState({ loading: false, loadingText: `` });
        }
    }

    handleAddModal() {

        if (this.state.description && this.state.date) {

            this.setState({ excelModal: true });

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{col.money ? CurrencyHelper.formatMoney(row[col.name]) : row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%', marginBottom: 15 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        label="Mês"
                        views={["year", "month"]}
                        openTo={'month'}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={(v) => { this.setState({ date: v }) }}
                        value={this.state.date}
                    />
                </MuiPickersUtilsProvider>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.handleAddModal() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    detailPanel(data) {

        if (!this.state.loadingPanel && data.id !== this.state.editId) {

            this.loadBalancete(data.id);
        }

        return (
            <div style={{ width: '100%' }}>

                {this.state.loadingPanel ?

                    <div style={{ display: 'flex', justifyContent: 'center', padding: 25 }}>
                        <ClipLoader
                            size={25}
                            color={Colors.primary}
                            loading={true}
                        />
                    </div>

                    :

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>{'Centro de Resultado'}</TableCell>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>{'Descrição do Centro de Resultado'}</TableCell>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>{'Conta'}</TableCell>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }}>{'Descrição da Conta'}</TableCell>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }} align={'right'}>{'Débito'}</TableCell>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }} align={'right'}>{'Crédito'}</TableCell>
                                <TableCell style={{ backgroundColor: 'lightgrey', fontWeight: 'bold' }} align={'right'}>{'Movimento do Período'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.balanceteRows.map((row, key) => {

                                    let account = row.account ? this.state.accountPlanDocs.find(item => item.id === row.account) : {};
                                    let resultCenter = row.result_center ? this.state.resultCenterDocs.find(item => item.id === row.result_center) : {};

                                    return (
                                        <TableRow>
                                            <TableCell>{resultCenter ? resultCenter.code : ''}</TableCell>
                                            <TableCell>{resultCenter ? resultCenter.description : ''}</TableCell>
                                            <TableCell>{account ? account.code : ''}</TableCell>
                                            <TableCell>{account ? account.description : ''}</TableCell>
                                            <TableCell align={'right'}>{CurrencyHelper.formatMoney(row.debit, false)}</TableCell>
                                            <TableCell align={'right'}>{CurrencyHelper.formatMoney(row.credit, false)}</TableCell>
                                            <TableCell align={'right'}>{CurrencyHelper.formatMoney(row.value, false)}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                }

            </div>
        )
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>

                <DefaultTable
                    title={'Balancete'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Mês', field: 'date', editable: false, render: rowData => rowData.date ? moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('MM/YYYY') : <div></div>, customFilterAndSearch: (term, rowData) => rowData.date ? moment(rowData.date.toDate()).format('DD/MM/YYYY HH:mm').indexOf(term) > -1 : false },
                        { title: 'Data de Cadastro', field: 'date', editable: false, render: rowData => <div>{rowData.created_at ? moment(rowData.created_at.toDate ? rowData.created_at.toDate() : rowData.created_at).format('DD/MM/YYYY HH:mm') : ''}</div> },
                    ]}
                    data={this.state.docs}
                    detailPanel={(rowData) => { return this.detailPanel(rowData) }}
                    onRowClick={(evt, rowData, togglePanel) => { togglePanel() }}
                    onRowUpdate={async (oldData, newData) => {

                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev[index] = newData;

                        this.setState({ docs: prev })
                        if (oldData.id) {

                            let data = {
                                description: newData.description,
                            };

                            await Firestore.update(data, 'balancete', oldData.id);
                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.forceUpdate();

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        if (oldData.id) {

                            toast.warn('Esse processo pode levar um tempo, por favor aguarde...', { autoClose: false });

                            let balanceteRows = await Firestore.customQuery('balancete_row').where('id_balancete', '==', oldData.id).get();

                            if (balanceteRows.size > 0) {

                                let docs = DataHelper.createSubArrays(balanceteRows.docs, 500);
                                await Promise.all(docs.map( async (chunk) => { await this.removeBalanceteChunk(chunk) }));
                            }

                            await Firestore.delete('balancete', oldData.id);

                            prev.splice(index, 1);
                            this.setState({ docs: prev });

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false, addModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Balancete'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} width={'80%'} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} width={'80%'} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
