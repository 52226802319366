export default {
    
    trim: (data) => {
        
        if (typeof data === 'string') {

            return data.trim();
        }

        return data;
    },
}