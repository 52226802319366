import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { Card, Button } from '@material-ui/core';
import DefaultInput from '../components/DefaultInput';
import Colors from '../constants/Colors';
import Auth from '../api/firebase/Auth';
import { withRouter } from 'react-router-dom';
import DefaultLoader from '../components/DefaultLoader';

class LoginPage extends Component {

    state = {
        email: '',
        password: '',
        emailError: false,
        passwordError: false,
        emailErrorMessage: '',
        passwordErrorMessage: '',
        error: false,
        loading: false
    }

    async login() {

        this.setState({ emailError: false, passwordError: false, emailErrorMessage: '', passwordErrorMessage: '', error: false, loading: true });

        if (this.state.email && this.state.password) {

            Auth.login(this.state.email, this.state.password).then((result) => {

                if (result.user) {

                    this.props.history.push('/');

                } else {

                    this.setState({ passwordError: true, emailError: true, passwordErrorMessage: 'E-mail ou senha incorretos', loading: false });
                }
            });

        } else {

            if (!this.state.email) {
                this.setState({ emailError: true, emailErrorMessage: 'Informe o e-mail', loading: false, });
            }

            if (!this.state.password) {
                this.setState({ passwordError: true, passwordErrorMessage: 'Informe a senha', loading: false });
            }
        }
    }

    handleShortcut = (evt) => {
        if (evt.key === "Enter") {
            this.login();
        }
    }

    renderLoading() {
        return <DefaultLoader css={{ position: 'absolute', top: '38vh', left: '49%' }} />
    }

    render() {
        return (
            <Container style={{ backgroundColor: Colors.primary, display: 'flex', justifyContent: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'auto' }} maxWidth={false}>
                <div style={{ height: '100vh' }}>
                    <Card style={{ padding: 30, marginTop: '20vh', filter: this.state.loading ? 'blur(5px)' : '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ paddingBottom: 70, paddingTop: 15, height: 64, }} src={`${process.env.PUBLIC_URL}/logo-dark.png`} />
                        <form>
                            <DefaultInput onKeyDown={this.handleShortcut} error={this.state.emailError} helperText={this.state.emailErrorMessage} onChange={(v) => { this.setState({ email: v }) }} required={true} id={'email'} label={'E-mail'} />
                            <DefaultInput onKeyDown={this.handleShortcut} error={this.state.passwordError} helperText={this.state.passwordErrorMessage} onChange={(v) => { this.setState({ password: v }) }} required={true} id={'password'} type={'password'} label={'Senha'} />
                            <Button onClick={() => { this.login() }} style={{ width: '100%', fontWeight: 'bold', marginTop: 30, padding: 15, backgroundColor: Colors.primary, color: 'white' }} variant="contained" >ENTRAR</Button>
                        </form>
                        <a href={'/password_reset'} className={'register-link'}>{'Esqueci minha senha'}</a>
                    </Card>
                    {this.state.loading ? this.renderLoading() : null}
                </div>
            </Container>
        );
    }
}

export default withRouter(LoginPage);
