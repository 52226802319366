import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';

export default class ProductPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        description: '',
        start: moment().startOf('month'),
        end: moment().startOf('month').add(3, 'month'),
        loadingText: 'Carregando períodos...',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('period').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async addNewDoc() {

        if (this.state.description && SessionHelper.getData().id_company) {

            try {
                this.setState({ loadingModal: true });

                let data = {
                    description: this.state.description,
                    start: this.state.start.toDate(),
                    end: this.state.end.toDate(),
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                await Firestore.insert(data, 'period');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.getDocs();

            } catch (error) {

                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false,
            addModal: false,
            description: '',
        });
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        label="Início do Período"
                        views={["year", "month"]}
                        openTo={'month'}
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={async (v) => { await this.setState({ start: moment(v).startOf('month') }) }}
                        value={this.state.start}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
                        invalidDateMessage={false}
                        format={'MM/yyyy'}
                        autoOk={true}
                        openTo={'month'}
                        views={["year", "month"]}
                        label="Fim do Período"
                        cancelLabel={'Cancelar'}
                        okLabel={'Confirmar'}
                        onChange={async (v) => { await this.setState({ end: moment(v).startOf('month') }) }}
                        value={this.state.end}
                    />
                </MuiPickersUtilsProvider>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, description: '' }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    async canDelete(id) {

        let canDelete = true;

        let planning = await Firestore.customQuery('planning').where('id_period', '==', id).limit(1).get();
        let tax = await Firestore.customQuery('taxes').where('id_period', '==', id).limit(1).get();

        if (planning.size > 0) {

            canDelete = false;
        }

        if (tax.size > 0) {

            canDelete = false;
        }

        return canDelete;
    }

    renderDateEdit(props) {

        let value = props.value;

        if (value.seconds) {

            value = new Date(value.seconds * 1000);
        }

        return (
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    style={{ width: '100%' }}
                    invalidDateMessage={false}
                    format={'dd/MM/yyyy'}
                    autoOk={true}
                    cancelLabel={'Cancelar'}
                    okLabel={'Confirmar'}
                    onChange={ (v) => { props.onChange(v) }}
                    value={value}
                />
            </MuiPickersUtilsProvider>
        )
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    marginTop={10}
                    title={'Períodos'}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Início', field: 'start', render: rowData => <div>{moment(rowData.start.toDate ? rowData.start.toDate() : rowData.start).format('MM/YYYY')}</div>, editComponent: props => this.renderDateEdit(props) },
                        { title: 'Fim', field: 'end', render: rowData => <div>{moment(rowData.end.toDate ? rowData.end.toDate() : rowData.end).format('MM/YYYY')}</div>, editComponent: props => this.renderDateEdit(props) },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if ( await (this.canDelete(oldData.id))) {
                         
                            let prev = this.state.docs;

                            newData.start = newData.start === null ? null : newData.start.seconds ? new Date(newData.start.seconds * 1000) : newData.start;
                            newData.end = newData.end === null ? null : newData.end.seconds ? new Date(newData.end.seconds * 1000) : newData.end;
    
                            if (oldData.id) {
    
                                let data = {
                                    description: newData.description,
                                    start: newData.start,
                                    end: newData.end,
                                };
    
                                await Firestore.update(data, 'period', oldData.id);
    
                                toast.success("Editado com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
        
                                prev[prev.indexOf(oldData)] = newData;
        
                                this.setState({ docs: prev });
        
                                return prev;
                            }

                        } else {

                            toast.warn('Não é possível editar um período com vínculos no planejamento', { autoClose: false });
                            return;
                        }
                    }}
                    onRowDelete={async (oldData) => {

                        if ( await (this.canDelete(oldData.id))) {

                            let prev = this.state.docs;
                            prev.splice(prev.indexOf(oldData), 1);

                            this.setState({ docs: prev });

                            if (oldData.id) {

                                await Firestore.delete('period', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;
                            }

                        } else {

                            toast.warn('Não é possível remover um período com vínculos no planejamento', { autoClose: false });
                            return;
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Período'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
