import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

export default class TagPage extends Component {

    state = {
        docs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        description: '',
        loadingText: 'Carregando tags...',
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('tag').where('id_company', '==', SessionHelper.getData().id_company).orderBy('date', 'desc');

        query = await query.get();

        let docs = [];

        for (let index = 0; index < query.docs.length; index++) {
            
            const doc = query.docs[index];
         
            let data = doc.data();
            data.id = doc.id;

            let actionPlans = await Firestore.customQuery('action_plan').where('id_tags', 'array-contains', data.id).get();
            let actionPlansDocs = [];

            for (let index = 0; index < actionPlans.docs.length; index++) {

                const actionPlanDoc = actionPlans.docs[index];
                actionPlansDocs.push(actionPlanDoc.data());
            }

            data.action_plans = actionPlansDocs;
            docs.push(data);
        }

        this.setState({ docs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async addNewDoc() {

        if (this.state.description && SessionHelper.getData().id_company) {

            try {
                this.setState({ loadingModal: true });

                let data = {
                    description: this.state.description,
                    id_company: SessionHelper.getData().id_company,
                    date: new Date(),
                };

                await Firestore.insert(data, 'tag');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.getDocs();

            } catch (error) {

                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false,
            addModal: false,
            description: '',
        });
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, description: '' }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    async canDelete(id) {

        let canDelete = true;

        let actionPlans = await Firestore.customQuery('action_plan').where('tags', 'array-contains', id).limit(1).get();

        if (actionPlans.size > 0) {

            canDelete = false;
        }

        return canDelete;
    }

    renderCounter(docs) {

        let tooltip = docs.map((item, key) => item.description);
        let color = Colors.danger;

        if (docs.length > 0) {

            color = Colors.success;
        }

        return (

            <Tooltip title={tooltip.join(', ')}>
                <div style={{ fontWeight: 'bold', backgroundColor: color, color: 'white', width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 4 }}>{docs.length}</div>
            </Tooltip>
        )
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    marginTop={10}
                    title={'Tags'}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Planos de Ação', field: 'action_plans', render: rowData => this.renderCounter(rowData.action_plans) },
                        { title: 'Data', field: 'date', editable: false, render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm')}</div> },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        let prev = this.state.docs;
    
                        if (oldData.id) {

                            let data = {
                                description: newData.description,
                            };

                            await Firestore.update(data, 'tag', oldData.id);

                            toast.success("Editado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
    
                            prev[prev.indexOf(oldData)] = newData;
    
                            this.setState({ docs: prev });
    
                            return prev;
                        }
                    }}
                    onRowDelete={async (oldData) => {

                        if ( await (this.canDelete(oldData.id))) {

                            let prev = this.state.docs;
                            prev.splice(prev.indexOf(oldData), 1);

                            this.setState({ docs: prev });

                            if (oldData.id) {

                                await Firestore.delete('tag', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;
                            }

                        } else {

                            toast.warn('Não é possível remover uma tag com vínculos nos planos de ação', { autoClose: false });
                            return;
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Tag'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
