import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultSelect from '../components/DefaultSelect';
import DefaultInput from '../components/DefaultInput';
import DefaultModal from '../components/DefaultModal';
import DefaultTable from '../components/DefaultTable';
import DefaultButton from '../components/DefaultButton';
import IosSwitch from '../components/IosSwitch';
import Colors from '../constants/Colors';
import { List, ListItem, Button, Tooltip, FormLabel, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import Functions from '../api/firebase/Functions';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SessionHelper from '../helper/SessionHelper';
import firebase from 'firebase/app';
import { toast, ToastContainer } from 'react-toastify';
import AddCircleIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InputMask from "react-input-mask";
import LocationPicker from '../components/LocationPicker';
import GeographicHelper from '../helper/GeographicHelper';
import DocumentHelper from '../helper/DocumentHelper';
import Geohash from '../helper/Geohash';
import 'firebase/firestore';

moment.locale('pt-br');

export default class AdminPage extends Component {

    state = {
        loading: false,
        loadingModal: false,
        addCompanyModal: false,
        addModal: false,
        consultorsModal: false,
        companyConsultorsModal: false,
        injectedCompany: false,
        docs: [],
        consultantDocs: [],
        auxDocs: [],
        search: '',
        name: '',
        email: '',
        sector: '',
        password: '',
        passwordConfirmation: '',
        editId: null,
        companyConsultors: [],
        fetchedCompanyConsultors: false,
        consultant: false,
        companyData: {
            email: '',
            city: '',
            ddd: '',
            name: '',
            phone: '',
            cnpj: '',
            zipcode: '',
            state: '',
            street: '',
            number: '',
            complemento: '',
            neighborhood: '',
            coordinates: GeographicHelper.setFirebaseGeopoint(-27, -52),
            regime_tributario: 'simples',
            type: 'industria',
        }
    }

    async componentDidMount() {

        await this.setState({ loading: true });

        await this.getDocs();
        await this.getConsultors();
        await this.verifyCacheCompany();

        await this.setState({ loading: false });
    }

    async getDocs() {

        let query = await Firestore.customQuery('company').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, auxDocs: docs });
    }

    async getConsultors() {

        let query = await Firestore.customQuery('user').where('type', '==', 'consultant').orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ consultantDocs: docs });
    }

    async verifyCacheCompany() {

        let hasCompany = localStorage.getItem('id_company');

        if (hasCompany) {

            let company = this.state.docs.find(item => item.id === hasCompany);

            if (company) {

                this.enterCompany(company);
            }
        }
    }

    async handleSwitch(idCompany, value) {

        if (idCompany) { await Firestore.update({ active: value }, 'company', idCompany) }
    }

    async handleSearch(text) {

        let search = text.toLowerCase();

        await this.setState({ search: search });

        if (this.state.search.toString().length > 2) {

            let result = [];

            if (this.state.auxDocs.length) {

                let array = this.state.auxDocs;

                result = array.filter((company) => {

                    const name = company.name ? company.name.toLowerCase() : ''.toLowerCase();
                    const cnpj = company.cnpj ? company.cnpj.toString().replace(/\D+/g, '').toLowerCase() : ''.toLowerCase();

                    const search = this.state.search.toString().toLowerCase();

                    if (name.indexOf(search) > -1) {

                        return true;
                    }

                    if (cnpj.indexOf(search) > -1) {

                        return true;
                    }
                });
            }

            this.setState({ docs: result });

        } else if (this.state.search.toString().length === 0) {

            this.getDocs();
        }
    }

    async addCompany() {

        try {

            this.setState({ loadingModal: true });

            let valid = await this.validateCompanyData();
            if (!valid) { this.setState({ loadingModal: false }); return };

            let idCompany = await Firestore.getId('company');
            let idPermission = await Firestore.getId('permission_group');

            let coordinates = GeographicHelper.getLatLngFromGeopoint(this.state.companyData.coordinates);

            let companyData = {
                active: true,
                city: this.state.companyData.city,
                complemento: this.state.companyData.complemento,
                country: 'Brasil',
                language: 'br',
                ddd: this.state.companyData.ddd.replace(/[^\d]/g, ''),
                email: this.state.companyData.email,
                name: this.state.companyData.name,
                type: this.state.companyData.type,
                regime_tributario: this.state.companyData.regime_tributario,
                neighborhood: this.state.companyData.neighborhood,
                number: this.state.companyData.number.replace(/[^\d]/g, ''),
                phone: this.state.companyData.phone.replace(/[^\d]/g, ''),
                state: this.state.companyData.state,
                street: this.state.companyData.street,
                zipcode: this.state.companyData.zipcode.replace(/[^\d]/g, ''),
                g: {
                    geohash: Geohash.encode(coordinates.lat, coordinates.lng),
                },
                cnpj: this.state.companyData.cnpj.replace(/[^\d]/g, ''),
                cnpj: this.state.companyData.cnpj.replace(/[^\d]/g, ''),
                created_at: new Date(),
            }

            let geopoint = new firebase.firestore.GeoPoint(this.state.companyData.coordinates.latitude, this.state.companyData.coordinates.longitude);
            companyData.coordinates = geopoint;
            companyData.g.geopoint = geopoint;

            let permissionDoc = {
                name: 'Administrador',
                id_company: idCompany,
                routes: {
                    responsability_matrix: true,
                    period: true,
                    revenue_planning_settings: true,
                    revenue_planning: true,
                    deductions: true,
                    costs: true,
                    expenses: true,
                    people_expenses: true,
                    expenses_and_revenue: true,
                    other_expenses_and_revenue: true,
                    taxes: true,
                    budget_resume: true,
                    balancete: true,
                    orcado_versus_accomplished: true,
                    action_plan: true,
                    chart_builder: true,
                    company: true,
                    permission: true,
                    user: true,
                    result_center: true,
                    account_plan: true,
                    account_package: true,
                    budget_structure: true,
                    product: true,
                    role: true,
                    employee: true,
                    dissidio: true,
                    tag: true,
                },
            };

            await Firestore.insert(companyData, 'company', idCompany);
            await Firestore.insert(permissionDoc, 'permission_group', idPermission);

            await this.getDocs();

            toast.success('Empresa adicionada com sucesso');
            this.setState({ loadingModal: false, addCompanyModal: false });

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao cadastrar essa empresa');
        }
    }

    async addConsultor() {

        if (this.state.name && this.state.email && this.state.sector && this.state.password) {

            if (this.state.password === this.state.passwordConfirmation) {

                try {

                    this.setState({ loadingModal: true });

                    let data = {
                        name: this.state.name,
                        email: this.state.email,
                        sector: this.state.sector,
                        password: this.state.password,
                    };

                    let response = await Functions.request('POST', 'addConsultant', data);

                    if (response.status !== 200) {

                        throw new Error('Consultor error');
                    }

                    toast.success('Consultor criado com sucesso');
                    this.setState({ loadingModal: false, addModal: false });

                    this.getConsultors();

                } catch (error) {

                    toast.error('Houve um problema ao inserir o consultor')
                    this.setState({ loadingModal: false });
                }

            } else {

                toast.warn('As senhas são diferentes');
            }

        } else {

            toast.warn('Preencha todos os campos');
        }
    }

    enterCompany(doc) {

        if (doc) {

            let session = SessionHelper.getData();
            session.id_company = doc.id;
            session.company = doc;

            SessionHelper.setData(session);
            localStorage.setItem('id_company', doc.id);

            this.props.app.forceUpdate();
        }
    }

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    async getCompanyConsultors() {

        let query = await Firestore.customQuery('user').where('companies', 'array-contains', this.state.editId).get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ companyConsultors: docs, fetchedCompanyConsultors: true });
    }

    async removeConsultor(companies, id_user) {

        this.setState({ loadingModal: true });

        companies.splice(companies.indexOf(this.state.editId), 1);

        await Firestore.update({ companies: companies }, 'user', id_user);
        await this.getCompanyConsultors();

        this.getConsultors();

        this.setState({ loadingModal: false });
        toast.success('Consultor desvinculado com sucesso');
    }

    async addCompanyConsultant() {

        if (this.state.consultant) {

            let company = this.state.editId;
            let user = this.state.consultantDocs.find(item => item.id === this.state.consultant);

            if (user) {

                let companies = user.companies || [];

                if (!companies.includes(company)) {

                    this.setState({ loadingModal: true });

                    companies.push(company);

                    await Firestore.update({ companies: companies }, 'user', user.id);
                    await this.getCompanyConsultors();

                    this.setState({ loadingModal: false });
                    toast.success('Consultor vinculado com sucesso');

                } else {

                    toast.warn('Esse consultor já está vinculado a essa empresa')
                }
            }

        } else {

            toast.warn('Escolha um consultor');
        }
    }

    async validateCompanyData() {

        let flag = true;

        if (this.state.companyData.name && this.state.companyData.email && this.state.companyData.ddd && this.state.companyData.phone && this.state.companyData.cnpj && this.state.companyData.zipcode && this.state.companyData.state && this.state.companyData.city && this.state.companyData.street && this.state.companyData.number && this.state.companyData.neighborhood) {

            if (DocumentHelper.cnpjValid(this.state.companyData.cnpj.replace(/[^\d]/g, ''))) {
    
                let emailExists;

                try {
                    
                    let request = await Functions.request('POST', 'emailExists', { email: this.state.companyData.email });
    
                    if (request.status === 200) {
    
                        emailExists = request.result;
    
                    } else {
    
                        emailExists = null;
                    }
    
                } catch (error) {
    
                    emailExists = null;
                }

                if (emailExists === true || emailExists === null) {
    
                    flag = false;
                    toast.warn('Já existe um login com o e-mail escolhido');
                }

                let companyExists;
    
                try {
    
                    let request = await Functions.request('POST', 'companyExists', { cnpj: this.state.companyData.cnpj, type: 'pj' });
    
                    if (request.status === 200) {
    
                        companyExists = request.result;
    
                    } else {
    
                        companyExists = null;
                    }
    
                } catch (error) {
    
                    companyExists = null;
                }
    
                if (companyExists === true || companyExists === null) {
    
                    flag = false;
                    toast.warn(`Já existe uma empresa com o ${this.state.companyData.type === 'pj' ? 'Cnpj' : 'Cpf'} escolhido`);
                }
    
                if (this.state.inputError) {
                    flag = false;
                    toast.warn('Preencha todos os campos corretamente');
                }

            } else {

                flag = false;
                toast.warn(`O cnpj informado é inválido`);
            }

        } else {

            flag = false;
            toast.warn('Preencha todos os campos');
        }

        return flag;
    }

    handleMapChange = (geodata) => {
        let state = this.state.companyData;

        if (geodata.address) {
            Object.keys(geodata.address).forEach(key => {
                if (key == 'number') {
                    state[key] = geodata.address[key].replace(/\D*/, '');
                    state['complemento'] = geodata.address[key].replace(/\d*/, '');
                } else {
                    state[key] = geodata.address[key];
                }
            });
        }
        if (geodata.coords) {
            state.coordinates = GeographicHelper.setFirebaseGeopoint(geodata.coords.lat, geodata.coords.lng);
        }

        this.setState({ companyData: { ...this.state.companyData, ...state } });
    }

    renderCompanies() {

        return this.state.docs.map((doc, key) => {

            return (
                <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: this.state.docs[key + 1] ? '1px solid lightgrey' : '', padding: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '60%' }}>
                        <div style={{ minWidth: 250 }}>
                            <img style={{ height: 80, marginRight: 20, width: 250 }} src={doc.logo ? doc.logo : 'http://placehold.it/900x300'} />
                        </div>
                        <div style={{}}>
                            <div style={{ fontWeight: 'bold' }}>{`${doc.name} • ${doc.cnpj}`}</div>
                            <div style={{ color: 'grey', marginTop: 8 }}>{`${doc.street}, ${doc.number}${doc.complemento}, ${doc.neighborhood}. ${doc.city} - ${doc.state}, ${doc.zipcode}.`}</div>
                            <div style={{ color: 'grey' }}>{`${doc.email} • ${doc.phone ? doc.phone : 'Sem Telefone'}`}</div>
                            <div style={{ color: 'grey', fontSize: 12, marginTop: 8 }}>{`Na plataforma desde ${moment(doc.created_at.toDate()).format('DD/MM/YYYY')}`}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title={'Acessar Empresa'}>
                            <div>
                                <DefaultButton onClick={() => { this.enterCompany(doc) }} color={Colors.primary} loading={this.state.loadingModal} title={''} leftIcon={<ExitToAppIcon />}/>
                            </div>
                        </Tooltip>
                        <div style={{ marginLeft: 8 }} />
                        <Tooltip title={'Consultores Vinculados'}>
                            <div>
                                <DefaultButton onClick={ async () => { await this.setState({ companyConsultorsModal: true, editId: doc.id }); this.getCompanyConsultors(); }} color={Colors.primary} loading={this.state.loadingModal} title={''} leftIcon={<PeopleIcon />}/>
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{ marginRight: 20 }}>
                        <IosSwitch onChange={(v) => { this.handleSwitch(doc.id, v) }} labelPlacement={'start'} label={'Ativa'} checked={doc.active} />
                    </div>
                </ListItem>
            )
        });
    }

    renderEmpty() {

        return (
            <p style={{ textAlign: 'center', color: 'grey' }}>{'Nenhuma empresa encontrada.'}</p>
        )
    }

    renderSearch() {

        return (
            <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 20, borderBottom: '1px solid lightgrey' }}>
                <input value={this.state.search} onChange={(evt) => { this.handleSearch(evt.target.value) }} style={{ width: '100%', border: '1px solid lightgrey', padding: 20, borderRadius: 10, backgroundColor: 'white', boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', fontSize: 16 }} placeholder={'Busque por nome e cnpj...'} />
                {this.state.search ? <ClearIcon onClick={() => { this.setState({ search: '' }); this.getDocs(); }} style={{ fontSize: 34, cursor: 'pointer', position: 'absolute', right: 0, marginRight: 40 }} /> : <SearchIcon style={{ fontSize: 34, cursor: 'pointer', position: 'absolute', right: 0, marginRight: 40 }} />}
            </ListItem>
        )
    }

    companyConsultorsModal() {

        if (this.state.companyConsultorsModal && this.state.fetchedCompanyConsultors) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: 50 }}>
                        <DefaultSelect
                            search={true}
                            searchField={'name'}
                            id={'consultant_attatch'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.consultant}
                            onChange={(v) => { this.setState({ consultant: v.target.value }) }}
                            docs={this.state.consultantDocs}
                            label={'Selecione um consultor para vincular'}
                        />
                        <div style={{ marginRight: 5, marginLeft: 5 }} />
                        <Button onClick={() => { this.addCompanyConsultant() }} variant={'contained'} style={{ fontWeight: 'bold', color: 'white', fontSize: 26, backgroundColor: Colors.primary }}>+</Button>
                    </div>
                    {

                        this.state.companyConsultors.length ? this.state.companyConsultors.map((consultor, key) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid lightgrey', borderTop: !this.state.companyConsultors[key + 1] ? '1px solid lightgrey' : '', padding: 15 }} key={key}>
                                    <img style={{ height: 65, borderRadius: 65 / 2, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', marginRight: 15 }} src={consultor.photo ? consultor.photo : `${process.env.PUBLIC_URL}/empty_avatar.png`} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontWeight: 'bold' }}>{consultor.name}</div>
                                        <div style={{ fontSize: 14, color: 'grey' }}>{consultor.email}</div>
                                        <div style={{ fontSize: 14, color: 'grey' }}>{consultor.sector}</div>
                                    </div>
                                    <Tooltip title={'Desvincular Consultor'}><ClearIcon onClick={() => { this.removeConsultor(consultor.companies, consultor.id) }} style={{ marginLeft: 'auto', cursor: 'pointer', color: 'red' }} /></Tooltip>
                                </div>
                            )
                        }) : null

                    }
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ companyConsultorsModal: false, fetchedCompanyConsultors: false, companyConsultors: [], }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    addModal() {
        if (this.state.addModal) {
            return (
                <div>
                    <DefaultInput label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }} />
                    <DefaultInput label={'E-mail'} onBlur={(v) => { this.setState({ email: v }) }} />
                    <DefaultInput label={'Setor'} onBlur={(v) => { this.setState({ sector: v }) }} />
                    <DefaultInput label={'Senha'} type={'password'} onBlur={(v) => { this.setState({ password: v }) }} />
                    <DefaultInput label={'Confirme a Senha'} type={'password'} onBlur={(v) => { this.setState({ passwordConfirmation: v }) }} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addConsultor() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    addCompanyModal() {
        if (this.state.addCompanyModal) {
            return (
                <div style={{ marginBottom: 50, width: '100%', height: 550, overflowY: 'scroll' }}>
                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Dados</FormLabel>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.companyData.name} onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, name: v } }) }} label={'Razão'} />
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} email={true} value={this.state.companyData.email} onChange={(text) => { this.setState({ companyData: { ...this.state.companyData, email: text.toLowerCase() } }) }} label={'E-mail'} />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <InputMask onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, ddd: v } }) }} mask="99" value={this.state.companyData.ddd}>
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={2} label={'DDD'} />
                        </InputMask>
                        <div style={{ marginLeft: 3, marginRight: 3 }} />
                        <InputMask onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, phone: v } }) }} mask="9 9999-99999999" maskPlaceholder={''} value={this.state.companyData.phone}>
                            <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={9} label={'Telefone'} />
                        </InputMask>
                    </div>
                    <InputMask onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, cnpj: v } }) }} mask="99.999.999/9999-99" value={this.state.companyData.cnpj}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={14} label={'Cnpj'} />
                    </InputMask>
                    <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Tipo de Empresa</FormLabel>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} value={this.state.companyData.type} onChange={(evt) => { this.setState({ companyData: { ...this.state.companyData, type: evt.target.value } }) }}>
                        <FormControlLabel value="industria" control={<Radio style={{ color: Colors.primary }} />} label="Indústria" />
                        <FormControlLabel value="comercio" control={<Radio style={{ color: Colors.primary }} />} label="Comércio" />
                        <FormControlLabel value="servico" control={<Radio style={{ color: Colors.primary }} />} label="Serviços" />
                    </RadioGroup>
                    <FormLabel style={{ marginTop: 18, fontWeight: '800' }} component="legend">Regime Tributário</FormLabel>
                    <RadioGroup style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} value={this.state.companyData.regime_tributario} onChange={(evt) => { this.setState({ companyData: { ...this.state.companyData, regime_tributario: evt.target.value } }) }}>
                        <FormControlLabel value="lucro_presumido" control={<Radio style={{ color: Colors.primary }} />} label="Lucro Presumido" />
                        <FormControlLabel value="lucro_real" control={<Radio style={{ color: Colors.primary }} />} label="Lucro Real" />
                        <FormControlLabel value="simples" control={<Radio style={{ color: Colors.primary }} />} label="Simples" />
                    </RadioGroup>
                    <FormLabel style={{ paddingBottom: 18, paddingTop: 18 }} component="legend">Endereço</FormLabel>
                    
                    <div style={{ paddingBottom: 50 }}>
                        <LocationPicker callback={this.handleMapChange} initialCenter={GeographicHelper.getLatLngFromGeopoint(this.state.companyData.coordinates)} />
                    </div>
                    
                    <InputMask disabled={true} mask="99999-999" value={this.state.companyData.zipcode}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} minLength={8} label={'Cep'} />
                    </InputMask>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultInput value={'Brasil'} disabled={true} label={'País'} />
                        <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                        <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.companyData.state} onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, state: v } }) }} label={'Estado'} />
                    </div>

                    <DefaultInput disabled onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.companyData.city} onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, city: v } }) }} label={'Cidade'} />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.companyData.street} onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, street: v } }) }} label={'Rua'} />
                        <div style={{ paddingRight: 5, paddingLeft: 5 }} />
                        <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                            <InputMask onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, number: v } }) }} mask="999999999999" maskPlaceholder={''} alwaysShowMask={false} value={this.state.companyData.number}>
                                <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} hasMask={true} number={true} required={true} label={'Número'} />
                            </InputMask>
                        </div>
                        <div style={{ paddingRight: 5, paddingLeft: 5, width: '50%' }}>
                            <DefaultInput value={this.state.companyData.complemento} onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, complemento: v } }) }} label={'Complemento'} />
                        </div>
                    </div>
                    <DefaultInput onError={(error, msg) => { this.setState({ inputError: error }) }} required={true} value={this.state.companyData.neighborhood} onChange={(v) => { this.setState({ companyData: { ...this.state.companyData, neighborhood: v } }) }} label={'Bairro'} />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                        <DefaultButton onClick={() => { this.addCompany() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                        <DefaultButton onClick={() => { this.setState({ addCompanyModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                    </div>
                </div>
            )
        }
    }

    renderConsultantCompanies(companies) {

        let text = '';

        if (companies && companies.length) {

            companies.map((company, key) => {

                let doc = this.state.docs.find(item => item.id === company);

                if (doc) { text += doc.name }
                if (companies[key + 1]) { text += ', ' }
            });
        }

        return text;
    }

    consultorsModal() {
        if (this.state.consultorsModal) {
            return (
                <DefaultTable
                    title={'Consultores'}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Nome', field: 'name' },
                        { title: 'E-mail', field: 'email', editable: false },
                        { title: 'Setor', field: 'sector' },
                        { title: 'Empresas', field: 'companies', editable: false, render: rowData => this.renderConsultantCompanies(rowData.companies) },
                    ]}
                    data={this.state.consultantDocs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;

                        try {
                            if (oldData.id) {

                                let data = {
                                    name: newData.name,
                                    email: newData.email,
                                    sector: newData.sector,
                                };

                                await Firestore.update(data, 'user', oldData.id);
                            }

                            toast.success("Editado com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            prev[prev.indexOf(oldData)] = newData;

                            this.setState({ docs: prev });
                        } catch (e) {
                            toast.error("Erro ao Editar", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {

                        let prev = this.state.docs;
                        prev.splice(prev.indexOf(oldData), 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {

                            let response = await Functions.request('POST', 'deleteUser', { uid: oldData.id });

                            if (response.status == 200) {
                                await Firestore.delete('user', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });

                                return prev;

                            } else {

                                toast.error("Erro ao remover", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }
                        }
                    }}
                />
            )
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ backgroundColor: Colors.primary, padding: 50, height: '100vh' }}>
                <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, marginBottom: 15 }}>
                    <ListItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                            <DefaultButton onClick={() => { this.setState({ addCompanyModal: true }) }} color={Colors.primary} loading={this.state.loadingModal} title={'ADICIONAR EMPRESA'} leftIcon={<AddCircleIcon />}/>
                            <div style={{ marginLeft: 8 }}/>
                            <DefaultButton onClick={() => { this.setState({ addModal: true }) }} color={Colors.primary} loading={this.state.loadingModal} title={'ADICIONAR CONSULTOR'} leftIcon={<AddCircleIcon />}/>
                            <div style={{ marginLeft: 8 }}/>
                            <DefaultButton onClick={() => { this.setState({ consultorsModal: true }) }} color={Colors.primary} loading={this.state.loadingModal} title={'CONSULTORES CADASTRADOS'} leftIcon={<PeopleIcon />}/>
                        </div>
                        <a style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { this.logout() }}>Sair</a>
                    </ListItem>
                </List>
                <List style={{ width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5 }}>
                    {this.renderSearch()}
                    {this.renderCompanies()}
                    {!this.state.docs.length && this.renderEmpty()}
                </List>
                <DefaultModal loading={this.state.loadingModal} content={this.addCompanyModal()} title={'Nova Empresa'} onClose={() => { this.setState({ addCompanyModal: false }) }} open={this.state.addCompanyModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Consultor'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.companyConsultorsModal()} title={'Vincular Consultores'} onClose={() => { this.setState({ companyConsultorsModal: false, fetchedCompanyConsultors: false, companyConsultors: [] }) }} open={this.state.companyConsultorsModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.consultorsModal()} title={''} onClose={() => { this.setState({ consultorsModal: false }) }} open={this.state.consultorsModal} width={window.screen.width - 200} />
                <ToastContainer />
            </div>
        );
    }
}
