import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, TextareaAutosize, TableRow, TableCell, TableContainer, TableBody, Table, TableHead, InputLabel, Tooltip, IconButton } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import ExcelModal from '../components/ExcelModal';
import ProfileCard from '../components/ProfileCard';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import DefaultSelect from '../components/DefaultSelect';
import IosSwitch from '../components/IosSwitch';
import AddIcon from '@material-ui/icons/Add';
import GroupIcon from '@material-ui/icons/Group';
import DescriptionIcon from '@material-ui/icons/Description';
import ExcelImportValidators from '../constants/ExcelImportValidators';
import DataHelper from '../helper/DataHelper';

export default class ResultCenterPage extends Component {

    state = {
        docs: [],
        users: [],
        readUsers: [
            SessionHelper.getFirebaseAuth().uid
        ],
        writeUsers: [
            SessionHelper.getFirebaseAuth().uid
        ],
        responsable: [SessionHelper.getFirebaseAuth().uid],
        allWrite: false,
        allRead: false,
        loading: true,
        loadingModal: false,
        addModal: false,
        permissionModal: false,
        multiplePermissonModal: false,
        multiplePermissionModalUser: null,
        multiplePermissionModalResultCenters: [],
        multiplePermissionModalResultRead: true,
        multiplePermissionModalResultWrite: false,
        currentPermissionDoc: {},
        allReadEdit: false,
        allWriteEdit: false,
        responsableEdit: null,
        editId: null,
        description: null,
        code: null,
        observation: '',
        excelModal: false,
        loadingText: '',
        duplicates: [],
        codesIndex: [],
        duplicateModal: false,
        insertedCounter: 0,
        colsub: [
            { name: 'description', alias: 'Descrição', required: true, example: 'Desc. Qualquer' },
            { name: 'code', alias: 'Código', required: true, example: '42', validators: [ExcelImportValidators.trim] },
            { name: 'observation', alias: 'Observação', example: 'Obs. Qualquer' }
        ],
    }

    async getDocs() {
        this.setState({ loading: true });

        let query = Firestore.customQuery('result_center').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    async getUsers() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ users: docs, loading: false });
    }

    async componentDidMount() {
        await this.getDocs();
        await this.getUsers();
    }

    async addResultCenter() {

        if (this.state.code && this.state.responsable.length > 0 && this.state.description && this.state.readUsers.length > 0 && this.state.writeUsers.length > 0 && SessionHelper.getData().id_company) {

            let data = {
                description: this.state.description,
                code: this.state.code,
                observation: this.state.observation,
                responsable: this.state.responsable,
                id_company: SessionHelper.getData().id_company,
                readUsers: this.state.readUsers,
                writeUsers: this.state.writeUsers
            };

            if (this.state.allRead == true) { data.readUsers = []; data.readUsers.push('allRead'); }
            if (this.state.allWrite == true) { data.writeUsers = []; data.writeUsers.push('allWrite'); }

            try {
                this.setState({ loadingModal: true });

                const codeExists = await this.codeExists(data);

                if (codeExists == false) {
                    await Firestore.insert(data, 'result_center');
                } else {
                    toast.warn("Este código já existe", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({ loadingModal: false });

                    return;
                }

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.getDocs();
            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false,
            addModal: false,
            readUsers: [
                SessionHelper.getFirebaseAuth().uid
            ],
            writeUsers: [
                SessionHelper.getFirebaseAuth().uid
            ],
            responsable: [SessionHelper.getFirebaseAuth().uid],
            allRead: false,
            allWrite: false,
            codesIndex: []
        });
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <DefaultInput required={true} label={'Código'} onBlur={(v) => { this.setState({ code: v }) }} />
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        disabled={false}
                        searchField={'name'}
                        id={'user_selection_responsable'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.responsable}
                        onChange={(v) => {
                            this.setState({ responsable: v.target.value })
                        }}
                        docs={this.state.users}
                        label={'Usuários Responsáveis*'}
                    />
                </div>

                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allRead}
                            searchField={'name'}
                            id={'user_selection_read'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.readUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ readUsers: v.target.value })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Visualizar'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Visualizar*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allRead: evt }); }} checked={this.state.allRead} />
                    </div>
                </div>

                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allWrite}
                            searchField={'name'}
                            id={'user_selection_write'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.writeUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ writeUsers: v.target.value })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Editar e Excluir'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Editar e Excluir*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch disabled={!this.state.allRead} label="Todos" onChange={(evt) => { this.setState({ allWrite: evt }); }} checked={this.state.allWrite} />
                    </div>
                </div>
                <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Observação</FormLabel>
                <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.observation} onChange={(v) => { this.setState({ observation: v.target.value }) }} placeholder="Escreva uma descrição..." />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addResultCenter() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    permissionModalEdit() {
        return (
            <div style={{ marginTop: 30 }}>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        searchField={'name'}
                        id={'user_selection_responsable_edit'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.responsableEdit}
                        onChange={(v) => {
                            this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, responsable: v.target.value }, responsableEdit: v.target.value })
                        }}
                        docs={this.state.users}
                        label={'Usuários Responsáveis*'}
                    />
                </div>
                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allReadEdit}
                            searchField={'name'}
                            id={'user_selection_read_edit'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.currentPermissionDoc.readUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, readUsers: v.target.value } })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Visualizar'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Visualizar*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allReadEdit: evt }); }} checked={this.state.allReadEdit} />
                    </div>
                </div>

                <div style={{ marginTop: 25, marginBottom: 20, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allWriteEdit}
                            searchField={'name'}
                            id={'user_selection_write_edit'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.currentPermissionDoc.writeUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, writeUsers: v.target.value } })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Editar e Excluir'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Editar e Excluir*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allWriteEdit: evt }); }} checked={this.state.allWriteEdit} />
                    </div>
                </div>
            </div>
        );
    }

    renderPeopleList(array, field) {
        if (!array) return;

        return (
            <div style={{ overflowY: 'auto', maxHeight: 210, width: '100%' }}>
                {array.map((item, key) => {
                    let user = this.state.users[this.state.users.findIndex(doc => doc.id === item)];

                    if (user && user.id) {
                        return (
                            <div style={{ marginBottom: 5, borderTop: '0.5px solid lightgrey' }}>
                                <Tooltip onClick={() => { this.removePerson(key, field) }} style={{ position: 'relative', float: 'right', color: 'red', marginTop: 5 }} title="Remover"><IconButton><DeleteIcon /></IconButton></Tooltip>
                                <div style={{ padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <ProfileCard userId={user.id} user={user} useMouseAsAnchor={true} iconColor={Colors.primary} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>{user.name}</div>
                                    </div>
                                </div>
                                {!this.state.docs[key + 1] && <div style={{ borderBottom: '0.5px solid lightgrey' }} />}
                            </div>
                        )
                    } else {
                        return null;
                    }
                })}
            </div>
        )
    }

    removePerson(key, field) {
        let currentPermissionDoc = this.state.currentPermissionDoc;

        if (field === 'responsable') {

            if (typeof currentPermissionDoc[field] === 'object') {

                currentPermissionDoc[field].splice(key, 1);

            } else {

                currentPermissionDoc[field] = null;
            }

            this.setState({ responsableEdit: currentPermissionDoc[field] });

        } else {

            currentPermissionDoc[field].splice(key, 1);
        }

        this.setState({ currentPermissionDoc });
    }

    saveEditPerms = async () => {
        let data = {};

        Object.assign(data, this.state.currentPermissionDoc);

        if (this.state.allReadEdit == true) { data.readUsers = []; data.readUsers.push('allRead'); }
        if (this.state.allWriteEdit == true) { data.writeUsers = []; data.writeUsers.push('allWrite'); }
        if (this.state.responsableEdit) { data.responsable = this.state.responsableEdit }

        try {
            this.setState({ loadingModal: true });
            const docId = data.id;

            delete data.tableData;
            delete data.id;

            await Firestore.update(data, 'result_center', docId);

            toast.success("Editado com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });

            await this.getDocs();
        } catch (error) {
            toast.error("Houve um problema ao editar", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({ loadingModal: false });
            return;
        }

        this.setState({ loadingModal: false, permissionModal: false });
    }

    permissionModal() {
        return (
            <div>
                {this.permissionModalEdit()}
                <InputLabel style={{ paddingTop: 10, paddingBottom: 10 }}>Usuários Responsáveis{!this.state.currentPermissionDoc.responsable ? ': Não atribuído' : ''}</InputLabel>
                {this.state.currentPermissionDoc.responsable ? this.renderPeopleList(typeof this.state.currentPermissionDoc.responsable === 'object' ? this.state.currentPermissionDoc.responsable : [this.state.currentPermissionDoc.responsable], 'responsable') : null}

                <InputLabel style={{ paddingTop: 10, paddingBottom: 10 }}>Usuários que podem Visualizar{this.state.currentPermissionDoc.readUsers && this.state.currentPermissionDoc.readUsers.includes('allRead') ? ': Todos na Empresa' : ''}</InputLabel>
                {this.state.currentPermissionDoc.readUsers && !this.state.currentPermissionDoc.readUsers.includes('allRead') || SessionHelper.getData().type != 'user' || SessionHelper.getData().permission.result_center === true ? this.renderPeopleList(this.state.currentPermissionDoc.readUsers, 'readUsers') : null}

                <InputLabel style={{ paddingTop: 10, paddingBottom: 30 }}>Usuários que podem Editar e Excluir{this.state.currentPermissionDoc.writeUsers && this.state.currentPermissionDoc.writeUsers.includes('allWrite') ? ': Todos na Empresa' : ''}</InputLabel>
                {this.state.currentPermissionDoc.writeUsers && !this.state.currentPermissionDoc.writeUsers.includes('allRead') || SessionHelper.getData().type != 'user' || SessionHelper.getData().permission.result_center === true ? this.renderPeopleList(this.state.currentPermissionDoc.writeUsers, 'writeUsers') : null}

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.saveEditPerms() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'SALVAR'}</Button>
                    <Button onClick={() => { this.setState({ permissionModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    multiplePermissonModal() {
        return (
            <div>
                <div style={{ marginTop: 30 }}>
                    <div style={{ marginTop: 20 }}>
                        <DefaultSelect
                            search={true}
                            searchField={'name'}
                            id={'multiple_user_selection_read'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.multiplePermissionModalUser}
                            onChange={(v) => {
                                this.setState({ multiplePermissionModalUser: v.target.value })
                            }}
                            docs={this.state.users}
                            label={'Usuário a conceder Permissão'}
                        />
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={!this.state.multiplePermissionModalUser || !this.state.multiplePermissionModalResultRead}
                            searchField={'description'}
                            id={'multiple_result_center_selection_read'}
                            valueField={'id'}
                            displayField={'description'}
                            secondaryDisplay={'code'}
                            value={this.state.multiplePermissionModalResultCenters}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ multiplePermissionModalResultCenters: v.target.value })
                                } else {
                                    toast.warn("Selecione pelo menos um Centro de Resultado", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.docs}
                            label={'Centros de Resultado'}
                        />
                    </div>

                    <InputLabel style={{ paddingTop: 30, paddingBottom: 5 }} id="hour-type">Permissões nos Centros de Resultado Selecionados</InputLabel>

                    <IosSwitch label="Visualizar" onChange={(evt) => { this.setState({ multiplePermissionModalResultRead: evt }); }} checked={this.state.multiplePermissionModalResultRead} />
                    <IosSwitch disabled={!this.state.multiplePermissionModalResultRead} label="Editar e Excluir" onChange={(evt) => { this.setState({ multiplePermissionModalResultWrite: evt }); }} checked={this.state.multiplePermissionModalResultWrite} />
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button disabled={!this.state.multiplePermissionModalUser || !this.state.multiplePermissionModalResultRead} onClick={() => { this.addMultiplePermission() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ multiplePermissonModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    addMultiplePermission = async () => {
        if (this.state.multiplePermissionModalResultCenters.length > 0 && this.state.multiplePermissionModalUser != null) {
            this.setState({ loadingModal: true });

            const userId = this.state.multiplePermissionModalUser;

            for (let i = 0; i < this.state.multiplePermissionModalResultCenters.length; i++) {
                const resultCenterId = this.state.multiplePermissionModalResultCenters[i];

                let resultCenter = {}

                this.state.docs.forEach(doc => {
                    if (doc.id == resultCenterId) Object.assign(resultCenter, doc);
                });

                if (resultCenter) {
                    delete resultCenter.tableData;
                    delete resultCenter.id;

                    try {
                        if (!resultCenter.readUsers) resultCenter.readUsers = [];
                        if (!resultCenter.writeUsers) resultCenter.writeUsers = [];

                        if (!resultCenter.readUsers.includes(userId) && !resultCenter.readUsers.includes('allRead')) {
                            if (this.state.multiplePermissionModalResultRead == true) resultCenter.readUsers.push(userId);
                        }

                        if (!resultCenter.writeUsers.includes(userId) && !resultCenter.writeUsers.includes('allWrite')) {
                            if (this.state.multiplePermissionModalResultWrite == true) resultCenter.writeUsers.push(userId);
                        }

                        await Firestore.update(resultCenter, 'result_center', resultCenterId);
                    } catch (e) {
                        toast.error("Ocorreu um erro ao conceder permissões", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.setState({ loadingModal: false });
                        return;
                    }
                }
            }

            toast.success("Permissões concedidas com sucesso!", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({
                loadingModal: false, multiplePermissonModal: false, multiplePermissionModalResultRead: true,
                multiplePermissionModalResultWrite: false, multiplePermissionModalUser: null, multiplePermissionModalResultCenters: []
            });

        } else {
            toast.warn("Selecione os valores necessários", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
    }

    duplicateModal() {
        const totalColSpan = 100;
        let unitColSpan;

        unitColSpan = parseInt(totalColSpan / this.state.colsub.length);

        return (
            <div>
                <div style={{ marginBottom: 20, marginTop: 30 }}>
                    <FormLabel>Total de inseridos {this.state.insertedCounter}</FormLabel>
                    <br />
                    <FormLabel error={true}>Total de não inseridos: {this.state.duplicates.length}</FormLabel>
                    <br />
                    <br />
                    <FormLabel>Lista de dados não inseridos por estarem duplicados ou fora do padrão:</FormLabel>
                </div>
                <div style={{ maxHeight: 300, minHeight: 200, overflowY: 'auto' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow colspan={unitColSpan}>
                                    {this.state.colsub.map((col, key) => <TableCell align="center">{col.alias}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.duplicates.map(row => <TableRow colspan={unitColSpan}>{this.state.colsub.map((col) => row[col.name] ? <TableCell align="center">{row[col.name]}</TableCell> : <TableCell align="center"></TableCell>)}</TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.setState({ duplicates: [], duplicateModal: false, insertedCounter: 0 }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '50%' }} variant={'contained'}>{'Ok'}</Button>
                </div>
            </div>
        )
    }

    codeExists = async (doc) => {
        let exists = false;
        let duplicates = this.state.duplicates;
        let codesIndex = this.state.codesIndex;

        if (doc.code) {
            if (codesIndex.length < 1) {
                const query = await Firestore.customQuery('result_center').where('id_company', '==', doc.id_company).get();

                if (query.docs && query.docs.length > 0) {
                    for (let document of query.docs) {
                        if (document.data().code) {
                            if (!codesIndex.includes(document.data().code)) codesIndex.push(document.data().code);
                        }
                    }
                }
            }

            if (codesIndex.includes(`${doc.code}`) || codesIndex.includes(doc.code)) {
                exists = true;
                duplicates.push(doc);
            } else {
                exists = false;
                codesIndex.push(doc.code);
            }

        } else {
            exists = true;
            duplicates.push(doc);
        }

        await this.setState({ duplicates, codesIndex });

        return exists;
    }

    handleExcelImport = async (data) => {
        if (data && data.length > 0) {
            this.setState({ loading: true });

            try {

                let chunks = DataHelper.createSubArrays(data, 500);
                let count = 0;

                await Promise.all(chunks.map( async (chunk) => {
                
                    for (let i = 0; i < chunk.length; i++) {
    
                        chunk[i].writeUsers = [SessionHelper.getFirebaseAuth().uid];
                        chunk[i].readUsers = [SessionHelper.getFirebaseAuth().uid];
                        chunk[i].responsable = SessionHelper.getFirebaseAuth().uid;
    
                        if ((await this.codeExists(chunk[i])) == false) {
                            chunk[i].code = chunk[i].code.toString();
                            await Firestore.insert(chunk[i], 'result_center');
                        }

                        this.setState({ loadingText: `Inserindo ${count} de ${data.length}` });
                    }
                }));

                if (this.state.duplicates.length > 0) {
                    this.setState({ duplicateModal: true });
                }

                count++;
                this.setState({ insertedCounter: data.length - this.state.duplicates.length });

                toast.success(`${data.length - this.state.duplicates.length} registros importados com sucesso!`, {
                    position: toast.POSITION.TOP_RIGHT
                });


            } catch (error) {

                console.log(error);

                toast.error("Erro ao importar .xlsx", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            await this.getDocs();

            this.setState({ loading: false, loadingText: `` });
        }

        this.setState({ codesIndex: [] });
    }

    async canDelete(id) {

        let canDelete = true;

        let employee = await Firestore.customQuery('employee').where('result_center', '==', id).limit(1).get();
        let revenuePlanningSettings = await Firestore.customQuery('revenue_planning_settings').where('result_centers', 'array-contains', id).limit(1).get();
        let planningRow = await Firestore.customQuery('planning_row').where('relationship.id_result_center', '==', id).limit(1).get();

        if (employee.size > 0) {

            canDelete = false;
        }

        if (revenuePlanningSettings.size > 0) {

            canDelete = false;
        }

        if (planningRow.size > 0) {

            canDelete = false;
        }

        return canDelete;
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <div style={{ marginRight: 10 }}>
                            <DefaultButton leftIcon={<AddIcon />} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                        </div>
                        <div style={{ marginRight: 10 }}>
                            <DefaultButton leftIcon={<GroupIcon />} onClick={() => { this.setState({ multiplePermissonModal: true }) }} title={'Conceder permissões'} width={250} />
                        </div>
                        <div style={{}}>
                            <DefaultButton leftIcon={<DescriptionIcon />} onClick={() => { this.setState({ excelModal: true }) }} title={'Importar do Excel (.xlsx)'} width={290} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>

                    </div>
                </div>

                <DefaultTable
                    title={'Centros de Resultado'}
                    marginTop={10}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        { title: 'Código', field: 'code' },
                        { title: 'Observação', field: 'observation' },
                    ]}
                    actions={[
                        {
                            icon: PeopleIcon,
                            tooltip: 'Permissões',
                            onClick: (event, rowData) => {
                                let data = {};

                                this.setState({ currentPermissionDoc: rowData, allReadEdit: rowData.readUsers.includes('allRead'), allWriteEdit: rowData.writeUsers.includes('allWrite'), responsableEdit: typeof rowData.responsable === 'object' ? rowData.responsable : [rowData.responsable], permissionModal: true })
                            }
                        },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {

                        if (oldData.code != newData.code && await this.codeExists(newData)) {
                            toast.warn("O código informado já existe", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return;
                        }

                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev[index] = newData;

                        this.setState({ docs: prev })
                        if (oldData.id) {

                            let data = {
                                description: newData.description,
                                code: newData.code,
                                observation: newData.observation
                            };

                            await Firestore.update(data, 'result_center', oldData.id);

                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        this.forceUpdate();

                        return prev;

                    }}
                    onRowDelete={async (oldData) => {

                        if ( await (this.canDelete(oldData.id))) {

                            let prev = this.state.docs;
                            const index = prev.indexOf(oldData);
    
                            prev.splice(index, 1);
    
                            this.setState({ docs: prev });

                            if (oldData.id) {
                                await Firestore.delete('result_center', oldData.id);

                                toast.success("Removido com sucesso", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            } else {
                                toast.error("Não foi possível remover", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }

                        } else {

                            toast.warn('Não é possível remover um centro de resultado com vínculos no planejamento', { autoClose: false });
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Centro de Resultado'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.permissionModal()} title={'Permissões de Centro de Resultado'} onClose={() => { this.setState({ permissionModal: false }) }} open={this.state.permissionModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.multiplePermissonModal()} title={'Conceder permissões de Centro de Resultado a Usuário'} onClose={() => { this.setState({ multiplePermissonModal: false }) }} open={this.state.multiplePermissonModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.duplicateModal()} title={'Não inseridos'} onClose={() => { this.setState({ duplicates: [], duplicateModal: false }) }} open={this.state.duplicateModal} />
                <ExcelModal
                    saveCallback={this.handleExcelImport}
                    cols={this.state.colsub}
                    onClose={() => { this.setState({ excelModal: false }) }}
                    visible={this.state.excelModal}
                    onColsChange={(cols) => { this.setState({ colsub: cols }) }}
                />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
