import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CalculusHelper from '../helper/CalculusHelper';
import TableHelper from '../helper/TableHelper';

export default class RevenueTable extends Component {
    render() {
        let table;

        let rows = this.props.rows;

        let plannedCounter = 0;
        let historyCounter = 0;

        table = (
            <div style={styles.tableContainer}>
                <TableContainer>
                    <Table id={'revenue-planning'}>
                        {TableHelper.getTableHeader(rows, true, true)}

                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Orçado (R$)</TableCell>
                                {rows.map((period, key) => {
                                    plannedCounter += period.value;
                                    return <TableCell align="right">{CalculusHelper.formatFloat(period.value)}</TableCell>
                                })}


                                {TableHelper.getMeanCell('money', plannedCounter, rows, 'value')}
                                {TableHelper.getTotalCell('money', plannedCounter)}
                            </TableRow>


                            <TableRow>
                                <TableCell align="left" style={{ fontWeight: 'bold' }}>Histórico (R$)</TableCell>
                                {rows.map((period, key) => {
                                    historyCounter += period.value_history;
                                    return <TableCell align="right">{CalculusHelper.formatFloat(period.value_history)}</TableCell>
                                })}

                                {TableHelper.getMeanCell('money', historyCounter, rows, 'value_history')}
                                {TableHelper.getTotalCell('money', historyCounter)}
                            </TableRow>

                            {TableHelper.getVariationRow(
                                rows,
                                false,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.value,
                                        period.value_history,
                                        false
                                    );
                                },
                                true,
                                true
                            )}
                            {TableHelper.getVariationRow(
                                rows,
                                true,
                                plannedCounter,
                                historyCounter,
                                this.props.moreIsBetter || false,
                                (period, key) => {
                                    return CalculusHelper.variation(
                                        period.value,
                                        period.value_history,
                                        true
                                    );
                                },
                                true,
                                true
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );

        return table;
    }

}

const styles = {
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        borderRadius: 5
    }
}