import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultModal from '../components/DefaultModal';
import Colors from '../constants/Colors';
import { Button, FormLabel, InputLabel, Tooltip, IconButton, TextareaAutosize, Table } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import PeopleIcon from '@material-ui/icons/People';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SessionHelper from '../helper/SessionHelper';
import ProfileCard from '../components/ProfileCard';
import { toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EditIcon from '@material-ui/icons/Edit';
import DefaultSelect from '../components/DefaultSelect';
import IosSwitch from '../components/IosSwitch';
import DefaultInput from '../components/DefaultInput';
import DefaultButton from '../components/DefaultButton';

export default class ResponsabilityMatrixPage extends Component {

    state = {
        resultCenterDocs: [],
        accountPackageDocs: [],
        accountPlansDocs: [],
        users: [],
        accountPlanPackages: [],
        actualAccountPlanPackages: [],
        loading: true,
        loadingModal: false,
        loadingText: 'Carregando Matriz de Responsabilidades...',
        permissionModal: false,
        readUsers: [
            SessionHelper.getFirebaseAuth().uid
        ],
        writeUsers: [
            SessionHelper.getFirebaseAuth().uid
        ],
        responsable: SessionHelper.getFirebaseAuth().uid,
        allWrite: false,
        allRead: false,
        addModal: false,
        editModal: false,
        accountPlanPackagesModal: false,
        accountPlan: null,
        accountPlanDescription: '',
        accountPlanCode: '',
        description: '',
        code: '',
        observation: '',
        responsableEdit: [],
    }

    async componentDidMount() {

        await this.getResultCenter();
        await this.getAccountPackages();
        await this.getUsers();

        this.setState({ loading: false });
    }
 
    async getUsers() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('user').where('id_company', '==', SessionHelper.getData().id_company).orderBy('name', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ users: docs, loading: false });
    }

    async getResultCenter() {

        let query = Firestore.customQuery('result_center').orderBy('code', 'asc').where('id_company', '==', SessionHelper.getData().id_company);
        let docs = [];

        query = await query.get();

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ resultCenterDocs: docs });
    }

    async getAccountPackages() {

        let query = Firestore.customQuery('account_package').where('id_company', '==', SessionHelper.getData().id_company);
        let docs = [];

        query = await query.get();

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        await this.setState({ accountPackageDocs: docs });

        await this.getAccountPlans();
    }

    async getAccountPlans() {

        let query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {
            let data = doc.data();
            data.id = doc.id;

            if (data.children && data.children.length) {

                let childrens = data.children;
                data.children = [];

                childrens.forEach((children, key) => {

                    let childrenAccount = null;
                    let childrenAccountIndex = query.docs.findIndex(item => item.id === children);

                    if (childrenAccountIndex > -1) {
                        
                        childrenAccount = query.docs[childrenAccountIndex].data();
                        childrenAccount.id = query.docs[childrenAccountIndex].id;

                        data.children.push(childrenAccount);
                    }
                });
            }

            docs.push(data);
        });

        this.setState({ accountPlansDocs: [...docs, ...docs, ...docs, ...docs, ...docs, ...docs] });
    }

    permissionModalEdit() {
        return (
            <div style={{ marginTop: 30 }}>
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        // disabled={SessionHelper.getFirebaseAuth().uid != this.state.currentPermissionDoc.responsable && SessionHelper.getData().type == 'user'}
                        searchField={'name'}
                        id={'user_selection_responsable_edit'}
                        valueField={'id'}
                        displayField={'name'}
                        value={this.state.responsableEdit}
                        onChange={(v) => {
                            this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, responsable: v.target.value }, responsableEdit: v.target.value });
                        }}
                        docs={this.state.users}
                        label={'Usuários Responsáveis*'}
                    />
                </div>
                <div style={{ marginTop: 25, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allReadEdit}
                            searchField={'name'}
                            id={'user_selection_read_edit'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.currentPermissionDoc.readUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, readUsers: v.target.value } })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Visualizar'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Visualizar*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allReadEdit: evt }); }} checked={this.state.allReadEdit} />
                    </div>
                </div>

                <div style={{ marginTop: 25, marginBottom: 20, flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginRight: 15, minWidth: '80%', maxWidth: '80%' }}>
                        <DefaultSelect
                            search={true}
                            multiple={true}
                            disabled={this.state.allWriteEdit}
                            searchField={'name'}
                            id={'user_selection_write_edit'}
                            valueField={'id'}
                            displayField={'name'}
                            value={this.state.currentPermissionDoc.writeUsers}
                            onChange={(v) => {
                                if (v.target.value.length > 0) {
                                    this.setState({ currentPermissionDoc: { ...this.state.currentPermissionDoc, writeUsers: v.target.value } })
                                } else {
                                    toast.warn("Você precisa selecionar pelo menos um usuário para 'Editar e Excluir'", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                            docs={this.state.users}
                            label={'Permissão: Usuários que podem Editar e Excluir*'}
                        />
                    </div>
                    <div style={{ minWidth: '20%', maxWidth: '20%' }}>
                        <IosSwitch label="Todos" onChange={(evt) => { this.setState({ allWriteEdit: evt }); }} checked={this.state.allWriteEdit} />
                    </div>
                </div>
            </div>
        );
    }

    permissionModal() {

        if (this.state.permissionModal) {

            return (
                <div>
                    {this.permissionModalEdit()}
    
                    <InputLabel style={{ paddingTop: 10, paddingBottom: 10 }}>Usuários Responsáveis{!this.state.currentPermissionDoc.responsable ? ': Não atribuído' : ''}</InputLabel>
                    {this.state.currentPermissionDoc.responsable ? this.renderPeopleList(typeof this.state.currentPermissionDoc.responsable === 'object' ? this.state.currentPermissionDoc.responsable : [this.state.currentPermissionDoc.responsable], 'responsable') : null}
    
                    <InputLabel style={{ paddingTop: 10, paddingBottom: 10 }}>Usuários que podem Visualizar{this.state.currentPermissionDoc.readUsers && this.state.currentPermissionDoc.readUsers.includes('allRead') ? ': Todos na Empresa' : ''}</InputLabel>
                    {this.state.currentPermissionDoc.readUsers && !this.state.currentPermissionDoc.readUsers.includes('allRead') || SessionHelper.getData().type != 'user' ? this.renderPeopleList(this.state.currentPermissionDoc.readUsers, 'readUsers') : null}
    
                    <InputLabel style={{ paddingTop: 10, paddingBottom: 30 }}>Usuários que podem Editar e Excluir{this.state.currentPermissionDoc.writeUsers && this.state.currentPermissionDoc.writeUsers.includes('allWrite') ? ': Todos na Empresa' : ''}</InputLabel>
                    {this.state.currentPermissionDoc.writeUsers && !this.state.currentPermissionDoc.writeUsers.includes('allRead') || SessionHelper.getData().type != 'user' ? this.renderPeopleList(this.state.currentPermissionDoc.writeUsers, 'writeUsers') : null}
    
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.saveEditPerms() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'SALVAR'}</Button>
                        <Button onClick={() => { this.setState({ permissionModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
    
                </div>
            )
        }
    }

    renderPeopleList(array, field) {
        return (
            <div style={{ overflowY: 'auto', maxHeight: 210, width: '100%' }}>
                {array.map((item, key) => {
                    let user = this.state.users[this.state.users.findIndex(doc => doc.id === item)];

                    if (user && user.id) {
                        return (
                            <div style={{ marginBottom: 5, borderTop: '0.5px solid lightgrey' }}>
                                <Tooltip onClick={() => { this.removePerson(key, field) }} style={{ position: 'relative', float: 'right', color: 'red', marginTop: 5 }} title="Remover"><IconButton><DeleteIcon /></IconButton></Tooltip>
                                <div style={{ padding: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <ProfileCard userId={user.id} user={user} useMouseAsAnchor={true} iconColor={Colors.primary} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>{user.name}</div>
                                    </div>
                                </div>
                                {!this.state.resultCenterDocs[key + 1] && <div style={{ borderBottom: '0.5px solid lightgrey' }} />}
                            </div>
                        )
                    } else {
                        return null;
                    }
                })}
            </div>
        )
    }

    removePerson(key, field) {
        let currentPermissionDoc = this.state.currentPermissionDoc;

        if (field === 'responsable') {

            if (typeof currentPermissionDoc[field] === 'object') {

                currentPermissionDoc[field].splice(key, 1);

            } else {

                currentPermissionDoc[field] = null;
            }

            this.setState({ responsableEdit: currentPermissionDoc[field] });

        } else {

            currentPermissionDoc[field].splice(key, 1);
        }

        this.setState({ currentPermissionDoc });
    }

    saveEditPerms = async () => {
        let data = {};

        Object.assign(data, this.state.currentPermissionDoc);

        if (this.state.allReadEdit == true) { data.readUsers = []; data.readUsers.push('allRead'); }
        if (this.state.allWriteEdit == true) { data.writeUsers = []; data.writeUsers.push('allWrite'); }
        if (this.state.responsableEdit) { data.responsable = this.state.responsableEdit }

        try {
            this.setState({ loadingModal: true });
            const docId = data.id;

            delete data.tableData;
            delete data.id;

            await Firestore.update(data, 'result_center', docId);

            toast.success("Editado com sucesso", {
                position: toast.POSITION.TOP_RIGHT
            });

            await this.getResultCenter();
            await this.getAccountPackages();

        } catch (error) {
            toast.error("Houve um problema ao editar", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({ loadingModal: false });
            return;
        }

        this.setState({ loadingModal: false, permissionModal: false });
    }

    toggleAccountPackage(key) {

        let accountPlans = this.state.accountPlansDocs;
        accountPlans[key].toggle = !accountPlans[key].toggle;

        this.setState({ accountPlansDocs: accountPlans });
    }

    toggleChildrenAccountPackage(parent, key) {

        let accountPlans = this.state.accountPlansDocs;
        accountPlans[parent].children[key].toggle = !accountPlans[parent].children[key].toggle;

        this.setState({ accountPlansDocs: accountPlans });
    }

    addModal() {
        return (
            <div>
                {this.state.parentId && this.state.parentName ? <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 18 }} component="legend">Conta Filha de {this.state.parentName}</FormLabel> : null}
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} />
                <DefaultInput required={true} label={'Código'} onBlur={(v) => { this.setState({ code: v }) }} />
                <FormLabel style={{ paddingBottom: 18, paddingTop: 18, fontSize: 13 }} component="legend">Observação</FormLabel>
                <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem' }} rowsMax={8} rowsMin={8} value={this.state.observation} onChange={(v) => { this.setState({ observation: v.target.value }) }} placeholder="Escreva uma descrição..." />
                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addAccount(this.state.parentId) }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    editModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} value={this.state.accountPlanDescription} onChange={(v) => { this.setState({ accountPlanDescription: v }) }} />
                <DefaultInput required={true} label={'Código'} value={this.state.accountPlanCode} onChange={(v) => { this.setState({ accountPlanCode: v }) }}/>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                    <Button onClick={() => { this.editAccountPlan() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'SALVAR'}</Button>
                    <Button onClick={() => { this.setState({ editModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    async saveAccountPlanPackages() {

        try {

            this.setState({ loadingModal: true });

            if (this.state.actualAccountPlanPackages.length) {

                this.state.actualAccountPlanPackages.forEach( async (accountPackage, key) => {

                    if (!this.state.accountPlanPackages.includes(accountPackage)) {

                        let accountPackageDoc = this.state.accountPackageDocs.find(item => item.id === accountPackage);

                        if (accountPackageDoc) {

                            if (accountPackageDoc.account_plans && accountPackageDoc.account_plans.length) {

                                if (accountPackageDoc.account_plans.includes(this.state.accountPlan)) {
        
                                    let index = accountPackageDoc.account_plans.findIndex(item => item === this.state.accountPlan);
        
                                    if (index > -1) {
        
                                        accountPackageDoc.account_plans.splice(index, 1);
        
                                        await Firestore.update(accountPackageDoc, 'account_package', accountPackageDoc.id);
                                    }
                                }
                            }
                        }
                    }
                });
            }

            if (this.state.accountPlanPackages.length) {

                this.state.accountPlanPackages.forEach( async (accountPackage, key) => {

                    let accountPackageDoc = this.state.accountPackageDocs.find(item => item.id === accountPackage);

                    if (accountPackageDoc) {

                        if (!accountPackageDoc.account_plans) {

                            accountPackageDoc.account_plans = [];
                        }

                        accountPackageDoc.account_plans.push(this.state.accountPlan);

                        await Firestore.update(accountPackageDoc, 'account_package', accountPackageDoc.id);
                    }
                });
            }

            await this.getAccountPackages();

            toast.success('Pacotes salvos com sucesso');
            this.setState({ accountPlanPackagesModal: false, accountPlanPackages: [], actualAccountPlanPackages: [], loadingModal: false, accountPlan: null });

        } catch (error) {

            this.setState({ loadingModal: false });
            toast.error('Houve um problema ao salvar os pacotes dessa conta');
        }
    }

    accountPlanPackagesModal() {

        if (this.state.accountPlanPackagesModal) {

            return (
                <div>
                    <DefaultSelect
                        search={true}
                        multiple={true}
                        searchField={'description'}
                        id={'account_plan_packages'}
                        valueField={'id'}
                        displayField={'description'}
                        value={this.state.accountPlanPackages}
                        onChange={(v) => { this.setState({ accountPlanPackages: v.target.value }) }}
                        docs={this.state.accountPackageDocs}
                        label={'Pacotes'}
                    />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.saveAccountPlanPackages() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%' }} variant={'contained'}>{'SALVAR'}</Button>
                        <Button onClick={() => { this.setState({ editMaccountPlanPackagesModal: false }) }} style={{ width: '48%', fontWeight: 'bold', marginLeft: '2%' }} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    renderAccountPackageChilds(accountPlan, parentKey) {

        let childrenAccountPlans = [];

        if (accountPlan.children && accountPlan.children.length) {

            accountPlan.children.forEach((children, key) => {

                let doc = this.state.accountPlansDocs.find(item => item.id === children.id);
                childrenAccountPlans.push(doc);
            });
        }

        let render = [];

        childrenAccountPlans.map((childrenAccountPlan, key) => {
            
            let toggle = accountPlan.children[key].toggle;

            render.push(
                <tr>
                    <td style={{textAlign: 'center', position: 'sticky', zIndex: 1, backgroundColor: 'white', left: 0, minWidth: 200, maxWidth: 200 }}>
                        
                        { childrenAccountPlan.children && childrenAccountPlan.children.length ?
                        
                            accountPlan.children[key].toggle ? <ExpandMoreIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.toggleChildrenAccountPackage(parentKey, key) }}/> : <NavigateNextIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.toggleChildrenAccountPackage(parentKey, key) }}/>
                        
                        : <ExpandMoreIcon style={{ color: '#f5f5f5' }}/> }

                        <ExpandMoreIcon style={{ color: 'white' }}/>

                        <Tooltip title={'Adicionar Conta Filha'}>
                            <AddBoxIcon style={{ color: 'grey', cursor: 'pointer', marginLeft: 16 }} onClick={() => { this.insertChild(childrenAccountPlan.id, childrenAccountPlan.description) }}/>
                        </Tooltip>
                        <Tooltip title={'Editar'}>
                            <EditIcon onClick={() => { this.setState({ accountPlanDescription: childrenAccountPlan.description, accountPlanCode: childrenAccountPlan.code, editId: childrenAccountPlan.id, editModal: true }) }} style={{ color: Colors.success, cursor: 'pointer', marginLeft: 8 }}/>
                        </Tooltip>

                        <Tooltip title={'Remover'}>
                            <DeleteIcon onClick={() => { this.removeAccountPlan(childrenAccountPlan) }} style={{ color: Colors.error, cursor: 'pointer', marginLeft: 8 }}/>
                        </Tooltip>
                    </td>

                    <td style={{ backgroundColor: '#f5f5f5', position: 'sticky', zIndex: 1, left: 200 }}><Tooltip title={childrenAccountPlan.description}><div>{childrenAccountPlan.description.length < 19 ? childrenAccountPlan.description : childrenAccountPlan.description.slice(0, 19) + '...'}</div></Tooltip></td>

                    <td style={{ backgroundColor: '#f5f5f5', position: 'sticky', zIndex: 1, left: 400, }}>{childrenAccountPlan.code}</td>

                    { 
                        this.state.resultCenterDocs.map((resultCenter, key) => {
                            return this.renderPermission(resultCenter, childrenAccountPlan, true);
                        }) 
                    }
                </tr>
            );

            if (toggle) { render.push(this.renderAccountPackageChilds(childrenAccountPlan, key)) }
        });

        return render;
    }

    insertChild(parentId, parentName) {
        this.setState({ addModal: true, parentId, parentName });
    }

    codeExists = async (code) => {
        let exists = false;

        if (code) {
            const query = await Firestore.customQuery('account_plan').where('id_company', '==', SessionHelper.getData().id_company).where('code', '==', code).limit(1).get();

            if (query.docs.length > 0) {
                exists = true;
            }
        } else {
            exists = true;
        }


        return exists;
    }

    async addChildToParent(parentId, childId) {
        let parent = (await Firestore.getDoc('account_plan', parentId)).data();

        if (!parent.children) parent.children = [];

        parent.children.push(childId);

        await Firestore.update(parent, 'account_plan', parentId);
    }

    async addAccount(parentId) {
        
        if (this.state.code && this.state.description && SessionHelper.getData().id_company) {

            let data = {
                description: this.state.description,
                code: this.state.code,
                observation: this.state.observation,
                id_company: SessionHelper.getData().id_company,
            };

            if (parentId) {
                data.parent_id = parentId;
            }

            try {

                this.setState({ loadingModal: true });

                if ((await this.codeExists(data.code)) == false) {

                    let childId;

                    if (parentId) {
                        childId = await Firestore.getId('account_plan');

                        await Firestore.insert(data, 'account_plan', childId);
                    } else {
                        await Firestore.insert(data, 'account_plan');
                    }

                    toast.success("Cadastrado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    if (parentId && childId) {
                        await this.addChildToParent(parentId, childId);
                    }

                    await this.getAccountPackages();
                } else {
                    toast.error("Este código já existe", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return;
                }
            } catch (error) {
                console.log(error)
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {
            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });

            return;
        }

        this.setState({ loadingModal: false, addModal: false, description: null, code: null, observation: '' });
    }

    async recursiveDeleteChildren(childrenNode) {
        if (childrenNode == null) {
            return;
        }

        let nextChildrenNode = null;

        this.setState({ loading: true });

        for (let i = 0; i < childrenNode.length; i++) {
            let children = (await Firestore.getDoc('account_plan', childrenNode[i])).data();

            await Firestore.delete('account_plan', childrenNode[i]);

            if (children && children.children) {
                if (children.children.length > 0) {
                    nextChildrenNode = children.children;
                }
            }
        }

        await this.recursiveDeleteChildren(nextChildrenNode);
    }

    async removeAccountPlan(accountPlan) {

        let confirm = window.confirm('Tem certeza que deseja remover essa conta?');

        if (confirm) {  

            if (accountPlan.children && accountPlan.children.length > 0) {
                //No nodes under it
                await Firestore.delete('account_plan', accountPlan.id);
            } else if (!accountPlan.children && accountPlan.parent_id) {
                //Has nodes under it
                await this.recursiveDeleteChildren(accountPlan.children);
                if (accountPlan.parent_id) {
                    //Has a parent
                    await this.removeChildFromParent(accountPlan.parent_id, accountPlan.id);
                }
                await Firestore.delete('account_plan', accountPlan.id);
            }
        }

        toast.success("Removido com sucesso", {
            position: toast.POSITION.TOP_RIGHT
        });

        this.getAccountPackages();
    }

    async removeChildFromParent(parentId, childId) {

        let parent = (await Firestore.getDoc('account_plan', parentId)).data();

        if (parent.children) {
            if (parent.children.includes(childId)) {
                const index = parent.children.indexOf(childId);

                parent.children.splice(index, 1);

                await Firestore.update(parent, 'account_plan', parentId);
            }
        }
    }

    async editAccountPlan() {

        if (this.state.accountPlanDescription) {

            try {

                await this.setState({ loadingModal: true });

                await Firestore.update({ description: this.state.accountPlanDescription, code: this.state.accountPlanCode }, 'account_plan', this.state.editId);
                await this.getAccountPackages();

                this.setState({ editId: null, accountPlanDescription: '', editModal: false, loadingModal: false });

                toast.success('Conta editada com sucesso');

            } catch (error) {

                toast.error('Houve um problema ao editar essa conta');
            }

        } else {

            toast.warn('Preencha a descrição');
        }
    }

    renderPermission(resultCenter, accountPlan, isChildren = false) {

        const style = { fontWeight: 'bold', minWidth: 110 };

        let accountPackages = this.state.accountPackageDocs;
        let accountPackageUsers = [];

        let resultCenterResponsable = resultCenter ? resultCenter.responsable : null;
        
        accountPackages.forEach((accountPackage, key) => {

            if (accountPackage.account_plans && accountPackage.account_plans.includes(accountPlan.id)) {

                if (accountPackage.responsable) {

                    let name = [];
                    let fullNames = [];

                    if (typeof accountPackage.responsable === 'string') {

                        accountPackage.responsable = [accountPackage.responsable];
                    }

                    for (let index = 0; index < accountPackage.responsable.length; index++) {
                        
                        const id = accountPackage.responsable[index];
                        let accountPlanUser = this.state.users.find(item => item.id === id);

                        if (accountPlanUser) {
    
                            let firstName = accountPlanUser.name.split(' ')[0];
                            name.push(firstName);
                            fullNames.push(accountPlanUser.name);
                        }
                    }

                    name = name.join(', ');
                    name = name.length < 11 ? name : name.slice(0, 11) + '...';

                    fullNames = fullNames.join(', ');

                    accountPackageUsers.push(
                        name.length > 0 ? <Tooltip title={`${fullNames} (${accountPackage.description})`}><div style={style}>{name}</div></Tooltip> : null
                    )
                }
            }
        });

        let name = [];
        let fullNames = [];

        if (resultCenterResponsable) {

            if (typeof resultCenter.responsable === 'string') {

                resultCenter.responsable = [resultCenter.responsable];
            }

            for (let index = 0; index < resultCenter.responsable.length; index++) {
                
                const id = resultCenter.responsable[index];
                let resultCenterUser = this.state.users.find(item => item.id === id);

                if (resultCenterUser) {

                    let firstName = resultCenterUser.name.split(' ')[0];
                    name.push(firstName);
                    fullNames.push(resultCenterUser.name);
                }
            }

            name = name.join(', ');
            name = name.length < 11 ? name : name.slice(0, 11) + '...';

            fullNames = fullNames.join(', ');
        }

        return (
            <td style={{ backgroundColor: !isChildren ? 'white' : '#f5f5f5', minWidth: 250 }}>
                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                    {accountPackageUsers.length ? accountPackageUsers : <div style={{ fontWeight: 'bold', minWidth: 110 }}>{'--'}</div>}
                    <div style={{ marginLeft: 5, marginRight: 5, color: 'grey' }}>X</div>
                    {name.length > 0 ? <Tooltip title={`${fullNames} (${resultCenter.description})`}><div style={style}>{name}</div></Tooltip> : null}
                </div>
            </td>
        )
    }

    handleAccountPlanPackages(accountPlan) {
        
        let accountPackages = this.state.accountPackageDocs;
        let result = [];

        accountPackages.forEach((accountPackage, key) => {

            if (accountPackage.account_plans && accountPackage.account_plans.includes(accountPlan.id)) {

                result.push(accountPackage.id);
            }
        });

        this.setState({ accountPlanPackagesModal: true, accountPlanPackages: result, actualAccountPlanPackages: result, accountPlan: accountPlan.id });
    }

    renderTable() {

        return (

            <div style={{ maxHeight: window.screen.height - 200, overflow: 'scroll', boxShadow: Colors.boxShadow }}>
                <Table id={'responsability-matrix'}>
                    <tr style={{ }}>
                        <th style={{ position: 'sticky', top: 0, zIndex: 3, left: 0, backgroundColor: '#e8e8e8' }} colSpan={2} rowSpan={4}>Contas X Centros de Resultado</th>
                    </tr>

                    <tr>
                        <th style={{ backgroundColor: '#e8e8e8', position: 'sticky', top: 0, zIndex: 3, left: 400 }}>Ações</th>
                        { 
                            this.state.resultCenterDocs.map((resultCenter, key) => {
                                return (

                                    <td style={{textAlign: 'center', backgroundColor: 'white', height: 42, minHeight: 42, maxHeight: 42, position: 'sticky', top: 0, zIndex: 2}}>
                                        <Tooltip onClick={() => { this.setState({ currentPermissionDoc: resultCenter, allReadEdit: resultCenter.readUsers.includes('allRead'), allWriteEdit: resultCenter.writeUsers.includes('allWrite'), responsableEdit: typeof resultCenter.responsable === 'object' ? resultCenter.responsable : [resultCenter.responsable], permissionModal: true }) }} title={'Permissões'}>
                                            <PeopleIcon style={{ color: 'grey', cursor: 'pointer' }}/>
                                        </Tooltip>
                                    </td>
                                )
                            }) 
                        }
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: '#e8e8e8', position: 'sticky', top: 42, zIndex: 3, left: 400 }}>Descrição</th>
                        { 
                            this.state.resultCenterDocs.map((resultCenter, key) => {
                                return <td style={{ position: 'sticky', top: 42, zIndex: 2, backgroundColor: 'white', textAlign: 'center', height: 42, minHeight: 42, maxHeight: 42 }}><Tooltip title={resultCenter.description}><div>{resultCenter.description.length < 19 ? resultCenter.description : resultCenter.description.slice(0, 19) + '...'}</div></Tooltip></td>
                            }) 
                        }
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: '#e8e8e8', position: 'sticky', top: 84, zIndex: 3, left: 400 }} rowSpan={2}>Código</th>
                        { 
                            this.state.resultCenterDocs.map((resultCenter, key) => {
                                return <td style={{ backgroundColor: 'white', position: 'sticky', top: 84, zIndex: 2, backgroundColor: 'white', height: 42, minHeight: 42, maxHeight: 42 }}>{resultCenter.code}</td>
                            }) 
                        }
                    </tr>

                    <tr>
                        <th style={{ backgroundColor: '#e8e8e8', position: 'sticky', top: 126, zIndex: 2, left: 0 }}>Ações</th>
                        <th style={{ backgroundColor: '#e8e8e8', position: 'sticky', top: 126, zIndex: 2, left: 200 }}>Descrição</th>
                        <th style={{ backgroundColor: '#e8e8e8', position: 'sticky', top: 126, zIndex: 1 }} colSpan={this.state.resultCenterDocs.length}>Responsáveis</th>
                    </tr>

                    { 
                        this.state.accountPlansDocs.map((accountPlan, key) => {

                            if (!accountPlan.parent_id) {
                                return (
                                    <tbody>
                                        <tr>
                                            <td style={{textAlign: 'center', position: 'sticky', zIndex: 1, backgroundColor: 'white', left: 0, minWidth: 200, maxWidth: 200 }}>
                                                
                                                { accountPlan.children && accountPlan.children.length ?
                                                
                                                    this.state.accountPlansDocs[key].toggle ? <ExpandMoreIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.toggleAccountPackage(key) }}/> : <NavigateNextIcon style={{ color: 'grey', cursor: 'pointer' }} onClick={() => { this.toggleAccountPackage(key) }}/>
                                                
                                                : <ExpandMoreIcon style={{ color: 'white' }}/> }
        
                                                <Tooltip title={'Pacotes'}>
                                                    <PeopleIcon style={{ color: 'grey', cursor: 'pointer', marginLeft: 8 }} onClick={() => { this.handleAccountPlanPackages(accountPlan) }}/>
                                                </Tooltip>

                                                <Tooltip title={'Adicionar Conta Filha'}>
                                                    <AddBoxIcon style={{ color: 'grey', cursor: 'pointer', marginLeft: 8 }} onClick={() => { this.insertChild(accountPlan.id, accountPlan.description) }}/>
                                                </Tooltip>
        
                                                <Tooltip title={'Editar'}>
                                                    <EditIcon style={{ color: Colors.success, cursor: 'pointer', marginLeft: 8 }} onClick={() => { this.setState({ accountPlanDescription: accountPlan.description, accountPlanCode: accountPlan.code, editId: accountPlan.id, editModal: true }) }}/>
                                                </Tooltip>

                                                <Tooltip title={'Remover'}>
                                                    <DeleteIcon style={{ color: Colors.error, cursor: 'pointer', marginLeft: 8 }} onClick={() => { this.removeAccountPlan(accountPlan) }}/>
                                                </Tooltip>
                                            </td>
        
                                            <td style={{ position: 'sticky', zIndex: 1, backgroundColor: 'white', left: 200 }}><Tooltip title={accountPlan.description}><div>{accountPlan.description.length < 19 ? accountPlan.description : accountPlan.description.slice(0, 19) + '...'}</div></Tooltip></td>
        
                                            <td style={{ position: 'sticky', zIndex: 1, backgroundColor: 'white', left: 400 }}>{accountPlan.code}</td>
        
                                            { 
                                                this.state.resultCenterDocs.map((resultCenter, key) => {
                                                    return this.renderPermission(resultCenter, accountPlan);
                                                }) 
                                            }
        
                                        </tr>
                                        
                                        { accountPlan.toggle ? this.renderAccountPackageChilds(accountPlan, key) : null }
        
                                    </tbody>
                                )
                            }
                        }) 
                    }

                </Table>
            </div>
        )
    }

    render() {

        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (

            <div style={{ margin: 25, borderRadius: 8 }}>

                {this.renderTable()}

                <DefaultModal loading={this.state.loadingModal} content={this.permissionModal()} title={'Permissões de Centro de Resultado'} onClose={() => { this.setState({ permissionModal: false }) }} open={this.state.permissionModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Adicionar Conta Filha'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.editModal()} title={'Editar Conta'} onClose={() => { this.setState({ editModal: false }) }} open={this.state.editModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.accountPlanPackagesModal()} title={'Pacotes da Conta'} onClose={() => { this.setState({ accountPlanPackagesModal: false }) }} open={this.state.accountPlanPackagesModal} />
            </div>
        )
    }
}