import React, { Component } from 'react';
import DefaultLoader from '../DefaultLoader';
import { Card, Grid } from '@material-ui/core';
import Firestore from '../../api/firebase/Firestore';
import Colors from '../../constants/Colors';
import SessionHelper from '../../helper/SessionHelper';
import CompanyChart from '../CompanyChart';

export default class UserDashboard extends Component {

    state = {
        loading: true,
        companyCharts: [],
        loadedCharts: [],
    }

    async componentDidMount() {

        await this.getCompanyCharts();

        this.setState({ loading: false });
    }

    async getCompanyCharts() {

        let query = await Firestore.customQuery('charts').where('id_company', '==', SessionHelper.getData().id_company).where('id_user', '==', SessionHelper.getFirebaseAuth().uid).orderBy('description', 'asc').get();
        let docs = [];
        let loadedCharts = [];

        query.forEach((doc, key) => {

            let data = { ...doc.data(), id: doc.id };
            docs.push(data);
        });

        if (docs.length) {

            loadedCharts.push(docs[0]);
            docs.splice(0, 1);
    
            this.setState({ companyCharts: docs, loadedCharts });
        }
    }

    loadNextChart() {

        let loadedCharts = this.state.loadedCharts;
        let docs = this.state.companyCharts;

        if (docs.length) {

            loadedCharts.push(docs[0]);
            docs.splice(0, 1);
    
            this.setState({ companyCharts: docs, loadedCharts });
        }
    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (

            <div style={{ width: '100%' }}>
                <Grid container spacing={1}>
                    
                    {
                        this.state.loadedCharts.map((chart, key) => {
                                
                            return (
                                <Grid item xs={6}>
                                    <Card style={{ boxShadow: Colors.boxShadow, padding: 25, minHeight: 300 }}>
                                        <CompanyChart onLoadComplete={() => { this.loadNextChart() }} id={chart.id}/>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                    {
                        this.state.companyCharts.map((chart, key) => {
                                
                            return (
                                <Grid item xs={6}>
                                    <Card style={{ boxShadow: Colors.boxShadow, padding: 25, minHeight: 300 }}>
                                        <CompanyChart id={null}/>
                                    </Card>
                                </Grid>
                            )
                        })
                    }

                </Grid>
          </div>
        )
    }
}