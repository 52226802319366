import React from "react";
import FirebaseConfig from './constants/Firebase';
import FirebaseConfigTest from './constants/FirebaseTest';
import firebase from 'firebase/app';
import 'firebase/auth'
import LoginNavigator from './navigation/LoginNavigator';
import RootNavigator from './navigation/RootNavigator';
import DefaultLoader from './components/DefaultLoader';
import SessionHelper from './helper/SessionHelper';
import Firestore from './api/firebase/Firestore';
import AdminPage from "./page/AdminPage";
import ConsultantPage from "./page/ConsultantPage";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import UserManagement from "./page/UserManagement";

function isDevelopmentMode() {
    return window.location.hostname == "localhost";
}

if (firebase.apps.length === 0) { isDevelopmentMode() == true ? firebase.initializeApp(FirebaseConfigTest) : firebase.initializeApp(FirebaseConfig) }

if(!isDevelopmentMode()) {
    Sentry.init({
        dsn: "https://767df0e79dde4cf48c9ebdd972a8e958@o913330.ingest.sentry.io/5855902",
        integrations: [new Integrations.BrowserTracing()],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
}

function authUser() {
    return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {

            if (user) {
                SessionHelper.setFirebaseAuth(user);

                let userDoc = await Firestore.getDoc('user', user.uid);
                let data;

                if (userDoc.exists) {

                    data = userDoc.data();

                    if (userDoc.data().id_company) {

                        let company = await Firestore.getDoc('company', userDoc.data().id_company);

                        if (company.exists) {

                            data.company = company.data();
                        }
                    }

                    if (userDoc.data().id_permission_group) {

                        let permission = await Firestore.getDoc('permission_group', userDoc.data().id_permission_group);

                        if (permission.exists) {

                            data.permission = permission.data();
                        }
                    }

                    if (data.company && data.company.id_plan) {

                        let plan = await Firestore.getDoc('plan', data.company.id_plan);

                        if (plan.exists) {

                            data.plan = plan.data();
                        }
                    }

                    SessionHelper.setData(data)
                }

            } else {

                SessionHelper.destroy();
            }

            resolve(user);
        });
    });
}

export default class App extends React.Component {

    state = {
        isAuthenticating: true,
        user: null,
    }

    componentDidMount() {

        authUser().then((user) => {
            this.setState({ isAuthenticating: false, user: user });
        }, (error) => {
            this.setState({ isAuthenticating: false });
            alert(error);
        });
    }

    renderPlatform() {

        let type = SessionHelper.getData().type;
        let company = SessionHelper.getData().id_company;

        if (type === 'admin' && !company) {

            return <AdminPage app={this} />

        } else if (type === 'consultant' && !company) {

            return <ConsultantPage app={this} />

        } else {

            return <RootNavigator />
        }
    }

    isUserManagement() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        let id = urlParams.get('oobCode');
        
        if (id === null) {

            return false;
        }
        
        return true;
    }

    render() {

        if (this.isUserManagement()) return <UserManagement />;
        if (this.state.isAuthenticating) return <DefaultLoader />;

        return this.state.user ? this.renderPlatform() : <LoginNavigator />;
    }
}