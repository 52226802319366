import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@material-ui/core';
import CalculusHelper from '../helper/CalculusHelper';
import TableHelper from '../helper/TableHelper';
import Keyboard from '@material-ui/icons/Keyboard';
import moment from 'moment';
import 'moment/locale/pt-br';
import DataHelper from '../helper/DataHelper';

export default class PayrollCompositionTable extends Component {
    getLabelsLookUp() {
        return {
            description: "Nome",
            dissidio: "Dissídio",
            dissidio_date: "Mês Dissídio",
            fire_date: "Desligamento em",
            hire_date: "Contratado em",
            promotion: "Promoção",
            promotion_date: "Mês Promoção",
            role: "Cargo",
            salary: "Salário Base"
        }
    }

    getTableAlign() {
        return {
            description: "left",
            dissidio: "right",
            dissidio_date: "center",
            fire_date: "center",
            hire_date: "center",
            promotion: "right",
            promotion_date: "center",
            role: "left",
            salary: "right"
        }
    }

    getFieldType() {
        return {
            description: "string",
            dissidio: "percentage",
            dissidio_date: "date",
            fire_date: "date",
            hire_date: "date",
            promotion: "percentage",
            promotion_date: "date",
            role: "string",
            salary: "money"
        }
    }

    renderField(data, type) {
        switch (type) {
            case "string":
                return data ? `${data}` : null;
            case "date":
                return data ? `${moment(data.toDate ? data.toDate() : data).format('MM/YYYY')}` : null;
            default:
                return data ? CalculusHelper.formatType(type, CalculusHelper.formatFloat(data)) : null;
        }
    }

    getEditingValue(data, type) {
        switch (type) {
            case "string":
                return `${data}`;
            case "date":
                return data;
            default:
                return Number(data);
        }
    }

    render() {
        let table;

        let rows = this.props.rows;

        table = (
            <div style={styles.tableContainer}>
                <TableContainer style={{ height: 'auto', maxHeight: (window.screen.height) - 390, width: '100%' }}>
                    <Table id={'revenue-planning'} stickyHeader>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'lightgray' }}>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Nome</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Cargo</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Salário Base</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>% Dissídio</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Mês Dissídio</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Promoção</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Mês Promoção</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Desligamento em</TableCell>
                                <TableCell align="center" style={{ backgroundColor: 'lightgray' }}>Contratado em</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {DataHelper.orderArrayToRender(rows, `description`).map((data, rowIndex) => {
                                let orderedObj = {
                                    description: data.description,
                                    role: data.role,
                                    salary: data.salary,
                                    dissidio: data.dissidio,
                                    dissidio_date: data.dissidio_date,
                                    promotion: data.promotion,
                                    promotion_date: data.promotion_date,
                                    fire_date: data.fire_date,
                                    hire_date: data.hire_date,
                                }

                                return (
                                    <TableRow>
                                        {Object.keys(orderedObj).map((key, index) => {
                                            let type = this.getFieldType()[key];
                                            let editingValue = this.getEditingValue(orderedObj[key], type);

                                            return <TableCell align={this.getTableAlign()[key]} onDoubleClick={() => { this.props.setParentState({ editingData: true, editInputIndex: index, editingElement: key, rowIndex: rowIndex, editInputValue: editingValue, editInputType: type, editingDocId: data.id }); }}>
                                                {this.props.state.editingData && index == this.props.state.editInputIndex && key == this.props.state.editingElement && rowIndex == this.props.state.rowIndex ?
                                                    this.props.renderInputEditing(editingValue, type)
                                                    : this.renderField(orderedObj[key], type)}
                                            </TableCell>
                                        })}

                                    </TableRow>
                                )

                            })}


                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        )

        return table;
    }
}

const styles = {
    tableContainer: {
        backgroundColor: '#fff',
        width: '100%',
        maxHeight: '100%',
        height: 'auto',
        borderRadius: 5
    }
}