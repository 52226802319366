import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Button, FormLabel, TextareaAutosize, TableRow, TableCell, TableContainer, TableBody, Table, TableHead, InputLabel, Tooltip, IconButton } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import DefaultSelect from '../components/DefaultSelect';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PermissionHelper from '../helper/PermissionHelper';
import AddIcon from '@material-ui/icons/Add';

export default class RevenuePlanningSettings extends Component {
    
    state = {
        docs: [],
        productsDocs: [],
        resultCentersDocs: [],
        loading: true,
        loadingModal: false,
        addModal: false,
        permissioneEdit: null,
        editId: null,
        description: ``,
        result_centers: [],
        products: []
    }

    async getDocs() {

        this.setState({ loading: true });

        let isSuperAdmin = await PermissionHelper.isSuperAdmin('result_center');
        let resultCenters = await PermissionHelper.getUserResultCenters(SessionHelper.getFirebaseAuth().uid, ['write', 'owner']);

        let query = Firestore.customQuery('revenue_planning_settings').where('id_company', '==', SessionHelper.getData().id_company).orderBy('description', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            let hasResultCenter = false;

            data.result_centers.forEach((resultCenter, key) => {

                if (resultCenters.find(item => item.id === resultCenter)) {
                    hasResultCenter = true;
                }
            });

            if (hasResultCenter || isSuperAdmin) {
                docs.push(data);
            }
        });

        this.setState({ docs: docs, loading: false });
    }

    async getProducts() {

        this.setState({ loading: true });

        let query = Firestore.customQuery('product').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc');

        query = await query.get();

        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ productsDocs: docs, loading: false });
    }

    async getResultCenters() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('result_center').where('id_company', '==', SessionHelper.getData().id_company).orderBy('code', 'asc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ resultCentersDocs: docs, loading: false });
    }

    async componentDidMount() {
        await this.getProducts();
        await this.getResultCenters();
        await this.getDocs();
    }

    async addNewDoc() {

        if (this.state.description && this.state.result_centers.length > 0 && this.state.products.length > 0 && SessionHelper.getData().id_company) {

            let data = {
                description: this.state.description,
                result_centers: this.state.result_centers,
                products: this.state.products,
                id_company: SessionHelper.getData().id_company
            };

            try {
                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'revenue_planning_settings');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.getDocs();
            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }

        } else {

            toast.warn("Preencha os campos obrigatórios", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        this.setState({
            loadingModal: false,
            addModal: false,
            description: ``,
            result_centers: [],
            products: []
        });
    }

    addModal() {
        return (
            <div>
                <DefaultInput required={true} label={'Descrição'} onBlur={(v) => { this.setState({ description: v }) }} defaultValue={this.state.description} />
                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={true}
                        disabled={false}
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.result_centers}
                        onChange={(v) => {
                            this.setState({ result_centers: v.target.value })
                        }}
                        docs={this.state.resultCentersDocs}
                        label={'Centro de Resultado*'}
                    />
                </div>

                <div style={{ marginTop: 20, flexDirection: 'row', display: 'flex' }}>
                    <DefaultSelect
                        multiple={true}
                        disabled={false}
                        search={true}
                        searchField={['description', 'code']}
                        displayField={'description'}
                        secondaryDisplay={'code'}
                        valueField={'id'}
                        value={this.state.products}
                        onChange={(v) => {
                            this.setState({ products: v.target.value })
                        }}
                        docs={this.state.productsDocs}
                        label={'Produtos*'}
                    />
                </div>

                <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', paddingTop: 50 }}>
                    <DefaultButton onClick={() => { this.addNewDoc() }} color={Colors.primary} loading={this.state.loadingModal} width={'48%'} title={'CONFIRMAR'} />
                    <DefaultButton onClick={() => { this.setState({ addModal: false, description: ``, products: [], result_centers: [] }) }} color={Colors.secondaryButton} textColor={Colors.dark} loading={this.state.loadingModal} width={'48%'} title={'CANCELAR'} />
                </div>
            </div>
        )
    }

    getColsSub() {
        return [
            { name: 'description', alias: 'Descrição', required: true, example: 'Desc. Qualquer' },
            { name: 'code', alias: 'Código', required: true, example: '42' },
            { name: 'observation', alias: 'Observação', example: 'Obs. Qualquer' }
        ];
    }

    getResultCenterLookup() {
        let docs = this.state.resultCentersDocs;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = `${doc.description} (${doc.code})`;
        });

        return lookup;
    }

    getProductLookup() {
        let docs = this.state.productsDocs;
        let lookup = {};

        docs.forEach((doc, key) => {
            lookup[doc.id] = `${doc.description} (${doc.code})`;
        });

        return lookup;
    }

    render() {
        return this.state.loading ? <div><DefaultLoader loadingText={this.state.loadingText} /></div> : (
            <div style={styles.container}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: 'white', padding: 15, boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px', borderRadius: 5 }} className={'header-actions-buttons'}>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        <DefaultButton leftIcon={<AddIcon/>} onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} className={'header-actions-buttons'}>
                        
                    </div>
                </div> 

                <DefaultTable
                    title={'Modelos de Planejamento de Receitas'}
                    marginTop={10}
                    actions={[
                        {
                            icon: FileCopyIcon,
                            tooltip: 'Copiar configuração',
                            onClick: (event, rowData) => { this.setState({ addModal: true, products: rowData.products, result_centers: rowData.result_centers, description: `${rowData.description} (Cópia)` }) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Descrição', field: 'description' },
                        {
                            title: 'Centro de Resultado',
                            field: 'result_centers',
                            editComponent: props => <DefaultSelect width={500} secondaryDisplay={'code'} multiple={true} search={true} searchField={'description'} valueField={'id'} displayField={'description'} onChange={(v) => {
                                v.target.value.length > 0 ? props.onChange(v.target.value) : toast.warn("Campo não pode ser vazio", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                return;
                            }} value={props.value} docs={this.state.resultCentersDocs} />,
                            render: rowData => <Tooltip title={rowData.result_centers.map((id, key) => this.getResultCenterLookup()[id]).join(', ')}><div>{rowData.result_centers.length} centros de resultado vinculados</div></Tooltip>
                        },
                        {
                            title: 'Produtos',
                            field: 'products',
                            editComponent: props => <DefaultSelect width={500} secondaryDisplay={'code'} multiple={true} search={true} searchField={'description'} valueField={'id'} displayField={'description'} onChange={(v) => {
                                v.target.value.length > 0 ? props.onChange(v.target.value) : toast.warn("Campo não pode ser vazio", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                return;
                            }} value={props.value} docs={this.state.productsDocs} />,
                            render: rowData => <Tooltip title={rowData.products.map((id, key) => this.getProductLookup()[id]).join(', ')}><div>{rowData.products.length} produtos ou serviços vinculados</div></Tooltip>,
                            filtering: false
                        },
                    ]}
                    data={this.state.docs}
                    onRowUpdate={async (oldData, newData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev[index] = newData;

                        this.setState({ docs: prev })
                        if (oldData.id) {

                            let data = {
                                description: newData.description,
                                result_centers: newData.result_centers,
                                products: newData.products
                            };

                            await Firestore.update(data, 'revenue_planning_settings', oldData.id);

                        }

                        toast.success("Editado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        return prev;
                    }}
                    onRowDelete={async (oldData) => {
                        let prev = this.state.docs;
                        const index = prev.indexOf(oldData);

                        prev.splice(index, 1);

                        this.setState({ docs: prev });

                        if (oldData.id) {
                            await Firestore.delete('revenue_planning_settings', oldData.id);

                            toast.success("Removido com sucesso", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            toast.error("Não foi possível remover", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }}
                />
                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Nova Configuração de Planejamento de Receita'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal} />

            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
