export default {
    primary: '#B30000',
    secondaryButton: '#d5d5d5',
    contrast: '#D90000',
    disabled: '#585858',
    selected: '#FFFFFF',
    notSelected: '#b4bcc8',
    dark: '#22262E',
    light: '#FFFFFF',
    secondary: '#FFFFFF',
    background: '#FAFAFA',
    boxShadow: 'rgba(50, 50, 50, 0.1) 1px 1px 10px 0px',
    error: '#d74d4e',
    success: '#59a63d',
    danger: 'orange',
    info: '#17a2b8',
    report: {
        green: {
            background: '#dff0d8',
            text: '#59a63d'
        },
        red: {
            background: '#f2dede',
            text: '#d74d4e'
        },
        default: {
            background: 'white',
            text: ''
        },
    }
}

// Paleta SIG
// #D90000 #B30000 #000000 #333333 #585858 #22262E #b4bcc8 #141518 #FFFFFF